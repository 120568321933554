import { connect } from 'react-redux'
import React from 'react';
import { useNavigate} from "react-router-dom";
import { Paper, keyframes,Blockquote, createStyles,Container, Title, Group, Text, ThemeIcon, rem, Space} from '@mantine/core';
import { useMediaQuery} from '@mantine/hooks';

import '../index.css';
import { IconCheck, IconArrowNarrowLeft, IconArrowNarrowRight, IconBrandReactNative, IconBrandGoogle, IconBrandSpotify} from '@tabler/icons-react';

import {ReactComponent as Iosappsvg} from './svgs/iosapp.svg';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';

const float_top = keyframes({
    '0%': {transform:'translatey(0px)'},
    '50%': {transform:'translatey(-10px)'},
    '100%': {transform: 'translatey(0px)'}
  });

const useStyles = createStyles((theme) => ({
    ios_light : {
        animation: `${float_top} 6s ease-in-out infinite`,
        '#iphone-button': {fill: theme.colors.dark[9], stroke: theme.colors.dark[9]},
        '#iphone_card-7':{fill: theme.colors.red[7], stroke: theme.colors.red[9]},
    },
    ios_dark: {
        animation: `${float_top} 6s ease-in-out infinite`,
        '#iphone_bezel': {stroke: theme.colors.yellow[4]},
        '#iphone_frame': {stroke: theme.colors.yellow[4]},
        '#iphone-button': {fill: theme.colors.yellow[4], stroke: theme.colors.yellow[4]},
        '#iphone_card-7':{fill: theme.colors.grape[4], stroke: theme.colors.grape[6]},
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,
        [theme.fn.smallerThan('55em')]: {
            maxWidth: '100%',
            marginRight: 0,
          },
      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
      },

      alertHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).color : theme.fn.variant({ variant: 'fill', color: 'red' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).background : theme.fn.variant({ variant: 'light', color: 'red' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).color : theme.fn.variant({ variant: 'light', color: 'red' }).color, }
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
}))

function IOS({ld_theme}) {
    const { classes, cx, theme } = useStyles();
    const isMobile = useMediaQuery("(max-width: 55em)");
    let navigate = useNavigate();
    

    function addCheck() {
        return(
            <ThemeIcon size={20} variant="filled" color={ld_theme.theme == "dark" ? "#BE4BDB" : "#FA5252"}>
                <IconCheck size={rem(12)} stroke={3} />
            </ThemeIcon>
        )
    }
   return (
    <div>
        <Container>
            <div className={classes.inner}>
            <div className={classes.content}>
                <Title className={classes.title}>
                Painless, Uncomplicated<br /><span className={classes.highlight}>Data Entry</span>
                </Title>
                <Text mt="md" fz="md">
                Throughout my <span className={classes.textHighlight}><a href='https://www.linkedin.com/in/stev0/'>data career</a></span>, I’ve stumbled across many projects - and even built my own POCs - that required some sort of UI or forms-based workflow to piece together transactional or reference data to build business or asset data pools.  And for such  an important function to any business - the data entry piece is most often overlooked (I’m looking at you Enterprise software).
                </Text>
            </div>
                {isMobile ? null : <Iosappsvg width='50%'  viewBox="20 40 200 170" className={ld_theme.theme == 'dark' ? classes.ios_dark : classes.ios_light}/> }
            </div>
            {isMobile ? <Group position="center"><Iosappsvg height={rem(200)} width={rem(300)} viewBox="70 70 100 150" className={ld_theme.theme == 'dark' ? classes.ios_dark : classes.ios_light}/></Group>: null}
            <Text mt="md" fz="md">
                Allow me to step up on my soapbox for a moment; I tend to have some strong opinions about this.  Businesses run on data, so I think it’s imperative that data entry needs to stay focused on making it as uncomplicated and prioritized as possible.  From the Forms - I’m talking your text inputs, drop-downs, focused controls, helper lookups, multi-selects, standardization formulas, business rules, form feedback user errors, success messages - to the prioritized attention to different types of data, Reference, Functional, Transactional… It all matters, and it all plays a role in building a sort of “data trust” that keeps everyone engaged in building a culture around data quality.  From here, I’m going to deep-dive into 2 main tenets of data entry:  
                <Space h="md" />
                <b>The Business Owns the Data</b> That much is true; however, how often does it ever occur that the business also maintains that data?  Too often, I typically see different companies engage in a more ‘centralized’ data entry organization.  The experts of the data provide the policy, training, but the engagement in data maintenance is usually low.  Ever wonder why?  My opinion is it can fall into two (2) buckets:  poor user experience or lack of efficient data entry.  Without a doubt, both can make data an experience that’s just not rewarding.  If it’s running the business, then let’s treat it like it’s a primary focus.  
                <Space h="md" />
                <b>Reference Data Reigns Supreme</b> It doesn’t matter what type business you’re running, if your core reference data (often referred to as master data) is not well governed, your entire data pool is potentially compromised.  I’ve often witnessed projects, bumped up against impossible timelines, just plan for getting some sort of data migration pulled in and an off-the-shelf-product stood up as fast as possible - neglecting some core tenets of data governance.  And when production dates have passed, the company declares victory for a release, can you guess what happens next?  There’s typically a decline in data quality.  And for all the types of data that gets questioned the last position any business wants to be in is to question its reference data - quality or integrity. 
                <Space h="md" />
                Bringing it back around to the iOS App, I wanted the data entry piece to follow in what I have strong opinions about - an easy, uncomplicated data entry application for my household’s films we watch, the restaurants we eat at, and the artists we listen to.  The input or form controls will have the user experience in mind — having no more than 2-3 combinations of a hand gesture, text entry, or a press of a button to enter the data.  The controls are logical, obvious, and give instant feedback when errors occur or if saves are successful, and the app will rely on API integrations for Reference Data information to provide search results, detailed descriptions, reviews, and more.  
                </Text>
                <Space h="xl" />
                <Title className={classes.subHeading}>
                iOS Tech Stack
                </Title>
                <Text mt="md" fz="md">
                For the user experience piece of the UI, I used React Native.  I'm familiar with React, so it felt natural to use React Native to build the front-end for the app UI.  The only tricky part with getting familiar with building the App was its push notifications.  For that piece, I used the 3rd party provider OneSignal (can't recommend them enough!).
                <Space h="xl" />
                <Group position="center">
                <IconBrandReactNative height={isMobile ? rem(60): rem(75)} width={isMobile ? rem(100): rem(200)} color={ld_theme.theme == 'dark' ? darkTheme : lightTheme}/>
                <svg xmlns="http://www.w3.org/2000/svg" height={isMobile ? rem(60): rem(60)}  width={isMobile ? rem(100): rem(200)}>
                    <path fill={ld_theme.theme == 'dark' ? darkTheme : lightTheme} d="M27.948 0C12.498.03-.086 12.745 0 28.233a28.11 28.11 0 0 0 7.274 18.713 27.978 27.978 0 0 0 17.883 9.052.321.321 0 0 0 .355-.322V28.072h-2.176a.322.322 0 0 1-.322-.323v-4.356a.323.323 0 0 1 .322-.322h6.83a.321.321 0 0 1 .322.322v32.283a.323.323 0 0 0 .354.322 27.98 27.98 0 0 0 18.416-9.657 28.116 28.116 0 0 0 6.704-19.721 28.102 28.102 0 0 0-8.7-18.922A27.965 27.965 0 0 0 27.947 0Zm7.958 49.745a.32.32 0 0 1-.394-.156.323.323 0 0 1-.035-.149v-4.607a.485.485 0 0 1 .276-.438 18.056 18.056 0 0 0 8.505-8.487 18.12 18.12 0 0 0 1.308-11.959 18.084 18.084 0 0 0-6.467-10.134 18.005 18.005 0 0 0-11.378-3.822c-9.579.147-17.439 7.89-17.743 17.49a18.135 18.135 0 0 0 2.626 9.99 18.07 18.07 0 0 0 7.65 6.922.483.483 0 0 1 .277.438v4.607a.325.325 0 0 1-.278.32.321.321 0 0 1-.152-.015 23.062 23.062 0 0 1-11.015-8.543A23.152 23.152 0 0 1 4.99 27.859C5.1 15.327 15.315 5.093 27.821 5c12.776-.103 23.195 10.287 23.195 23.07 0 9.942-6.299 18.435-15.11 21.674Z"></path>
                </svg>
                </Group>
                <Space h="xl" />
                For the reference data integration, I used three (3) APIs for Restaurants, Films, and Artists: Google Place API, The Movie Database, and Spotify
                <Space h="xl" />
                <Group position="center">
                <IconBrandGoogle height={isMobile ? rem(60): rem(75)} width={isMobile ? rem(100): rem(200)} color={ld_theme.theme == 'dark' ? darkTheme : lightTheme}/>
                <svg viewBox='20 -30 100 150' height={isMobile ? rem(60): rem(60)}  width={isMobile ? rem(100): rem(200)}>
                    <path xmlns="http://www.w3.org/2000/svg" fill={ld_theme.theme == 'dark' ? darkTheme : lightTheme} d="M105.67,36.06h66.9A17.67,17.67,0,0,0,190.24,18.4h0A17.67,17.67,0,0,0,172.57.73h-66.9A17.67,17.67,0,0,0,88,18.4h0A17.67,17.67,0,0,0,105.67,36.06Zm-88,45h76.9A17.67,17.67,0,0,0,112.24,63.4h0A17.67,17.67,0,0,0,94.57,45.73H17.67A17.67,17.67,0,0,0,0,63.4H0A17.67,17.67,0,0,0,17.67,81.06ZM10.41,35.42h7.8V6.92h10.1V0H.31v6.9h10.1Zm28.1,0h7.8V8.25h.1l9,27.15h6l9.3-27.15h.1V35.4h7.8V0H66.76l-8.2,23.1h-.1L50.31,0H38.51ZM152.43,55.67a15.07,15.07,0,0,0-4.52-5.52,18.57,18.57,0,0,0-6.68-3.08,33.54,33.54,0,0,0-8.07-1h-11.7v35.4h12.75a24.58,24.58,0,0,0,7.55-1.15A19.34,19.34,0,0,0,148.11,77a16.27,16.27,0,0,0,4.37-5.5,16.91,16.91,0,0,0,1.63-7.58A18.5,18.5,0,0,0,152.43,55.67ZM145,68.6A8.8,8.8,0,0,1,142.36,72a10.7,10.7,0,0,1-4,1.82,21.57,21.57,0,0,1-5,.55h-4.05v-21h4.6a17,17,0,0,1,4.67.63,11.66,11.66,0,0,1,3.88,1.87A9.14,9.14,0,0,1,145,59a9.87,9.87,0,0,1,1,4.52A11.89,11.89,0,0,1,145,68.6Zm44.63-.13a8,8,0,0,0-1.58-2.62A8.38,8.38,0,0,0,185.63,64a10.31,10.31,0,0,0-3.17-1v-.1a9.22,9.22,0,0,0,4.42-2.82,7.43,7.43,0,0,0,1.68-5,8.42,8.42,0,0,0-1.15-4.65,8.09,8.09,0,0,0-3-2.72,12.56,12.56,0,0,0-4.18-1.3,32.84,32.84,0,0,0-4.62-.33h-13.2v35.4h14.5a22.41,22.41,0,0,0,4.72-.5,13.53,13.53,0,0,0,4.28-1.65,9.42,9.42,0,0,0,3.1-3,8.52,8.52,0,0,0,1.2-4.68A9.39,9.39,0,0,0,189.66,68.47ZM170.21,52.72h5.3a10,10,0,0,1,1.85.18,6.18,6.18,0,0,1,1.7.57,3.39,3.39,0,0,1,1.22,1.13,3.22,3.22,0,0,1,.48,1.82,3.63,3.63,0,0,1-.43,1.8,3.4,3.4,0,0,1-1.12,1.2,4.92,4.92,0,0,1-1.58.65,7.51,7.51,0,0,1-1.77.2h-5.65Zm11.72,20a3.9,3.9,0,0,1-1.22,1.3,4.64,4.64,0,0,1-1.68.7,8.18,8.18,0,0,1-1.82.2h-7v-8h5.9a15.35,15.35,0,0,1,2,.15,8.47,8.47,0,0,1,2.05.55,4,4,0,0,1,1.57,1.18,3.11,3.11,0,0,1,.63,2A3.71,3.71,0,0,1,181.93,72.72Z"/></svg>
                <IconBrandSpotify height={isMobile ? rem(60): rem(75)} width={isMobile ? rem(100): rem(200)} color={ld_theme.theme == 'dark' ? darkTheme : lightTheme}/>
                </Group>
                <Space h="xl" />
                The goal of this entire tech stack was to custom build each piece, except the push notifications.  Learning how to build each screen, feature, service and even the user authentication has provided myself a good understanding on how, both, the user experience and the respect for reference data can be core to even the most simple of applications.  
                <Space h="md" />
                I often favor custom-built applications to off-the-shelf products, just because it forces each project to focus on data entry and user experiences — instead of relying on some other product to provide it for you.  Because even the most expensive data applications can still miss out on the core tenets I mention.  To quote a colleague from a prior gig:
                
                <Blockquote color={ld_theme.theme == "dark" ? "yellow" : "blue"} cite="- Former Colleague">
                You never want to be in the position where we bought a computer, and we use it like a pencil.
                </Blockquote>
                <Space h="md" />
                The first major feature in the iOS app to explore is the <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Experience</a></span>
                </Text>
          
          <Space h="xl" />
            {isMobile ?
          <div>  

          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">About - @docs/</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/eatitems')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>Eat Items - @iosapp/eatitems</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">About - @docs/</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/eatitems')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>Eat Items - @iosapp/eatitems</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(IOS)