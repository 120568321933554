import { useMantineColorScheme, ActionIcon, Group } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons-react';
import { connect } from 'react-redux'

const SwitchToggle = ({ld_theme, dispatch}) => {

  function toolbarToggle(event) {
    dispatch({
      type: 'TOGGLE_THEME',
      payload: {...ld_theme, theme: event}
    })
  }

  return (
    <Group position="center" my="xl">
      <ActionIcon
            variant="outline"
            color={ld_theme.theme == 'dark' ? 'yellow' : 'blue'}
        onClick={() => toolbarToggle(ld_theme.theme == 'light' ? 'dark' : 'light')}
        size="lg"
      >
        {ld_theme.theme == 'dark' ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
      </ActionIcon>
    </Group>
  );
}

const mapStateToProps = state => {
    return { ld_theme: state.ld_theme}
  }

export default connect(mapStateToProps)(SwitchToggle);