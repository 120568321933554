import { connect } from 'react-redux'
import React from 'react';
import { useNavigate} from "react-router-dom";
import { keyframes, SimpleGrid, createStyles, Container, Title, Group, Tabs, Text, ThemeIcon, rem, Space, Divider, Paper} from '@mantine/core';
import { useMediaQuery} from '@mantine/hooks';

import '../index.css';
import { IconArrowNarrowRight, IconArrowNarrowLeft, IconBrandAmazon, IconBrandApple, IconBrandReact, IconCheck} from '@tabler/icons-react';
import {ReactComponent as FullArchitecture} from './svgs/full_architecture.svg';
import {ReactComponent as Watchsvg} from './svgs/tv.svg';
import {ReactComponent as Musicsvg} from './svgs/music.svg';
import {ReactComponent as Eatsvg} from './svgs/eat.svg';

const float_top = keyframes({
    '0%': {transform:'translatey(0px)'},
    '50%': {transform:'translatey(-10px)'},
    '100%': {transform: 'translatey(0px)'}
  });

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';

const useStyles = createStyles((theme) => ({
    ios : {
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#google_stroke':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_google': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_tmdb': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#tmdb_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_to_eats_step': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#eat_step': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#eats_get_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#eats_image_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#eats_verify_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#eats_reviewers_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#eat_step_to_google': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_to_watch_step': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#watch_step': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#watch_get_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#watch_image_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#watch_verify_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#watch_reviewers_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api-2': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#spotify_outer_circle':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#spotify_band_1':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#spotify_band_2':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#spotify_band_3':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#music_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#musicget_to_spotify': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#music_get_to_s3_temp': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#s3_music_tmp': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#music_polling_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#musicpolling_to_s3_music_tmp':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    datafeed : {
        '#feed_generator': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#feedgenerator_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#s3_landing': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme}, 
        '#landing_to_filewatcheer': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme}, 
        '#file_watcher_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#filewatcher_to_404': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#filewatcher_to_bronze-2': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#path_to_404-start': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#path_to_404': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#discard_folder_1_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#discard_folder_2_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#bronze_layer_1_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#bronze_layer_2_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#bronze_to_transform': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#file_transform_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#transform_to_gold': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#gold_layer_1_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#gold_layer_2_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    frontend : {
        '#laptop_screen': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#laptop_base': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dash_api_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#laptop_to_dashapi': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme}, 
        '#dashapi_to_dash': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dash_to_gold': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dashboard_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme}, 
        '#gold_layer_1_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#gold_layer_2_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    tv_light : {
        '#base': {fill: theme.colors.lime[8], stroke: theme.colors.lime[8]},
        '#side': {fill: theme.colors.lime[8], stroke: theme.colors.lime[8]},
        '#base_accent': {fill: 'black', stroke: theme.colors.lime[9]},
        '#front': {fill: theme.colors.cyan[9], stroke: theme.colors.lime[5]},
        '#bunnyears': {stroke: 'black'},
        '#screen': {fill: theme.colors.blue[6], stroke: theme.colors.blue[9]},
        '#knob-2': {fill: theme.colors.cyan[9]},
        '#knob-4': {fill: theme.colors.cyan[9]},
        '#knob_background': {fill: 'black', stroke: 'black'},
        '#knob_background-2': {fill: 'black', stroke: 'black'},
        '#switch': {stroke: theme.colors.yellow[3]},
        '#switch-2': {stroke: theme.colors.yellow[3]},
        '#switch-3': {stroke: theme.colors.yellow[3]},
        '#switch-4': {stroke: theme.colors.yellow[3]},

    },
    tv_dark : {
        '#base': {fill: theme.colors.grape[8], stroke: theme.colors.grape[8]},
        '#side': {fill: theme.colors.grape[8], stroke: theme.colors.grape[8]},
        '#base_accent': {fill: 'black', stroke: theme.colors.grape[9]},
        '#front': {fill: theme.colors.gray[8], stroke: theme.colors.grape[5]},
        '#bunnyears': {stroke: theme.colors.gray[7]},
        '#screen': {fill: theme.colors.yellow[6], stroke: theme.colors.yellow[9]},
        '#knob-2': {fill: theme.colors.gray[8]},
        '#knob-4': {fill: theme.colors.gray[8]},
        '#knob_background': {fill: 'black', stroke: 'black'},
        '#knob_background-2': {fill: 'black', stroke: 'black'},
        '#switch': {stroke: theme.colors.red[5]},
        '#switch-2': {stroke: theme.colors.red[5]},
        '#switch-3': {stroke: theme.colors.red[5]},
        '#switch-4': {stroke: theme.colors.red[5]},
    },
    music_light : {
        animation: `${float_top} 6s ease-in-out infinite`,
        '#accent':{fill: 'white', stroke: 'white'},
        '#accent-2':{fill: 'white', stroke: 'white'},
        '#headband': {fill: theme.colors.gray[8]},
        '#headband-2': {fill: theme.colors.gray[8]},
        '#headband_accent': {stroke: theme.colors.blue[6]},
        '#outer_accent_button': {fill: theme.colors.blue[6]}, 
        '#outer_accent': {fill: theme.colors.lime[6], stroke: theme.colors.blue[7]},
        '#inner_light':{fill: 'black', stroke: theme.colors.gray[8]},
        '#outer_light':{fill: theme.colors.lime[7], stroke: theme.colors.lime[7]},
        '#outer_light-2': {fill: theme.colors.lime[7], stroke: theme.colors.lime[7]},
        '#inner_dark':{fill: theme.colors.gray[8], stroke: theme.colors.gray[8]},
        '#inner_dark-2': {fill: theme.colors.gray[9]},
        '#outer_dark': {fill: theme.colors.lime[9], stroke: theme.colors.lime[9]},
        '#outer_dark-2':  {fill: theme.colors.lime[9], stroke: theme.colors.lime[9]},
    },
    music_dark: {
        animation: `${float_top} 6s ease-in-out infinite`,
        '#accent':{fill: 'white', stroke: 'white'},
        '#accent-2':{fill: 'white', stroke: 'white'},
        '#headband': {fill: theme.colors.gray[8]},
        '#headband-2': {fill: theme.colors.gray[8]},
        '#headband_accent': {stroke: theme.colors.yellow[4]},
        '#outer_accent_button': {fill: theme.colors.yellow[5]}, 
        '#outer_accent': {fill: theme.colors.grape[5], stroke: theme.colors.yellow[5]},
        '#inner_light':{fill: 'black', stroke: theme.colors.gray[8]},
        '#outer_light':{fill: theme.colors.grape[7], stroke: theme.colors.grape[7]},
        '#outer_light-2': {fill: theme.colors.grape[7], stroke: theme.colors.grape[7]},
        '#inner_dark':{fill: theme.colors.gray[8], stroke: theme.colors.gray[8]},
        '#inner_dark-2': {fill: theme.colors.gray[9]},
        '#outer_dark': {fill: theme.colors.grape[9], stroke: theme.colors.grape[9]},
        '#outer_dark-2':  {fill: theme.colors.grape[9], stroke: theme.colors.grape[9]},
    },
    eat_light: {
        '#pepp-1':{fill: theme.colors.red[6]},
        '#pepp-1-shadow':{fill: theme.colors.red[9]},
        '#pepp-2':{fill: theme.colors.red[6]},
        '#pepp-2-shadow':{fill: theme.colors.red[9]},
        '#pepp-3':{fill: theme.colors.red[6]},
        '#pepp-3-shadow':{fill: theme.colors.red[9]},
        '#cheese':{fill: theme.colors.yellow[4]},
        '#drip-1':{fill: theme.colors.yellow[4]},
        '#drip-2':{fill: theme.colors.yellow[4]},
        '#sauce':{fill: theme.colors.yellow[6]},
        '#crust':{fill: theme.colors.orange[7]},
        '#hot-steam-1': {stroke: theme.colors.gray[6]},
        '#hot-steam-2': {stroke: theme.colors.gray[6]},
        '#glass-opening': {stroke: theme.colors.gray[6]},
        '#straw-outline': {stroke:theme.colors.lime[6]},
        '#right-ankle': {stroke: theme.colors.gray[6]},
        '#left-ankle': {stroke: theme.colors.gray[6]},
        '#ball': {stroke: theme.colors.gray[6]},
        '#juice-top': {fill:theme.colors.blue[8]},
        '#glass-body': {stroke: theme.colors.gray[6]},
        '#drink-body': {fill:theme.colors.blue[5]},
        '#glass-base': {stroke: theme.colors.gray[6]},
        '#straw-band-1': {stroke:theme.colors.lime[6]},
        '#straw-band-2': {stroke:theme.colors.lime[6]},
        '#straw-band-3': {stroke:theme.colors.lime[6]},
        '#straw-band-4': {stroke:theme.colors.lime[6]},
        '#straw-band-5': {stroke:theme.colors.lime[6]},
        '#straw-band-6': {stroke:theme.colors.lime[6]},
        '#straw-band-7': {stroke:theme.colors.lime[6]},
        '#straw-band-8': {stroke:theme.colors.lime[6]},
        '#straw-band-9': {stroke:theme.colors.lime[6]},
        '#straw-band-10': {stroke:theme.colors.lime[6]},
        '#straw-band-11': {stroke:theme.colors.lime[6]},
        '#straw-band-12': {stroke:theme.colors.lime[6]},
        '#straw-band-13': {stroke:theme.colors.lime[6]},
        '#straw-band-14': {stroke:theme.colors.lime[6]},
        '#umbrella-stick': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-1': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-2': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-3': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-4': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-5': {stroke: theme.colors.gray[7]},
        '#bubble-1': {stroke: theme.colors.gray[3]},
        '#bub-2': {stroke: theme.colors.gray[3]},
        '#bub-3': {stroke: theme.colors.gray[3]},
        '#bub-4': {stroke: theme.colors.gray[3]},
        '#bub-5': {stroke: theme.colors.gray[3]},
        '#bub-6': {stroke: theme.colors.gray[3]},
    },
    eat_dark: {
        //outline: '3px solid green',
        '#pepp-1':{fill: theme.colors.red[5]},
        '#pepp-1-shadow':{fill: theme.colors.red[9]},
        '#pepp-2':{fill: theme.colors.red[5]},
        '#pepp-2-shadow':{fill: theme.colors.red[9]},
        '#pepp-3':{fill: theme.colors.red[5]},
        '#pepp-3-shadow':{fill: theme.colors.red[9]},
        '#cheese':{fill: theme.colors.yellow[3]},
        '#drip-1':{fill: theme.colors.yellow[3]},
        '#drip-2':{fill: theme.colors.yellow[3]},
        '#sauce':{fill: theme.colors.yellow[5]},
        '#crust':{fill: theme.colors.orange[6]},
        '#hot-steam-1': {stroke: theme.colors.gray[5]},
        '#hot-steam-2': {stroke: theme.colors.gray[5]},
        '#glass-opening': {stroke: theme.colors.gray[4]},
        '#straw-outline': {stroke:theme.colors.yellow[5]},
        '#right-ankle': {stroke: theme.colors.gray[4]},
        '#left-ankle': {stroke: theme.colors.gray[4]},
        '#ball': {stroke: theme.colors.gray[4]},
        '#juice-top': {fill:theme.colors.grape[8]},
        '#glass-body': {stroke: theme.colors.gray[4]},
        '#drink-body': {fill:theme.colors.grape[5]},
        '#glass-base': {stroke: theme.colors.gray[4]},
        '#straw-band-1': {stroke:theme.colors.yellow[5]},
        '#straw-band-2': {stroke:theme.colors.yellow[5]},
        '#straw-band-3': {stroke:theme.colors.yellow[5]},
        '#straw-band-4': {stroke:theme.colors.yellow[5]},
        '#straw-band-5': {stroke:theme.colors.yellow[5]},
        '#straw-band-6': {stroke:theme.colors.yellow[5]},
        '#straw-band-7': {stroke:theme.colors.yellow[5]},
        '#straw-band-8': {stroke:theme.colors.yellow[5]},
        '#straw-band-9': {stroke:theme.colors.yellow[5]},
        '#straw-band-10': {stroke:theme.colors.yellow[5]},
        '#straw-band-11': {stroke:theme.colors.yellow[5]},
        '#straw-band-12': {stroke:theme.colors.yellow[5]},
        '#straw-band-13': {stroke:theme.colors.yellow[5]},
        '#straw-band-14': {stroke:theme.colors.yellow[5]},
        '#umbrella-stick': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-1': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-2': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-3': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-4': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-5': {stroke: theme.colors.gray[4]},
        '#bubble-1': {stroke: theme.colors.gray[2]},
        '#bub-2': {stroke: theme.colors.gray[2]},
        '#bub-3': {stroke: theme.colors.gray[2]},
        '#bub-4': {stroke: theme.colors.gray[2]},
        '#bub-5': {stroke: theme.colors.gray[2]},
        '#bub-6': {stroke: theme.colors.gray[2]},
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {

      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
}))

function About({ld_theme}) {
    const { classes, cx, theme } = useStyles();
    const isMobile = useMediaQuery("(max-width: 55em)");
    let navigate = useNavigate();
    
    function addCheck() {
        return(
            <ThemeIcon size={20} variant="filled" color={ld_theme.theme == "dark" ? "#BE4BDB" : "#FA5252"}>
                <IconCheck size={rem(12)} stroke={3} />
            </ThemeIcon>
        )
    }
   return (
    <div>
        <Container>
                <Title className={classes.title}>
                So...<br /> Why build something like this?
                </Title>
                <Text mt="md" fz="md">
                The age-old thought experiment, "What do you or could you watch on repeat in your home?” was the phrase that hooked me into starting this project. A simple ice-breaker of a question really had myself thinking in steps from the simple thought experiments to… Well… How would I be able to track something like that? Wouldn't it be cool if I could use data to show what I constantly watch on repeat in our home? ...
                <Space h="md" />
                And that one thought experiment is what led me through the journey you’re reading now.  I’m always fascinated with building and learning new programming languages, taking advantage of cutting-edge technologies to solve even the most simple questions.  But this, documented, App Ecosystem steps into my full-stack approach in using a variety of services to solve just the simple question from the first paragraph - track my household’s weekly Films/Shows we watch, the Restaurants we have dates at, and the trending patterns of Artists we listen to each week.
                </Text>
            <Space h="xl" />
            <Group position="center">
                <Eatsvg height={isMobile ? rem(80): rem(100)} width={isMobile ? rem(100): rem(200)} viewBox="70 90 100 150" className={ld_theme.theme == 'dark' ? classes.eat_dark : classes.eat_light}/>
                <Watchsvg height={isMobile ? rem(80) : rem(100)} width={isMobile ? rem(110) : rem(200)} viewBox="70 160 100 150" className={ld_theme.theme == 'dark' ? classes.tv_dark : classes.tv_light} />
                <Musicsvg height={isMobile ? rem(80): rem(100)} width={isMobile ? rem(82) : rem(200)} viewBox="-20 0 300 300" className={ld_theme.theme == 'dark' ? classes.music_dark : classes.music_light}/>
            </Group>
            <Space h="xl" />
            <Text mt="md" fz="md">
            So, you’ve got that what… What about the How?  The entire project is built as a a server-less solution using AWS Services to build a tech backbone that rests on an approach to mimic a data lake of sorts.  The focus, really, is to develop for three core features:
            <Space h="md" />
            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} spacing={50}>
            <div className={classes.feature}>
                <div className={classes.overlay} />
                <div className={classes.featureContent}>
                    <IconBrandApple variant= "light" size={rem(38)} className={classes.featureIcon} stroke={1.5} />
                    <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                    Easy-to-Use Data Entry
                    </Text>
                    <Text fz="sm">
                    Built for capturing our Household’s Watches, Eats, and Listens throughout each week from our personal devices
                    </Text>
                </div>
            </div> 
            <div className={classes.feature}>
                <div className={classes.overlay} />
                <div className={classes.featureContent}>
                    <IconBrandAmazon size={rem(38)} className={classes.featureIcon} stroke={1.5} />
                    <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                    Data Feed
                    </Text>
                    <Text fz="sm">
                    Weekly ETL job to move data from our central data capture database to organized data files for history and reporting
                    </Text>
                </div>
            </div> 
            <div className={classes.feature}>
                <div className={classes.overlay} />
                <div className={classes.featureContent}>
                    <IconBrandReact size={rem(38)} className={classes.featureIcon} stroke={1.5} />
                    <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                   Dashboard
                    </Text>
                    <Text fz="sm">
                    Front-end access to those folks, external to the Household, to view weekly data updates, provided by the data feed
                    </Text>
                </div>
            </div> 
            </SimpleGrid>
            <Space h="xl" />
            <Space h="xl" />
            <Title className={classes.subHeading}>
                Using this Documentation
            </Title>
            <Space h="md" />
            As I mentioned before, these features will be thoroughly documented as you move through each of the core features.  Each section will attempt its best at describing user-flows, example screens, and providing all the code used to build each core component with a summarized, interactive Tech Architecture diagram (like the one below) that you can use to view some really in-depth elaborations how I’m really making this all work.
            </Text> 
            <Space h="md" />
            It’s time to Nerd Out.  Use the tabs below to view the diagrams and display the full picture of services in detail for each feature (highlighted)
            <Space h="sm" />
            <Space h="md" />
            <Tabs color={ld_theme.theme == "dark" ? "grape":"red"}variant="default" defaultValue="iOS">
            <Tabs.List grow>
                <Tabs.Tab value="iOS" icon={<IconBrandApple size="0.8rem" />}>iOS App</Tabs.Tab>
                <Tabs.Tab value="dataFeed" icon={<IconBrandAmazon size="0.8rem" />}>Data Feed</Tabs.Tab>
                <Tabs.Tab value="frontEnd" icon={<IconBrandReact size="0.8rem" />}>Frontend </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="iOS" pt="xs">
            <div style={{textAlign: 'center'}} className={classes.ios}>
                <FullArchitecture height={!isMobile ? 350: 435} viewBox="0 0 250 295"/>
            </div>
            </Tabs.Panel>
            <Tabs.Panel value="dataFeed" pt="xs">
            <div style={{textAlign: 'center'}} className={classes.datafeed}>
                <FullArchitecture height={!isMobile ? 350: 435} viewBox="0 0 250 295"/>
            </div>
            </Tabs.Panel>
            <Tabs.Panel value="frontEnd" pt="xs">
            <div style={{textAlign: 'center'}} className={classes.frontend}>
                <FullArchitecture height={!isMobile ? 350: 435} viewBox="0 0 250 295"/>
            </div>
            </Tabs.Panel>
            </Tabs>
            <Space h="md" />
            <Divider
                my="xs"
                variant="dashed"
            />
            <Text mt="md" fz="md">
            In each major section, I walk through the through process of how I built each feature, give my opinions on some topics such as Data Entry, Data Feeds, and document my approach to help you follow the "what" and "why" for each piece of functionality.  Ready to dive in?  Let’s start where it all began… the <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp')}>iOS App</a></span>
            </Text>
            <Space h="xl" />
            {isMobile ?
          <div> 
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">Dashboard - @dashboard/</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>iOS App - @docs/iosapp</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">Dashboard - @dashboard/</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>iOS App - @docs/iosapp</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(About)