import { connect } from 'react-redux'
import React from 'react';
import { useNavigate} from "react-router-dom";
import { Paper, Blockquote, createStyles, Image, Container, Title, Group, Text, List, ThemeIcon, rem, Space, Timeline, Table, Alert, Tabs, Badge} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery} from '@mantine/hooks';
import { Prism } from '@mantine/prism';

import '../index.css';
import { IconMovie, IconDeviceTv, IconCheck, IconAlertCircle, IconInfoCircle, IconSend, IconPackage, IconArrowNarrowLeft, IconArrowNarrowRight} from '@tabler/icons-react';

import {ReactComponent as Watchsvg} from './svgs/tv.svg';
import ArchWatches from '../components/architecture/archWatches';

import nav1 from './images/watch-nav-1.png';
import nav2 from './images/listen-nav-2.png';
import details1 from './images/watch-search-6.png';
import search1 from './images/watch-search-1.png';
import search2 from './images/watch-search-2.png';
import search3 from './images/watch-search-3.png';
import search4 from './images/watch-search-4.png';
import search5 from './images/watch-search-5.png';
import error from './images/watch-messages-1.png';
import success from './images/watch-messages-2.png';
import notification from './images/watch-messages-3.png';


const apiCall = `
let selection;

if (selectTab == 1) {
  selection = 'movie'
} else selection = 'tv'

axios({
  method: 'get',
  url: \`https://api.themoviedb.org/3/search/\${selection}?api_key=\${TMDB_API_KEY}&language=en-US&query=\${search}&page=\${page}&include_adult=false\`,
})`;

const apiResponse = `
{
  page: 1,
  results: [
    ...
    {
      adult: false, 
      genre_ids: [12, 35, 878], 
      backdrop_path: "/5bzPWQ2dFUl2aZKkp7ILJVVkRed.jpg", 
      id: 105, 
      original_language: "en", 
      original_title: "Back to the Future", 
      overview: "Eighties teenager Marty McFly is accidentally sent back in time to 1955, inadvertently disrupting his parents' first meeting and attracting his mother's romantic interest. Marty must repair the damage to history by rekindling his parents' romance and - with the help of his eccentric inventor friend Doc Brown - return to 1985.", 
      popularity: 51.487, 
      poster_path: "/fNOH9f1aA7XRTzl1sAOx9iF553Q.jpg", 
      release_date: "1985-07-03", 
      title: "Back to the Future", 
      video: false, 
      vote_average: 8.3, 
      vote_count: 18082
    }
  ]
}`;

const watchItem = `
{
  pk: "USER#stev0b@stev0b.com",
  sk: "WATCH#2RhkqyyYEzWSozpNa7Yf4lcQIUW",
  createdAt: "2023-06-25T16:18:14.514Z",
  expiresAt: "2023-07-09T16:17:54.483Z",
  film_id: "122",
  film_title: "The Lord of the Rings: The Return of the King",
  film_type: "MOVIE",
  gsi1pk: "FEED",
  gsi1sk: "2023-06-25T16:18:14.514Z",
  house_id: "b37692e6-288f-4bb6-be0b-3d48439be132",
  id: "2RhkqyyYEzWSozpNa7Yf4lcQIUW",
  TTL: 1688919474.483,
  type: "WATCH",
  user: "stev0b@stev0b.com"
}`;

const filmItem = `
{
  pk: "FILM#MOVIE#105",
  sk: "FILM#MOVIE#105",
  backdrop_path: "5bzPWQ2dFUl2aZKkp7ILJVVkRed.jpg",
  budget: 19000000,
  confirmed: true,
  createdAt: "2023-06-04T21:31:21.306Z",
  film_id: "105",
  genres: {
      "0": "Adventure",
      "1": "Comedy",
      "2": "Science Fiction"
    },
  gsi1pk: "FEED",
  gsi1sk: "2023-06-04T21:31:21.306Z",
  imdb_id: "tt0088763",
  overview: "Eighties teenager Marty McFly is accidentally sent back in time to 1955, inadvertently disrupting his parents' first meeting and attracting his mother's romantic interest. Marty must repair the damage to history by rekindling his parents' romance and - with the help of his eccentric inventor friend Doc Brown - return to 1985.",
  poster_path: "fNOH9f1aA7XRTzl1sAOx9iF553Q.jpg",
  release_date: "1985-07-03",
  revenue: 381109762,
  runtime: 116,
  tagline: "He's the only kid ever to get into trouble before he was born.",
  title: "Back to the Future",
  type: "MOVIE"
}`;

const tvItem = `
{
  pk: "FILM#TV#1400",
  sk: "FILM#TV#1400",
  backdrop_path: "wEP4CLuxGFrkECTyY50cVMGySLm.jpg",
  confirmed: true,
  createdAt: "2023-04-25T21:19:15.246Z",
  film_id: "1400",
  first_air_date: "1989-07-05",
  genres: {
      "0": "Comedy"
  },
  gsi1pk: "FEED",
  gsi1sk: "2023-04-25T21:19:15.246Z",
  imdb_id: "tt0098904",
  name: "Seinfeld",
  number_of_episodes: 180,
  number_of_seasons: 9,
  overview: "A stand-up comedian and his three offbeat friends weather the pitfalls and payoffs of life in New York City in the '90s. It's a show about nothing.",
  poster_path: "aCw8ONfyz3AhngVQa1E2Ss4KSUQ.jpg",
  status: "Ended",
  tagline: "These pretzels are making me thirsty!",
  type: "TV"
}`;

const useStyles = createStyles((theme) => ({
    tv_light : {
        '#base': {fill: theme.colors.lime[8], stroke: theme.colors.lime[8]},
        '#side': {fill: theme.colors.lime[8], stroke: theme.colors.lime[8]},
        '#base_accent': {fill: 'black', stroke: theme.colors.lime[9]},
        '#front': {fill: theme.colors.cyan[9], stroke: theme.colors.lime[5]},
        '#bunnyears': {stroke: 'black'},
        '#screen': {fill: theme.colors.blue[6], stroke: theme.colors.blue[9]},
        '#knob-2': {fill: theme.colors.cyan[9]},
        '#knob-4': {fill: theme.colors.cyan[9]},
        '#knob_background': {fill: 'black', stroke: 'black'},
        '#knob_background-2': {fill: 'black', stroke: 'black'},
        '#switch': {stroke: theme.colors.yellow[3]},
        '#switch-2': {stroke: theme.colors.yellow[3]},
        '#switch-3': {stroke: theme.colors.yellow[3]},
        '#switch-4': {stroke: theme.colors.yellow[3]},

    },
    tv_dark : {
        '#base': {fill: theme.colors.grape[8], stroke: theme.colors.grape[8]},
        '#side': {fill: theme.colors.grape[8], stroke: theme.colors.grape[8]},
        '#base_accent': {fill: 'black', stroke: theme.colors.grape[9]},
        '#front': {fill: theme.colors.gray[8], stroke: theme.colors.grape[5]},
        '#bunnyears': {stroke: theme.colors.gray[7]},
        '#screen': {fill: theme.colors.yellow[6], stroke: theme.colors.yellow[9]},
        '#knob-2': {fill: theme.colors.gray[8]},
        '#knob-4': {fill: theme.colors.gray[8]},
        '#knob_background': {fill: 'black', stroke: 'black'},
        '#knob_background-2': {fill: 'black', stroke: 'black'},
        '#switch': {stroke: theme.colors.red[5]},
        '#switch-2': {stroke: theme.colors.red[5]},
        '#switch-3': {stroke: theme.colors.red[5]},
        '#switch-4': {stroke: theme.colors.red[5]},
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,
        [theme.fn.smallerThan('55em')]: {
            maxWidth: '100%',
            marginRight: 0,
          },
      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
}))

function Watches({ld_theme}) {
    const { classes, cx, theme } = useStyles();
    const isMobile = useMediaQuery("(max-width: 55em)");
    let navigate = useNavigate();
    
    function addCheck() {
        return(
            <ThemeIcon size={20} variant="filled" color={ld_theme.theme == "dark" ? "#BE4BDB" : "#FA5252"}>
                <IconCheck size={rem(12)} stroke={3} />
            </ThemeIcon>
        )
    }
   return (
    <div>
        <Container>
            <div className={classes.inner}>
            <div className={classes.content}>
                <Title className={classes.title}>
                Roads?<br /> Where we're going, we don't need roads
                </Title>
                <Text color="dimmed" mt="md">
                <span className={classes.highlight}>Watch</span> Items are where the household has watched TV shows and Movies throughout the week. If this is the household's first time watching a film,
                a reference item for the Film will be generated. As Watch items are created, users are given a chance to rate their viewing experience. 
                </Text>

                <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                    <ThemeIcon size={20} radius="xl" variant="light" color={ld_theme.theme == "dark" ? "yellow" : "blue"}>
                    <IconCheck size={rem(12)} stroke={2} />
                    </ThemeIcon>
                }
                >
                <List.Item>
                    <b>TMDB Integration</b> - The iOS Application integrates directly with The Movie Database API for real-time info about all Movie Films and TV Series
                </List.Item>
                <List.Item>
                    <b>Weekly Watches</b> – after each Watch experience is reviewed, it is picked up by the Data Feed to be viewable in the Stev0B frontend for the week. 
                </List.Item>
                </List>
                </div>
                {isMobile ? null : <Watchsvg width='35%' viewBox="0 55 200 300" className={ld_theme.theme == 'dark' ? classes.tv_dark : classes.tv_light}/> }
            </div>
            {isMobile ? <Group position="center"><Watchsvg height={rem(200)} width={rem(300)} viewBox="70 160 100 150" className={ld_theme.theme == 'dark' ? classes.tv_dark : classes.tv_light}/></Group>: null}
            <Title className={classes.subHeading}>
                What are Watch Items?
            </Title>
            <Text mt="md" fz="md">
                I was asked a question, "What do you watch on repeat in your home?" And while I was noodling for an answer,
                it fueled the passion to create this project (more about this <span className={classes.textHighlight}><a onClick={()=> navigate('/docs')}>here</a></span>)
                It's the entity that started me on the path of creating this entire App ecosystem.   
                Watch Items are, by my definition, the films and shows our household experiences.  Every Film or Show watched throughout the week will 
                get logged into the App as an item to be <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>Reviewed</a></span>.  
                As each Film experience gets reviewed, the Data Feed will pick it up and present all the weekly 'Watches' for the household, and the user like or dislike for each
                experience.
                And to answer the question, there's usually two (2) things I can watch on repeat: <b>Seinfeld</b> and <b>Back to the Future</b>. 
            </Text> 
            <Space h="md" />
            <Title className={classes.subHeading}>
                How are they created?
            </Title>
            <Text mt="md" fz="md">
                Watch Items, just like <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Items</a></span> , are generated directly by the User.  It's up to each household to track (and remember) to enter the Film experience on each watch. From 
                there, the system takes care of the rest.  Although the entries are tracked for each Watch Item, the system will also step through and create a Reference Data item for each Film that is new to 
                the household (more details on that below).    
            </Text>
            <Space h="md" />
            <Title className={classes.subHeading}>
                User Flow
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="Navigate">
                    <Text color="dimmed" size="sm">Navigate to Watch area of application</Text>
                </Timeline.Item>
                <Timeline.Item title="Search Restaurants">
                    <Text color="dimmed" size="sm">Search for Movies and TV Shows by Text Search</Text>
                </Timeline.Item>
                <Timeline.Item title="Get Details">
                    <Text color="dimmed" size="sm">Get Film Summary and details for a selected Movie or Show</Text>
                </Timeline.Item>
                <Timeline.Item title="Save Experience">
                    <Text color="dimmed" size="sm">User sets all household members for Reviewing the Experience and Saves </Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Navigate
            </Title>
            <Text mt="md" fz="md">
                The iOS App features a dedicated section for entering Watch Items.  By default, the app initializes and lands on the <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Item</a></span> entry screen. To reach the Watch Item screen, the user can navigate to the dedicated
                Watch section of the iOS app by a horizontal touch gesture from Left-to-Right to reveal the menu and selecting Watch. 
                Also, the menu can be revealed by tapping the Avatar icon in the upper-right area of the screen 
            </Text>
            <Space h="sm" />
            <Alert icon={<IconAlertCircle size="1rem" />} title="Note" color="red">
                Only House Admins can create Watch Items.  If the user is not a House Admin, they will not have access to this section of the application
            </Alert>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> 
            </Group>
            }
           <Space h="md" />
            <Title className={classes.thirdHeading}>
                Search Films & Movies
            </Title>
            <Space h="sm" />
            <Text mt="md" fz="md">
                With the Watch section accessed, the iOS App will render a simple page with a search box and two (2) methods of searching:
            </Text>
            <Space h="sm" />
            <List>
                <List.Item><b>Movie</b></List.Item>
                <List.Item><b>TV Series</b></List.Item>
            </List>
            <Space h="sm" />
            <Text mt="md" fz="md">
                Since the <span className={classes.textHighlight}><a href='https://developer.themoviedb.org/docs'>The Movie Database API</a></span> 
                doesn't combine Movies and Films together in result sets, so this toggle option in the UI will help form the right query methods when interacting with the external calls
                to return the right results.
                Tapping the Search bar will render the iOS Keyboard.  After the user has input the name of the move or film, the application will call <span className={classes.textHighlight}><a href='https://developer.themoviedb.org/docs'></a>The Movie Database</span> to fetch the results
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={search1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={search2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={search1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={search2} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="sm" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                Leaving the <b>Search bar</b> blank/empty will disable the Search option in the iOS keyboard
            </Alert>
            <Space h="sm" />
            <Text>When the API returns a successful response, the application will dismiss the iOS keyboard and render the results in a Vertical, Scrollable List for the User to view a few key pieces of information from the object array returned from the API.
                Remember, the goal is to ensure the user identifies the correct Film or TV Show, so the presented information needs to be helpful.  Each List Item contains the following datapoints from the <span className={classes.textHighlight}><a href='https://developer.themoviedb.org/docs'>The Movie Database API</a></span> 
            </Text>
            <Space h="sm" />
                <List>
                    <List.Item>Movie Title or TV Series Name</List.Item>
                    <List.Item>Release Year</List.Item>
                    <List.Item>Poster Image</List.Item>
                </List>
            <Space h="sm" />
            <Text>The user has additional controls to view the details of an item, fetch more movies/shows, or clear the results and Search again:</Text>
            <Space h="sm" />
            <List>
                <List.Item>Details - View the Film Item</List.Item>
                <List.Item>Next Page - Paginate to return an additional +20 Results from the API. Pagination will only move you from page to page.  It will not append results as a continuous scroll</List.Item>
                <List.Item>Clear Results - Empties the result set</List.Item>
                <List.Item>Reset Search - Clear the Search entry</List.Item>
            </List>
            <Space h="sm" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                The <b>Reset Search</b> option is only available after <b>Clear Results</b> has been pressed.  Resetting a search will clear the search bar of text, focus the cursor on the Input, and render the iOS Keyboard. 
            </Alert>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={search3} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={search4} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={search5} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={search3} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={search4} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={search5} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="md"/>
            <Title className={classes.thirdHeading}>
                Get Details
            </Title>
            <Text mt="md" fz="md">
                If the user clicks <b>Details</b> from the Search Results the application will shift over to render a new Screen with a few more datapoints from the <span className={classes.textHighlight}><a href='https://developer.themoviedb.org/docs'>The Movie Database API</a></span> 
            </Text>
            <Space h="sm" />
            <List>
                <List.Item>Release Month & Year for Movies, Series Debut Month & Year if it's a TV Show</List.Item>
                <List.Item>Summary or Overview of the Film or Series</List.Item>
            </List>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={details1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={details1} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="md"/>
            <Title className={classes.thirdHeading}>
                Save Experience
            </Title>
            <Text mt="md" fz="md">
                If the watch is correct for saving the experience, the user simply just needs to make the selection of all household members attending the same experience and click <b>Save</b> 
            </Text>
            <Space h="sm" />
            <Alert icon={<IconAlertCircle size="1rem" />} title="Note" color="red">
                The currently signed-in User in the application cannot de-select themselves from the list of household members. For example, If <b>Steven B</b> is signed into the Application, he cannot
                remove or de-select <b>Steven B</b> from the experience
            </Alert>
            <Space h="sm" />
            <Text mt="md" fz="md">
                There are two (2) types of messages a user can be presented when saving an Experience:
                <Space h="sm" />
                <List>
                    <List.Item>Error - System fails to save the experience due to one of the following:
                        <List withPadding listStyleType="circle">
                            <List.Item>Invalid session</List.Item>
                            <List.Item>Invalid Film ID</List.Item> 
                            <List.Item>Blank or Empty Film Title</List.Item>
                            <List.Item>Blank or Empty Film Type or Film Type not matching TV or Movie</List.Item>
                            <List.Item>No Selected Household Users</List.Item> 
                            <List.Item>Internal Server Error</List.Item>
                        </List>
                    </List.Item>
                    <List.Item>Success - System saves the Experience with no returned errors.  
                        Users tagged to the Experience will receive a notification to leave a <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>Review</a></span>
                    </List.Item>
                </List>
            </Text>
            <Space h="sm" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                On <b>Success</b> the application will programmatically navigate back to the search results screen.  
            </Alert>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={error} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={success} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={notification} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={error} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={success} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={notification} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="md" />
            <Title className={classes.subHeading}>
                Tech Overview
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="API Call">
                    <Text color="dimmed" size="sm">The Movie Database API</Text>
                </Timeline.Item>
                <Timeline.Item title="Data Model">
                    <Text color="dimmed" size="sm">Watch & Film Entity data schemas</Text>
                </Timeline.Item>
                <Timeline.Item title="Tech Flow">
                    <Text color="dimmed" size="sm">Tech flow for saving Watch Items</Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                API Call
            </Title>
            <Text mt="md" fz="md">
                As mentioned throughout this section, almost all of the Watch experience is revolving around the <span className={classes.textHighlight}><a href='https://developer.themoviedb.org/docs'>The Movie Database API</a></span> 
                to capture a viewing experience. The application uses the <span className={classes.textHighlight}><a href='https://developer.themoviedb.org/reference/search-movie'>Search Movie</a></span> and <span className={classes.textHighlight}><a href='https://developer.themoviedb.org/reference/search-tv'>Search TV</a></span>
                 APIs to make calls. 
                A simple case statement is used to build the query string differently based on whether the user enables <b>Movie</b> or <b>TV</b>.  
                When calling the API, we toss in the variables into the search query:
                <List>
                    <List.Item>Search String</List.Item>
                    <List.Item>Search Type of Movie or TV</List.Item>
                    <List.Item>Language == "en-US" for US based english movies</List.Item>
                    <List.Item>Page for Pagination (default is <b>1</b>)</List.Item>  
                    <List.Item>Set the Adult Rated films == <b>false</b></List.Item>  
                </List>
                This gives a simple query to use, and control pages as a State component in the UI.  See example code below:
            </Text>
            <Space h="sm" />
            <Space h="md" />
            <Tabs color={ld_theme.theme == "dark" ? "grape":"red"}variant="default" defaultValue="apicall">
            <Tabs.List grow>
                <Tabs.Tab value="apicall" icon={<IconSend size="0.8rem" />}>API Call</Tabs.Tab>
                <Tabs.Tab value="apiresponse" icon={<IconPackage size="0.8rem" />}>Response</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="apicall" pt="xs">
                <Prism noCopy language="jsx">{apiCall}</Prism>
            </Tabs.Panel>
            <Tabs.Panel value="apiresponse" pt="xs">
                <Prism noCopy language="jsx">{apiResponse}</Prism>
            </Tabs.Panel>
            </Tabs>
            <Space h="md" />
            <Text mt="md" fz="md">
              The Results array is parsed by the Application in the Search Results screen for the user to interact and scroll through in the UI.  The results attribute definitions are not centrally located in the documentation of TMDB's API docs, but the attributes are very straight-forward. 
                <Space h="md" />
                The table below goes through each returned attribute from the API and 
                documents its relationship to where it's used in the UI of the iOS App
            </Text>
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md" >
            <thead>
                <tr>
                    <th colSpan="3">The Movie Database API</th>
                </tr>
                <tr>
                    <th colSpan="1" style={{"background": ld_theme.theme == "dark" ? "#AE3EC9" : "#FA5252", "color": "white"}}>Movies</th>
                    <th colSpan="2" style={{"background": ld_theme.theme == "dark" ? "#AE3EC9" : "#FA5252", "color": "white"}}>iOS App</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>attr</th>
                    <th>search</th>
                    <th>detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>adult</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>genre_ids</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>backdrop_path</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>id</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>original_language</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>original_title</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>overview</td>
                    <td></td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>popularity</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>poster_path</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>release_date</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>title</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>video</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>vote_average</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>vote_count</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th colSpan="1" style={{"background": ld_theme.theme == "dark" ? "#AE3EC9" : "#FA5252", "color": "white"}}>TV Series</th>
                    <th colSpan="2" style={{"background": ld_theme.theme == "dark" ? "#AE3EC9" : "#FA5252", "color": "white"}}>iOS App</th>
                </tr>
                <tr>
                    <th>attr</th>
                    <th>search</th>
                    <th>detail</th>
                </tr>
                <tr>
                    <td>adult</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>genre_ids</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>backdrop_path</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>id</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>origin_country</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>original_language</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>original_name</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>overview</td>
                    <td></td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>popularity</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>poster_path</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>first_air_date</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>name</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>vote_average</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>vote_count</td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
            </Table>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Data Model
            </Title>
            <Text mt="md" fz="md">
                Much like <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Items</a></span> Watch Items have a very simple data model.  
                The goal of the Watch entity is to log the viewing experience, so the DynamoDB Item will not contain the full set of reference data attributes of the Film itself - all the information
                tucked away in the Film Cards on the <span className={classes.textHighlight}><a onClick={()=> navigate('/')}>Dashboard</a></span> - more on this in a minute.  
                <Space h="sm" />
                The Watch Item just needs to save a few reference pieces from The Movie Database API Response when saving an item to log the experience.  
                Since the Watch Items are user-specific, they have an access pattern by User 
                and Watch Date created, represented by a <span className={classes.textHighlight}><a href='https://www.npmjs.com/package/ksuid'>KSUID</a></span>.
                As mentioned, or you will see mentioned, other sections of the application, KSUIDs are great for creating unique IDs that have an embedded Date within it.  This allows the system to take
                advantage of DynamoDB to use this as its sort key. 
                The Global Secondary Index (GSI) on create will have the gsi-pk labeled with <b>FEED</b> and the gsi-sk with a readable date. 
                This will be what the weekly data feed queries to know what data to pick up on its next run. 
                <Space h="sm" />
                Below is a summary table for the Watch Entity and example records:
            </Text>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <thead>
                <tr>
                    <th colSpan="2">Primary Key</th>
                    <th colSpan="2">Secondary Index</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>pk</th>
                    <th>sk</th>
                    <th>gsi1-pk</th>
                    <th>gsi1-sk</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>USER#[user_id]</td>
                    <td>WATCH#[ksuid]</td>
                    <td>FEED</td>
                    <td>[created_date]</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <caption>Item Attributes</caption>
            <thead>
                <tr>
                    <th>attribute</th>
                    <th>description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>pk</td>
                    <td rowSpan="2">Primary key</td>
                </tr>
                <tr>
                    <td>sk</td>
                </tr>
                <tr>
                    <td>createdAt</td>
                    <td>Date the experience was generated (ISO format)</td>
                </tr>
                <tr>
                    <td>expiresAt</td>
                    <td>Date the record will be removed (readable TTL)</td>
                </tr>
                <tr>
                    <td>film_id</td>
                    <td>API Response.[place_id]</td>
                </tr>
                <tr>
                    <td>film_title</td>
                    <td>Movie: API Response.[title]
                       <Space v="sm" />
                        TV:  API Response.[name]
                    </td>
                </tr>
                <tr>
                    <td>film_type</td>
                    <td>Movie or TV</td>
                </tr>
                <tr>
                    <td>gsi1pk</td>
                    <td rowSpan="2">Global secondary key</td>
                </tr>
                <tr>
                    <td>gsi1sk</td>
                </tr>
                <tr>
                    <td>house_id</td>
                    <td>Household id</td>
                </tr>
                <tr>
                    <td>id</td>
                    <td>Watch id</td>
                </tr>
                <tr>
                    <td>TTL</td>
                    <td>Time-to-Live (milliseconds) remaining </td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>Type of Experience</td>
                </tr>
                <tr>
                    <td>user</td>
                    <td>User who saved the experience</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Prism language="jsx">{watchItem}</Prism>
            <Space h="sm" />
            <Text>If you look closely, there's some film fields lacking that we used in the iOS App and on the Dashboard cards.
                Just like <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Items</a></span> we use a separate entity for our reference information: <b>films</b>. The reasoning
                for separating this bit of reference data is due to the 1:N relationship we have between a Movie or TV Show and the number of times our household can view them, and also to keep track of when our household 
                watches a new film or tv series. Since this is a Reference Entity, Films will need to be retrievable and hold all the necessary attributes to present our info in the Dashboard.  As an Item with reference data,
                it only needs a Primary Key of the type of Film it is (Movie or TV Series) and its <b>TMDB.id</b> passed from the Watch experience. We create the Secondary Index to allow the Feed to pick up the new Film and tag it appropriately, so it can render as a <Badge color="green">First Watch</Badge> for the Household in the Dashboard. 
                The Tech Flow section below will walk through the details of how Film items are created from a Watch experience:   
            </Text>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <thead>
                <tr>
                    <th colSpan="2">Primary Key</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>pk</th>
                    <th>sk</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>FILM#[TYPE]#[id]</td>
                    <td>FILM#[TYPE]#[id]</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <caption>Item Attributes</caption>
            <thead>
                <tr>
                    <th>attribute</th>
                    <th>description</th>
                </tr>
            </thead>
            
            <tbody>
                <tr>
                    <th colSpan="2" style={{"background": ld_theme.theme == "dark" ? "#AE3EC9" : "#FA5252", "color": "white"}}>Movies</th>
                </tr>
                <tr>
                    <td>pk</td>
                    <td rowSpan="2">Primary key</td>
                </tr>
                <tr>
                    <td>sk</td>
                </tr>
                <tr>
                    <td>backdrop_path</td>
                    <td>Backdrop image S3 Identifier</td>
                </tr>
                <tr>
                    <td>budget</td>
                    <td>Movie budget provided by the API </td>
                </tr>
                <tr>
                    <td>confirmed</td>
                    <td>Boolean Flag for successful Update from Step Function (below)</td>
                </tr>
                <tr>
                    <td>createdAt</td>
                    <td>Date the record was created</td>
                </tr>
                <tr>
                    <td>film_id</td>
                    <td>Film ID provided by the API</td>
                </tr>
                <tr>
                    <td>genres</td>
                    <td>List of genres provided by the API</td>
                </tr>
                <tr>
                    <td>gsi1pk</td>
                    <td rowSpan="2">Global secondary key</td>
                </tr>
                <tr>
                    <td>gsi1sk</td>
                </tr>
                <tr>
                    <td>imdb_id</td>
                    <td>IMDB identifier provided by the API</td>
                </tr>
                <tr>
                    <td>overview</td>
                    <td>Short summary provided by the API</td>
                </tr>
                <tr>
                    <td>poster_path</td>
                    <td>Poster image S3 Identifier</td>
                </tr>
                <tr>
                    <td>release_date</td>
                    <td>Release date of the film provided by the API</td>
                </tr>
                <tr>
                    <td>revenue</td>
                    <td>Revenue made by the film provided by the API</td>
                </tr>
                <tr>
                    <td>runtime</td>
                    <td>Runtime of the film provided by the API</td>
                </tr>
                <tr>
                    <td>tagline</td>
                    <td>Tagline of the film provided by the API</td>
                </tr>
                <tr>
                    <td>title</td>
                    <td>Title of the film provided by the API</td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>Type of Reference Data</td>
                </tr>
                <tr>
                    <th colSpan="2" style={{"background": ld_theme.theme == "dark" ? "#AE3EC9" : "#FA5252", "color": "white"}}>TV Series</th>
                </tr>
                <tr>
                    <td>backdrop_path</td>
                    <td>Backdrop image S3 Identifier</td>
                </tr>
                <tr>
                    <td>confirmed</td>
                    <td>Boolean Flag for successful Update from Step Function (below)</td>
                </tr>
                <tr>
                    <td>createdAt</td>
                    <td>Date the record was created</td>
                </tr>
                <tr>
                    <td>film_id</td>
                    <td>Film ID provided by the API</td>
                </tr>
                <tr>
                    <td>first_air_date</td>
                    <td>Date the series aired provided by the API</td>
                </tr>
                <tr>
                    <td>genres</td>
                    <td>List of genres provided by the API</td>
                </tr>
                <tr>
                    <td>gsi1pk</td>
                    <td rowSpan="2">Global secondary key</td>
                </tr>
                <tr>
                    <td>gsi1sk</td>
                </tr>
                <tr>
                    <td>imdb_id</td>
                    <td>IMDB identifier provided by the API</td>
                </tr>
                <tr>
                    <td>name</td>
                    <td>Name of the TV Series provided by the API</td>
                </tr>
                <tr>
                    <td>number_of_episodes</td>
                    <td>TV Series total number of episodes provided by the API</td>
                </tr>
                <tr>
                    <td>number_of_seasons</td>
                    <td>TV Series total number of seasons provided by the API</td>
                </tr>
                <tr>
                    <td>overview</td>
                    <td>Short summary provided by the API</td>
                </tr>
                <tr>
                    <td>poster_path</td>
                    <td>Poster image S3 Identifier</td>
                </tr>
                <tr>
                    <td>status</td>
                    <td>Status of the TV Series provided by the API</td>
                </tr>
                <tr>
                    <td>tagline</td>
                    <td>Tagline of the film provided by the API</td>
                </tr>
                <tr>
                    <td>title</td>
                    <td>Title of the film provided by the API</td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>Type of Reference Data</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Space h="md" />
            <Tabs color={ld_theme.theme == "dark" ? "grape":"red"}variant="default" defaultValue="iconMovie">
            <Tabs.List grow>
                <Tabs.Tab value="iconMovie" icon={<IconMovie size="0.8rem" />}>Movie</Tabs.Tab>
                <Tabs.Tab value="iconTV" icon={<IconDeviceTv size="0.8rem" />}>TV Series</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="iconMovie" pt="xs">
                <Prism noCopy language="jsx">{filmItem}</Prism>
            </Tabs.Panel>
            <Tabs.Panel value="iconTV" pt="xs">
                <Prism noCopy language="jsx">{tvItem}</Prism>
            </Tabs.Panel>
            </Tabs>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Tech Flow
            </Title>
            <Text mt="md" fz="md">
                As you'll see below, what's set up is essentially an AWS 
                <span className={classes.textHighlight}><a href='https://en.wikipedia.org/wiki/Rube_Goldberg_machine'>Rube Goldberg Machine</a></span>
                to just create Watch and Film items.  The Tech Flow for Watch Items is similar to <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Items</a></span> 
                and besides the obvious data-model differences, the two follow the same general pattern:
                <Space h="sm" />
                <List>
                    <List.Item>Save Watch Experience</List.Item>
                    <List.Item>Determine if Film ID & Type combination exists</List.Item>
                    <List withPadding listStyleType="disc">
                            <List.Item>If Exists - Generate Reviews for Users</List.Item>
                            <List.Item>If Doesn't Exist - Kick off Step Function</List.Item>
                            <List withPadding listStyleType="circle">
                                <List.Item>Call API - Get Film Data</List.Item>
                                <List.Item>Get Poster and Backdrop Images</List.Item> 
                                <List.Item>Confirm data is saved in both S3 and DynamoDB</List.Item>
                                <List.Item>Generate Reviews for Users</List.Item>
                                <List.Item>If Error, set <b>confirmed == false</b></List.Item>
                            </List> 
                    </List>
                </List>
                <Space h="sm" />
                Again, it's probably not how I'd design this in production, but this is my fun spin on "going the extra mile" by setting this up to have two (2) entities follow the
                same Tech flow... plus... it helps keep my Architecture diagram symmetrical. 
                <Space h="sm" /> To quote a show I usually have on repeat 
            </Text>
            <Blockquote color={ld_theme.theme == "dark" ? "yellow" : "blue"} cite="- Jerry Seinfeld">
            Well, you really built yourself up into something.
            </Blockquote>
            <Space h="sm" />
            <Group position="center"><ArchWatches /></Group>
            <Space h="xl" />
            <Space h="xl" />
            {isMobile ?
          <div>  

          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/eatitems')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">Eat Items - @docs/iosapp/eatitems</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/listenitems')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>Listen Items - @iosapp/listenitems</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/eatitems')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">Eat Items - @docs/iosapp/eatitems</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/listenitems')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>Listen Items - @iosapp/listenitems</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(Watches)