import { useState } from 'react';
import { connect } from 'react-redux'
import { ActionIcon, createStyles, Header, Group, Container, rem } from '@mantine/core';
import { useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as Logo} from '../../logo.svg';
import SwitchToggle from './color_scheme';

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: rem(56),

    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'flex-start',
    },
  },

  logoDark: {
    "#background" : {rx: "5px", ry: "5px"},
    "#pepp-3-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-2-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-1-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-1-shadow-2" : {fill: theme.colors.red[9]},
    "#pepp-4-2" : {fill: theme.colors.red[5]},
    "#pepp-5-2" : {fill: theme.colors.red[5]},
    "#pepp-6-2" : {fill: theme.colors.red[5]},
    "#cheese-2-2" : {fill: theme.colors.yellow[3], stroke: theme.colors.yellow[8]},
    "#drip-4-2" : {fill: theme.colors.yellow[3]},
    "#drip-3-2" : {fill: theme.colors.yellow[3]},
    "#crust-2-2" : {fill: theme.colors.orange[6],  stroke: theme.colors.orange[9]},
  },
  logoLight: {
    "#background" : {rx: "5px", ry: "5px"},
    "#pepp-3-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-2-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-1-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-1-shadow-2" : {fill: theme.colors.red[9]},
    "#pepp-4-2" : {fill: theme.colors.red[6]},
    "#pepp-5-2" : {fill: theme.colors.red[6]},
    "#pepp-6-2" : {fill: theme.colors.red[6]},
    "#cheese-2-2" : {fill: theme.colors.yellow[4], stroke: theme.colors.yellow[8]},
    "#drip-4-2" : {fill: theme.colors.yellow[4]},
    "#drip-3-2" : {fill: theme.colors.yellow[4]},
    "#crust-2-2" : {fill: theme.colors.orange[7],  stroke: theme.colors.orange[9]},
  },

  links: {
    width: rem(260),

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  social: {
    width: rem(260),

    [theme.fn.smallerThan('sm')]: {
      width: 'auto',
      marginLeft: 'auto',
    },
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
  icon: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7]: "white",
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

const TopNav = ({ links, ld_theme}) => {
  const { classes, cx } = useStyles();
  let location = useLocation();
  let navigate = useNavigate();
  const refLocation = location.pathname.split('/')[1].split('/')[0];

  const navLink =  function(e){
    navigate(e.link)
  }

  const activeLink = function (e) {
    if (refLocation == e.link.toLowerCase())  {
    return "linkActive"
  } else { return "link"}
}

  const items = links.map((link) => (
    <a
      key={link.label}
      active={refLocation == link.link.toLowerCase() ? true: false}
      className={cx(useStyles[activeLink(link)])}
      onClick={() => navLink(link)}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={56} mb={120}>
      <Container className={classes.inner}>
        <Group><ActionIcon size="xl" variant="filled" className={classes.icon} ><Logo className={ld_theme.theme == "dark" ? classes.logoDark : classes.logoLight} height={50} width={50} onClick={()=> navigate('/')}/></ActionIcon> Stev0B</Group>
        <Group spacing={0} className={classes.social} position="right" noWrap>
            <SwitchToggle />
        </Group>
      </Container>
    </Header>
  );
}

const mapStateToProps = state => {
  return { navbar: state.navbar, ld_theme: state.ld_theme}
}

export default connect(mapStateToProps)(TopNav)