import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getToken} from '../service/authService';


const PrivateRoute = () => {
    const auth = !getToken(); // determine if unauthorized

    return auth ? <Outlet /> : <Navigate to="/home/dashboard" />;
}

export default PrivateRoute;