import { connect } from 'react-redux'
import React from 'react';
import { rem, createStyles, keyframes } from '@mantine/core';

import SvgStepper from './svgStepper';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';



const useStyles = createStyles((theme) => ({
  highlight: {
    position: 'relative',
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(2)} ${rem(6)}`,
  },
    second : {
      '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#google_stroke':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#app_to_google': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#google_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
      '#google_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    third : {
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#apigateway_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#apigateway_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    fourth : {
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#sessions_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    fifth : {
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dynamodb_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#dynamodb_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    sixth : {
      '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#eat_step':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#google_stroke':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#sessions_to_eats_step':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#eat_step_to_google':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#eats_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#eatsf_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#eatsf_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  },
  seventh : {
    '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    
    '#eat_step':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#google_stroke':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#sessions_to_eats_step':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#eat_step_to_google':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#eats_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#eats_image_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    
    '#eatsf_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#eatsf_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
},
eighth : {
  '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  
  '#eat_step':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_to_eats_step':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

  '#eats_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eats_image_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eats_verify_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eatsf_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eatsf_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
},
ninth : {
  '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  
  '#eat_step':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_to_eats_step':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

  '#eats_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eats_image_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eats_verify_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eats_reviewers_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eatsf_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#eatsf_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
}

}))

function ArchEats() {
const { classes, cx, theme } = useStyles();

const imageTitle = "Eat Experience"
const steps =[
{step: "first", 
    viewbox:"0 0 250 300",
    breakdown: [], 
},
{step: "second", 
  viewbox:"0 10 160 180", 
  desc: "iOS App makes calls to API to retrieve Movies and Shows for logging the Watch experience.  The primary interaction is just between the iOS App and the Google Place API",
  breakdown:[
  {
      divider:'API Request',
      system: 'iOS App',
      desc:'Application sends a request to the API Endpoint with its Google Token in the API Query:',
      external_packages: [{name: 'Google Place API', url: 'https://developers.google.com/maps/documentation/places/web-service/overview'}, {name: 'Axios', url: 'https://www.npmjs.com/package/axios'}],
      code: `{
  let query = \`https://maps.googleapis.com/maps/api/place/textsearch/json?query=\${search.name}&location=\${search.lat}%2C\${search.long}&radius=\${search.radius}&type=\${search.type}&key=\${GOOGLE_API_KEY}\`
  axios({
      method: 'get',
      url: query,
  })
}`,
  },
      {
          divider: 'Catch API Response',
          system: 'Google Place API',
          desc: 'The Google API evaluates the request query, ensures the token is authorized to search, and the path is a valid endpoint. If the request is good, it will pass an Array of results to the iOS App',
          errors: [{type:'Invalid URL or Path', code: '403', message: 'Unauthorized'}, {type:'Invalid Access Token', code: '403', message: 'Forbidden'}],
          on_success: {label: 'Payload passes an Array of objects with Restaurants', nav: '/docs'}
      }
  ]
  },
{step: "third", 
viewbox:"0 10 160 180", 
desc: "User requests to save an Eat Experience. This is the first point where the AWS Ecosystem will take care of the Reference Data Points and Log the Experience",
breakdown:[
{
    divider:'App Request',
    system: 'iOS App',
    desc:'Application sends a request to the API Endpoint with its application-specific API Token in the request header, a bearer token to verify the User Session, and the Eat request body:',
    external_packages: [{name: 'Axios', url: 'https://www.npmjs.com/package/axios'}],
    code: `{
  url: 'stev0b-api-url/eat/stev0b@stev0b.com',
  method: 'post',
  headers: {
    Authorization: 'bearer jardv3Lp-Szew-UHSbf',
    x-api-key: 'TXkOp3J1SbuadZqudDXT11CsWNa8xi'
  },
  body: {
    rest_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ', // API.place_id
    rest_name: 'O-Ku' 
    eat_users: ['stev0b@stev0b.com'],
  }
}`,
},
    {
        divider: 'Evaluate Request',
        system: 'API Gateway',
        desc: 'The API Gateway evaluates the request header url and ensures the path is a valid endpoint. As an added layer of security, the x-api-key is validated as only the application should have that token.',
        errors: [{type:'Invalid URL or Path', code: '403', message: 'Unauthorized'}, {type:'Invalid API Key', code: '403', message: 'Forbidden'}],
        on_success: {label: 'Payload passes to Sessions lambda as an event for further processing', nav: '/docs'}
    }
]
},
{step: "fourth", 
viewbox:"0 10 160 180", 
desc: "The Sessions Lambda will determine which function to execute based on the validated payload received from the API Gateway and process the event.",
breakdown:[
{
    divider:'API Event',
    system: 'API Gateway',
    desc:'API Gateway passes the payload to the Sessions Lambda for further processing:',
    code: `{
  url: 'stev0b-api-url/eat/stev0b@stev0b.com',
  method: post,
  headers: {
    Authorization: bearer jardv3Lp-Szew-UHSbf,
    x-api-key: TXkOp3J1SbuadZqudDXT11CsWNa8xi
  },
  body: {
    rest_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ', // API.place_id
    rest_name: 'O-Ku' 
    eat_users: ['stev0b@stev0b.com'],
  }
}`,
},
    {
        divider: 'Determine Function to Execute',
        system: 'Sessions Lambda',
        desc: 'The Sessions Lambda contains many stored functions and will use the API Gateway event to determine which function to execute based on the payload received',
        code: `const { createEatItemForUser } = require('./handlers/createEatItemForUser');
const eatUserPath = '/eat/{user}';

exports.handler = async (event) => {
  let response;
  switch(true) {
    ...
    case event.httpMethod === 'POST' && event.path === eatUserPath: 
      response = await createEatItemForUser(event);
      break;
    
    default:
      response = { statusCode: 404, body: JSON.stringify(event)};
  }
  return response
}`,
        errors: [{type:'Invalid Path or Undetermined Function', code: '404', message: 'Unauthorized'}],
    },
    {
        divider: 'Create Eat Item - Validate Session',
        system: 'Sessions Lambda',
        desc: 'The Create Eat Item function will first confirm the user is a valid and logged-in user from the Authorization token from the passed event and the URL user matches with the Session ID stored in DynamoDB.',
        code: `const { getSession } = require('../data');
const { Session } = require('../entities')
const { buildResponse } = require('./utils');

const handler = async (event) => {
  const sessionId = event.headers.Authorization.toLowerCase().replace('bearer ', '');
  const sessionItem = new Session({sessionId: sessionId}) 
  
  try {
    const user = await getSession(sessionItem)
    ...
  } catch(error) {
    buildResponse(403, "Invalid Session, Login Required")
  }

}
module.exports.updateReview = handler;`,
        errors: [{type:'Invalid Session', code: '403', message: 'Unauthorized'}],
    },
    {
        divider: 'Create Eat Item - Validate Schema',
        system: 'Sessions Lambda',
        desc: `The Create Eat Item function will also confirm the schema in the event payload is also valid.  If the function returns an error, the entire error array will be sent in the response (i.e. more than one field could have an error). Sessions uses the external library Yup for schema validation`,
        external_packages: [{name: 'Yup', url: 'https://www.npmjs.com/package/yup'}],
        code: `const { buildResponse } = require('./utils');

const handler = async (event) => {
  const event_body = JSON.parse(event.body);
  
  try {
    await eatSchema.validate(event_body)
    ...
  } catch(error) {
    buildResponse(400, {client_errors: err.errors})
  }

}
module.exports.updateReview = handler;

const eatSchema = yup.object({
  rest_id: yup
    .string({eat_id:'String Values only'})
    .required({eat_id:'Eat ID is Required'})
    .max(1000,{eat_id: 'Eat IDs cannot be more than 1k characters'}),

  rest_name: yup
    .string({eat_name:'String Values only'})
    .required({eat_name:'Restaurant Name is Required'})
    .max(500,{eat_name: 'Restaurant Names cannot be more than 254 characters'}),

  eat_users: yup
    .array()
    .min(1, {watch_users: "At least one (1) user needs to be selected"})
});`,
        errors: [{type:'Invalid Schema', code: '400', message: 'Unauthorized'}],
        on_success: {label: 'Sessions passes the event body to DynamoDB to commit the update', nav: '/docs'}
    }
 ],
},
{step: "fifth", 
viewbox:"0 10 160 180", 
desc: "The Sessions Lambda will determine which Entities to create in DynamoDB by checking if an existing Restaurant Item already exists for the Household.  If it doesn't the Step Function will get called",
 breakdown: [
{
    divider:'Sessions Prep Data',
    system: 'Sessions Lambda',
    desc:'With the user and schema validated, Sessions will construct the Eat Item in a format for saving to DynamoDB by calling its Eat Class and constructing the Eat Item.  The main goal at this step is for the function to determine which function to call for DynamoDB: Create Eat Item with User Reviews (Restaurant Item exists) or Create Eat Item and Restaurant Item (Restaurant Item does not exist',
    code: `const { Eat } = require('../entities')
const { getSession, getRestaurant, createEatItemForUser, createEatItemRestItemForUser } = require('../data');
  
const handler = async (event) => {
  const event_body = JSON.parse(event.body);
  const user = await getSession(sessionItem)
  ...
  try {
    const eatItem = new Eat({
      user: user.user_id,
      restId: event_body.rest_id,
      restName: event_body.rest_name,
      houseId: user.house_id
    })
    const eatUsers = event_body.eat_users
    const restItem = await getRestaurant(eatItem.restId)

    let response;
    if (restItem.status_code === 200) {   //Restaurant Found
      const response = await createEatItemForUser(eatItem, eatUsers, restItem)
    }
    if (restItem.status_code !== 200) {  //Restaurant Not Found
      const response = await createEatItemRestItemForUser(eatItem, eatUsers)
    }
    const statusCode = response.eatItem ? 200:500
    return buildResponse(statusCode, {
        response,
        server_error: response.error
      })
  } catch(error) {
    buildResponse(500, "API Error")
  }
}`,
},
    {
        divider: 'Restaurant Exists - Create Eat Item and Reviews for Users',
        system: 'DynamoDB',
        desc: 'If the Sessions Lambda determines the Restaurant already exists, this will be the called function to create the Eat Experience and Generate User Reviews in DynamoDB. The function will apply a Time-To-Live (ttl) to the Eat Item and set the Global Secondary Index attributes (gsi1) for the Feed to query on its next run.  TTL indicates that the record can expire or auto-delete after 14 days. The Reviews will use existing attributes from the found Restaurant Item from Sessions Lambda and create the Reviews as a Batch Item to ensure data integrity (if the batch fails, no reviews are created).  This helps with data consistency.',
        code: `const EXPIRATION = 14 * 86400000;
const currentDate = new Date();
const currentDateExp = new Date(currentDate.getTime() + EXPIRATION);
const ttl = currentDateExp.getTime() / 1000

const createEatItemForUser = async (eatItem, eatUsers, restItem) => {
  try {
    await createEatItem(eatItem)
    await createReviews(eatItem, eatUsers, restItem)
    return { eatItem }
  } catch (error){
    return {error: error}
  }
}

const createEatItem = async (eatItem, eatUsers, restItem) => {
  try {
    await dynamodb.putItem({
        TableName: process.env.TABLE_NAME,
        Item: {
          'pk' : {'S' : \`USER#\${eatItem.user}\`},
          'sk' : {'S' : \`EAT#\${eatItem.eatId}\`},
          'gsi1pk' : {'S' : 'FEED'},
          'gsi1sk' : {'S' : \`\${currentDate.toISOString()}\`},
          'type' : {'S' : 'EAT'},
          'user' : {'S' : \`\${eatItem.user}\`},
          'id' : {'S' : \`\${eatItem.eatId}\`},
          'restaurant_id' : {'S' : \`\${eatItem.restId}\`},
          'restaurant_name' : {'S' : \`\${eatItem.restName}\`},
          'house_id' : {'S' : \`\${eatItem.houseId}\`},
          'createdAt' : {'S' : \`\${currentDate.toISOString()}\`},
          'expiresAt' : {'S' : \`\${currentDateExp.toISOString()}\`},
          'TTL' : {'S' : \`\${ttl.toISOString()}\`},
        }
    }).promise()
    return { eatItem }
  } catch (error) {
    return { error: error }
  }
}

const createReviews = async (eatItem, eatUsers, restItem) => {
  const reviewPut = eatUsers.map((i) => {
    const reviewItem = {
      'pk' : {'S' : \`USER#\${i}\`},
      'sk' : {'S' : \`REVIEW#\${eatItem.eatId}\`},
      'createdAt' : {'S' : \`\${currentDate.toISOString()}\`},
      'gsi1pk': {'S' : \`HOUSE#\${eatItem.house_id}\`},
      'gis1sk' : {'S' : \`REVIEW#\${eatItem.eatId}\`},
      'r_id': {'S' : \`\${eatItem.restId}\`,
      'id' : {'S' : \`\${eatItem.eatId}\`},
      'title' : {'S' : \`\${restItem.name}\`},
      'user' : {'S' : \`\${i}\`},
      'type' : {'S' : 'eat'},
      'image': {'S' : \`ste0b-restaurant-images/\${restItem.image_path}\`},
      'house_id' : {'S' : \`HOUSE#\${eatItem.house_id}\`},
    }
    return { PutRequest: {
        Item: reviewItem 
      }
    }   
  })
  try {
      await dynamodb.batchWriteItem({
          RequestItems: {
              [process.env.TABLE_NAME]: reviewput
          }
      }).promise()
  } catch(error) {
      return 'Could not create reviews.'
  }
}
module.exports = { createEatItemForUser }`,
        errors: [{type:'Server Error', code: '500', message: 'Unauthorized'}],
        on_success: {label: 'Eat Item and Reviews successfully saved. Sessions sends two responses back to the iOS App. First is the Success message from the successful Eat Save.  The 2nd is for each User created reviews will receive a message that a Review is waiting for their input', nav: '/docs'}
    },
    {
      divider: 'Restaurant Null - Create Eat Item and Restaurant Item',
      system: 'DynamoDB',
      external_packages: [{name: 'uuid v4', url: 'https://www.npmjs.com/package/uuidv4'}],
      desc: 'If the Sessions Lambda determines the Restaurant does not exist, this function will be the called to create the Eat Experience and Generate a placeholder Restaurant Item in DynamoDB. The function will apply a Time-To-Live (ttl) to the Eat Item and set the Global Secondary Index attributes (gsi1) for the Feed to query on its next run.  TTL indicates that the record can expire or auto-delete after 14 days. After, both, the Eat Item and Restaurant Item are created, the function will call the Step Function to begin sourcing the Restaurant Reference Data',
      code: `const EXPIRATION = 14 * 86400000;
const currentDate = new Date();
const currentDateExp = new Date(currentDate.getTime() + EXPIRATION);
const ttl = currentDateExp.getTime() / 1000
const { v4: uuidv4 } = require('uuid');

const createEatItemRestItemForUser = async (eatItem, eatUsers) => {
  try {
    await createEatItem(eatItem)
    await startStateMachine(eatItem, eatUsers)
    return { eatItem }
  } catch (error){
    return {error: error}
  }
}

const createEatItem = async (eatItem, restItem) => {
  try {
    const params = {
      TransactItems: [
        {
          Put: {
            Item: dynamoEatItem,
            TableName: process.env.TABLE_NAME,
            ConditionExpression: "attribute_not_exists(PK)"
          },
        },
        {
          Put: {
            Item: dynamoRestItem,
            TableName: process.env.TABLE_NAME,
            ConditionExpression: "attribute_not_exists(PK)"
          },
        }
      ]
    }
    await executeTransactWrite({ client: dynamodb, params })
    return {
      eat_item: eatItem,
      restaurant_item: restItem,
      message: "Eat and Restaurant Session Successfully Saved"
    }
  } catch (error) {
    return { error: error }
  }
}

const startStateMachine = async function startStateMachine(restItem, eatItem, eatUsers) {
  const job_id = uuidv4();
  const params = {
    stateMachineArn: \`\${process.env.SF_ARN}:get-restaurant-orchestrator\`,
    name: job_id,
    input: JSON.stringify({
        job_id: job_id,
        restItem: {restaurant_id: eatItem.restId, createdAt: currentDate},
        eatItem: eatItem,
        eat_users: eat_users
    })
  };

  return await stepfunctions.startExecution(params).promise()
}

const dynamoEatItem = {
  'pk' : {'S' : \`USER#\${eatItem.user}\`},
  'sk' : {'S' : \`EAT#\${eatItem.eatId}\`},
  'gsi1pk' : {'S' : 'FEED'},
  'gsi1sk' : {'S' : \`\${currentDate.toISOString()}\`},
  'type' : {'S' : 'EAT'},
  'user' : {'S' : \`\${eatItem.user}\`},
  'id' : {'S' : \`\${eatItem.eatId}\`},
  'restaurant_id' : {'S' : \`\${eatItem.restId}\`},
  'restaurant_name' : {'S' : \`\${eatItem.restName}\`},
  'house_id' : {'S' : \`\${eatItem.houseId}\`},
  'createdAt' : {'S' : \`\${currentDate.toISOString()}\`},
  'expiresAt' : {'S' : \`\${currentDateExp.toISOString()}\`},
  'TTL' : {'S' : \`\${ttl.toISOString()}\`},
}

const dynamoRestItem = {
  'pk' : {'S' : \`REST#\${eatItem.restId}\`},
  'sk' :{'S' : \`REST#\${eatItem.restId}\`},
  'gsi1pk' : {'S' : 'FEED'},
  'gsi1sk' : {'S' : \`\${currentDate.toISOString()}\`},
  'type' : {'S' : 'RESTAURANT'},
  'restaurant_id': {'S' : \`REST#\${eatItem.restId}\`},
  'createdAt': { 'S': this.createdAt.toISOString() }
}

module.exports = { createEatItemRestItemForUser }`,
      errors: [{type:'Server Error', code: '500', message: 'Unauthorized'}],
      on_success: {label: 'Eat Item and Restaurant Item placeholder successfully saved. Sessions sends one success response back to the iOS App', nav: '/docs'}
  },    
]
},
{step: "sixth", 
  viewbox:"0 10 160 180", 
  desc: "Step Function Step 1 - Gather Data.  If no Restaurant is identified, the Step Function is called to begin sourcing data. It will call the Google Place API to pull & save Restaurant reference data points.",
  breakdown:[
  {
      divider:'Sessions Payload to Data Lambda',
      system: 'get-restaurant-orchestrator',
      desc:'Payload passed from Sessions Lambda',

      code: `{
  job_id: '0c53628e-9392-434d-8d02-3bd0cb500efe',
  restItem: {
    restaurant_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
    createdAt: '2023-06-24T21:03:33.274Z'
  },
  eatItem: {
    user: 'stev0b@stev0b.com',
    eatId: '2RfUQbcmWw0rf6vLZKR9xTcxVfD',
    restId: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
    restName: 'O-Ku',
    createdAt: '2023-05-09T19:16:51.083Z',
    house_id: 'b37692e6-288f-4bb6-be0b-3d48439be132',
    expiresAt: '2023-05-23T19:16:51.083Z',
    ttl: 1688850175.998
  },
  eat_users: ["stev0b@stev0b.com"]
}
  }`,
  },
      {
          divider: 'Get Restaurant Reference Data',
          system: 'restaurant-get-api',
          external_packages: [{name: 'Google Place API', url: 'https://developers.google.com/maps/documentation/places/web-service/overview'}, {name: 'Axios', url: 'https://www.npmjs.com/package/axios'}, {name: 'Yup', url: 'https://www.npmjs.com/package/yup'}],
    code: `
exports.handler = async (event) => {
  const restaurant_id = event.restItem.restaurant_id;
  const error_prop = 'statusCode';

  let response;
  let query = \`https://maps.googleapis.com/maps/api/place/details/json?place_id=\${restaurant_id}&key=\${GOOGLE_API_KEY}\`
  const google_response = await axios({method: 'get', url: query})
  
  if (google_response.hasOwnProperty(error_prop)) {
    response = buildResponse(500, "API Error: GOOGLE API Resource Not Found or API Connection Error", restaurant_id)
  } else {
      //parse and assert schema validity
      try {
          await restaurantSchema.validate(google_response.data.result)
          response = await putRestaurant(event, google_response.data.result)
      } catch (err) {
          response = buildResponse(500, err.message, key)
      }
    }
}

const restaurantSchema = yup.object({
  formatted_address: yup.string(),
  formatted_phone_number: yup.string(),
  geometry: yup.object({
      location: yup.object().required()
  }),
  name: yup.string().required(),
  price_level: yup.number().integer(),
  rating: yup.number().float,
  user_ratings_total: yup.number().integer(),
  website: yup.string().url()
})`,
          desc: 'The Payload from the Step Function is received in the Lambda as an event, and can carry forward the data request.  It will call the Google Place Details API endpoint and retrieve its data based on the restaurant id (place_id) in the payload and validate its Schema using the Yup package.',
          errors: [{type:'Server Error', code: '500', message: 'API Error: GOOGLE API Resource Not Found or API Connection Error'}],
      },
      {
        divider: 'Save Restaurant Reference Data',
        system: 'restaurant-get-api',
        desc: 'If the lambda validates the schema successfully, with no errors returned, it will attempt to update the Restaurant DynamoDB Item with its full Reference Data. A few conditionals are in the save function if some values are returned NULL from the Google API (think newer restaurants without many attributions). The save response will return additional attributes for the Step Function to use in additional steps',
        code: `const handler = async (event, data) => {
try {
  await dynamodb.updateItem({
    TableName: process.env.TABLE_NAME,
    Key: {
      "pk": { "S": \`REST#\${event.restItem.restaurant_id}\` },
      "sk": { "S": \`REST#\${event.restItem.restaurant_id}\` }
    },
    ConditionExpression: "attribute_exists(pk)",
    UpdateExpression: "SET #editorial_summary = :editorial_summary, #formatted_address = :formatted_address, #formatted_phone_number = :formatted_phone_number, #lat = :lat, #lng = :lng, #name = :name, #price_level = :price_level, #rating = :rating, #user_ratings_total = :user_ratings_total, #website = :website",
    ExpressionAttributeNames: {
      '#editorial_summary': 'editorial_summary',
      '#formatted_address': 'formatted_address',
      '#formatted_phone_number': 'formatted_phone_number',
      '#lat': 'lat',
      '#lng': 'lng',
      '#name': 'name',
      '#price_level': 'price_level',
      '#rating': 'rating',
      '#user_ratings_total': 'user_ratings_total',
      '#website': 'website'
    },
    ExpressionAttributeValues: {
      ':editorial_summary': { 'S': data.editorial_summary ? data.editorial_summary.overview : 'NONE'},
      ':formatted_address' : {'S': data.formatted_address ? data.formatted_address : 'NONE'},
      ':formatted_phone_number': {'S': data.formatted_phone_number ? data.formatted_phone_number : 'NONE'},
      ':lat' : {'N': data.geometry.location.lat.toString()},
      ':lng': {'N': data.geometry.location.lng.toString()},
      ':name' : {'S': data.name },
      ':price_level' : {'N': null_price_level.toString()},
      ':rating' : {'N': null_rating.toString()},
      ':user_ratings_total' : {'N': null_user_rating_total.toString()},
      ':website' : {'S': data.website ? data.website : 'NONE'}
    },
    ReturnValues: 'ALL_NEW'
  }).promise()
  return {
    statusCode: 200,
    name: data.name,
    restaurant_id: event.restItem.restaurant_id,
    lat: data.geometry.location.lat,
    long: data.geometry.location.lng,
    key: \`REST#\${event.restItem.restaurant_id}\`
  }
} catch(error) {
  let errorMessage = 'Could not update Restaurant ID'
    
  if (error.code === 'ConditionalCheckFailedException') {
      errorMessage = 'Restaurant PK does not exist.'
  }
  return {
      statusCode: 500,
      error: errorMessage
  }
}
};

module.exports.putRestaurant = handler`,
        errors: [{type:'Restaurant PK does not exist', code: '500', message: 'Restaurant PK does not exist'}, {type:'Could not update Restaurant ID', code: '500', message: 'Could not update Restaurant ID'}],
        on_success: {label: 'Step Function will see the returned 200 status and continue to the next step.  If the Step Function step fails (500 Error), it will stop the Step Function entirely', nav: '/docs'}
    }, 
  ]
},
{step: "seventh", 
    viewbox:"0 10 160 180", 
    desc: "Step Function - Generate Image.  The Step function takes latitude and longitude reference data from Step 1 of the Step Function, generates an Image using Google Static Map API, and saves it to S3",
    breakdown:[
    {
        divider:'Payload passed to Image Lambda',
        system: 'get-restaurant-orchestrator',
        desc:'Payload passed from Restaurant Get Data Lambda.  Notice the additional attributes keeping track now include Keys for downstream updates',
  
        code: `{
  response: {
    statusCode: 200,
    name: 'O-Ku',
    restaurant_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
    lat: 35.1992811,
    long: -80.841413,
    key: "REST#ChIJaaSMp3CfVogRpYMMsxTAWhQ"
  },
  restItem: {
    restaurant_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
    createdAt: '2023-06-24T21:03:33.274Z'
  },
  eatItem: {
    user: 'stev0b@stev0b.com',
    eatId: '2RfUQbcmWw0rf6vLZKR9xTcxVfD',
    restId: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
    restName: 'O-Ku',
    createdAt: '2023-05-09T19:16:51.083Z',
    house_id: 'b37692e6-288f-4bb6-be0b-3d48439be132',
    expiresAt: '2023-05-23T19:16:51.083Z',
    ttl: 1688850175.998
  },
  eat_users: ['stev0b@stev0b.com']
}`,
    },
        {
            divider: 'Generate Restaurant Image from Coordinates',
            system: 'restaurant-get-image-api',
            external_packages: [{name: 'Google Static Map API', url: 'https://developers.google.com/maps/documentation/maps-static/overview'}, {name: 'Axios', url: 'https://www.npmjs.com/package/axios'}, {name: 'Yup', url: 'https://www.npmjs.com/package/yup'}],
      code: `const { saveImage } = require('saveImage');

exports.handler = async (event) => {
  const imageName = event.resp.restaurant_id;
  const lat = event.resp.lat;
  const long = event.resp.long;
  
  const google_response = await getImage(imageName, lat, long)
  const error_prop = 'statusCode';

  let response;
  if (google_response.hasOwnProperty(error_prop)) {
    response = buildResponse(500, "API Error: GOOGLE API Resource Not Found or API Connection Error", event.response.key)
  } else {
    const {title, error } = await saveImage(imageName, image_response.data, event.response)
  }
}
  
const getImage = async (imageName, lat, long) => {
  try {
    const response = await axios.get(\`https://maps.googleapis.com/maps/api/staticmap?center=\${lat}%2c%20\${long}&zoom=15&markers=color:red%7Clabel:\${imageName}%7C\${lat},\${long}&size=800x800&format=png&scale=2&key=\${GOOGLE_API_KEY}\`, {responseType: 'arraybuffer'})
    return response;
  } catch (error) {
    return buildResponse(500, error.response.statusText)
  }
}`,
            desc: 'The Payload from the Step Function is received in the Lambda as an event, and can carry forward the image request.  It will call the Google Static Map API endpoint and retrieve the image using the latitude and longitude as variables from the event.',
            errors: [{type:'Server Error', code: '500', message: 'API Error: GOOGLE API Resource Not Found or API Connection Error'}],
        },
        {
          divider: 'Save Restaurant Image S3',
          system: 'restaurant-get-image-api',
          desc: 'If the lambda grabs the image successfully, with no errors returned, it will attempt to save the image file in the Restaurant S3 Bucket and update the Restaurant Reference Data Record in DynamoDB',
          code: `
const saveImage = async (title, image, event) => {   
  const saveData = await putImage(event, title);
  try {
    await  s3.putObject({
            'Body': image,
            'Bucket': 'stev0b-gold-dev-us-east-1',
            'Key': \`stev0b-restaurant-images/\${title}\`
        }).promise()
    return {
      title: 'Success Saving Image'
    }
  } catch(error) {
    return {
        error: \`Could not save restaurant\`
    }
  }
};

const saveData = async () {
  async (event, imageName) => {  
    try {
      await dynamodb.updateItem({
        TableName: process.env.TABLE_NAME,
        Key: {
          "pk": { "S": event.key },
          "sk": { "S": event.key }
        },
        ConditionExpression: "attribute_exists(pk)",
        UpdateExpression: "SET #image_path = :image_path",
        ExpressionAttributeNames: {
            "#image_path": "image_path"
        },
        ExpressionAttributeValues: {
          ":image_path" : {'S': imageName }
        },
          ReturnValues: 'ALL_NEW'
        }).promise()
        return {
            imageName
        }
    } catch(error) {
      let errorMessage = 'Could not update Restaurant ID Image Path'
      if (error.code === 'ConditionalCheckFailedException') {
        errorMessage = 'Restaurant PK does not exist.'
      }
      return {
        error: errorMessage
      }
    }
};

module.exports = { saveImage }`,
          errors: [{type:'Restaurant PK does not exist', code: '500', message: 'Restaurant PK does not exist'}, {type:'Could not update Restaurant ID', code: '500', message: 'Could not updated Restaurant ID'}],
          on_success: {label: 'Step Function will see the returned 200 status and continue to the next step.  If the Step Function step fails (500 Error), it will stop the Step Function entirely', nav: '/docs'}
      }, 
    ]
},
{step: "eighth", 
  viewbox:"0 10 160 180", 
  desc: "Step Function - Create Reviewers.  The Step function will create Review Items for each user originally marked from the the Eat Item and send a notification to each user to leave a Review",
  breakdown:[
  {
      divider:'Payload passed to Create Reviews Lambda',
      system: 'get-restaurant-orchestrator',
      desc:'Payload passed from Restaurant Image Lambda.',
      code: `{
    response: {
      statusCode: 200,
      name: 'O-Ku',
      restaurant_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
      lat: 35.1992811,
      long: -80.841413,
      key: "REST#ChIJaaSMp3CfVogRpYMMsxTAWhQ"
    },
    restItem: {
      restaurant_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
      createdAt: '2023-06-24T21:03:33.274Z'
    },
    eatItem: {
      user: 'stev0b@stev0b.com',
      eatId: '2RfUQbcmWw0rf6vLZKR9xTcxVfD',
      restId: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
      restName: 'O-Ku',
      createdAt: '2023-05-09T19:16:51.083Z',
      house_id: 'b37692e6-288f-4bb6-be0b-3d48439be132',
      expiresAt: '2023-05-23T19:16:51.083Z',
      ttl: 1688850175.998
    },
    eat_users: ['stev0b@stev0b.com']
  }`,
      },
          {
              divider: 'Generate Eat Reviews',
              system: 'stev0b-create-reviewers',
        code: `
exports.handler = async (event) => {

  //determine Type of Review to create
  let put_item;

  if (event.restItem) {
    const image_path = \`stev0b-restaurant-images/\${event.restId}\`
    put_item = {
        'sk': { 'S': \`REVIEW#\${event.eatId}\` },
        'gsi1pk': { 'S': \`HOUSE#\${event.house_id}\` },
        'gsi1sk': { 'S': \`REVIEW#\${event.eatId}\`},
        'type': { 'S': 'eat' },
        'r_id': {'S': event.restId },
        'id': { 'S': event.eatId },
        'title': {'S': event.response.name },
        'image': {'S': image_path },
        'house_id' : {'S': event.house_id }
    }
  }
  if (event.watchId) {
      const image_path = \`stev0b-film-images\${event.response.poster_path}\`
      put_item = {
          'sk': { 'S': \`REVIEW#\${event.watchId}\` },
          'gsi1pk': { 'S': \`HOUSE#\${event.house_id}\` },
          'gsi1sk': { 'S': \`REVIEW#\${event.watchId}\`},
          'type': { 'S': event.filmType },
          'r_id': {'S': event.filmId },
          'id': { 'S': event.watchId },
          'title': {'S': event.response.title },
          'image': {'S': image_path },
          'house_id' : {'S': event.item.house_id }
      }
  }
        
  let server_response;
  const createdAt = new Date()
  const reviewput = event.users.map((user) => {
    return { 
      PutRequest: {
        Item: {
          'pk': { 'S': \`USER#\${user}\` },
          'user': { 'S': user },
          'createdAt': { 'S': createdAt.toISOString() },
          ...put_item
        }
      } 
    }  
  })
  try {
    await dynamodb.batchWriteItem({
      RequestItems: {
        [process.env.TABLE_NAME]: reviewput
      }
    }).promise()
    server_response = event
  } catch(error) {
    return 'Could not create reviews.'
  }
  return response
}`,
    desc: 'The Payload from the Step Function is received in the Lambda as an event, and can carry forward the create reviews request to create Review Items in DynamoDB.  Since this Lambda is shared across Watch Items and Eat Items, the Function will use the event to determine which type of review to create.',
    errors: [{type:'Server Error', code: '500', message: 'Could not create reviews'}],
    on_success: {label: 'iOS User Notifications are sent to each user, notifying them they can now leave a review for the Eat Experience', nav: '/docs'}
        },
    ]
},
{step: "ninth", 
  viewbox:"0 10 160 180", 
  desc: "Step Function - Confirm Successful Save.  The Step function will stamp the reference item with a Confirmed data attribute as the final step in the series.",
  breakdown:[
  {
      divider:'Payload passed to Create Reviews Lambda',
      system: 'get-restaurant-orchestrator',
      desc:'Payload passed from Create Reviewers Lambda.',
      code: `{
    response: {
      statusCode: 200,
      name: 'O-Ku',
      restaurant_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
      lat: 35.1992811,
      long: -80.841413,
      key: "REST#ChIJaaSMp3CfVogRpYMMsxTAWhQ"
    },
    restItem: {
      restaurant_id: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
      createdAt: '2023-06-24T21:03:33.274Z'
    },
    eatItem: {
      user: 'stev0b@stev0b.com',
      eatId: '2RfUQbcmWw0rf6vLZKR9xTcxVfD',
      restId: 'ChIJaaSMp3CfVogRpYMMsxTAWhQ',
      restName: 'O-Ku',
      createdAt: '2023-05-09T19:16:51.083Z',
      house_id: 'b37692e6-288f-4bb6-be0b-3d48439be132',
      expiresAt: '2023-05-23T19:16:51.083Z',
      ttl: 1688850175.998
    },
    eat_users: ['stev0b@stev0b.com']
  }`,
  },
  {
    divider: 'Update Direct from Step Function ',
    system: 'get-restaurant-orchestrator',
    code: `
{
  "TableName": TABLE_NAME,
  "Key": {
    "pk": {
      "S.$": "$.response.key"
    },
    "sk": {
      "S.$": "$.response.key"
    }
  },
  "UpdateExpression": "SET confirmed = :confirmed",
  "ExpressionAttributeValues": {
    ":confirmed": {
      "BOOL": true
    }
  }
}
    `,
    desc: 'The Step Function will stamp the item directly from the Step Function workflow (no lambda needed).  Since the step has been reached, it is safe to assume S3 and DynamoDB are matching in terms of data integrity, so this Restaurant can be stamped as Confirmed.',
    on_success: {label: 'Step Function ends', nav: '/docs'}
        },
    ]
},
]
  
   return (
    <div>
        <SvgStepper imageTitle = {imageTitle} steps = {steps} classes={classes} theme={theme.colorScheme === 'dark' ? theme.colors.yellow[6] : theme.colors.blue[6]}/>
    </div>
  );
}
const mapStateToProps = state => {
    return { zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(ArchEats)