import dayjs from 'dayjs';
import { connect } from 'react-redux'
import {Helmet} from "react-helmet";
import React, {useState, useEffect} from "react";
import {getUser} from '../../service/authService';
import { useNavigate} from "react-router-dom";
import { useMediaQuery} from '@mantine/hooks';
import { Box, Overlay, Button, Modal, Drawer, Container, Group, Badge, Switch, createStyles, Space, Indicator, Divider, Text, Image, Card, Avatar, LoadingOverlay, Grid, ActionIcon, rem} from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { IconCalendarEvent, IconSettings, IconList, IconCirclePlus, IconCalendarTime } from '@tabler/icons-react';

import UserSettings from './settings';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const oneDay = 24 * 60 * 60 * 1000;


function getDatesBetween(startDate, endDate) {
    const currentDate = new Date(startDate.getTime());
    const dates = [];
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

const useStyles = createStyles((theme) => ({
    card: {
      cursor: 'pointer',
    },
    cal_trip: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[2] : theme.colors.red[7],
        fontWeight: 'bold'
      },
    cal_local: {
        color: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.dark[7],
        fontWeight: 'bold'
    },
    card_trip: {
      
      color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'filled', color: 'red' }).color,
      backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'filled', color: 'red' }).background ,
    },
    card_local: {
      color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'blue' }).color : theme.fn.variant({ variant: 'filled', color: 'blue' }).color,
      backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'blue' }).background : theme.fn.variant({ variant: 'filled', color: 'dark' }).background ,
  },

  rating: {
    position: 'absolute',
    top: theme.spacing.xs,
    right: rem(12),
    pointerEvents: 'none',
  },
 
}));

const UserDashboard = ({dispatch, cal_data, ld_theme}) => {
    const { classes, theme } = useStyles();
    let navigate = useNavigate();
    const user = getUser();
    const name = user !== 'undefine' && user ? user.name : '';
    const isMobile = useMediaQuery("(max-width: 55em)");
    const [calendarView, setCalendarView] = useState(cal_data.view);
    const [selected, setSelected] = useState([]);
    const [value, setValue] = useState();
    const [loading, setLoading] = useState();
    const [data, setData] = useState()
    const [settings, setSettings] = useState(false);
    const [allDates, setAllDates] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0)
  
    useEffect(() => {
        const getDatas = async () => {
            setLoading(true)
            if (!user || user == 'undefine') {
              return navigate('/home/login/visitor')
            }
            const response = await fetch(process.env.REACT_APP_EVENT, {
              method: 'GET',
              headers: {'x-api-key': process.env.REACT_APP_LOGIN_API_KEY, 'Content-Type': 'application/json'},
            });
            const data = await response.json();       
            const datesArray =[]

            data.events.forEach((i) => {
                const dates = getDatesBetween(new Date(i.from_date), new Date(i.to_date))
                const map_to_type = dates.map((x) => ({day: x, event_type: i.event_type}));
                datesArray.push(...map_to_type);
                // datesArray.push(...dates);
            });
            
            const event_data = data.events.map((x) => ({...x, from_date: new Date(x.from_date), to_date: new Date(x.to_date)}));

            setSelected(datesArray)
            const event_data_sorted = event_data.sort(function(a,b){
              return b.from_date - a.from_date
            });
            setData(event_data_sorted)
            dispatch({
              type: 'UPDATE_CAL',
              payload: {...cal_data, data: datesArray, events: event_data_sorted}
            })
            setValue(event_data_sorted)
            setLoading(false)
        }
        if (Object.keys(cal_data.data).length === 0) {
          getDatas()
        } else {
          setSelected(cal_data.data)
          setData(cal_data.events)
          !cal_data.view ? setValue(cal_data.events) : setValue(cal_data.selection)
          !cal_data.view ? setScrollPosition(cal_data.scrollPosition) : setScrollPosition(0)    
        }
        
      },[]);

    const handleAllDates = async (e) => {
        setLoading(true)
        setAllDates(false)
        if (!user || user == 'undefine') {
          return navigate('/home/login/visitor')
        }
        const response = await fetch(`${process.env.REACT_APP_EVENT}/all`, {
          method: 'GET',
          headers: {'x-api-key': process.env.REACT_APP_LOGIN_API_KEY, 'Content-Type': 'application/json'},
        });
        const data = await response.json();       
        const datesArray =[]

        data.events.forEach((i) => {
            const dates = getDatesBetween(new Date(i.from_date), new Date(i.to_date))
            const map_to_type = dates.map((x) => ({day: x, event_type: i.event_type}));
            datesArray.push(...map_to_type);
            // datesArray.push(...dates);
        });
        
        const event_data = data.events.map((x) => ({...x, from_date: new Date(x.from_date), to_date: new Date(x.to_date)}));

        dispatch({
          type: 'UPDATE_CAL',
          payload: {...cal_data, data: datesArray, events: event_data}
        })
        setSelected(datesArray)
        const event_data_sorted = event_data.sort(function(a,b){
          return b.from_date - a.from_date
        });
        setData(event_data_sorted)
        setLoading(false)
    } 
    const handleSelect = (e) => {
        const result = data.filter(d => {
            const selectedDate = new Date(e).getTime()
            const startDate = new Date(d.from_date).getTime();
            const endDate = new Date(d.to_date).getTime();
            return (startDate <= selectedDate && selectedDate <= endDate);
        });
        setValue(result)
        dispatch({
          type: 'UPDATE_CAL',
          payload: {...cal_data, selection: result}
        })
    }

    const handleNavigate = (e) => {
      dispatch({
        type: 'UPDATE_CAL',
        payload: {...cal_data, scrollPosition: window.pageYOffset}
      })
      navigate(`/home/dashboard/event/${e}`)
    }

    const handleSwitch = (e) => {
        if (e === true) {
            setValue(null)
        }
        if (e === false) {
            setValue(data)
        }
        setCalendarView(e)
        setScrollPosition(0)
        dispatch({
          type: 'UPDATE_CAL',
          payload: {...cal_data, view: e}
        })
    }
    return (
        <Container>
          {loading ? <LoadingOverlay visible={loading} overlayBlur={2} /> :
            <div>
            <Group position="apart">
                <Switch
                    checked={calendarView}
                    onChange={(event) => handleSwitch(event.currentTarget.checked)}
                    size="lg"
                    color={ld_theme.theme == "dark" ? 'gray' : 'blue'}
                    onLabel={<IconCalendarEvent size="1rem" stroke={2.5}  />}
                    offLabel={<IconList size="1rem" stroke={2.5} />}
                />
                {/* <ActionIcon size="2rem"
                    radius="xl" 
                    onClick={()=> setAllDates(!allDates)}
                    color={ld_theme.theme == "dark" ? 'gray' : 'blue'}
                >
                  <IconCalendarTime  />
                </ActionIcon> */}
                { user.role == "admin" ? 
                <ActionIcon size="2rem"
                    radius="xl" 
                    onClick={()=> navigate('/home/dashboard/new_event')}
                    color={ld_theme.theme == "dark" ? 'gray' : 'blue'}
                >
                  <IconCirclePlus  />
                </ActionIcon>: null}
                <ActionIcon size="2rem"
                    radius="xl" 
                    onClick={()=> setSettings(!settings)}
                    color={ld_theme.theme == "dark" ? 'gray' : 'blue'}
                >
                  <IconSettings  />
                </ActionIcon>
            </Group>
            <Space h="xl" />
            { calendarView ? 
            <div>
            <Group position="center">    
            <Calendar
                hideOutsideDates
                weekendDays={[]}
                firstDayOfWeek={0}
                numberOfColumns={ isMobile ? 1: 2}
                type="range"
                value={value}
                defaultDate={new Date(cal_data.selectedDate)}
                onNextMonth={(e)=> dispatch({type: 'UPDATE_CAL', payload: {...cal_data, selectedDate: e}})}
                onPreviousMonth={(e)=> dispatch({type: 'UPDATE_CAL', payload: {...cal_data, selectedDate: e}})}
                getDayProps={(date) => ({
                    onClick: () => handleSelect(date),
                })}
                renderDay={(date) => {
                    const days = selected.some((s) => dayjs(date).isSame(s, 'date'));
                    const trips =  selected.some((s) => dayjs(date).isSame(s.day, 'date') && s.event_type == "trip");
                    const local =  selected.some((s) => dayjs(date).isSame(s.day, 'date') && s.event_type == "local");
                    const day = date.getDate();
                    return (
                      <Indicator size={6} color={ld_theme.theme == "dark"? "#FF6B6B": "red"} offset={-2} disabled={!days}>
                        <div className={trips ? classes.cal_trip : local ? classes.cal_local : null }>{day}</div>
                      </Indicator>
                    );
                  }}            
            />
            </Group>
            <Divider my="xs"/></div> : null }
            <Grid
            > 
            {value ? value.map((i) => {
            const nights = Math.round((i.to_date - i.from_date)/oneDay)
            
            return (
                <Grid.Col key={i.id} span={isMobile ? 12: !isMobile && calendarView ? 6 : 6 } offset={isMobile ? 0 : !isMobile && !calendarView ? 0:3}>
                  <Card  className={classes.card} shadow="sm" padding="lg" radius="md" withBorder component="a" onClick={()=> handleNavigate(i.id)} target="_blank">
                    <Card.Section>
                      <Image
                        src={i.image_url}
                        height={160}
                        alt={i.title}
                        withPlaceholder
                      />
                    </Card.Section>

                    <Group position="apart" mt="md" mb="xs">
                      <Text weight={500}>{i.title}</Text>
                      { new Date(i.to_date.getTime() + 3600 * 1000 * 48 ) > new Date(Date.now()) ? 
                      <Badge className={i.event_type == "local" ? classes.card_local : classes.card_trip} variant="light">
                        {i.event_type}
                      </Badge> : 
                      <Badge variant="light" color="grape">
                        {i.event_type}
                      </Badge>}
                    </Group>
                    <Box h={65}>
                    <Text size="sm" color="dimmed">
                      {i.description}
                    </Text></Box>
                    <Group position="apart" mt="md">
                      <Avatar.Group spacing="xs">
                        {i.steven_b ? <Avatar size="md" radius="lg" variant="filled">SB</Avatar> : null }
                        {i.amanda_a ?<Avatar size="md" radius="lg" variant="filled">AA</Avatar> : null }
                        {i.invites.filter((x) => (x.status == "accepted" || x.status=="house_accepted")).length > 0 ? <Avatar size="sm" radius="lg" variant="outline">{`+${i.invites.filter((x) => (x.status == "accepted" || x.status == "house_accepted")).length}`}</Avatar>: null}
                      </Avatar.Group>
                      <Text size="sm" fw={700} color="dimmed">
                        {i.from_date.toLocaleDateString()}
                      </Text>
                      {i.event_type == "trip" ? <Text size="sm" color="dimmed">
                        {nights > 1 ? `${nights} nights`: `${nights} night`}
                      </Text> : null 
                      }
                      {i.event_type == "local" ? <Text size="sm" color="dimmed">
                        {new Date('1970-01-01T' + i.time + 'Z').toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'})}
                      </Text> : null 
                      }
                    </Group> 
                    <Card.Section>
                    <Group grow mt="xs">
                    { new Date(i.to_date.getTime() + 3600 * 1000 * 48 ) > new Date(Date.now()) ? 
                      <Badge radius="xs" variant="light" color={i.invite == "open"? "green" : "gray"}>
                          {i.invite}
                      </Badge>
                    : <Badge radius="xs" variant="light" color="grape">
                        {i.event_type == "local" ? "Past event" : "past trip"}
                      </Badge>}
                    </Group>
                    </Card.Section>           
                  </Card>
              </Grid.Col>
            );
          })
          : null}
          </Grid>
                <Drawer 
                    opened={settings}
                    position="right" 
                    onClose={()=> setSettings(!settings)} 
                    title="Settings"
                    overlayProps={{ opacity: 0.5, blur: 4 }}
                >
                <UserSettings data={data}/>
                </Drawer>
                <Modal 
                  opened={allDates} 
                  onClose={()=> setAllDates(!allDates)}
                  size="auto" 
                  centered
                  title="View Past Events">
                  <Text>Would you like to view all previous events logged in <b>Amanda and Steve's Calendar</b>?</Text>
                  <Group mt="xl" position="center">
                  <Button variant="outline" color="gray" onClick={()=> setAllDates(!allDates)}>
                      No
                    </Button>
                    <Button variant="outline" color={ld_theme.theme == "dark"? "yellow":"blue"} onClick={()=> handleAllDates()}>
                      Yes
                    </Button>
                  </Group>
                </Modal>
            </div>}
        </Container>
    )
}

const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, cal_data: state.cal_data}
}

export default connect(mapStateToProps)(UserDashboard);