import React, {useState, useEffect} from 'react';
import { createStyles, Title, Text, Button, Container, Group, rem, Alert, LoadingOverlay } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { useNavigate, useLocation} from "react-router-dom";
import axios from 'axios';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(80),
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: rem(220),
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(120),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: rem(38),

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(32),
    },
  },

  description: {
    maxWidth: rem(500),
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },
}));

function Unsubscribe() {
  const { classes } = useStyles();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const currentPath=location.pathname
    const email = currentPath.substring(currentPath.lastIndexOf("/") + 1, currentPath.length);
    
    const unsubscribeSubscriber = (email) => {
      setLoading(true);
      setErrorMessage(null);

      axios.delete(process.env.REACT_APP_SUBSCRIBE, {headers: {'x-api-key': process.env.REACT_APP_API_KEY}, data: {email: email }}).then((response) => {
        setLoading(false)
      }).catch((error) => {
        setErrorMessage('Error on Remove')
        setLoading(false);
      })
  }

  unsubscribeSubscriber(email);
    
  },[]);

  return (
    <Container className={classes.root}>
      {/* <div className={classes.label}>Success</div> */}
      { loading ? <LoadingOverlay visible={loading} overlayBlur={2} /> :
      <div>
      <Alert className={classes.description} icon={<IconAlertCircle size="1rem" />} title={errorMessage ? "Bummer!": "Success!"} color={errorMessage ? "red": "green"}>
        {errorMessage ? "Something terrible happened! The system encountered an error when removing your email from the subscription list.  Please refresh to try again, or contact me directly to make sure you're removed from the list.": "You will no longer receive our weekly updates.  Thanks for hanging with us as long as you did!"}
      </Alert>
        <Group position="center">
        {errorMessage ? 
          <Button variant="subtle" size="md" onClick={()=> navigate('/contact')}>
            Contact Steve
          </Button> :
          <Button variant="subtle" size="md" onClick={()=> navigate('/')}>
            Take me back to the dashboard
          </Button>}
        </Group>
      </div>
      }
    </Container>
  );
}

export default Unsubscribe