import { connect } from 'react-redux'
import React from 'react';
import { useNavigate} from "react-router-dom";
import { Paper, keyframes,SimpleGrid, createStyles,Container, Title, Group, Text, ThemeIcon, rem, Space, List} from '@mantine/core';
import { useMediaQuery} from '@mantine/hooks';

import '../index.css';
import { IconCheck, IconArrowNarrowLeft, IconArrowNarrowRight, IconBrandReact} from '@tabler/icons-react';

import {ReactComponent as Frontendsvg} from './svgs/frontend.svg';
import {ReactComponent as Mantine} from './svgs/mantine.svg';
import {ReactComponent as Keyshape} from './svgs/keyshape.svg';
import ArchFrontend from '../components/architecture/archFrontend';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';

const float_top = keyframes({
    '0%': {transform:'translatey(0px)'},
    '50%': {transform:'translatey(-10px)'},
    '100%': {transform: 'translatey(0px)'}
  });

const useStyles = createStyles((theme) => ({
    frontend_light : {
        animation: `${float_top} 6s ease-in-out infinite`,

        '#iphone-button': {fill: 'black'},
        '#donut_1':{fill: theme.colors.lime[8], stroke: theme.colors.grape[9]},
        '#donut_2':{fill: theme.colors.blue[6], stroke: theme.colors.grape[9]},
        '#area-1':{fill: theme.colors.lime[8], stroke: theme.colors.grape[9]},
        '#area-2':{fill: theme.colors.blue[6], stroke: theme.colors.grape[9]},
        '#bar-1':{fill: theme.colors.blue[6], stroke: theme.colors.gray[7]},
        '#bar-2':{fill: theme.colors.blue[6], stroke: theme.colors.gray[7]},
        '#bar-3':{fill: theme.colors.blue[6], stroke: theme.colors.gray[7]},
        '#bar-4':{fill: theme.colors.blue[6], stroke: theme.colors.gray[7]},
        '#dial-1':{stroke: theme.colors.gray[8]},
        '#dial-2':{stroke: theme.colors.gray[8]},
        '#dial-3':{stroke: theme.colors.gray[8]},
        '#dial-4':{fill: theme.colors.lime[5], stroke: theme.colors.gray[8]},
    },
    frontend_dark: {
        animation: `${float_top} 6s ease-in-out infinite`,
        '#iphone_bezel': {stroke: theme.colors.yellow[4]},
        '#iphone_frame': {stroke: theme.colors.yellow[4]},
        '#iphone-button': {fill: theme.colors.yellow[4], stroke: theme.colors.yellow[4]},
        '#donut_1':{fill: theme.colors.red[5], stroke: theme.colors.grape[9]},
        '#donut_2':{fill: theme.colors.grape[6], stroke: theme.colors.grape[9]},
        '#area-1':{fill: theme.colors.grape[6], stroke: theme.colors.grape[9]},
        '#area-2':{fill: theme.colors.red[5], stroke: theme.colors.grape[9]},
        '#bar-1':{fill: theme.colors.red[5], stroke: theme.colors.gray[8]},
        '#bar-2':{fill: theme.colors.red[5], stroke: theme.colors.gray[8]},
        '#bar-3':{fill: theme.colors.red[5], stroke: theme.colors.gray[8]},
        '#bar-4':{fill: theme.colors.red[5], stroke: theme.colors.gray[8]},
        '#dial-1':{stroke: theme.colors.gray[8]},
        '#dial-2':{stroke: theme.colors.gray[8]},
        '#dial-3':{stroke: theme.colors.gray[8]},
        '#dial-4':{fill: theme.colors.grape[5], stroke: theme.colors.gray[8]},
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,
        [theme.fn.smallerThan('55em')]: {
            maxWidth: '100%',
            marginRight: 0,
          },
      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
      },

      alertHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).color : theme.fn.variant({ variant: 'fill', color: 'red' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).background : theme.fn.variant({ variant: 'light', color: 'red' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).color : theme.fn.variant({ variant: 'light', color: 'red' }).color, }
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
}))

function Frontend({ld_theme}) {
    const { classes, cx, theme } = useStyles();
    const isMobile = useMediaQuery("(max-width: 55em)");
    let navigate = useNavigate();
    

    function addCheck() {
        return(
            <ThemeIcon size={20} variant="filled" color={ld_theme.theme == "dark" ? "#BE4BDB" : "#FA5252"}>
                <IconCheck size={rem(12)} stroke={3} />
            </ThemeIcon>
        )
    }
   return (
    <div>
        <Container>
            <div className={classes.inner}>
            <div className={classes.content}>
                <Title className={classes.title}>
                It's not the destination<br /><span className={classes.highlight}>it's the journey</span>
                </Title>
                <Text mt="md" fz="md">
                So it wouldn’t be <i>that</i> great of a data ecosystem if we couldn’t represent the data to anyone outside the household, would it?  The frontend is our piece of functionality to take what the <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/datafeed')}>Data Feed</a></span>deposits into the Gold layer and it points an API at it.  It will retrieve whatever data payloads we need, and display it.  As stated in the data feed section, we have separation of access, so we are not even providing access to our critical infrastructure  - just an S3 bucket of trusted data files, originating from the household.
                </Text>
            </div>
                {isMobile ? null : <Frontendsvg width='45%' viewBox="20 40 200 170" className={ld_theme.theme == 'dark' ? classes.frontend_dark : classes.frontend_light}/> }
            </div>
            {isMobile ? <Group position="center"><Frontendsvg height={rem(200)} width={rem(300)} viewBox="70 70 100 150" className={ld_theme.theme == 'dark' ? classes.frontend_dark : classes.frontend_light}/></Group>: null}
            <Text mt="md" fz="md">
                I always consider the frontend UI as “the final mile.”  It’s an important piece of a project supply chain to think about, but it should not be the core focus of any project.  I think of it as an added touch of polish that keeps a user engaged to the served up, trusted data.  If you’ve been on the journey with me throughout the documentation so far, you’ll notice that even navigation-wise, I put the frontend section last.  Let me explain, why.
                <Space h="md" />
                <b>Build the Data Model First</b>
                <Space h="xs" />
                 I can’t state this enough.  Too often, I see projects focus on how the frontend should look, feel, interact, and how the data should <i>fit</i> and <i>conform</i> to that user interface.  It doesn’t take long, when the data models get short-sighted, the UI designs quickly fall apart, go through revisions, and adjust just to make the data or process “work” to keep things moving for a business.   
                <Space h="xs" />
                If I’m involved in a project where the ownership of the full data lifecycle is internal, my typical - or ideal - approach does not even usually involve a UI, until it aligns to a well thought out data process.  Keep this in mind when you stumble upon any brownfield or greenfield project in your career. 
                <Space h="md" />
                <b>Map out the Data Process</b> 
                <Space h="xs" />Tasks, Arrows, Decision Points, and Swimlanes.  Get it on paper, and make sure every stakeholder is involved.  For our Household, this meant taking considerations for the family members to have Roles, Ease-of-Use for data entry, and making the ‘logging’ of what we watch, eat, and listen to as easy as possible.
                <Space h="md" />   
                <b>Define your Access Patterns</b> 
                <Space h="xs" />Very important (especially for systems like DynamoDB).  This is where it takes some rolling-up-the-sleeves.  Really understand how data needs to be captured and how the data will be queried.  If you are dealing with DynamoDB, this is where I HIGHLY recommend reading up on Alex DeBrie’s <span className={classes.textHighlight}><a href='https://dynamodbbook.com/'>The DynamoDB Book</a></span> It is a great starter on this very topic on setting up a one-table design and provide creative ideas on how to get this started and stay organized to meet your data needs.  For my household, that meant defining all the Entities or Data Sets to define, for example:
                <Space h="md" />
                <List>
                    <List.Item>Houses</List.Item>
                    <List.Item>Users</List.Item>
                    <List.Item>Watches</List.Item>
                    <List.Item>Films</List.Item>
                    <List.Item>Eats</List.Item>
                    <List.Item>Restaurants</List.Item>
                    <List.Item>Listens</List.Item>
                    <List.Item>Reviews</List.Item>
                </List>
                <Space h="md" />
                Laying out all the data entities then lets you define how your defined processes can account for the data maintenance to insert and update these datapoints and what systems will be accessing that data to either pull into Data Feeds, pull directly via API endpoints, or otherwise.  
                <Space h="md" />
                <b>Define Data Governance</b>
                <Space h="xs" />
                Don’t overlook this — Your data needs to be trusted.  How do you ensure that it can be trusted?  This is where your UI starts to play a role — On the point of data entry, how do you provide proactive data entry validation?  Can some fields be validated against common stores of reference data (e.g. cities, zip codes) or reinforce data integrity (e.g. matched keys between a database and upload file location).  If you’re using a Data Feed for distribution of datapoints, define where the balance of governance is hosted.  Frontends can take the brunt of proactive data entry errors, while Data Feeds can use complex logic to do some heavy lifting to really refine the data trust pipeline. 
                <Space h="md" />
                I know I ran with a tangent up above, but with all of this in mind, a data model will start to surface for all the datapoints required to properly run the business.  And that's where you can then build frontends to support it - via workflow forms, documentation, calls-to-action - or like here - dashboards.  Again, I consider it the “final mile” because frontends really do shine when the underlying data is well thought-out and adds that extra touch of polish on top.  Instead of me, every week, manually pulling data out, pushing to a data file, saving to my local macine and uploading for my frontend to read — my family just needs to simply capture their data, and the rest takes care of itself.  Data-driven, in a sense.
                </Text>
                <Space h="xl" />
                <Space h="xl" />
            <Title className={classes.thirdHeading}>
                Tech Overview
            </Title>
            <Text mt="md" fz="md">
                So this Frontend you’re looking at right now is built on a few primary components
                <Space h="md" />
                <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} spacing={50}>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />
                        <div className={classes.featureContent}>
                            <IconBrandReact size={rem(38)} className={classes.featureIcon} stroke={1.5} />
                            <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                            React
                            </Text>
                            <Text fz="sm">
                            Javascript component-driven infrastructure for wiring up a frontend application.  It's the backbone of any web-based ui. 
                            </Text>
                        </div>
                    </div>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />
                        <div className={classes.featureContent}>
                            <Mantine viewBox={isMobile ? "40 -04 1000 120" : "40 -04 750 120" }className={classes.featureIcon} stroke={1.5} />
                            <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                            Mantine
                            </Text>
                            <Text fz="sm">
                            Rich library for frontend design: tables, forms, typogorophy, and styling guidelines for React components. 
                            </Text>
                        </div>
                    </div>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />
                        <div className={classes.featureContent}>
                            <Keyshape viewBox={isMobile ? "60 30 1500 200": "60 30 1500 235"} className={classes.featureIcon} stroke={1.5} />
                            <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                            Keyshape
                            </Text>
                            <Text fz="sm">
                            2-D Vector Art program for MacOS to create lightweight SVGs for better user engagement with complimentary artwork. 
                            </Text>
                        </div>
                    </div> 
            </SimpleGrid>
            <Space h="xl" />
            <span className={classes.textHighlight}><a href='https://create-react-app.dev/'>React</a></span>  is the main engine here.  I have been a fan for a while now, and it’s a simple <span className={classes.textHighlight}><a href='https://dev.to/hiteshtech/a-beginners-guide-to-create-spa-with-react-js-491c'>SPA</a></span> that allows for quick prototyping or building full-scale applications.  The build process helps ease up my CI/CD pipeline easily by just pushing updates directly to the AWS S3 Bucket this is hosted in.
            <Space h="md" />
            <span className={classes.textHighlight}><a href='https://mantine.dev/pages/getting-started/'>Mantine</a></span> is the UI package used for making the site look as amazing as it is.  For anyone considering building a modern UI and have not considered Mantine, I think it is highly underrated.  For those unaware, React is the frontend engine that does the magic to build and render sites, the component libraries are what your eyes get glued to on the screen.  Mantine is filled with many components that have just made the process of building this site and documentation so much fun.  
            <Space h="md" />
            <span className={classes.textHighlight}><a href='https://www.keyshapeapp.com/'>Keyshape</a></span> is the MacOS Application used to create the artwork on this site.  Yes, each SVG image is custom created.  For many images, starting points of artwork were pulled direct from <span className={classes.textHighlight}><a href='https://tabler.io/icons'>Tabler</a></span>  - an absolutely fantastic icon library.  If I didn't use icons, I created images from scratch using <span className={classes.textHighlight}><a href='https://lottiefiles.com/'>LottieFiles</a></span> or a Google Search to draw upon inspiration.  I think images, even if they’re simple vector images, can go a long way in keeping a user engaged with your documentation.
            <Space h="md" />
            By combining these three (3) great tools, I think it provides a great foundation of providing a frontend presentation layer.  There are many custom components lying around this Frontend, but the one I want to mention is the Architecture Diagrams used throughout all the documentation to step through the processes (including the last one below!).   I created one (1) single SVG image of the app ecosystem using Keyshape,  used styling by Mantine to control the CSS color schemes, and wired up React to render those pops of color as the user would progress through the Stepper components. 
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Tech Flow
            </Title>
            <Space h="md" />
            Before I wrap things up and send you back to the Dashboard, let me quickly highlight the Frontend Tech Flow.  If you remember, the Data Feed Gold layer is where we’re fetching our data from, so our Frontend work is quite simple and straightforward.  On landing, there is an API call to the gold bucket to aggregate the House Data and produce a payload for the React application to render.  

            Use the stepper below to follow the tech cycle of the Frontend calling the Data Feed Gold Layer:
            </Text>        
            <Space h="sm" />
            <Group position="center"><ArchFrontend /></Group>
          
          <Space h="xl" />
            {isMobile ?
          <div>  

          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/datafeed')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">Data Feed - @docs/datafeed/</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
              Return to dashboard
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>Dashboard - @dashboard/</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/datafeed')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">Data Feed - @docs/datafeed/</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Return to dashboard
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>Dashboard - @dashboard/</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(Frontend)