import { connect } from 'react-redux'
import React from 'react';
import { rem, createStyles, keyframes } from '@mantine/core';

import SvgStepper from './svgStepper';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';



const useStyles = createStyles((theme) => ({
  highlight: {
    position: 'relative',
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(2)} ${rem(6)}`,
  },
    second : {
      '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#tmdb_stroke':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#app_to_tmdb': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#tmdb_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
      '#tmdb_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    third : {
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#apigateway_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#apigateway_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    fourth : {
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#sessions_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    fifth : {
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dynamodb_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#dynamodb_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    sixth : {
      '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#watch_step':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#tmdb_stroke':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#sessions_to_watch_step':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#watch_step_to_tmdb':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      
      '#watch_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      
      '#app_to_api-2':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      
      '#watchsf_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#watchsf_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  },
  seventh : {
    '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#watch_step':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#tmdb_stroke':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#sessions_to_watch_step':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#watch_step_to_tmdb':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    
    '#watch_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#watch_image_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#app_to_api-2':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    
    '#watchsf_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    '#watchsf_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
},
eighth : {
  '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watch_step':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_to_watch_step':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  
  '#watch_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watch_image_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watch_verify_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  
  '#watchsf_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watchsf_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
},
ninth : {
  '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watch_step':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#sessions_to_watch_step':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  
  '#watch_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watch_image_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watch_verify_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watch_reviewers_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

  
  '#watchsf_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
  '#watchsf_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
}

}))

function ArchWatches() {
const { classes, cx, theme } = useStyles();

const imageTitle = "Watch Experience"
const steps =[
{step: "first", 
    viewbox:"0 0 250 300",
    breakdown: [], 
},
{step: "second", 
  viewbox:"100 10 160 180", 
  desc: "iOS App makes calls to API to retrieve Movies and Shows for logging the Watch experience.  The primary interaction is just between the iOS App and the TMDB API",
  breakdown:[
  {
      divider:'API Request',
      system: 'iOS App',
      desc:'Application sends a request to the API Endpoint with its The Movie Database Token in the API Query:',
      external_packages: [{name: 'TMDB API', url: 'https://developer.themoviedb.org/docs'}, {name: 'Axios', url: 'https://www.npmjs.com/package/axios'}],
      code: `{
  let query = \`https://api.themoviedb.org/3/search/\${selection}?api_key=\${TMDB_API_KEY}&language=en-US&query=\${search}&page=\${page}&include_adult=false\`
  axios({
      method: 'get',
      url: query,
  })
}`,
  },
      {
          divider: 'Catch API Response',
          system: 'TMDB API',
          desc: 'The Movie Database API evaluates the request query, ensures the token is authorized to search, and the path is a valid endpoint. If the request is good, it will pass an Array of results to the iOS App',
          errors: [{type:'Invalid URL or Path', code: '403', message: 'Unauthorized'}, {type:'Invalid Access Token', code: '403', message: 'Forbidden'}],
          on_success: {label: 'Payload passes an Array of objects with Restaurants', nav: '/docs'}
      }
  ]
  },
{step: "third", 
viewbox:"100 10 160 180", 
desc: "User requests to save a Watch Experience.  This is the first point where the AWS Ecosystem will take care of the Reference Data Points and Log the Experience",
breakdown:[
{
    divider:'App Request',
    system: 'iOS App',
    desc:'Application sends a request to the API Endpoint with its application-specific API Token in the request header, a bearer token to verify the User Session, and the Watch request body:',
    external_packages: [{name: 'Axios', url: 'https://www.npmjs.com/package/axios'}],
    code: `{
  url: 'stev0b-api-url/watch/stev0b@stev0b.com',
  method: 'post',
  headers: {
    Authorization: 'bearer jardv3Lp-Szew-UHSbf',
    x-api-key: 'TXkOp3J1SbuadZqudDXT11CsWNa8xi'
  },
  body: {
    film_id: '1400', // API.id
    film_title: 'Seinfeld',
    film_type: 'TV', 
    watch_users: ['stev0b@stev0b.com'],
  }
}`,
},
    {
        divider: 'Evaluate Request',
        system: 'API Gateway',
        desc: 'The API Gateway evaluates the request header url and ensures the path is a valid endpoint. As an added layer of security, the x-api-key is validated as only the application should have that token.',
        errors: [{type:'Invalid URL or Path', code: '403', message: 'Unauthorized'}, {type:'Invalid API Key', code: '403', message: 'Forbidden'}],
        on_success: {label: 'Payload passes to Sessions lambda as an event for further processing', nav: '/docs'}
    }
]
},
{step: "fourth", 
viewbox:"100 10 160 180", 
desc: "The Sessions Lambda will determine which function to execute based on the validated payload received from the API Gateway and process the event.",
breakdown:[
{
    divider:'API Event',
    system: 'API Gateway',
    desc:'API Gateway passes the payload to the Sessions Lambda for further processing:',
    code: `{
  url: 'stev0b-api-url/watch/stev0b@stev0b.com',
  method: post,
  headers: {
    Authorization: bearer jardv3Lp-Szew-UHSbf,
    x-api-key: TXkOp3J1SbuadZqudDXT11CsWNa8xi
  },
  body: {
    film_id: '1400', // API.id
    film_title: 'Seinfeld',
    film_type: 'TV', 
    watch_users: ['stev0b@stev0b.com']
  }
}`,
},
    {
        divider: 'Determine Function to Execute',
        system: 'Sessions Lambda',
        desc: 'The Sessions Lambda contains many stored functions and will use the API Gateway event to determine which function to execute based on the payload received',
        code: `const { createWatchItemForUser } = require('./handlers/createWatchItemForUser');
const watchUserPath = '/watch/{user}';

exports.handler = async (event) => {
  let response;
  switch(true) {
    ...
    case event.httpMethod === 'POST' && event.path === watchUserPath: 
      response = await createWatchItemForUser(event);
      break;
    
    default:
      response = { statusCode: 404, body: JSON.stringify(event)};
  }
  return response
}`,
        errors: [{type:'Invalid Path or Undetermined Function', code: '404', message: 'Unauthorized'}],
    },
    {
        divider: 'Create Watch Item - Validate Session',
        system: 'Sessions Lambda',
        desc: 'The Create Watch Item function will first confirm the user is a valid and logged-in user from the Authorization token from the passed event and the URL user matches with the Session ID stored in DynamoDB.',
        code: `const { getSession } = require('../data');
const { Session } = require('../entities')
const { buildResponse } = require('./utils');

const handler = async (event) => {
  const sessionId = event.headers.Authorization.toLowerCase().replace('bearer ', '');
  const sessionItem = new Session({sessionId: sessionId}) 
  
  try {
    const user = await getSession(sessionItem)
    ...
  } catch(error) {
    buildResponse(403, "Invalid Session, Login Required")
  }

}
module.exports.updateReview = handler;`,
        errors: [{type:'Invalid Session', code: '403', message: 'Unauthorized'}],
    },
    {
        divider: 'Create Watch Item - Validate Schema',
        system: 'Sessions Lambda',
        desc: `The Create Watch Item function will also confirm the schema in the event payload is also valid.  If the function returns an error, the entire error array will be sent in the response (i.e. more than one field could have an error). Sessions uses the external library Yup for schema validation`,
        external_packages: [{name: 'Yup', url: 'https://www.npmjs.com/package/yup'}],
        code: `const { buildResponse } = require('./utils');

const handler = async (event) => {
  const event_body = JSON.parse(event.body);
  
  try {
    await watchSchema.validate(event_body)
    ...
  } catch(error) {
    buildResponse(400, {client_errors: err.errors})
  }

}
module.exports.updateReview = handler;

const watchSchema = yup.object({
  film_id: yup
    .string({film_id:'String Values only'})
    .required({film_id:'Film ID is Required'})
    .max(50,{film_id: 'Film IDs cannot be more than 50 characters'}),

  film_title: yup
    .string({film_title:'String Values only'})
    .required({film_title:'Film Title is Required'})
    .max(254,{film_title: 'Film Title cannot be more than 254 characters'}),
  
  film_type: yup
   .string({film_type:'String Values only'})
   .matches(/(tv|movie)/,{message: {film_type:"Invalid film type - Films should only be a Movie or TV"}}),

  watch_users: yup
    .array()
    .min(1, {watch_users: "At least one (1) user needs to be selected"})
});`,
        errors: [{type:'Invalid Schema', code: '400', message: 'Unauthorized'}],
        on_success: {label: 'Sessions passes the event body to DynamoDB to commit the update', nav: '/docs'}
    }
 ],
},
{step: "fifth", 
viewbox:"100 10 160 180", 
desc: "The Sessions Lambda will determine which Entities to create in DynamoDB by checking if an existing Film Item already exists for the Household.  If it doesn't the Step Function will get called",
 breakdown: [
{
    divider:'Sessions Prep Data',
    system: 'Sessions Lambda',
    desc:'With the user and schema validated, Sessions will construct the Watch Item in a format for saving to DynamoDB by calling its Watch Class and constructing the Watch Item.  The main goal at this step is for the function to determine which function to call for DynamoDB: Create Watch Item with User Reviews (Restaurant Item exists) or Create Watch Item and Film Item (Film Item does not exist',
    code: `const { Watch } = require('../entities')
const { getSession, getFilm, createWatchItemForUser, createWatchItemFilmItemForUser } = require('../data');
  
const handler = async (event) => {
  const event_body = JSON.parse(event.body);
  const user = await getSession(sessionItem)
  ...
  try {
    const watchItem = new Watch({
      user: user.user_id,
      filmId: event_body.film_id,
      filmTitle: event_body.film_title,
      filmType: event_body.film_type,
      houseId: user.house_id
    })
    const watchUsers = event_body.watch_users
    const filmItem = await getFilm(watchItem.filmId)

    let response;
    if (filmItem.status_code === 200) {   //Film Found
      const response = await createWatchItemForUser(watchItem, watchUsers, filmItem)
    }
    if (restItem.status_code !== 200) {  //Film Not Found
      const response = await createWatchItemFilmItemForUser(watchItem, watchUsers)
    }
    const statusCode = response.watchItem ? 200:500
    return buildResponse(statusCode, {
        response,
        server_error: response.error
      })
  } catch(error) {
    buildResponse(500, "API Error")
  }
}`,
},
    {
        divider: 'Film Exists - Create Watch Item and Reviews for Users',
        system: 'DynamoDB',
        desc: 'If the Sessions Lambda determines the Film already exists, this will be the called function to create the Watch Experience and Generate User Reviews in DynamoDB. The function will apply a Time-To-Live (ttl) to the Watch Item and set the Global Secondary Index attributes (gsi1) for the Feed to query on its next run.  TTL indicates that the record can expire or auto-delete after 14 days. The Reviews will use existing attributes from the found Film Item from Sessions Lambda and create the Reviews as a Batch Item to ensure data integrity (if the batch fails, no reviews are created).  This helps with data consistency.',
        code: `const EXPIRATION = 14 * 86400000;
const currentDate = new Date();
const currentDateExp = new Date(currentDate.getTime() + EXPIRATION);
const ttl = currentDateExp.getTime() / 1000

const createWatchItemForUser = async (watchItem, watchUsers, filmItem) => {
  try {
    await createWatchItem(watchItem)
    await createReviews(watchItem, watchUsers, filmItem)
    return { watchItem }
  } catch (error){
    return {error: error}
  }
}

const createEatItem = async (watchItem, watchUsers, filmItem) => {
  try {
    await dynamodb.putItem({
        TableName: process.env.TABLE_NAME,
        Item: {
          'pk' : {'S' : \`USER#\${watchItem.user}\`},
          'sk' : {'S' : \`WATCH#\${watchItem.watchId}\`},
          'gsi1pk' : {'S' : 'FEED'},
          'gsi1sk' : {'S' : \`\${currentDate.toISOString()}\`},
          'type' : {'S' : 'WATCH'},
          'user' : {'S' : \`\${watchItem.user}\`},
          'id' : {'S' : \`\${watchItem.watchId}\`},
          'film_id' : {'S' : \`\${watchItem.filmId}\`},
          'film_title' : {'S' : \`\${watchItem.filmTitle}\`},
          'film_type' : {'S' : \`\${watchItem.filmType}\`},
          'house_id' : {'S' : \`\${watchItem.houseId}\`},
          'createdAt' : {'S' : \`\${currentDate.toISOString()}\`},
          'expiresAt' : {'S' : \`\${currentDateExp.toISOString()}\`},
          'TTL' : {'S' : \`\${ttl.toISOString()}\`},
        }
    }).promise()
    return { watchItem }
  } catch (error) {
    return { error: error }
  }
}

const createReviews = async (watchItem, watchUsers, filmItem) => {
  const reviewPut = watchUsers.map((i) => {
    const reviewItem = {
      'pk' : {'S' : \`USER#\${i}\`},
      'sk' : {'S' : \`REVIEW#\${watchItem.watchId}\`},
      'createdAt' : {'S' : \`\${currentDate.toISOString()}\`},
      'gsi1pk': {'S' : \`HOUSE#\${watchItem.house_id}\`},
      'gis1sk' : {'S' : \`REVIEW#\${watchItem.watchId}\`},
      'r_id': {'S' : \`\${watchItem.filmId}\`,
      'id' : {'S' : \`\${watchItem.watchId}\`},
      'title' : {'S' : \`\${filmItem.title}\`},
      'user' : {'S' : \`\${i}\`},
      'type' : {'S' : 'watch'},
      'image': {'S' : \`ste0b-film-images/\${filmItem.poster_path}\`},
      'house_id' : {'S' : \`HOUSE#\${watchItem.house_id}\`},
    }
    return { PutRequest: {
        Item: reviewItem 
      }
    }   
  })
  try {
      await dynamodb.batchWriteItem({
          RequestItems: {
              [process.env.TABLE_NAME]: reviewput
          }
      }).promise()
  } catch(error) {
      return 'Could not create reviews.'
  }
}
module.exports = { createEatItemForUser }`,
        errors: [{type:'Server Error', code: '500', message: 'Unauthorized'}],
        on_success: {label: 'Watch Item and Reviews successfully saved. Sessions sends two responses back to the iOS App. First is the Success message from the successful Watch Save.  The 2nd is for each User created reviews will receive a message that a Review is waiting for their input', nav: '/docs'}
    },
    {
      divider: 'Film Null - Create Watch Item and Film Item',
      system: 'DynamoDB',
      external_packages: [{name: 'uuid v4', url: 'https://www.npmjs.com/package/uuidv4'}],
      desc: 'If the Sessions Lambda determines the Film does not exist, this function will be called to create the Watch Experience and Generate a placeholder Film Item in DynamoDB. The function will apply a Time-To-Live (ttl) to the Watch Item and set the Global Secondary Index attributes (gsi1) for the Feed to query on its next run.  TTL indicates that the record can expire or auto-delete after 14 days. After, both, the Watch Item and Film Item are created, the function will call the Step Function to begin sourcing the Film Reference Data',
      code: `const EXPIRATION = 14 * 86400000;
const currentDate = new Date();
const currentDateExp = new Date(currentDate.getTime() + EXPIRATION);
const ttl = currentDateExp.getTime() / 1000
const { v4: uuidv4 } = require('uuid');

const createWatchItemFilmItemForUser = async (watchItem, watchUsers) => {
  try {
    await createEatItem(watchItem)
    await startStateMachine(watchItem, watchUsers)
    return { watchItem }
  } catch (error){
    return {error: error}
  }
}

const createEatItem = async (watchItem, filmItem) => {
  try {
    const params = {
      TransactItems: [
        {
          Put: {
            Item: dynamoEatItem,
            TableName: process.env.TABLE_NAME,
            ConditionExpression: "attribute_not_exists(PK)"
          },
        },
        {
          Put: {
            Item: dynamoRestItem,
            TableName: process.env.TABLE_NAME,
            ConditionExpression: "attribute_not_exists(PK)"
          },
        }
      ]
    }
    await executeTransactWrite({ client: dynamodb, params })
    return {
      watch_item: watchItem,
      film_item: filmItem,
      message: "Watch and Film Session Successfully Saved"
    }
  } catch (error) {
    return { error: error }
  }
}

const startStateMachine = async function startStateMachine(filmItem, watchItem, watchUsers) {
  const job_id = uuidv4();
  const params = {
    stateMachineArn: \`\${process.env.SF_ARN}:get-film-orchestrator\`,
    name: job_id,
    input: JSON.stringify({
        job_id: job_id,
        filmItem: {film_id: watchItem.filmId, createdAt: currentDate},
        watchItem: watchItem,
        eat_users: eatUsers
    })
  };

  return await stepfunctions.startExecution(params).promise()
}

const dynamoWatchItem = {
  'pk' : {'S' : \`USER#\${watchItem.user}\`},
  'sk' : {'S' : \`WATCH#\${watchItem.watchId}\`},
  'gsi1pk' : {'S' : 'FEED'},
  'gsi1sk' : {'S' : \`\${currentDate.toISOString()}\`},
  'type' : {'S' : 'WATCH'},
  'user' : {'S' : \`\${watchItem.user}\`},
  'id' : {'S' : \`\${watchItem.watchId}\`},
  'film_id' : {'S' : \`\${watchItem.filmId}\`},
  'film_title' : {'S' : \`\${watchItem.filmTitle}\`},
  'film_type' : {'S' : \`\${watchItem.filmType}\`},
  'house_id' : {'S' : \`\${watchItem.houseId}\`},
  'createdAt' : {'S' : \`\${currentDate.toISOString()}\`},
  'expiresAt' : {'S' : \`\${currentDateExp.toISOString()}\`},
  'TTL' : {'S' : \`\${ttl.toISOString()}\`},
}

const dynamoFilmItem = {
  'pk' : {'S' : \`FILM#\${watchItem.filmType}#\${watchItem.filmId}\`},
  'sk' :{'S' : \`FILM#\${watchItem.filmType}#\${watchItem.filmId}\`},
  'gsi1pk' : {'S' : 'FEED'},
  'gsi1sk' : {'S' : \`\${currentDate.toISOString()}\`},
  'type' : {'S' : '\${watchItem.filmType}'},
  'film_id': {'S' : \`FILM#\${watchItem.filmId}\`},
  'createdAt': { 'S': this.createdAt.toISOString() }
}

module.exports = { createWatchItemFilmItemForUser }`,
      errors: [{type:'Server Error', code: '500', message: 'Unauthorized'}],
      on_success: {label: 'Watch Item and Film Item placeholder successfully saved. Sessions sends one success response back to the iOS App', nav: '/docs'}
  },    
]
},
{step: "sixth", 
  viewbox:"100 10 160 180", 
  desc: "Step Function Step 1 - Gather Data.  When no Film is identified, the Step Function is called to begin sourcing data. It will call the TMDB API to pull & save Movie or TV reference data points.",
  breakdown:[
  {
      divider:'Sessions Payload to Data Lambda',
      system: 'get-restaurant-orchestrator',
      desc:'Payload passed from Sessions Lambda',

      code: `{
  job_id: '0c53628e-9392-434d-8d02-3bd0cb500efe',
  filmItem: {
    film_id: '1400',
    film_type: 'TV',
    createdAt: '2023-06-24T21:03:33.274Z'
  },
  watchItem: {
    user: 'stev0b@stev0b.com',
    watchId: '2RfUQbcmWw0rf6vLZKR9xTcxVfD',
    filmId: '1400',
    filmTitle: 'Seinfeld',
    filmType: 'TV',
    createdAt: '2023-05-09T19:16:51.083Z',
    house_id: 'b37692e6-288f-4bb6-be0b-3d48439be132',
    expiresAt: '2023-05-23T19:16:51.083Z',
    ttl: 1688850175.998
  },
  eat_users: ["stev0b@stev0b.com"]
}
  }`,
  },
      {
          divider: 'Get Film Reference Data',
          system: 'film-get-api',
          external_packages: [{name: 'TMDB API', url: 'https://developer.themoviedb.org/docs'}, {name: 'Axios', url: 'https://www.npmjs.com/package/axios'}, {name: 'Yup', url: 'https://www.npmjs.com/package/yup'}],
    code: `
exports.handler = async (event) => {
  const film_id = event.filmItem.film_id;
  const film_type = event.filmItem.film_type;
  const error_prop = 'statusCode';

  let response;
  let query = \`await axios.get(\`https://api.themoviedb.org/3/\${film_type}/\${film_id}?api_key=\${TMDB_API_KEY}\`
  const tmdb_response = await axios({method: 'get', url: query})
  
  if (google_response.hasOwnProperty(error_prop)) {
    response = buildResponse(500, "API Error: TMDB API Resource Not Found or API Connection Error", film_id)
  } else {
      //parse and assert schema validity
      try {
          await filmSchema.validate(tmdb_response.data.result)
          response = await putRestaurant(event, tmdb_response.data.result)
      } catch (err) {
          response = buildResponse(500, err.message, key)
      }
    }
}

const tvSchema = yup.object({
  backdrop_path: yup.string().required(),
  genres: yup.array().required(),
  first_air_date: yup.string().required(),
  external_ids: yup.object({ 
      imdb_id:yup.string().required()
  }),
  number_of_episodes: yup.number().required().integer(),
  number_of_seasons: yup.number().required().integer(),
  name: yup.string().required(),
  overview: yup.string().required(),
  poster_path: yup.string().required(),
  status: yup.string().required(),
  tagline: yup.string()
});
          
const movieSchema = yup.object({
  genres: yup.array().required(),
  backdrop_path: yup.string().required(),
  budget: yup.number().required().integer(),
  imdb_id:yup.string().required(),
  overview: yup.string().required(),
  poster_path: yup.string().required(),
  release_date: yup.string().required(),
  revenue: yup.number().required().integer(),
  runtime: yup.number().required().integer(),
  tagline: yup.string().required(),
  title: yup.string().required()
});`,
          desc: 'The Payload from the Step Function is received in the Lambda as an event, and can carry forward the data request.  It will call The Movie Database API endpoint and retrieve its data based on the film id and film type in the payload and validate its Schema using the Yup package.',
          errors: [{type:'Server Error', code: '500', message: 'API Error: GOOGLE API Resource Not Found or API Connection Error'}],
      },
      {
        divider: 'Save Film Reference Data',
        system: 'film-get-api',
        desc: 'If the lambda validates the schema successfully, with no errors returned, it will attempt to update the Film DynamoDB Item with its full Reference Data. A few conditionals are in the save function if some values are returned NULL from the TMDB API (think newer films without many attributions). The save response will return additional attributes for the Step Function to use in additional steps',
        code: `const handler = async (event, data) => {
//below is the attributes used to save a TV Schema.  Movie schema is not included here -- 
try {
  await dynamodb.updateItem({
    TableName: process.env.TABLE_NAME,
    Key: {
      "pk": { "S": \`FILM#\${event.film_item.film_type}#\${event.film_item.film_id}\` },
      "sk": { "S": \`FILM#\${event.film_item.film_type}#\${event.film_item.film_id}\` }
  },
  ConditionExpression: "attribute_exists(pk)",
  UpdateExpression: "SET #genres = :genres, #first_air_date = :first_air_date, #imdb_id = :imdb_id, #number_of_episodes = :number_of_episodes, #number_of_seasons = :number_of_seasons, #name = :name, #overview = :overview, #status = :status, #tagline = :tagline",
  ExpressionAttributeNames: {
      '#genres': 'genres',
      '#first_air_date': 'first_air_date',
      '#imdb_id': 'imdb_id',
      '#number_of_episodes':'number_of_episodes',
      '#number_of_seasons': 'number_of_seasons',
      '#name': 'name',
      '#overview': 'overview',
      '#status': 'status',
      '#tagline': 'tagline',
  },
  ExpressionAttributeValues: {
      ':genres' : {'M': formatGenres},
      ':first_air_date' : {'S': data.first_air_date },
      ':imdb_id': {'S': data.external_ids.imdb_id },
      ':number_of_episodes': {'N': data.number_of_episodes.toString()},
      ':number_of_seasons': {'N': data.number_of_seasons.toString()},
      ':name': {'S': data.name},
      ':overview' : {'S': data.overview},
      ':status': {'S': data.status},
      ':tagline' : {'S': data.tagline}
  },
    ReturnValues: 'ALL_NEW'
  }).promise()
  return {
    statusCode: 200,
    title: data.name,
    film_id: event.filmItem.filmId,
    backdrop_path : data.backdrop_path,
    poster_path: data.poster_path,
    key: \`FILM#\${event.film_item.film_type}#\${event.film_item.film_id}\`
  }
} catch(error) {
  let errorMessage = 'Could not update Film ID'
    
  if (error.code === 'ConditionalCheckFailedException') {
      errorMessage = 'Film PK does not exist.'
  }
  return {
      statusCode: 500,
      error: errorMessage
  }
}
};

module.exports.putRestaurant = handler`,
        errors: [{type:'Film PK does not exist', code: '500', message: 'Film PK does not exist'}, {type:'Could not update Film ID', code: '500', message: 'Could not update Film ID'}],
        on_success: {label: 'Step Function will see the returned 200 status and continue to the next step.  If the Step Function step fails (500 Error), it will stop the Step Function entirely', nav: '/docs'}
    }, 
  ]
},
{step: "seventh", 
    viewbox:"100 10 160 180", 
    desc: "Step Function - Save Images.  Function will download the images from another area of the TMDB API and save both files to S3 and update the Film Item with the S3 paths.",
    breakdown:[
    {
        divider:'Payload passed to Image Lambda',
        system: 'get-film-orchestrator',
        desc:'Payload passed from Film Get Data Lambda.  Notice the additional attributes keeping track in the response created and its Key for downstream updates',
  
        code: `{
  response: {
    statusCode: 200,
    title: 'Seinfeld',
    film_id: '1400',
    film_type: 'TV',
    backdrop_path : '/wEP4CLuxGFrkECTyY50cVMGySLm.jpg',
    poster_path: '/aCw8ONfyz3AhngVQa1E2Ss4KSUQ.jpg',
    key: 'FILM#TV#1400'
  },
  filmItem: {
    film_id: '1400',
    film_type: 'TV',
    createdAt: '2023-06-24T21:03:33.274Z'
  },
  watchItem: {
    user: 'stev0b@stev0b.com',
    watchId: '2RfUQbcmWw0rf6vLZKR9xTcxVfD',
    filmId: '1400',
    filmType: "TV",
    createdAt: '2023-05-09T19:16:51.083Z',
    house_id: 'b37692e6-288f-4bb6-be0b-3d48439be132',
    expiresAt: '2023-05-23T19:16:51.083Z',
    ttl: 1688850175.998
  },
  watch_users: ['stev0b@stev0b.com']
}`,
    },
        {
            divider: 'Save Film Images',
            system: 'film-get-image-api',
            external_packages: [{name: 'TMDB API', url: 'https://developer.themoviedb.org/docs'}, {name: 'Axios', url: 'https://www.npmjs.com/package/axios'}, {name: 'Yup', url: 'https://www.npmjs.com/package/yup'}],
      code: `const { saveImage } = require('saveImage');

exports.handler = async (event) => {
  const backdropName = event.resp.backdrop_path.replace(/\//g, '');
  const posterName = event.resp.poster_path.replace(/\//g, '');
  
  let images = []
  const tmdb_response = await getImage(images)
  for (let i of images) {
    const tmdb_response = await getImage(i)
  }

  let response;
  if (google_response.hasOwnProperty(error_prop)) {
    response = buildResponse(500, "API Error: GOOGLE API Resource Not Found or API Connection Error", event.response.key)
  } else {
    const {title, error } = await saveImage(imageName, image_response.data, event.response)
  }
}
  
const getImage = async (imageName) => {
  try {
    const image = await axios.get(\`https://image.tmdb.org/t/p/original/\${imageName}\`, {responseType: 'arraybuffer'})
    await  s3.putObject({
      'Body': image,
      'Bucket': 'stev0b-gold-dev-us-east-1',
      'Key': \`stev0b-film-images/\${imageName}\`
    }).promise()
    await dynamodb.updateItem({
      TableName: process.env.TABLE_NAME,
        Key: {
          "pk": { "S": event.response.key },
          "sk": { "S": event.response.key }
        },
        ConditionExpression: "attribute_exists(pk)",
        UpdateExpression: "SET #image_path = :image_path",
        ExpressionAttributeNames: {
            "#image_path": "image_path"
        },
        ExpressionAttributeValues: {
          ":image_path" : {'S': imageName }
        },
          ReturnValues: 'ALL_NEW'
        }).promise()
        return {
            imageName
        }
      } catch(error) {
        let errorMessage = 'Could not update Film ID Image Path'
        if (error.code === 'ConditionalCheckFailedException') {
          errorMessage = 'Film PK does not exist.'
        }
        return {
          error: errorMessage
          }
        }
    };
  } catch (error) {
    return buildResponse(500, error.response.statusText)
  }
}`,
            desc: 'The Payload from the Step Function is received in the Lambda as an event, and can carry forward the image request.  It will call the TMDB Image API endpoint and retrieve the image using the ids of poster and backdrop paths as variables from the event.  If successful, it will then attempt to save the image to S3 and append the Reference DynamoDB item with the poster and backdrop paths in S3',
            errors: [{type:'Server Error', code: '500', message: 'API Error: GOOGLE API Resource Not Found or API Connection Error'}],
        },
    ]
},
{step: "eighth", 
  viewbox:"100 10 160 180", 
  desc: "Step Function - Create Reviewers.  The Step function will create Review Items for each user originally marked from the the Watch Item and send a notification to each user to leave a Review",
  breakdown:[
  {
      divider:'Payload passed to Create Reviews Lambda',
      system: 'get-film-orchestrator',
      desc:'Payload passed from Film Image Lambda.',
      code: `
{
  response: {
    statusCode: 200,
    title: 'Seinfeld',
    film_id: '1400',
    film_type: 'TV',
    backdrop_path : '/wEP4CLuxGFrkECTyY50cVMGySLm.jpg',
    poster_path: '/aCw8ONfyz3AhngVQa1E2Ss4KSUQ.jpg',
    key: 'FILM#TV#1400'
  },
  filmItem: {
    film_id: '1400',
    film_type: 'TV',
    createdAt: '2023-06-24T21:03:33.274Z'
  },
  watchItem: {
    user: 'stev0b@stev0b.com',
    watchId: '2RfUQbcmWw0rf6vLZKR9xTcxVfD',
    filmId: '1400',
    filmType: "TV",
    createdAt: '2023-05-09T19:16:51.083Z',
    house_id: 'b37692e6-288f-4bb6-be0b-3d48439be132',
    expiresAt: '2023-05-23T19:16:51.083Z',
    ttl: 1688850175.998
  },
  watch_users: ['stev0b@stev0b.com']
}`,
      },
          {
              divider: 'Generate Watch Reviews',
              system: 'stev0b-create-reviewers',
        code: `
exports.handler = async (event) => {

  //determine Type of Review to create
  let put_item;

  if (event.restItem) {
    const image_path = \`stev0b-restaurant-images/\${event.restId}\`
    put_item = {
        'sk': { 'S': \`REVIEW#\${event.eatId}\` },
        'gsi1pk': { 'S': \`HOUSE#\${event.house_id}\` },
        'gsi1sk': { 'S': \`REVIEW#\${event.eatId}\`},
        'type': { 'S': 'eat' },
        'r_id': {'S': event.restId },
        'id': { 'S': event.eatId },
        'title': {'S': event.response.name },
        'image': {'S': image_path },
        'house_id' : {'S': event.house_id }
    }
  }
  if (event.watchItem) {
      const image_path = \`stev0b-film-images\${event.response.poster_path}\`
      put_item = {
          'sk': { 'S': \`REVIEW#\${event.watchId}\` },
          'gsi1pk': { 'S': \`HOUSE#\${event.house_id}\` },
          'gsi1sk': { 'S': \`REVIEW#\${event.watchId}\`},
          'type': { 'S': event.filmType },
          'r_id': {'S': event.filmId },
          'id': { 'S': event.watchId },
          'title': {'S': event.response.title },
          'image': {'S': image_path },
          'house_id' : {'S': event.item.house_id }
      }
  }
        
  let server_response;
  const createdAt = new Date()
  const reviewput = event.users.map((user) => {
    return { 
      PutRequest: {
        Item: {
          'pk': { 'S': \`USER#\${user}\` },
          'user': { 'S': user },
          'createdAt': { 'S': createdAt.toISOString() },
          ...put_item
        }
      } 
    }  
  })
  try {
    await dynamodb.batchWriteItem({
      RequestItems: {
        [process.env.TABLE_NAME]: reviewput
      }
    }).promise()
    server_response = event
  } catch(error) {
    return 'Could not create reviews.'
  }
  return response
}`,
    desc: 'The Payload from the Step Function is received in the Lambda as an event, and can carry forward the create reviews request to create Review Items in DynamoDB.  Since this Lambda is shared across Watch Items and Eat Items, the Function will use the event to determine which type of review to create.',
    errors: [{type:'Server Error', code: '500', message: 'Could not create reviews'}],
    on_success: {label: 'iOS User Notifications are sent to each user, notifying them they can now leave a review for the Eat Experience', nav: '/docs'}
        },
    ]
},
{step: "ninth", 
  viewbox:"100 10 160 180", 
  desc: "Step Function - Confirm Successful Save.  The Step function will stamp the reference item with a Confirmed data attribute as the final step in the series.",
  breakdown:[
  {
      divider:'Payload passed to Create Reviews Lambda',
      system: 'get-film-orchestrator',
      desc:'Payload passed from Create Reviewers Lambda.',
      code: `{
response: {
  statusCode: 200,
  title: 'Seinfeld',
  film_id: '1400',
  film_type: 'TV',
  backdrop_path : '/wEP4CLuxGFrkECTyY50cVMGySLm.jpg',
  poster_path: '/aCw8ONfyz3AhngVQa1E2Ss4KSUQ.jpg',
  key: 'FILM#TV#1400'
},
filmItem: {
  film_id: '1400',
  film_type: 'TV',
  createdAt: '2023-06-24T21:03:33.274Z'
},
watchItem: {
  user: 'stev0b@stev0b.com',
  watchId: '2RfUQbcmWw0rf6vLZKR9xTcxVfD',
  filmId: '1400',
  filmType: "TV",
  createdAt: '2023-05-09T19:16:51.083Z',
  house_id: 'b37692e6-288f-4bb6-be0b-3d48439be132',
  expiresAt: '2023-05-23T19:16:51.083Z',
  ttl: 1688850175.998
},
watch_users: ['stev0b@stev0b.com']
}`},
  {
    divider: 'Update Direct from Step Function ',
    system: 'get-film-orchestrator',
    code: `
{
  "TableName": TABLE_NAME,
  "Key": {
    "pk": {
      "S.$": "$.response.key"
    },
    "sk": {
      "S.$": "$.response.key"
    }
  },
  "UpdateExpression": "SET confirmed = :confirmed",
  "ExpressionAttributeValues": {
    ":confirmed": {
      "BOOL": true
    }
  }
}
    `,
    desc: 'The Step Function will stamp the item directly from the Step Function workflow (no lambda needed).  Since the step has been reached, it is safe to assume S3 and DynamoDB are matching in terms of data integrity, so this Restaurant can be stamped as Confirmed.',
    on_success: {label: 'Step Function ends', nav: '/docs'}
        },
    ]
},
]
  
   return (
    <div>
        <SvgStepper imageTitle = {imageTitle} steps = {steps} classes={classes} theme={theme.colorScheme === 'dark' ? theme.colors.yellow[6] : theme.colors.blue[6]}/>
    </div>
  );
}
const mapStateToProps = state => {
    return { zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(ArchWatches)