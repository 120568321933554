
import { connect } from 'react-redux'
import * as yup from 'yup';
import { useForm, yupResolver  } from '@mantine/form';
import axios from 'axios';
import { notifications } from '@mantine/notifications';
import React, {useState} from "react";
import {getUser, getToken} from '../../service/authService';
import { Checkbox, createStyles, Button, getStylesRef, rem, Group, TextInput, Space} from '@mantine/core';

const emailSchema = yup.object({
    add_user: yup
    .string('String Values only')
    .email('Not a valid email address')
    .required('Email Address is Required')
    .max(254,'Emails cannot be more than 254 characters'),
});



const useStyles = createStyles((theme) => ({
    track: {
        color: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[7],
        fontWeight: 'bold'
      },
    indicator: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.colors.blue[7],
    },
    card: {
        position: 'relative',
        height: rem(280),
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    
        [`&:hover .${getStylesRef('image')}`]: {
          transform: 'scale(1.03)',
        },
      },
    
      image: {
        ...theme.fn.cover(),
        ref: getStylesRef('image'),
        backgroundSize: 'cover',
        transition: 'transform 500ms ease',
      },

      rating: {
        position: 'absolute',
        top: theme.spacing.xs,
        right: rem(12),
        pointerEvents: 'none',
      },
    
      overlay: {
        position: 'absolute',
        top: '20%',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .85) 90%)',
      },
    
      content: {
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        zIndex: 1,
      },
    
      title: {
        color: theme.white,
        marginBottom: rem(5),
      },
    
      bodyText: {
        color: theme.colors.gray[5],
        marginLeft: rem(7),
      },
    
      dates: {
        color: theme.colors.gray[5],
      },
}));

const UserSettings = ({dash_data, ld_theme, data}) => {
    const { classes, theme } = useStyles();
    const [loading, setLoading] = useState(false);
    const user = getUser();
    const token = getToken();

    const form = useForm({
        initialValues: {
          user_email: user.email,
          phone_number: user.phone_number,
          local_updates: user.local_updates,
          trip_updates: user.trip_updates,
          add_user: ''
        },
        initialErrors: {
            user_email: ' ',
            phone_number: 'reach out to Amanda or Steve to update your email or phone number',
        },
        validate: yupResolver(emailSchema),
    });

    const submitHandler = (event) => {
        setLoading(true)
        form.clearErrors();
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
            }
        }
        const requestBody = {
            user: user,
            follower_email: form.values.add_user,
            token: token
        }

        axios.post(process.env.REACT_APP_FOLLOWER_DATA, requestBody, requestConfig).then((response) => {
            form.reset();
            notifications.show({
                title: 'Save Success',
                message: 'New Follower Saved! Email will go out shortly.',
                color: 'green'
            })
            setLoading(false)
        }).catch((error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                form.setErrors({add_user: error.response.data.message})
                setLoading(false)
            } else {
                form.setErrors({add_user: 'Backend server down. Try again later.'})
                setLoading(false)
            }
        })
    }

    return (
        <form onSubmit={form.onSubmit((e) => submitHandler(e))}>
            <TextInput
                withAsterisk
                label="Email"
                disabled
                {...form.getInputProps('user_email')}
            />
            <TextInput
                withAsterisk
                label="Phone number"
                disabled
                {...form.getInputProps('phone_number')}
            />

            <Checkbox
                mt="md" 
                label="Email me updates for Local Events"
                {...form.getInputProps('local_updates', { type: 'checkbox' })}
            />

            <Checkbox
                mt="md" 
                label="Email me updates on Trips"
                {...form.getInputProps('trip_updates', { type: 'checkbox' })}
            />
            {user.role == "admin" ? 
            <div>
            <Space h="xl" />
                <TextInput
                    label="Add a new follower"
                    type = "text"
                    size="md"
                    mt="md"
                    placeholder="add a follower email"
                    {...form.getInputProps('add_user')}
                />
                <Button mt="md" loading={loading} type="submit" size="sm">
                    Save
                </Button></div> : null }
        </form>
    )
}

const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, dash_data: state.dash_data}
}

export default connect(mapStateToProps)(UserSettings);