import React, {useState} from 'react'
import { createStyles, Dialog, Container, Group, ActionIcon, rem, Text, TextInput, Button, FocusTrap} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate} from "react-router-dom";
import { connect } from 'react-redux'
import axios from 'axios';
import { IconBrandLinkedin, IconBrandSpotify, IconRss,IconDeviceMobileMessage } from '@tabler/icons-react';
import {ReactComponent as Logo} from '../../logo.svg';

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },

  },
  logoDark: {
    "#background" : {rx: "5px", ry: "5px"},
    "#pepp-3-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-2-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-1-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-1-shadow-2" : {fill: theme.colors.red[9]},
    "#pepp-4-2" : {fill: theme.colors.red[5]},
    "#pepp-5-2" : {fill: theme.colors.red[5]},
    "#pepp-6-2" : {fill: theme.colors.red[5]},
    "#cheese-2-2" : {fill: theme.colors.yellow[3], stroke: theme.colors.yellow[8]},
    "#drip-4-2" : {fill: theme.colors.yellow[3]},
    "#drip-3-2" : {fill: theme.colors.yellow[3]},
    "#crust-2-2" : {fill: theme.colors.orange[6], stroke: theme.colors.orange[9]},
  },
  logoLight: {
    "#background" : {rx: "5px", ry: "5px"},
    "#pepp-3-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-2-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-1-shadow-2-2" : {fill: theme.colors.red[9]},
    "#pepp-1-shadow-2" : {fill: theme.colors.red[9]},
    "#pepp-4-2" : {fill: theme.colors.red[6]},
    "#pepp-5-2" : {fill: theme.colors.red[6]},
    "#pepp-6-2" : {fill: theme.colors.red[6]},
    "#cheese-2-2" : {fill: theme.colors.yellow[4], stroke: theme.colors.yellow[8]},
    "#drip-4-2" : {fill: theme.colors.yellow[4]},
    "#drip-3-2" : {fill: theme.colors.yellow[4]},
    "#crust-2-2" : {fill: theme.colors.orange[7], stroke: theme.colors.orange[9]},
  },
}));

const Footer = ({dispatch, ld_theme, subscribeFocus}) => {
  const { classes, cx } = useStyles();
  const [opened, { toggle, close }] = useDisclosure(false);
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState();
  let navigate = useNavigate();

  function validateEmail (email) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  
  function toggleSubscriber() {
    dispatch({
        type: 'TOGGLE_SUBSCRIBER',
        payload: {...subscribeFocus, open: !subscribeFocus.open}
    })
  }

  const saveSubscriber = (event) => {
    setErrorMessage(null);
    if (!validateEmail(email)) {
      setErrorMessage('Email is invalid');
      return;
    }

    setLoading(true);
    event.preventDefault();
    if (email.trim() === '' ) {
        setErrorMessage('Email is required');
        return;
    }
  
    const requestConfig = {
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    }
    const requestBody = {
        email: email
    }
    axios.post(process.env.REACT_APP_SUBSCRIBE, requestBody, requestConfig).then((response) => {
      setEmail();
      toggleSubscriber();
      setLoading(false)
      notifications.show({
        title: 'Subscription Success',
        message: 'Thanks! A welcome email is on the way!',
        color: 'green'
      })
    }).catch((error) => {
        if (error.response.status !== 401 || error.response.status === 403) {
            setErrorMessage(error.response.data.message);
            close();
            setLoading(false)
            notifications.show({
              title: 'Error on Subscription',
              message: 'Email save didn\'t take.  Please try again.',
              color: 'red'
            })
        } else {
            setErrorMessage('Backend server is down. Try again later.')
            close();
            setLoading(false)
            notifications.show({
              title: 'Error on Subscription',
              message: 'Subscription server is down. Try again later.',
              color: 'red'
            })
        }
  })
}

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
      <Logo className={ld_theme.theme == "dark"? classes.logoDark : classes.logoLight} width="9%" height="9%"/>
      <Group spacing={0} className={classes.links} position="right" noWrap>
       <Button size="xs" leftIcon={<IconRss />} onClick={()=>toggleSubscriber()} variant="default" >Subscribe</Button>
      </Group>
      <Group spacing={0} className={classes.links} position="right" noWrap>
      <ActionIcon size="lg" onClick={()=>navigate('/contact')}>
          <IconDeviceMobileMessage size="1.5rem" stroke={1.5} />
        </ActionIcon>
        <ActionIcon size="lg" onClick={()=> window.location.href = "https://www.linkedin.com/in/stev0/"}>
          <IconBrandLinkedin size="1.5rem" stroke={1.5} />
        </ActionIcon>
        <ActionIcon size="lg" onClick={()=> window.location.href = "https://open.spotify.com/user/dardget"}>
          <IconBrandSpotify size="1.5rem" stroke={1.5} />
        </ActionIcon>
      </Group>
      <FocusTrap active={subscribeFocus.open}>
      <Dialog opened={subscribeFocus.open} withCloseButton onClose={()=>toggleSubscriber()} radius="md" zIndex={501}>
        <Text size="sm" mb="xs" weight={500}>
          Subscribe to our weekly Household updates. 
        </Text>

        <Group align="flex-end">
          <TextInput size="md" error={errorMessage ? errorMessage : false} data-autofocus placeholder={errorMessage ? null : "hello@stev0b.com"}  sx={{ flex: 1 }} value={email} onChange={(event) => setEmail(event.currentTarget.value)} />
          <Button loading={loading} onClick={saveSubscriber}>Subscribe</Button>
        </Group>
      </Dialog>
      </FocusTrap>
      </Container>
    </div>
  );
}

const mapStateToProps = state => {
  return { navbar: state.navbar, ld_theme: state.ld_theme, subscribeFocus: state.subscribeFocus}
}

export default connect(mapStateToProps)(Footer)