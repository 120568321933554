import React, {useLayoutEffect, useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import './index.css';
import App from './app';
import Dashboard from './routes/dashboard';
import Docs from './routes/docs';
import IOS from './routes/ios';
import Eats from './routes/eats';
import Watches from './routes/watch';
import Listens from './routes/listen';
import Reviews from './routes/reviews';
import Celery from './routes/celery';

import Datafeed from './routes/datafeed';
import Frontend from './routes/frontend';
import Contact from './routes/contact';

import Login from './routes/home_users/login';
import LoginVisitor from './routes/home_users/loginVisitor';
import Register from './routes/home_users/register';
import UserDashboard from './routes/home_users/dashboard';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

import Unsubscribe from './components/Unsubscribe';
import NotFound from './components/404';

import { Provider } from 'react-redux'
import store from './store'
import NewEvent from './routes/home_users/new_event';
import ViewEvent from './routes/home_users/view_event';
import EditEvent from './routes/home_users/edit_event';

const root = ReactDOM.createRoot(document.getElementById('root'));

//Helper wrapper to auto-scroll to the top of each window on route changes. 
const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

root.render(
    <Provider store={store}>
      <BrowserRouter>
      <Wrapper>
        <Routes>
            <Route path = '/' element={<App />}>
              <Route path = '/' element={<Dashboard />} />
              <Route path = '/docs/' element={<Docs />} />
              <Route path = '/docs/iosapp' element={<IOS />} />
              <Route path = '/docs/iosapp/eatitems' element={<Eats />} />
              <Route path = '/docs/iosapp/watchitems' element={<Watches />} />
              <Route path = '/docs/iosapp/listenitems' element={<Listens />} />
              <Route path = '/docs/iosapp/reviews' element={<Reviews />} />
              <Route path = '/docs/iosapp/celery' element={<Celery />} />
              <Route path = '/docs/datafeed' element={<Datafeed />} />
              <Route path = '/docs/frontend' element={<Frontend />} />
              <Route path = '/contact' element={<Contact />} />
              <Route exact path='/home/login' element={<PublicRoute/>}>
                <Route path = '/home/login' element={<Login />} />
                <Route path = '/home/login/visitor' element={<LoginVisitor />} />
              </Route>
              <Route exact path='/home/register/*' element={<Register/>}></Route>
              <Route exact path='/home/dashboard' element={<PrivateRoute/>}>
                <Route path = '/home/dashboard' element={<UserDashboard />} />
                <Route path = '/home/dashboard/new_event' element={<NewEvent />} />
                <Route path = '/home/dashboard/edit_event/*' element={<EditEvent />} />
                <Route path = '/home/dashboard/event/*' element={<ViewEvent />} />
              </Route>
              <Route
                path="/unsubscribe/*"
                element={
                  <Unsubscribe />
                }
              />
              <Route
                path="*"
                element={
                  <NotFound />
                }
              />
            </Route>
          </Routes>
          </Wrapper>
      </BrowserRouter>

    </Provider>

);
