import * as yup from 'yup';
import React, {useState} from 'react';
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import { connect } from 'react-redux'
import { notifications } from '@mantine/notifications';
import { setUserSession } from '../../service/authService';
import { TextInput, Space, Anchor, Group, createStyles, Button, rem, Box, Container , Text, Alert, PinInput, Center, Flex} from '@mantine/core';
import { useForm, yupResolver  } from '@mantine/form';
import { IconInfoCircle} from '@tabler/icons-react';

const visitorSchema = yup.object({
    email: yup
    .string('String Values only')
    .email('Not a valid email address')
    .required('Email Address is Required')
    .max(254,'Emails cannot be more than 254 characters'),
});

const useStyles = createStyles((theme) => ({
    alertHighlight:{
      position: 'relative',
      color: theme.fn.variant({ variant: 'fill', color: 'red' }).color,
      backgroundColor:  theme.fn.variant({ variant: 'light', color: 'red' }).background ,
      borderRadius: theme.radius.sm,
      padding: `${rem(2)} ${rem(6)}`,
      cursor: 'pointer',
      a: {textDecoration: 'none', color:theme.fn.variant({ variant: 'light', color: 'red' }).color, }
    }
}))

const LoginVisitor = ({lastPath}) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();
    const [code, setCode] = useState();
    const [error, setError] = useState(false);

    let navigate = useNavigate();

    
    const form = useForm({
        initialValues: {
          email: '',
        },
        validate: yupResolver(visitorSchema),
      });


    const submitHandler = (event) => {
        setLoading(true)
        setCode('')
        setError()
        form.clearErrors();

        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
            }
        }
        const requestBody = {
            email: form.values.email,
        }
        axios.post(process.env.REACT_APP_LOGIN_VISITOR_DATA, requestBody, requestConfig).then((response) => {
            setUser({user: response.data.user, token: response.data.token});
            setLoading(false)
            
        }).catch((error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                form.setErrors({email: error.response.data.message})
                setLoading(false)
            } else {
                form.setErrors({email: 'Backend server down. Try again later.', password: 'Backend server down. Try again later.'})
                setLoading(false)
            }
        })
    }


    const twilioHandler = (event) => {
        setError(false)
        setLoading(true)
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
            }
        }
        const requestBody = {
            phone_number: user.user.phone_number,
            code: code
        }
        axios.post(process.env.REACT_APP_SMSVERIFY_DATA, requestBody, requestConfig).then((response) => {
            setUserSession(user.user, user.token);
            lastPath.path.length > 20 ? navigate(`/home/dashboard/event/${lastPath.path}`) : navigate(`/home/dashboard`)
        }).catch((error) => {
            setError(true)
            setLoading(false)
        })
    }

    return (
        <Container >
        <div>
        <Space h="md" />
            <form onSubmit={form.onSubmit((e) => submitHandler(e))}>
            {user ? 
            <Box maw={400} h={300} mx="auto">
                <Text>Verification Code</Text>
                <Space h="sm" />
                <PinInput oneTimeCode length={6} type="number" value={code} onChange={setCode} onComplete={twilioHandler} error={error} size="md"/>
                {error ? <Text fz="sm" c="red"><Space h="sm" />*Error on code validation</Text> : null}
                <Space h="sm" />
                <Group>
                <Flex align="flex-end" gap="md">
                <Button onClick={twilioHandler} loading={loading}>Verify</Button>
                <Anchor td="underline" c="dimmed" fz="sm" component="button" type="button" onClick={submitHandler}>resend code</Anchor>
                </Flex>
                </Group>
                <Space h="xl" />
                <Text fz="sm" color="dimmed">A 6-digit pin has been sent to your registered number for verification</Text>
            </Box>
            :
            <Box maw={400} mx="auto">
            <TextInput
                label="Email"
                type = "text"
                size="md"
                mt="md"
                withAsterisk
                placeholder="email"
                name="email"
                {...form.getInputProps('email')}
            />
            <Space h="sm" />
            <Group position="left" mt="xl">
                <Button loading={loading} type="submit" size="md">
                Login
                </Button>
            </Group>
            <Space h="xl" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Visitor Login" color="blue" variant="outline">
                If you've been given access to Amanda and Steve's calendar, you can log in here
            </Alert>
            <Space h="xl" />
            <Anchor td="underline" c="dimmed" fz="sm" component="button" type="button" onClick={()=> navigate('/')}>return to dashboard</Anchor>
            </Box>}
            </form>
        </div>
        </Container>
    )
}
const mapStateToProps = state => {
    return { lastPath: state.lastPath}
}
export default connect(mapStateToProps)(LoginVisitor);