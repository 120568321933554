import { connect } from 'react-redux'
import React, {useState} from 'react';
import { useNavigate} from "react-router-dom";
import { Alert, Paper, Button, keyframes,createStyles,Container, List, Title, Group, Text, ThemeIcon, rem, Space, Tabs, SimpleGrid} from '@mantine/core';
import { useMediaQuery} from '@mantine/hooks';
import { Prism } from '@mantine/prism';

import '../index.css';
import { IconInfoCircle, IconArrowNarrowLeft, IconArrowNarrowRight, IconChecklist, IconPizza, IconDeviceTvOld} from '@tabler/icons-react';

import {ReactComponent as Datafeedsvg} from './svgs/datafeed.svg';
import {ReactComponent as Datalevelssvg} from './svgs/datalevels.svg';
import {ReactComponent as FullArchitecture} from './svgs/full_architecture_annotated.svg';
import {ReactComponent as DynamoDB} from './svgs/dynamodb.svg';
import {ReactComponent as Lambda} from './svgs/lambda.svg';
import {ReactComponent as S3} from './svgs/s3.svg';
import {ReactComponent as StepFunction} from './svgs/stepfunction.svg';
import ArchDatafeed from '../components/architecture/archDatafeed';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';

const float_top = keyframes({
    '0%': {transform:'translatey(0px)'},
    '50%': {transform:'translatey(-10px)'},
    '100%': {transform: 'translatey(0px)'}
  });

const useStyles = createStyles((theme) => ({
    bronze_folder : {
        '#bronze_layer_1_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#bronze_layer_2_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#gold_layer_1_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#gold_layer_2_path': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    dataFeed_light : {
        '#dynamodb_path': {fill: theme.colors.dark[3]},
        '#path_1': {stroke: theme.colors.gray[4]},
        '#path_2': {stroke: theme.colors.gray[4]},
        '#path_3': {stroke: theme.colors.gray[4]},
        '#path_4': {stroke: theme.colors.gray[4]},
        '#path_5': {stroke: theme.colors.gray[4]},
        '#film_circle': {stroke: theme.colors.blue[5]},
        '#film_circle-2': {stroke: theme.colors.blue[5]},
        '#film_circle-3': {stroke: theme.colors.blue[5]},
        '#s3_3-3': {stroke: theme.colors.blue[5]},
        '#s3_3-2': {stroke: theme.colors.blue[5]},
        '#s3_3': {stroke: theme.colors.blue[5]},
        '#pizza_1': {stroke: theme.colors.blue[5]},
        '#pizza_2': {stroke: theme.colors.blue[5]},
        '#pizza_3': {stroke: theme.colors.blue[5]},
        '#pizza_4': {stroke: theme.colors.blue[5]},
        '#film_strip_1': {stroke: theme.colors.blue[5]},
        '#film_strip_2': {stroke: theme.colors.blue[5]},
        '#film_strip_3': {stroke: theme.colors.blue[5]},
        '#film_strip_4': {stroke: theme.colors.blue[5]},
        '#film_strip_5': {stroke: theme.colors.blue[5]},
        '#film_strip_6': {stroke: theme.colors.blue[5]},
        '#film_strip_7': {stroke: theme.colors.blue[5]},
        '#film_strip_8': {stroke: theme.colors.blue[5]},
        '#house_1': {stroke: theme.colors.blue[5]},
        '#house_2': {stroke: theme.colors.blue[5]},
        '#house_3': {stroke: theme.colors.blue[5]},
        '#house_4': {stroke: theme.colors.blue[5]},
        '#house_5': {stroke: theme.colors.blue[5]},
    },
    dataFeed_dark: {
        '#dynamodb_path': {fill: theme.colors.dark[2]},
        '#path_1': {stroke: theme.colors.dark[4]},
        '#path_2': {stroke: theme.colors.dark[4]},
        '#path_3': {stroke: theme.colors.dark[4]},
        '#path_4': {stroke: theme.colors.dark[4]},
        '#path_5': {stroke: theme.colors.dark[4]},
        '#film_circle': {stroke: theme.colors.yellow[4]},
        '#film_circle-2': {stroke: theme.colors.yellow[4]},
        '#film_circle-3': {stroke: theme.colors.yellow[4]},
        '#s3_3-3': {stroke: theme.colors.yellow[4]},
        '#s3_3-2': {stroke: theme.colors.yellow[4]},
        '#s3_3': {stroke: theme.colors.yellow[4]},
        '#pizza_1': {stroke: theme.colors.yellow[4]},
        '#pizza_2': {stroke: theme.colors.yellow[4]},
        '#pizza_3': {stroke: theme.colors.yellow[4]},
        '#pizza_4': {stroke: theme.colors.yellow[4]},
        '#film_strip_1': {stroke: theme.colors.yellow[4]},
        '#film_strip_2': {stroke: theme.colors.yellow[4]},
        '#film_strip_3': {stroke: theme.colors.yellow[4]},
        '#film_strip_4': {stroke: theme.colors.yellow[4]},
        '#film_strip_5': {stroke: theme.colors.yellow[4]},
        '#film_strip_6': {stroke: theme.colors.yellow[4]},
        '#film_strip_7': {stroke: theme.colors.yellow[4]},
        '#film_strip_8': {stroke: theme.colors.yellow[4]},
        '#house_1': {stroke: theme.colors.yellow[4]},
        '#house_2': {stroke: theme.colors.yellow[4]},
        '#house_3': {stroke: theme.colors.yellow[4]},
        '#house_4': {stroke: theme.colors.yellow[4]},
        '#house_5': {stroke: theme.colors.yellow[4]},
    },
    data_layers_light: {
        '#circle_bronze_back': {stroke: theme.colors.orange[7], fill: theme.colors.orange[2]},
        '#circle_bronze_forward': {stroke: theme.colors.orange[7], fill: 'white'},
        '#circle_silver_back': {stroke: theme.colors.gray[6], fill: theme.colors.gray[2]},
        '#circle_silver_forward': {stroke: theme.colors.gray[6], fill: 'white'},
        '#circle_gold_back': {stroke: theme.colors.yellow[7], fill: theme.colors.yellow[2]},
        '#circle_gold_forward': {stroke: theme.colors.yellow[7], fill: 'white'},
        '#circle_diamond_back': {stroke: theme.colors.blue[7], fill: theme.colors.blue[2]},
        '#circle_diamond_forward': {stroke: theme.colors.blue[7], fill: 'white'},
        '#landing_to_bronze':{stroke: theme.colors.gray[4]},
        '#bronze_to_silver':{stroke: theme.colors.gray[4]},
        '#silver_to_gold':{stroke: theme.colors.gray[4]},
        '#gold_to_diamond':{stroke: theme.colors.gray[4]},
        '#gold_to_diamond_2':{stroke: theme.colors.gray[4]},
        '#diamond_to_bucket':{stroke: theme.colors.gray[4]},
        '#bronze_tri':{stroke: theme.colors.orange[7], fill:theme.colors.orange[2] },
        '#bronze_circle':{stroke: theme.colors.orange[7], fill:theme.colors.orange[2]},
        '#silver_tri':{stroke: theme.colors.gray[6], fill:theme.colors.gray[2] },
        '#silver_circle':{stroke: theme.colors.gray[6], fill:theme.colors.gray[2]},
        '#gold_tri':{stroke: theme.colors.yellow[7], fill:theme.colors.yellow[2] },
        '#gold_circle':{stroke: theme.colors.yellow[7], fill:theme.colors.yellow[2]},
        '#diamond_tri':{stroke: theme.colors.blue[6], fill:theme.colors.blue[2] },
        '#diamond_circle':{stroke: theme.colors.blue[6], fill:theme.colors.blue[2]},
        '#landing_zone': {stroke: theme.colors.green[6], fill:theme.colors.green[2]},
        '#landingfile_outline-2': {stroke: theme.colors.green[9]},
        '#landingfile_fold-2': {stroke: theme.colors.green[9]},
        '#gold_zone': {stroke: theme.colors.yellow[6], fill:theme.colors.yellow[2]},
        '#goldfile_outline': {stroke: theme.colors.yellow[9]},
        '#goldfile_fold': {stroke: theme.colors.yellow[9]},
        '#diamond_zone': {stroke: theme.colors.blue[6], fill:theme.colors.blue[2]},
        '#diamondfile_outline': {stroke: theme.colors.blue[9]},
        '#diamondfile_fold': {stroke: theme.colors.blue[9]},
        '#diamondfile_outline-2': {stroke: theme.colors.blue[9]},
        '#diamondfile_fold-2': {stroke: theme.colors.blue[9]},
        '#diamondfile_outline-3': {stroke: theme.colors.blue[9]},
        '#diamondfile_fold-3': {stroke: theme.colors.blue[9]},
    },
    data_layers_dark: {
        '#circle_bronze_back': {stroke: theme.colors.orange[7], fill: theme.colors.orange[2]},
        '#circle_bronze_forward': {stroke: theme.colors.orange[7], fill: theme.colors.gray[9]},
        '#circle_silver_back': {stroke: theme.colors.gray[6], fill: theme.colors.gray[2]},
        '#circle_silver_forward': {stroke: theme.colors.gray[6], fill: theme.colors.gray[9]},
        '#circle_gold_back': {stroke: theme.colors.yellow[7], fill: theme.colors.yellow[2]},
        '#circle_gold_forward': {stroke: theme.colors.yellow[7], fill: theme.colors.gray[9]},
        '#circle_diamond_back': {stroke: theme.colors.blue[7], fill: theme.colors.blue[2]},
        '#circle_diamond_forward': {stroke: theme.colors.blue[7], fill: theme.colors.gray[9]},
        '#landing_to_bronze':{stroke: theme.colors.dark[4]},
        '#bronze_to_silver':{stroke: theme.colors.dark[4]},
        '#silver_to_gold':{stroke: theme.colors.dark[4]},
        '#gold_to_diamond':{stroke: theme.colors.dark[4]},
        '#gold_to_diamond_2':{stroke: theme.colors.dark[4]},
        '#diamond_to_bucket':{stroke: theme.colors.dark[4]},
        '#bronze_tri':{stroke: theme.colors.orange[7], fill:theme.colors.orange[2] },
        '#bronze_circle':{stroke: theme.colors.orange[7], fill:theme.colors.orange[2]},
        '#silver_tri':{stroke: theme.colors.gray[6], fill:theme.colors.gray[2] },
        '#silver_circle':{stroke: theme.colors.gray[6], fill:theme.colors.gray[2]},
        '#gold_tri':{stroke: theme.colors.yellow[7], fill:theme.colors.yellow[2] },
        '#gold_circle':{stroke: theme.colors.yellow[7], fill:theme.colors.yellow[2]},
        '#diamond_tri':{stroke: theme.colors.blue[6], fill:theme.colors.blue[2] },
        '#diamond_circle':{stroke: theme.colors.blue[6], fill:theme.colors.blue[2]},
        '#landing_zone': {stroke: theme.colors.green[7], fill:theme.colors.green[3]},
        '#landingfile_outline-2': {stroke: theme.colors.green[9]},
        '#landingfile_fold-2': {stroke: theme.colors.green[9]},
        '#gold_zone': {stroke: theme.colors.yellow[7], fill:theme.colors.yellow[2]},
        '#goldfile_outline': {stroke: theme.colors.yellow[9]},
        '#goldfile_fold': {stroke: theme.colors.yellow[9]},
        '#diamond_zone': {stroke: theme.colors.blue[6], fill:theme.colors.blue[2]},
        '#diamondfile_outline': {stroke: theme.colors.blue[9]},
        '#diamondfile_fold': {stroke: theme.colors.blue[9]},
        '#diamondfile_outline-2': {stroke: theme.colors.blue[9]},
        '#diamondfile_fold-2': {stroke: theme.colors.blue[9]},
        '#diamondfile_outline-3': {stroke: theme.colors.blue[9]},
        '#diamondfile_fold-3': {stroke: theme.colors.blue[9]},
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',

        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,
        [theme.fn.smallerThan('75em')]: {
            maxWidth: '100%',
            marginRight: 0,
          },
      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
      },

      alertHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).color : theme.fn.variant({ variant: 'fill', color: 'red' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).background : theme.fn.variant({ variant: 'light', color: 'red' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).color : theme.fn.variant({ variant: 'light', color: 'red' }).color, }
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
}))

function DataFeed({ld_theme}) {
    const { classes, cx, theme } = useStyles();
    const isMobile = useMediaQuery("(max-width: 75em)");
    const [musicLoading, setMusicLoading] = useState(false);
    const [restaurantLoading, setRestaurantLoading] = useState(false);
    const [filmLoading, setFilmLoading] = useState(false);
    const [restaurantS3, setRestaurantS3] = useState({gold_layer: [{no_run: 'click Check Gold S3 folder to see all restaurants visited by the household'}]});
    const [filmS3, setFilmS3] = useState({gold_layer: [{no_run: 'click Check Gold S3 folder to see all films or tv shows watched by the household'}]});
    const [musicS3, setMusicS3] = useState({bronze_layer: [{no_run: 'click Check S3 Song Pile to see what is queued up for Reviews in the household'}]});
    let navigate = useNavigate();
    
    const callMusicS3 = async () =>  {
        setMusicLoading(true)
        const response = await fetch(process.env.REACT_APP_MUSIC_DATA, {
            method: 'GET',
            headers: {'x-api-key': process.env.REACT_APP_API_KEY, 'Content-Type': 'application/json'},
        });
        const data = await response.json()
        if (data.data && data.data.length > 0) {
            data.data.sort(function(a,b){
              return a.played_at.localeCompare(b.played_at);
        })}
        setMusicS3(data);
        setMusicLoading(false)
    }

    const callRestaurantS3 = async () =>  {
        setRestaurantLoading(true)
        const response = await fetch(process.env.REACT_APP_RESTAURANT_DATA, {
            method: 'GET',
            headers: {'x-api-key': process.env.REACT_APP_API_KEY, 'Content-Type': 'application/json'},
        });
        const data = await response.json()
        setRestaurantS3(data);
        setRestaurantLoading(false)
    }

    const callFilmS3 = async () =>  {
        setFilmLoading(true)
        const response = await fetch(process.env.REACT_APP_FILM_DATA, {
            method: 'GET',
            headers: {'x-api-key': process.env.REACT_APP_API_KEY, 'Content-Type': 'application/json'},
        });
        const data = await response.json()
        setFilmS3(data);
        setFilmLoading(false)
    }

   return (
    <div>
        <Container>
            <div className={classes.inner}>
            <div className={classes.content}>
                <Title className={classes.title}>
                Fitting the need <br/>for a Data Feed
                </Title>
                <Text mt="md" fz="md">
                Whelp, we got the data in here.  How do we get it out?  It may be easy to assume we can just set up an API to read directly from the database, create a UI to feed directly from it, but I’m a firm believer that <b>data feeds</b> should be a recommended vehicle to send data from an internal database or data pool to a central area in the ecosystem for anyone to access.     
                
                <Space h="md" />Unlike data entry, my soapbox is usually not that tall here.  Define the source, identify the target, what are the valid values, and the frequency it should refresh.  Maybe there are some convos about full data snapshots, delta loads, data quality rules to fire, but there’s not a lot of hard opinions about the concept. Data Feeds are just a different dressing for an <span className={classes.textHighlight}><a href='https://aws.amazon.com/what-is/etl/'>ETL</a></span> job; they help move data around to different parts of a data ecosystem. But in my opinion, there are two (2) reasons to use Data Feeds.  
                </Text>

            </div>
                {isMobile ? null : <Datafeedsvg width='45%' className={ld_theme.theme == 'dark' ? classes.dataFeed_dark : classes.dataFeed_light}/> }
            </div>
            {isMobile ? <Group position="center"><Datafeedsvg height={rem(200)} width={rem(300)} viewBox="50 20 100 150" className={ld_theme.theme == 'dark' ? classes.dataFeed_dark : classes.dataFeed_light}/></Group>: null}
            <div className={classes.inner}>
            <div className={classes.content}>
            <Text mt="md" fz="md">
                <Title className={classes.thirdHeading}>
                Data Quality through Transformation
                </Title>
                <Space h="md" />
                This is a big one.  ETLs have mostly been associated with the traditional data migration (at least in my experience) — peer to somewhat-peer movement.  How do we move data from one system to another.  Usually there’s points to improve some data quality issues while transforming the data, but for the most part it’s pretty much source-to-target and then call it a “win.”  What I’ve come to appreciate about Data Feeds or ETL Jobs in general, is that we can layer in areas of data quality linearly to move data to be more trusted.  If you’re unfamiliar with the concepts of Bronze, Silver, and Gold layer ETL architectures, then I highly recommend reading a Medium article by Pitethein Strongholt, a Microsoft data fellow who <span className={classes.textHighlight}><a href='https://piethein.medium.com/medallion-architecture-best-practices-for-managing-bronze-silver-and-gold-486de7c90055'>gives a great explanation of the concept.</a></span>

                <Space h="md" />
                At its core, the practice is a great breakthrough for me — let’s design a system where files will traverse different levels of refinement as it passes through layers of control - or different levels of data trust. We can use these levels to position our data for whatever needs that arise:  
                <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                    <ThemeIcon size={20} radius="xl" variant="light" color={ld_theme.theme == "dark" ? "yellow" : "blue"}>
                    <IconChecklist size={rem(20)} stroke={2} />
                    </ThemeIcon>
                }
                >
                <List.Item>
                Want to track and see ingestion history of accepted file drops in your ecosystem? <b>Bronze</b> has that for you.  
                </List.Item>
                <List.Item>
                Want to build dashboards that monitor data quality rules removing specific datapoints for flagrant issues or exceptions ? <b>Silver</b> is where you can go.   
                </List.Item>
                <List.Item>
                Need to have other internal groups in your organization have a central area to view, use, or perhaps pipe their data feeds into your ecosystem?  <b>Gold</b> is your best bet to host a trusted data store.
                </List.Item>
                </List>

            </Text>
            </div>
                {isMobile ? null : <Datalevelssvg width='45%' viewBox='0 0 200 300' className={ld_theme.theme == 'dark' ? classes.data_layers_dark : classes.data_layers_light}/> }
            </div>
            {isMobile ? <Group position="center"><Datalevelssvg height={rem(400)} className={ld_theme.theme == 'dark' ? classes.data_layers_dark : classes.data_layers_light}/></Group>: null}
            <Text mt="md" fz="md">
            If you read Stronghold’s article above or have a general knowledge of the quality layers, you’ll notice my image is slightly different.  Instead of stopping at Gold, I added a fourth (4) layer, <b>Diamond</b>, to depict that not everyone can be satisfied with a central Gold repository. As data requirements or scope grows, there are likely external groups outside your ecosystem (i.e. external clients) who may want to have that Gold Data store deliver data “sliced” in different ways(think client-specific schemas, refresh schedules,  or other ways to morph the data to suit their needs).  This Diamond layer is not inclusive of adding any additional data quality layers on top - just different transformations from your baseline Gold trusted data store for client-specific files.
            <Space h="md" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
            I provide a detailed example using my House Data passing through these data layers (stopping at Gold) on each weekly batch cycle. Keep reading to the <b>Tech Overview</b> section below.  
            </Alert>
            <Space h="xl" />
            <Title className={classes.thirdHeading}>
                Separation of Access
            </Title>
            <Space h="md" />
            I mentioned there are two (2) reasons why I’m a big fan of using Data Feeds in a serverless world.  The Data Quality world is certainly top-o- mind — why would you create untrusted data pools?  — but the second reason is separation of access.   By layering and building different storage areas around S3 across different levels of data trust, I can now point anyone who needs to see specific sets of data, without disrupting or providing access directly to my transactional database (DynamoDB, in this case for my Household).  Let’s take an example — if you’ve been reading everything up to this point, you may have come across a way to get a payload of the current <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/listenitems')}>Song Listens</a></span>  in the Household at any given time throughout the day.  When you go to fetch that, that’s actually calling a file stored in the <b>Bronze</b> layer of my ecosystem.
            <Space h="md" />
                <Prism noCopy language="jsx" className={musicS3.data ? 'inner-scroll' : null}>{`${JSON.stringify(musicS3, null, 2)}`}</Prism>
                <Space h="sm" />
                <Group position="left" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
                <Button loading={musicLoading} variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "blue"} onClick={callMusicS3}>Check Household Music Pile in AWS S3</Button>
                </Group>
            <Space h="xl" />
            What about all the distinct Restaurants we've had a date night or Films we've enjoyed?  Those are all stored in the <b>Gold</b> layer
            <Space h="sm" />
            <Space h="md" />
            <Tabs color={ld_theme.theme == "dark" ? "grape":"red"}variant="default" defaultValue="restaurants" height="30em">
            <Tabs.List grow>
            <Tabs.Tab value="restaurants" icon={<IconPizza size="0.8rem" />}>Restaurants</Tabs.Tab>
            <Tabs.Tab value="films" icon={<IconDeviceTvOld size="0.8rem" />}>Films</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="restaurants" pt="xs">
                <Prism noCopy language="jsx" className={filmS3.data || restaurantS3.data ? 'inner-scroll' : null}>{`${JSON.stringify(restaurantS3, null, 2)}`}</Prism>
                    <Space h="sm" />
                    <Group position="left" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
                    <Button loading={restaurantLoading} variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "blue"} onClick={callRestaurantS3}>Get Household Restaurants in S3 Gold</Button>
                    </Group>
            </Tabs.Panel>
            <Tabs.Panel value="films" pt="xs">
                <Prism noCopy language="jsx" className={filmS3.data || restaurantS3.data ? 'inner-scroll' : null}>{`${JSON.stringify(filmS3, null, 2)}`}</Prism>
                        <Space h="sm" />
                        <Group position="left" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
                        <Button loading={filmLoading} variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "blue"} onClick={callFilmS3}>Get Household Films in S3 Gold</Button>
                    </Group>
            </Tabs.Panel>
            <Tabs.Panel value="frontEnd" pt="xs">
            <div style={{textAlign: 'center'}} className={classes.frontend}>
                <FullArchitecture height={!isMobile ? 350: 435} viewBox="0 0 250 295"/>
            </div>
            </Tabs.Panel>
            </Tabs>
            <Space h="xl" />
            <Space h="xl" />
            <div className={classes.inner}>
            <div className={classes.content}>
            Because I'm creating, technically, an application support-type file for song listens, it sits in the Bronze layer.  As Restaurants and Films get the <b>Confirmation</b> treatment from their Step Functions, they're "trusted" as completed data sets to exist in Gold. 
            <Space h="md" /> 
            You'll notice that my Bronze and Gold are far and away from the transactional DynamoDB system, and they are data files stashed away along the Data Feed path in a respective S3 folder.   
            Looking at the image {isMobile ? 'below, ' : 'to the right, '} you can see none of these API calls are ever touching the DynamoDB transactional database used by the <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp')}>iOS App</a></span> so we’re actually <i>feeding</i> other systems' needs by pointing their attention to our different Data Layers. Sure, we could point everyone to the DynamoDB table, build APIs to query or scan the tables and return the data we want to see - but that starts to present some problems.  

            <Space h="xl" />
            </div>
            {isMobile ? null : <div style={{textAlign: 'center'}} className={classes.bronze_folder}>
                <FullArchitecture height={!isMobile ? 350: 435} viewBox="0 0 250 295"/>
            </div>}
            </div>
            {isMobile ? <div style={{textAlign: 'center'}} className={classes.bronze_folder}>
                <FullArchitecture height={!isMobile ? 350: 435} viewBox="0 0 250 295"/>
            </div>:null}
            <Space h="md" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
            I do not go into detail on a <b>Silver Layer</b>.  My current app ecosystem only accounts for Bronze and Gold.  Since all my file checks are Basic, there is not a need to build an intermediate layer to account for any Data Curation.  
            </Alert>
            <Space h="md" />
            First of all, we need to keep the DynamoDB table purposeful -- its primary function is to serve as a transactional system to keep users logged in, house data open for entry, films and restaurants reference data flowing, and reviews swiping.' It has built-in access patterns that may or may not work with other outside parties wanting to just simply view pieces of data.  So why bother trying to solve for that?  Unless there is a need for real-time access to that data, let's put that data somewhere that will direct the attention away from the table and keep it at a good level of separation.  
            <Space h="md" />
            Keeping with the data layers theme, I'm hoping this helps paint a good picture here. We're intentionally creating a purpose for each layer.  As my data journey moves along, Gold is good enough to have a couple of internal folks see, view, and do whatever they want with the data — download it, put APIs against it or in general, use it for whatever purpose is needed.  And, if the requests need to evolve to something beyond a Gold data pool - maybe something with different fields, columns, values, formats, etc… then Diamond may be the next step to build off these Gold tables into something targeted for precision delivery. 
            <Space h="md" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
            I explain how I am connecting to these Gold tables from my <b>Front End application</b> in the next section
            </Alert>
            <Space h="xl" />
            <Title className={classes.thirdHeading}>
                Tech Overview
            </Title>
            <Text mt="md" fz="md">
                So enough chit-chat. How do we build this? All the components used to build the data feed are using <span className={classes.textHighlight}><a href='https://aws.amazon.com'>AWS Services</a></span>  and for a serverless data feed, there's four (4) core components we need to properly build for scalability:
                <Space h="md" />
                <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} spacing={50}>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />
                        <div className={classes.featureContent}>
                            <S3 viewBox="0 0 800 50" className={classes.featureIcon} stroke={1.5} />
                            <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                            S3
                            </Text>
                            <Text fz="sm">
                            Tried and true. S3 is our hosting buckets for our different layers of ingestion files. Storage of data layer files will be in JSON format
                            </Text>
                        </div>
                    </div>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />
                        <div className={classes.featureContent}>
                            <DynamoDB viewBox="10 05 25 30" className={classes.featureIcon} stroke={1.5} />
                            <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                            DynamoDB
                            </Text>
                            <Text fz="sm">
                            Our file config storage.  This will detail simple info like schedules, filenames, etc. for our data feed ingestion.
                            </Text>
                        </div>
                    </div>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />
                        <div className={classes.featureContent}>
                            <Lambda viewBox="05 5 35 30" className={classes.featureIcon} stroke={1.5} />
                            <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                            Lambda
                            </Text>
                            <Text fz="sm">
                            All logic for each Data Layer will sit in these components - including landing, archiving, bronze, and gold. 
                            </Text>
                        </div>
                    </div> 
                    <div className={classes.feature}>
                        <div className={classes.overlay} />
                        <div className={classes.featureContent}>
                            <StepFunction variant= "light"  viewBox="05 5 30 30" className={classes.featureIcon} stroke={1.5} />
                            <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.featureTitle}>
                            Step Function
                            </Text>
                            <Text fz="sm">
                            The Workhorse of the Serverless World.  This will be our orchestrator of lambdas and file logic through all data layers. 
                            </Text>
                        </div>
                    </div> 
            </SimpleGrid>
            <Space h="xl" />
            To reiterate, data feeds at their core are meant to be the central area to <i>ingest</i> data into your ecosystem.  If you’re starting your data journey, ensure you take the time to set this up with ‘future-proofing’ in mind.  My setup may be a bit overkill for the task at hand, but I hope it can showcase the pattern that if you get the base components set.  The central theme of my setup is having one (1) step function, and individual lambdas to work with data at each data level.  The Lambdas will know how to treat each data layer, based on the file configuration looked up from DynamoDB on ingestion.  This setup is intended for adding more and more ingestions in my app’s future.  In my experience, adding additional ingestion files should be an easy-to-document process of development steps, which is why I try to stick with the file configuration route.  Also, keeping with one core central step function also accounts for future requirements if additional services need to be triggered on each data layer (think ETL Glue Jobs for mega data massaging and parquet file delivery or AWS Athena refresh at certain data layers).  
            <Space h="md" />
            So, to keep things simple, I outline the high level steps of a data feed when <i>any</i> file is dropped:
            <Space h="sm" />
            <List>
                <List.Item>File Dropped in Landing S3 bucket, detected by a File Watcher Lambda and kicks off the orchestration Step Function</List.Item>
                <List.Item><b>Bronze Layer</b> File Configuration in DynamoDB looked up for Config</List.Item>
                <List withPadding listStyleType="circle">
                    <List.Item>File Not Matched: File Archived in Temp Folder</List.Item>
                    <List.Item>File Match Found: Archive File and Perform Basic Checks by Lambda</List.Item>
                </List>
                <List.Item><b>Silver Layer</b> Data Curation performed by Lambda</List.Item>
                <List.Item><b>Gold Layer</b> File either appended (delta) or replaced (reference) based on File Config</List.Item>
                <List.Item><b>Diamond Layer</b> File further transposed and generated with additional instruction, S3 Locations, and filenames from File Config</List.Item>
            </List>
            <Space h="sm" />
            While the above is really a "happy path" of ingestion, each layer could cause the Step Function to fail, and that leaves the opportunity to add a fail-path to either provide notifications or log history to trace back any errors.  Also, if you picked up on the outline above, it describes some sort of “file drop” or upload.  In our app ecosystem, the Weekly Batch process creates the file and drops it into the Landing Area all on its own.  We can use our data feeds for our central use internal feeds from systems and provide trusted data, or we can use it for 3rd party use if any external data outside our systems need to be ingested in the future.  For a full rundown of the data feed ingestion that generates the <span className={classes.textHighlight}><a onClick={()=> navigate('/')}>Weekly Dashboard</a></span> see the detailed walkthrough with all the technical details below:   
            </Text>        
            </Text>
            <Space h="sm" />
            <Group position="center"><ArchDatafeed /></Group>
            <Space h="xl" />
            <Space h="xl" />
            {isMobile ?
          <div>  

          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/celery')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">Celery Run - @iosapp/celery</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/frontend')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>Front End Dashboard - @docs/frontend</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/celery')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">Celery Run - @iosapp/celery</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/frontend')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>Front End Dashboard - @docs/frontend</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(DataFeed)