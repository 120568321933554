
import * as yup from 'yup';
import { connect } from 'react-redux'
import React, {useState, useEffect, useRef} from 'react';
import {getUser, getToken, getFollowers} from '../../service/authService';
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import { useMediaQuery} from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { Box, LoadingOverlay, ActionIcon,ThemeIcon, Switch, MultiSelect,  NumberInput, Divider, CloseButton, Checkbox, TextInput, Stack, Space, Container, createStyles, SegmentedControl, Textarea, Grid, Card, Group, Text, Avatar, Badge, Image, Select, Paper} from '@mantine/core';
import { useForm, yupResolver  } from '@mantine/form';
import { DatePicker, TimeInput } from '@mantine/dates';
import { IconClock, IconCirclePlus, IconTrash , IconArrowBadgeUp, IconArrowBadgeDown, IconTrashFilled, IconArrowBadgeUpFilled, IconArrowBadgeDownFilled, IconTrain, IconHotelService, IconTicket, IconPlaneInflight, IconCar, IconDeviceFloppy} from '@tabler/icons-react';

const oneDay = 24 * 60 * 60 * 1000;

const formSchema = yup.object({
    title: yup
    .string('String Values only')
    .required('Title is Required')
    .max(25,'Titles cannot be more than 25 characters'),
    
    description: yup
    .string('String Values only')
    .required('Description is Required')
    .max(140,'Descriptions cannot be more than 140 characters'),

    image_url: yup
    .string('String Values only')
    .required('Image URL is Required'),

    event_type: yup
    .string('String Values only')
    .required('Event type is Required'),

    invite: yup
    .string('String Values only')
    .required('Invite type is Required'),

    steven_b: yup
    .boolean('Boolean Values only')
    .required('Steven B is Required'),

    amanda_a: yup
    .boolean('Boolean Values only')
    .required('Amanda A is Required'),

    dates: yup
    .array().length(2,'Dates must be selected.  Double-press the same date if local trip.')
    .required('Dates are required'),
});

const useStyles = createStyles((theme) => ({
    cal_trip: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[2] : theme.colors.red[7],
        fontWeight: 'bold'
      },
    cal_local: {
        color: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.dark[7],
        fontWeight: 'bold'
    },
    card_trip: {
      color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'filled', color: 'red' }).color,
      backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'filled', color: 'red' }).background ,
    },
    card_local: {
      color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'blue' }).color : theme.fn.variant({ variant: 'filled', color: 'blue' }).color,
      backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'blue' }).background : theme.fn.variant({ variant: 'filled', color: 'dark' }).background ,
  },
    indicator: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.colors.blue[7],
    },
    navButton: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
      size:"lg",
      cursor: 'pointer'
    },
 
}));

const NewEvent = ({ld_theme}) => {
    const { classes, cx, theme } = useStyles();
    const [loading, setLoading] = useState(false);
    const user = getUser();
    const token = getToken();
    const followers = getFollowers();
    const isMobile = useMediaQuery("(max-width: 55em)");
    const [legs, setLegs] = useState([]);
    const [followerList, setFollowers] = useState([]);
    const [invites, setInvites] = useState([]);
    const ref = useRef(HTMLInputElement);

    const r = (Math.random() + 1).toString(36).substring(7);
    
    
    const total = legs.reduce((allTotal, leg) => {
      const sum = leg.events.reduce((total, event) => {
        return total + event.total;
      }, 0);
      return allTotal + sum}, 0);

    const sums = function (type) { return legs.map((i)=>{
      return i.events.filter((e)=> e.type == type)}).flat().reduce((a,c)=> a+c.total ,0)};

    function edit_leg(signal, index, event, prop, value) {

      if (signal == "add_event") {
        // const x = index.events.push({event_id: r})
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              return { ...item, events: [...item.events, {event_id: r, pending: false, total: 0}] };
            } else {
              return item;
            }
          })
        );
      }
      if (signal == "update_event") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              const elem = item.events.find(({event_id}) => event_id===event.event_id);
              if (elem) elem[prop] = value;
              return {...item};
            } else {
              return item;
            }
          })
        );

      // console.log(legs)
      }
      if (signal == "delete_event") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              let elem
              item.events.map((e, i) => {
                if (e.event_id===event.event_id) {
                  elem= i
                }});
              if (elem == 0) item.events.shift()
              if (elem) item.events.splice(elem, 1)
              return {...item};
            } else {
              return item;
            }
          })
        );
      }
      if (signal == "delete_leg") {
        let elem
        console.log(index)
        var element = legs[index];
        legs.splice(index, 1);
        setLegs([...legs]);
      }
      if (signal == "move_eventUp") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              let elem
              item.events.map((e, i) => {
                if (e.event_id===event.event_id) {
                  elem= i
                }});
              if (elem) item.events.splice(elem - 1, 0, item.events.splice(elem, 1)[0]);
              return {...item};
            } else {
              return item;
            }
          })
        );
      }
      if (signal == "move_legUp") {
        let elem
        var element = legs[index];
        legs.splice(index, 1);
        legs.splice(index - 1, 0, element);
        setLegs([...legs]);
      }
      if (signal == "move_legDown") {
        let elem
        var element = legs[index];
        legs.splice(index, 1);
        legs.splice(index + 1, 0, element);
        setLegs([...legs]);
      }
      if (signal == "move_eventDown") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              let elem
              item.events.map((e, i) => {
                if (e.event_id===event.event_id) {
                  elem= i
                }});
              if (elem == 0) item.events.splice(elem + 1, 0, item.events.splice(elem, 1)[0]);
              if (elem) item.events.splice(elem + 1, 0, item.events.splice(elem, 1)[0]);
              return {...item};
            } else {
              return item;
            }
          })
        );
      }
      if (signal == "update_leg") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              item[prop] = value;
              return {...item};
            } else {
              return item;
            }
          })
        );
      }
    }
    function format_invites() {
      const values = invites.map((x) => {
        const l = followers.find(e => e.email === x)
        return {email: x, first_name: l.first_name, last_name: l.last_name, status: 'pending'}
      });
      return values
    }

    let navigate = useNavigate();
    
    useEffect(() => {
        const authUser = async () => {           
            if (!user || user == 'undefine') {
                return navigate('/home/login/visitor')
              }
            if (!user.role == "admin") {
                return navigate('/home/dashboard')
            }
            const values = followers.map((x) => ({value: x.email, label: `${x.first_name} ${x.last_name}`, test:"test"}));
            const values_sorted = values.sort((a, b) => a.label.localeCompare(b.label))
            setFollowers(values_sorted)
        }
        authUser()    
      },[]);
        
    const form = useForm({
        initialValues: {
          title: '',
          description:'',
          image_url: '',
          event_type: 'local',
          invite: 'private',
          time: '',
          dates: [],
          legs: [],
          steven_b: true,
          amanda_a: true
        },
        validate: yupResolver(formSchema),
      });
    
    const submitHandler = (event) => {
        setLoading(true)
        form.clearErrors();
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY 
            }
        }
        const formatted_invites = format_invites();
        const requestBody = {
            user: user,
            token: token,
            title: form.values.title,
            description: form.values.description,
            image_url: form.values.image_url,
            event_type: form.values.event_type,
            invite: form.values.invite,
            invites: format_invites(),
            pricing: [],
            totals: [
              {type: "flight", total: sums("flight"), part: Math.round((sums("flight")/total)*100)},
              {type: "hotel", total: sums("hotel"), part: Math.round((sums("hotel")/total)*100)},
              {type: "event", total: sums("event"), part: Math.round((sums("event")/total)*100)},
              {type: "car", total: sums("car"), part: Math.round((sums("car")/total)*100)},
              {type: "train", total: sums("train"), part: Math.round((sums("train")/total)*100)}
            ],
            legs: legs,
            time: form.values.time,
            from_date: form.values.dates[0],
            to_date: form.values.dates[1],
            steven_b: form.values.steven_b,
            amanda_a: form.values.amanda_a
        }
        axios.post(process.env.REACT_APP_EVENT, requestBody, requestConfig).then((response) => {
            //success
            notifications.show({
                title: 'Save Success',
                message: 'Success on save new event',
                color: 'green'
            })
            setLoading(false)
            navigate(`/home/dashboard/event/${response.data.id}`)
        }).catch((error) => {
            form.setErrors()
            console.log(error)
            notifications.show({
                title: error.response ? `Error ${error.response.status}` : 'Server Error',
                message: error.response ? error.response.data.message : "Server Error",
                color: 'red'
            })

            setLoading(false)
        })
    }
    const nights = Math.round((form.values.dates[1] - form.values.dates[0])/oneDay)
    return (
        <Container >
        <div>
        <Space h="md" />
            <form onSubmit={form.onSubmit((e) => submitHandler(e))}>
            <Grid>
            <Grid.Col span={isMobile ? 12: 7}>
            <SegmentedControl 
                mt="md"
                label="Type"
                size="md"
                data={['local', 'trip']} 
                {...form.getInputProps('event_type')}
            />
            <TextInput
                mt="md"
                size="md"
                placeholder="title"
                label="Title"
                withAsterisk
                maxLength={25}
                rightSection={<Text size="xs" c={form.values.title.length == 25 ? "red" : ""}>{form.values.title.length}</Text>}
                {...form.getInputProps('title')}
            />
            <Textarea
                mt="md"
                size="md"
                placeholder="description"
                label="Description"
                maxLength={140}
                withAsterisk
                rightSection={<Text size="xs" c={form.values.description.length == 140 ? "red" : ""}>{form.values.description.length}</Text>}
                {...form.getInputProps('description')}
            />
            <TextInput
                mt="md"
                size="md"
                placeholder="image_url"
                label="Image"
                withAsterisk
                rightSection={<CloseButton aria-label="Close modal" onClick={()=> form.setFieldValue('image_url', '')}/>}
                {...form.getInputProps('image_url')}
            />
            </Grid.Col>
            <Grid.Col span={isMobile ? 12: 5}>
                <Stack align="center" >
                <Text mt="xl" c="dimmed">{`Select ${form.values.type == "local" ? "a date" : "dates"}`}</Text>
                <DatePicker
                    size="md"
                    weekendDays={[]}
                    firstDayOfWeek={0}
                    allowSingleDateInRange
                    type="range"
                    {...form.getInputProps('dates')}
                />
                {form.values.event_type == "local" ? 
                <TimeInput
                    label="Time"
                    size="md"
                    ref={ref}
                    withAsterisk
                    rightSection={
                        <ActionIcon onClick={() => ref.current.showPicker()}>
                          <IconClock size="1rem" stroke={1.5} />
                        </ActionIcon>
                    }
                    maw={400}
                    mx="auto"
                    {...form.getInputProps('time')}
                >
                </TimeInput> : null 
                }
                </Stack>
            </Grid.Col>
            <Grid.Col span={isMobile ? 12: 7}>
                    <Text mt="md" c="dimmed">Invitees</Text>

                    <SegmentedControl 
                        mt="md"
                        label="Type"
                        size="md"
                        data={['private', 'open']} 
                        {...form.getInputProps('invite')}
                    />
                    <Group>
                        <Checkbox mt="md" {...form.getInputProps('amanda_a')} label="Amanda A" defaultChecked/>
                        <Checkbox mt="md" {...form.getInputProps('steven_b')} label="Steven B" defaultChecked/>
                    </Group>
                        <MultiSelect
                            mt="lg"
                            label="Send invites"
                            data={followerList}
                            onChange={setInvites}
                            placeholder="Select items"
                            maxDropdownHeight={300}
                            size="md"
                            nothingFound="Nobody here"
                            clearable
                            searchable
                        />
                        <Space h="md" />
            </Grid.Col>
            <Grid.Col span={isMobile ? 12: 5} >
            <Group position="center">
                <Text mt="md" c="dimmed">Card Preview</Text>
            </Group>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section>
                      <Image
                        src={form.values.image_url}
                        height={160}
                        alt={form.values.title}
                        withPlaceholder
                      />
                    </Card.Section>

                    <Group position="apart" mt="md" mb="xs">
                      <Text weight={500}>{form.values.title}</Text>
                      <Badge className={form.values.event_type == "local" ? classes.card_local : classes.card_trip} variant="light">
                        {form.values.event_type}
                      </Badge>
                    </Group>

                    <Text size="sm" color="dimmed">
                      {form.values.description}
                    </Text>
                    <Group position="apart" mt="md">
                      <Avatar.Group spacing="xs">
                        {form.values.steven_b ? <Avatar size="md" radius="lg" variant="filled">SB</Avatar> : null }
                        {form.values.amanda_a ?<Avatar size="md" radius="lg" variant="filled">AA</Avatar> : null }
                        { invites.length > 0 ? <Avatar size="sm" radius="lg" variant="outline">{`+${invites.length}`}</Avatar> : null}
                      </Avatar.Group>
                      <Text size="sm" fw={700} color={form.values.dates.length > 0 ? "dimmed":"red"}>
                        {form.values.dates.length > 0 ? form.values.dates[0].toLocaleDateString() : form.values.event_type == "local" ? "Select date" : "Select dates"}
                      </Text>
                      {form.values.event_type == "trip" ? <Text size="sm" color="dimmed">
                        {nights > 1 && form.values.dates.length == 2 || nights == 0 && form.values.dates.length == 2? `${nights} nights`: nights == 1 && form.values.dates.length == 2 ?`${nights} night` : null}
                      </Text> : null 
                      }
                      {form.values.event_type == "local" ? <Text size="sm" fw={700} color={!form.values.time ? "red":"dimmed"}>
                        {!form.values.time ? 'Select time' : new Date('1970-01-01T' + form.values.time + 'Z').toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'})}
                      </Text> : null 
                      }
                    </Group>
                    
            </Card>
            </Grid.Col>
            </Grid>

            {form.values.event_type == "trip" ? <div> 
            <Space h="sm" />
            <Divider mt="xl" my="xs" label="Legs" labelPosition="left" />
            {legs.map((i, index) => (
              <div key={i.leg_id} style={{border: `1px dashed ${theme.colors.dark[3]}`, borderRadius: '25px', marginTop: "1rem"}}>
                <Group position="apart"> 
                  <Text ml="md" fw={700}>{`Leg ${index +1}`}</Text>
                  <TextInput 
                  placeholder="leg description"
                  size="md"
                  withAsterisk
                  mt="xs"
                  mr="md"
                  onChange={(e) => edit_leg("update_leg", i, index, 'leg_description', e.currentTarget.value)}
                  />
                </Group>
                <Container>
              {i.events.map((x, index) => (  
                <div key={x.event_id} >
                <Group position="apart" mt="lg">
                <Select
                  label="Event Type"
                  placeholder="Pick one"
                  size="md"
                  data={['flight', 'train', 'hotel', 'event', 'car']}
                  onChange={(e) => edit_leg("update_event", i, x, 'type', e)}
                  withAsterisk
                />
                <Switch
                  label="pending"
                  onChange={(e) => edit_leg("update_event", i, x, 'pending', e.currentTarget.checked)}
                />
                <NumberInput
                  label="Total"
                  defaultValue={0}
                  size="md"
                  onChange={(e) => edit_leg("update_event", i, x, 'total', e)}
                  withAsterisk
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  formatter={(value) =>
                    !Number.isNaN(parseFloat(value))
                    ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                    : '$ '
                  }
                />
                </Group>
                  {x.type == "train" || x.type=="flight"? 
                  <Group position="apart" mt="md">
                  <TextInput
                    label="from"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'from', e.currentTarget.value)}
                    withAsterisk
                  />
                  <TimeInput
                    label="time"
                    size="md"
                    ref={ref}
                    withAsterisk
                    onChange={(e) => edit_leg("update_event", i, x, 'from_time', new Date('1970-01-01T' + e.target.value + 'Z').toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}))}
                    maw={400}

                  />
                  <Switch
                    label="direct"
                    onChange={(e) => edit_leg("update_event", i, x, 'direct', e.currentTarget.checked)}
                  />
                  <TextInput
                    label="to"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'to', e.currentTarget.value)}
                    withAsterisk
                  />
                  <TimeInput
                    label="time"
                    size="md"
                    ref={ref}
                    withAsterisk
                    onChange={(e) => edit_leg("update_event", i, x, 'to_time', new Date('1970-01-01T' + e.target.value + 'Z').toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}))}
                    maw={400}
                  />
                  </Group>: null }
                  {x.type == "hotel"? 
                  <Group position="apart" mt="md">
                  <TextInput
                    label="name"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'name', e.currentTarget.value)}
                    withAsterisk
                  />
                  <NumberInput
                    label="nights"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'nights', e)}
                    withAsterisk
                    // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    // formatter={(value) =>
                    //   !Number.isNaN(parseFloat(value))
                    //   ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                    //   : '$ '
                    // }
                    
                  />
                  <NumberInput
                    label="rating"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'rating', e)}
                    withAsterisk
                    precision={2}
                    min={0}
                    step={0.05}
                    max={5}
                  />
                  </Group>: null }
                  {x.type == "event"? 
                  <Group position="left" mt="md">
                  <TextInput
                    label="act or event name"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'act', e.currentTarget.value)}
                    withAsterisk
                  />
                  <TextInput
                    label="venue or location"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'venue', e.currentTarget.value)}
                    withAsterisk
                  />
                  </Group>: null }
                  {x.type == "car"? 
                  <Group position="apart" mt="md">
                  <TextInput
                    label="rental company"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'rental_company', e.currentTarget.value)}
                    withAsterisk
                  />
                  </Group>: null }
                  <TextInput
                    label="image url"
                    size="md"
                    mt="md"
                    // rightSection={<CloseButton aria-label="Close modal" onClick={()=> {edit_leg("update_event", i, x, 'image', '')}}/>}
                    onChange={(e) => edit_leg("update_event", i, x, 'image', e.currentTarget.value)}
                  />

                <Group position="apart">
                  <Group position="left">
                    {x.image ? <Avatar src={x.image} radius="sm" size="sm" /> : <ThemeIcon variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "red"}>{x.type == "flight" ? <IconPlaneInflight color={ld_theme.theme == "dark" ? "black" : "black"}/>: x.type == "train" ? <IconTrain color={ld_theme.theme == "dark" ? "black" : "black"}/> : x.type == "hotel" ?<IconHotelService color={ld_theme.theme == "dark" ? "black" : "black"}/> : x.type == "event" ?<IconTicket color={ld_theme.theme == "dark" ? "black" : "black"}/> : x.type == "car" ?<IconCar color={ld_theme.theme == "dark" ? "black" : "black"}/> : null}</ThemeIcon>}
                  </Group>
                  <Group position="right">
                  <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("move_eventUp", i, x)}>
                    <IconArrowBadgeUp size="2rem" stroke={1.5} />
                  </ActionIcon> 
                  <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("move_eventDown", i, x)}>
                    <IconArrowBadgeDown size="2rem" stroke={1.5} />
                  </ActionIcon>              
                  <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("delete_event", i, x)}>
                    <IconTrash size="2rem" stroke={1.5} />
                  </ActionIcon>
                  </Group>
                </Group> 
                <Divider mt="xl" my="xs"/></div>))}

                <Group position="apart">
                  <Group position="left">
                    <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("add_event", i)}>
                      <IconCirclePlus size="2rem" stroke={1.5} />
                    </ActionIcon>
                  </Group>
                  <Group position="right">
                    <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("move_legUp", index)}>
                    <IconArrowBadgeUpFilled size="2rem" stroke={1.5} />
                    </ActionIcon> 
                    <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("move_legDown", index)}>
                      <IconArrowBadgeDownFilled size="2rem" stroke={1.5} />
                    </ActionIcon>              
                    <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("delete_leg", index)}>
                      <IconTrashFilled size="2rem" stroke={1.5} />
                    </ActionIcon>
                </Group>
                </Group>

                </Container>
                
              </div>
            ))}
            <Group position="center">
            <ActionIcon mt="lg" onClick={() => setLegs([...legs, {leg_id: r, events: []}])}>
              <IconCirclePlus size="2rem" stroke={1.5} />
            </ActionIcon>

            </Group></div>: null}
            <Group grow mt="lg">
            <Box pos="relative">
                <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'white', variant: 'bars' }}/>
              <Paper withBorder p="md" radius="md" className={classes.navButton}
              component="a" onClick={()=> submitHandler()}
              >
                <Group position="apart">
                  <IconDeviceFloppy className={classes.navButton}/>
                  <Text size="lg" className={classes.navButton}>
                    Save
                  </Text>
                </Group>
                <Group position="right" spacing="xs" shadow="xs">
                  <Text fz="sm" color="dimmed">Calendar - @home/dashboard/view_event</Text>
                </Group>
              </Paper>
            </Box>
            </Group>
            </form>
        </div>
        </Container>
    )
}

const mapStateToProps = state => {
    return { ld_theme: state.ld_theme}
}

export default connect(mapStateToProps)(NewEvent);