import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconInfoSquare, IconThumbUp, IconThumbDown } from '@tabler/icons-react';
import {
  Card,
  Image,
  Text,
  ActionIcon,
  Badge,
  Group,
  Tooltip,
  Avatar,
  createStyles,
  rem,
  Modal,
  Table,
  Divider,
  Box,
  Button,
  Anchor
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  rating: {
    position: 'absolute',
    top: theme.spacing.xs,
    right: rem(12),
    pointerEvents: 'none',
  },

  table: {
    marginTop: theme.spacing.md,
  },

  title: {
    display: 'block',
    marginTop: theme.spacing.md,
    marginBottom: rem(5),
  },

  action: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    }),
  },
  highlight: {
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color
  },
  footer: {
    marginTop: theme.spacing.md,
  },
}));


const FilmCard =({
  data,
  badge,
  className,
  ...others
}) => {
  const { classes, cx, theme } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 50em)");
  const linkProps = {target: '_blank', rel: 'noopener noreferrer' };
  
  function addHighlight(e) {
    return(<Text span className={classes.highlight} inherit>{e}</Text>)
  }
  
  return (
    <Card withBorder radius="md" className={cx(classes.card, className)} {...others}>
      <Card.Section>
          <Image radius="sm" height={180} src={data.url} withPlaceholder/>
      </Card.Section>
      {data.week_count }

      <Text className={classes.title} fw={500} component="a" {...linkProps}>
        {data.name}
      </Text>

      <Text fz="sm" color="dimmed" lineClamp={3}>
      {data.overview} 
      </Text>

      <Group position="apart"  className={classes.footer}>
        <Group>
            <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="md">
                {data.steven_b.count == 0 ? null :
                <Tooltip label="Steven B" withArrow>
                    <Avatar src={null} alt="Steven B" color={data.steven_b.count == data.steven_b.sum ? "green": "red"} size="md" radius="md">SB</Avatar>
                </Tooltip>}
                {data.amanda_a.count == 0 ? null :
                <Tooltip label="Amanda A" withArrow>
                    <Avatar src={null} alt={`Amanda A`}  color={data.amanda_a.count == data.amanda_a.sum ? "green": "red"} size="md" radius="md">AA</Avatar>
                </Tooltip>}
                {/* <Avatar radius="xl">+5</Avatar> */}
            </Avatar.Group>
            </Tooltip.Group>
        </Group>
        <Group spacing={8} mr={0}>
        <ActionIcon className={classes.action} size="lg" onClick={open}>
            <IconInfoSquare size="1.1rem" />
        </ActionIcon>
        </Group>
      </Group>
      <Modal
        opened={opened}
        onClose={close}
        title={addHighlight(data.name)}
        fullScreen={isMobile}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <Divider
          my="xs"
          variant="dashed"
          labelPosition="center"
          label={
            <>
              <Box ml={5}>Reviews</Box>
            </>
          }
        />
        <Table className={classes.table} withBorder captionSide="top">
          <tbody>
          {data.amanda_a.count == 0 ? null :
            <tr>
              <td>Amanda A</td>
              <td style={{textAlign: 'left'}}>{data.amanda_a.count == data.amanda_a.sum ? 
                <IconThumbUp color="green" />: <IconThumbDown color="red" />}
              </td>
            </tr>}
            {data.steven_b.count == 0 ? null :
            <tr>
              <td>Steven B</td>
              <td style={{textAlign: 'left'}}>{data.steven_b.count == data.steven_b.sum ? 
                <IconThumbUp color="green" />: <IconThumbDown color="red" />}
              </td>
            </tr>}
            <tr>
            <td>House Status</td>
              <td style={{textAlign: 'left'}}>{badge == 1 ? <Badge color="green">First Watch</Badge>: <Badge color="yellow">{badge} Watches</Badge>}</td>
              </tr>
          </tbody>
        </Table>

        <Divider
          my="xs"
          variant="dashed"
          labelPosition="center"
          label={
            <>
              <Box ml={5}>About</Box>
            </>
          }
        />
        <Text>{data.overview}</Text>
        <br />
        <Text>{addHighlight('Tagline')}</Text>
        <Text>{data.tagline}</Text>
        <Table className={classes.table} withBorder captionSide="bottom">
          <caption>All data sourced from 
            <Anchor href={`${data.reference_url}`}>{addHighlight(' TMDB')}</Anchor>
          </caption>
          <tbody>
          {!data.runtime ? null :
            <tr>
              <td>Runtime</td>
              <td>{`${data.runtime} mins`}</td>
            </tr>}
            {!data.budget ? null :
            <tr>
              <td>Budget</td>
              <td>{`$${(data.budget/1000000)}M`}</td>
            </tr>}
            {!data.number_of_seasons ? null :
            <tr>
              <td>Number of Seasons</td>
              <td>{data.number_of_seasons}</td>
            </tr>}
            {!data.number_of_episodes ? null :
            <tr>
              <td>Number of Episodes</td>
              <td>{data.number_of_episodes}</td>
            </tr>}
            {!data.first_air_date ? null :
            <tr>
              <td>First Air Date</td>
              <td>{data.first_air_date}</td>
            </tr>}
            {!data.release_date ? null :
            <tr>
              <td>Release Date</td>
              <td>{data.release_date}</td>
            </tr>}
          </tbody>
        </Table>
        <br />
        <Image src={data.url} width='100%' position='center' withPlaceholder/>
        <br />
        <Group position="right" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
          <Button onClick={close} variant="light" radius="md" size="md">
            Close
          </Button>
        </Group>
        {isMobile? <div><br />
        <br />
        <br /></div>: null}
      </Modal>
    </Card>
  );
}

export default FilmCard