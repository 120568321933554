import React, {useState} from 'react';
import { TextInput, Textarea, SimpleGrid, Group, Title, Button, Divider, Box, Container , Text} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconDeviceMobileMessage } from '@tabler/icons-react';
import axios from 'axios';
import { useForm} from '@mantine/form';

const Contact = () => {
    const [loading, setLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validate: {
      name: (value) => value.trim().length < 2 ? 'Name is required with length > 2': null,
      email: (value) => !/^\S+@\S+$/.test(value) ? 'Invalid email': null,
      subject: (value) => value.trim().length === 0 ? 'Subject is required': null,
      message: (value) => value.trim().length === 0 ? 'Message is required': null,
    },
  });

  const saveMessage = (event) => {
    setErrorMessage(null);
    setLoading(true);
    
    const requestConfig = {
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    }
    const requestBody = {
        email: form.values.email,
        name: form.values.name,
        subject: form.values.subject,
        message: form.values.message
    }
    axios.post(process.env.REACT_APP_MESSAGE, requestBody, requestConfig).then((response) => {
      setLoading(false)
      form.reset();
      notifications.show({
        title: 'Message Success',
        message: 'Message Received! Thanks!',
        color: 'green'
      })
    }).catch((error) => {
        if (error.response.status == 400) {
            setErrorMessage(error.response.data.message);
            setLoading(false)
            notifications.show({
              title: 'Error on Save',
              message: 'Message save didn\'t take.  Please try again.',
              color: 'red'
            })
        } else {
            setErrorMessage('Backend server is down. Try again later.')
            setLoading(false)
            notifications.show({
              title: 'Error on Save',
              message: 'Message server is down. Try again later.',
              color: 'red'
            })
        }
  })
}

  return (
    <Container>
    <form onSubmit={form.onSubmit((e) => saveMessage(e))}>
      <Title
        order={2}
        size="h1"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}` })}
        weight={900}
        align="center"
      >
        Get in touch
      </Title>

      <SimpleGrid cols={2} mt="xl" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        <TextInput
          label="Name"
          type = "text"
          size="md"
          placeholder="Your name"
          name="name"
          variant="filled"
          {...form.getInputProps('name')}
        />
        <TextInput
          label="Email"
          placeholder="Your email"
          name="email"
          size="md"
          variant="filled"
          {...form.getInputProps('email')}
        />
      </SimpleGrid>

      <TextInput
        label="Subject"
        placeholder="Subject"
        mt="md"
        name="subject"
        size="md"
        variant="filled"
        {...form.getInputProps('subject')}
      />
      <Textarea
        mt="md"
        label="Message"
        placeholder="Your message"
        size="md"
        maxRows={10}
        minRows={5}
        autosize
        name="message"
        variant="filled"
        {...form.getInputProps('message')}
      />

      <Group position="center" mt="xl">
        <Button loading={loading} type="submit" size="md">
          Send message
        </Button>
      </Group>
      <Group position="center" mt="xl">
        <Text>or email me directly at <u>steve@stev0b.com</u> </Text>
      </Group>
    </form>
    </Container>
  );
}

export default Contact;