import { connect } from 'react-redux'
import React, {useState} from 'react';
import {ReactComponent as FullArchitecture} from '../../routes/svgs/full_architecture.svg';
import { IconAlertCircle, IconChevronLeft, IconChevronRight, IconInfoSquare} from '@tabler/icons-react';
import { Alert, Space, Switch, Button, ActionIcon, Group, Modal, Text, Divider, Box, Title, Code, List, Badge, Anchor, Container, Popover} from '@mantine/core';
import { useMediaQuery} from '@mantine/hooks';
import { Prism } from '@mantine/prism';

import '../../index.css';

const default_viewbox = "0 0 250 300"

function SVGStepper({steps, imageTitle, dispatch, classes, zoom_animation, ld_theme, info_popover}) {
    const [active, setActive] = useState(0);
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const isMobile = useMediaQuery("(max-width: 48em)");
    const [opened, setOpened] = useState(false);
    const [popover,setPopover] = useState(true);
    const [popoverPosition, setPopoverPosition] = useState();

    const nextStep = function () {
        
        setActive((current) => (current + 1));
    }
    const toggle_zoom = function() {
        dispatch({
            type: 'TOGGLE_ZOOM',
            payload: {...zoom_animation, zoom: !zoom_animation.zoom}
        })
    }
    function addHighlight(e) {
        return(<Text span className={classes.highlight}>{e}</Text>)
      }
    
    function infoClick(){
        dispatch({
            type: 'TOGGLE_POPOVER',
            payload: {...info_popover, popover: false}
        })
        setOpened(true)
    }

    const breakdown = steps[active].breakdown.map((i) => {
        return (<div >        
            <Divider
                my="xs"
                variant="dashed"
                labelPosition="center"
                label={
                <>
                    <Box ml={5}>{i.divider}</Box>
                </>
            }
          />
          <Title order={4}>{i.system}</Title>
          <Text>{i.desc}</Text>
          {i.external_packages ? <div><Space h="sm"/> {i.external_packages.map((e) => {return (<Anchor href={e.url} target="_blank"><Badge>{e.name}</Badge></Anchor>)})}</div>: null}
          <Space h="sm"/>
          {i.code ? <div><Prism noCopy language="jsx" colorScheme={ld_theme.theme}>{i.code}</Prism><Space h="sm"/></div> : null }
          {i.errors ? <div>
            <Alert icon={<IconAlertCircle size="1rem" />} title="Error Handling" color="red">
            {i.errors.map((e) => {
            return(<List size="sm" spacing="lg">
                <List.Item icon={<Badge color="red">{e.code}</Badge>}>{e.type}</List.Item>
                </List>)})}</Alert><Space h="sm"/></div>: null }
          {i.on_success ? <Alert icon={<IconAlertCircle size="1rem" />} title="On Success" color="green">
                {i.on_success.label} 
                <Space h="sm"/>
            </Alert> : null}
          </div>
        )
    });    
   return (
    <div>
    <Group position="center">
        <Switch onLabel="ON" offLabel="OFF" labelPosition="left" color="gray" label="Zoom" checked={zoom_animation.zoom} onChange={(event) => toggle_zoom()} />
    </Group>
    <Space h="sm" />
    <div style={{textAlign: 'center'}} className={classes[steps[active].step]}>
        <FullArchitecture height={!isMobile ? 350: 435} viewBox={zoom_animation.zoom ? steps[active].viewbox : default_viewbox}/>
    </div>
    <Container size='xs'>{active !== 0 ? <Text lineClamp={4} fz="md" ta="left">{steps[active].desc}</Text> : <Text fz="sm" lineClamp={2} ta="left">Click the Start button below to step through the {addHighlight(imageTitle)} cycle.</Text>}</Container>
    <Group position="center" spacing="lg" mt="xl">
        {!active == 0 ? <ActionIcon size="xl" variant="default" onClick={prevStep}><IconChevronLeft size="1.2rem" /></ActionIcon> : null }
        {!active == 0 ? <Popover 
            opened={info_popover.popover}
            position="bottom"
            onPositionChange={(e) => setPopoverPosition(e)}
            offset={20}
            withArrow 
            onChange={setPopover}>
                <Popover.Target>
                    <ActionIcon onClick={()=> infoClick()} 
                        size="xl" 
                        variant="default" 
                        color={ld_theme.theme == 'dark' ? 'yellow': 'blue'}>
                            <IconInfoSquare size="1.2rem"/>
                    </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown className={popoverPosition == "top" ? "float_top" : "float_bottom"} sx={(theme) => ({padding: theme.spacing.sm, borderRadius: theme.radius.md, backgroundColor: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.colors.blue[3]})}>
                    <div>
                        <Text c="black">click for more info</Text>
                    </div>
                    </Popover.Dropdown >
                </Popover>:null}
        {active == 0 ? <Button variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "blue"} onClick={nextStep}>Start</Button>: <ActionIcon size="xl" variant="default" disabled= {active === steps.length - 1 ? true : false} onClick={nextStep}><IconChevronRight size="1.2rem" /></ActionIcon>}
    </Group>
    <Modal
        opened={opened}
        id="test"
        onClose={()=> setOpened(false)}
        title={imageTitle}
        fullScreen={isMobile}
        size="calc(60vw - 3rem)"
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <Text>{steps[active].desc}</Text>
        <Space h="sm" />
        {active == 0 || active==1? null: <Button compact variant="outline" color="gray" onClick={prevStep}>Previous</Button>}
        {breakdown}
        <br />
        <Group position="right" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
          <Button onClick={()=> setOpened(false)} variant="light" radius="md" size="md">
            Close
          </Button>
        </Group>
        {isMobile? <div><br />
        <br />
        <br /></div>: null}
    </Modal>
  </div>
  );
}
const mapStateToProps = state => {
    return { zoom_animation: state.zoom_animation, ld_theme: state.ld_theme, info_popover: state.info_popover}
}
export default connect(mapStateToProps)(SVGStepper)