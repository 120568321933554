import { connect } from 'react-redux'
import React from 'react';
import { rem, createStyles, keyframes } from '@mantine/core';

import SvgStepper from './svgStepper';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';



const useStyles = createStyles((theme) => ({
  highlight: {
    position: 'relative',
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(2)} ${rem(6)}`,
  },
    second : {
      '#feed_generator_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dynamodb_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#feedgenerator_to_dynamo':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#feed_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
      '#feed_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    third : {
      '#feed_generator_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dynamodb_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#file_watcher_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#s3_landing':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

      '#feedgenerator_to_dynamo':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#landing_to_filewatcheer':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

      '#file_watcher_circle':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,  fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#file_watcher_animation': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    fourth : {
      '#feed_generator_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dynamodb_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#file_watcher_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#discard_folder_1_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#discard_folder_2_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#bronze_layer_1_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#bronze_layer_2_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#discard_folder_2_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#s3_landing':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

      '#feedgenerator_to_dynamo':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#landing_to_filewatcheer':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#filewatcher_to_bronze-2':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#filewatcher_to_404':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#path_to_404':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#path_to_404-start':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

      '#file_watcher_circle':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,  fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#file_watcher_animation': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    fifth : {
      '#feed_generator_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dynamodb_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#file_watcher_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#file_transform_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#discard_folder_1_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#discard_folder_2_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#bronze_layer_1_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#bronze_layer_2_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#gold_layer_1_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#gold_layer_2_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#discard_folder_2_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#s3_landing':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

      '#feedgenerator_to_dynamo':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#landing_to_filewatcheer':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#filewatcher_to_bronze-2':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#filewatcher_to_404':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#path_to_404':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#path_to_404-start':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#bronze_to_transform':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#transform_to_gold':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},

      '#file_transform_circle':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,  fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#file_transform_animation': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    }

}))

function ArchDatafeed() {
const { classes, cx, theme } = useStyles();

const imageTitle = "Weekly Data Feed Ingestion"
const steps =[
{step: "first", 
    viewbox:"0 0 250 300",
    breakdown: [], 
},
{step: "second", 
  viewbox:"10 125 160 180", 
  desc: "Data ecosystem calls the transactional DynamoDB table to retrieve House data for the week - Listens, Watches, Eats, Films, and Restaurants",
  breakdown:[
  {
      divider:'Scheduled Event',
      system: 'Stev0B Feed Generator - Event Bridge',
      desc:'Scheduled event fires every 7 Days from the Event Bridge with an empty payload to trigger the Lambda to query and create a landing file for the Data Feed',
      code: `
cron expression: 30 20 ? * SUN *
payload: { 
  "key1": "value1", 
  "key2": "value2", 
  "key3": "value3" 
}`,
  },
  {
    divider:'Query GSI1 Index for Data Feed Ingestion',
    system: 'Stev0B Feed Generator - Lambda',
    desc:'Lambda grabs Items from DynamoDB that are flagged with a GSI1 PK of "FEED" and a GSI1 SK within a window of the previous week then generates a file to save in the Landing Bucket',
    code: `
const { marshall, unmarshall } = require("@aws-sdk/util-dynamodb");
const AWS = require('aws-sdk');
const dynamodb = new AWS.DynamoDB();

const WINDOW = 7 * 86400000

Date.prototype.getWeek = function() {
    var onejan = new Date(this.getFullYear(), 0, 1);
    return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
}

exports.handler = async (event) => {
  const current_date = new Date();
  const last_date = new Date(current_date.getTime() - WINDOW);
  const week_numbr = current_date.getWeek()

  let response
  try {
    response = await dynamodb.query({
      TableName: process.env.TABLE_NAME,
      IndexName: process.env.INDEX_NAME,
      KeyConditionExpression: "#gsi1pk = :v_gsi1pk AND #gsi1sk BETWEEN :fromDateTime AND :toDateTime",
      ExpressionAttributeNames:{
          "#gsi1pk": "gsi1pk",
          "#gsi1sk": "gsi1sk"
      },
      ExpressionAttributeValues: {
          ":v_gsi1pk": { "S": \`FEED\` },
          ":fromDateTime": { "S": last_date.toISOString()},
          ":toDateTime": {"S": current_date.toISOString() },
      }
    }).promise()
      
    const items = response.Items.map((item) => {
      const norm_json = unmarshall(item) ;
      norm_json.week_number = week_numbr
      return norm_json
    });
    
    //create JSON File for ingestion (e.g stev0b-data-store-extract_20230730.json)
    await saveS3(JSON.stringify(items), \`stev0b-data-store-extract_\${current_date.toISOString().substring(0, 10).replace(/-/g, "")}\`)
  } catch(error) {
      return 'Error on Feed'
  }
}`,
errors: [{type:'Server Error or Invalid Function Call', code: '500', message: 'Unauthorized'}],
},
{
  divider:'S3 Save of Landing File',
  system: 'Stev0B Feed Generator - Lambda',
  desc:'Lambda attempts to save the generated JSON file in the Landing S3 bucket',
  code: `
  ...
  const saveS3 = async (data, nameOfFile) => {   
    try {
      await  s3.putObject({
        Bucket : "stev0b-landing-dev-us-east-1",
        Key : nameOfFile + ".json",
        Body : data
      }).promise()
      return {
          title: 'Success Saving File'
      }
    } catch(error) {
      return {
          error: "Could not save file"
      }
    }
}`,
errors: [{type:'Invalid S3 Path or Key', code: '500', message: 'Server Error'}],
on_success: {label: 'House File successfully saved to the central Landing Bucket that will begin the ingestion process.', nav: '/docs'}
},
  ]
},
{step: "third", 
  viewbox:"10 125 160 180", 
  desc: "S3 Landing Folder Receives the File and sends an event to the File Watcher Lambda.  This event will evaluate the file, its config, and determine its ingestion path.",
  breakdown:[
  {
      divider:'File Watcher - Validate File',
      system: 'File Watcher - Lambda',
      desc:'Lambda receives the Event Details and evaluates the File Name in the Landing Bucket',
      errors: [{type:'File config does not exist', code: '401', message: 'Unauthorized'}, {type:'File type does not exist for this config', code: '402', message: 'Unauthorized'}, {type:'Ingested file type does not match its configured file type', code: '403', message: 'Unauthorized'}, {type:'File date must be in YYYYMMDD format', code: '404', message: 'Unauthorized'}, {type:'File does not meet standard naming convention', code: '405', message: 'Unauthorized'}],
      code: `
exports.handler = async(event, context) => {
  let response
  const response = await validateService.verify(event, context.awsRequestId);
  ...
}

async function validateService(event, jobId) {

  //grab some event props for processing - Region for folder structure
  const region_name = event.Records[0].awsRegion;

  const envProps = (event.Records[0].s3.bucket.name.split(/[-]/));
  const env = envProps[envProps.length - 4];

  //grab entire object dropped in s3
  const dropped_file = event.Records[0].s3.object.key;
  
  //split Dropped File object in array and evaluate if it's configured properly ['file config name', 'date', 'file type']
  const objectProps = (dropped_file.split(/[_.]/));
  
  const configName = objectProps[0];
  const fileDate = objectProps[1];
  const fileType = objectProps[2];
  
  //check if the file is named properly for comparing fileConfig, Dates, and file types
  //e.g. stev0b-data-store-extract_20230529.json
  
  if (objectProps.length != 3) {
    return util.buildResponse(jobId, configName, 405, 'file does not meet standard naming convention (e.g. stev0b-example-file_20230221.csv', dropped_file, env, region_name, 'fail');
  }

  //check if the file config exists for downstream processing
  const fileConfig = await getConfig(configName.toLowerCase().trim(), jobId, dropped_file, region_name, env);

  if (!fileConfig || !fileConfig.dataset_name) {
    return util.buildResponse(jobId, configName, 401, 'file config does not exist', dropped_file, env, region_name, 'fail');
  }
  
  if (!fileConfig.expected_type) {
    return util.buildResponse(jobId, configName, 402, 'file type does not exist for this configuration', dropped_file, env, region_name, 'fail');
  }
  
  if (fileType != fileConfig.expected_type) {
    return util.buildResponse(jobId, configName, 403, \`ingested file type does not match its configured file type: recieved \${fileType} expected \${fileConfig.expected_type} file type\`, dropped_file, env, region_name, 'fail');
  }
  
  //check if the file contains a proper date in YYYYMMDD format
  if (validDateString(fileDate) === false) {
      return util.buildResponse(jobId, configName, 404, "file date must be in YYYYMMDD format", dropped_file, env, region_name, 'fail');        
  }

  return util.buildResponse(jobId, configName, 200, 'landing success', dropped_file, env, region_name, 'pass', fileDate, fileConfig);
}

//function to grab a config item in Table
async function getConfig(configName, jobId, dropped_file, region, env) {
    
  AWS.config.update({
    region: region
  });
    
  const dynamodb = new AWS.DynamoDB.DocumentClient();
  const configTable = \`stev0b-ingestion-config-\${env}\`;
  
  const params = {
    TableName: configTable,
    Key: {
      dataset_name: configName
    }
  };

  //Search Dynamo for Config
  return await dynamodb.get(params)
    .promise()
    .then(response => {
        return response.Item;
    }, error => {
        util.buildResponse(jobId, configName, 500, 'error retrieving config file from server, please try again', dropped_file, env, region, 'fail');
    }
  );
}

//function to validate the file date
function validDateString(s) {
  const match = s.match(/^(\d{4})(\d\d)(\d\d)$/);
  if (match) {
    const [year, month, day] = match.slice(1);
    const iso = \`\${year}-\${month}-\${day}T00:00:00.000Z\`;
    const date = new Date(Date.UTC(year, month - 1, day));
    return date.toISOString() === iso;
  }
  return false;
}`
}, 
{
  divider:'File Watcher - Begin Step Function',
  system: 'Stev0B Feed Generator - Lambda',
  desc:'Lambda begins Step Function with the File Config added to the Step Function Event',
  code: `
  exports.handler = async(event, context) => {

    const response = await validateService.verify(event, context.awsRequestId);
    return startStateMachine(response)
    
};

//function to start state machine with params and input from validate service
async function startStateMachine(response) {  
  const params = {
    stateMachineArn: \`arn:aws:states:\${response.region_name}:\${process.env.SF_ARN}:stateMachine:stev0b-file-orchestrator-\${response.env_name}\`,
    name: response.job_id,
    input: JSON.stringify({
      job_id: response.job_id,
      config_name: response.config_name,
      file_name: response.file_name,
      status_code: response.status_code, 
      message: response.message,
      landing: response.landing,
      bronze: response.bronze,
      silver: response.silver,
      gold: response.gold,
      env_name: response.env_name,
      region_name: response.region_name,
      as_of_date: response.as_of_date,
      file_config: response.fileConfig
    })
  };
  return await stepfunctions.startExecution(params).promise()
}`,
errors: [{type:'Server Error', code: '500', message: 'Server Error'}],
on_success: {label: 'Step Function successfully triggers, beginning the ingestion process', nav: '/docs'}
}]
},
{step: "fourth", 
  viewbox:"10 125 160 180", 
  desc: "Step Function begins with the File Archiver.  If the File has a configuration, it will proceed with Basic Data check; if it does not, it will be archived separately in a temp folder to be deleted.",
  breakdown:[
  {
      divider:'File Archiver - Event',
      system: 'File Archiver - Lambda',
      desc:'Event Example passed into the Step Function',
      errors: [{type:'Server Error', code: '500', message: 'Unauthorized'}],
      code: `
{
  job_id: "d803fa3e-22a5-4128-be5f-2e714f51806f",
  config_name: "stev0b-data-store-extract",
  file_name: "stev0b-data-store-extract_20230724.json",
  status_code: 200,
  message: "landing success",
  landing: "pass",
  bronze: "",
  silver: "",
  gold: "",
  env_name: "dev",
  region_name: "us-east-1",
  as_of_date: "20230724",
  file_config: {
    dataset_name: "stev0b-data-store-extract",
    expected_type: "json",
    frequency: "week"
  }
}`
}, 
{
  divider:'File Archiver - Failed File Function',
  system: 'Stev0B File Archiver - Lambda',
  desc:'If the File Validation is Failed in the previous Step, Lambda will archive and the file and Delete the file from the Landing Bucket',
  code: `
exports.handler = async (event) => {
  let response;
  
  if (event.status_code != 200 && event.landing == "fail") {
      response = await failedFileService.failedFile(event);
  }
  ...
  return response;

};

async function failedFile(event) {
  const copy_response = await copyS3Object(event);
  let delete_response;
  
  if (copy_response.status_code == 500) {
      return copy_response;
  }
  
  if (copy_response.status_code == 200) {
    delete_response = await deleteS3Object(event);
    return delete_response;
  }
}

async function copyS3Object(event) {
  const copy_params = {
      Bucket: \`stev0b-landing-failed-\${event.env_name}-\${event.region_name}\`,
      CopySource: \`stev0b-landing-\${event.env_name}-\${event.region_name}/\${event.file_name}\`,
      Key: \`\${event.status_code}/\${event.file_name}\`
  };

  return await s3.copyObject(copy_params).promise()
  .then(response => {
          return util.buildResponse(event, 200, \`success copying file to \${copy_params.Key}\`);
      }, error => {
          return util.buildResponse(event, 500, \`lambda error copying file to \${copy_params.Key}.  System could not locate file to archive in landing area or there is a server error. Try uploading again\`);
      })
}

async function deleteS3Object(event, passfail) {
  const delete_params = {
      Bucket : \`stev0b-landing-\${event.env_name}-\${event.region_name}\`,
      Key : \`\${event.file_name}\`
  };
  return await s3.deleteObject(delete_params).promise()
  .then(response => {
      return util.buildResponse(event, 200, \`failed file archived in: \${event.status_code}/\${event.file_name} and removed from \${delete_params.Bucket}\`);
  }, error => {
      return util.buildResponse(event, \`lambda error removing file from \${delete_params.Bucket}, manual correcting in S3 is required\`);
  })
}`,
errors: [{type:'Server Error', code: '500', message: 'Server Error'}],
},
{
  divider:'File Archiver - Passed File Function',
  system: 'Stev0B File Archiver - Lambda',
  desc:'If the File Validation is Passed in the previous Step, Lambda will archive according to its config and Delete the file from the Landing Bucket',
  code: `
exports.handler = async (event) => {
  let response;
  ...
  
  if (event.status_code == 200 && event.landing == "pass") {
      response = await passedFileService.passedFile(event);
  }
  
  return response;

};

async function passedFile(event) {
  //get config params for frequency for S3 folder structure
  
  const copy_response = await copyS3Object(event);
  let delete_response;
  
  if (copy_response.status_code == 200) {
      delete_response = await deleteS3Object(event, copy_response.keystring);
      return delete_response;
  }
}

async function copyS3Object(event) {
  //get date parameters to build the folder structure
  const date = event.as_of_date
  const year = date.substring(0,4);
  const month = date.substring(4,6);
  const day = date.substring(6,8);
  const week = getISOWeekInMonth(date);
  
  const freq = event.file_config.frequency;
  
  let keystring;
  switch(true) {
    case freq == "year":
      keystring = \`\${event.config_name}/year=\${year}/\${event.file_name}\`;
      break;
    case freq== "month":
      keystring = \`\${event.config_name}/year=\${year}/month=\${month}/\${event.file_name}\`;
      break;
    case freq == "day":
      keystring = \`\${event.config_name}/year=\${year}/month=\${month}/day=\${day}/\${event.file_name}\`;
      break;
    case freq == "week":
      keystring = \`\${event.config_name}/year=\${year}/month=\${month}/week=\${week.week}/\${event.file_name}\`;
      break;
    default:
      return util.buildResponse(event, 404, 'invalid frequency in config file', fail);
  }

  const copy_params = {
    Bucket: \`stev0b-bronze-\${event.env_name}-\${event.region_name}\`,
    CopySource: \`stev0b-landing-\${event.env_name}-\${event.region_name}/\${event.file_name}\`,
    Key: keystring
  };

  return await s3.copyObject(copy_params).promise()
  .then(response => {
    return util.buildResponse(event, 200, \`success copying file to \${copy_params.Key}\`, keystring);
  }, error => {
    return util.buildResponse(event, 500, \`lambda error copying file to \${copy_params.Key}.  System could not locate file to archive in landing area or there is a server error. Try uploading again\`, keystring);
  })
}

async function deleteS3Object(event, passfail) {
  const delete_params = {
      Bucket : \`stev0b-landing-\${event.env_name}-\${event.region_name}\`,
      Key : \`\${event.file_name}\`
  };
  return await s3.deleteObject(delete_params).promise()
  .then(response => {
      return util.buildResponse(event, 200, \`failed file archived in: \${event.status_code}/\${event.file_name} and removed from \${delete_params.Bucket}\`);
  }, error => {
      return util.buildResponse(event, \`lambda error removing file from \${delete_params.Bucket}, manual correcting in S3 is required\`);
  })
}`,
errors: [{type:'Server Error', code: '500', message: 'Server Error'}],
on_success: {label: 'Lambda returns a success response for the Step Function to continue to the Transformation Step of the ingestion process', nav: '/docs'}
}]
},
{step: "fifth", 
  viewbox:"10 125 160 180", 
  desc: "Step Function moves to File Transformation.  If the File has traversed this far, it has passed Bronze and moving to Gold; This step will create Gold files for all House data.",
  breakdown:[
  {
      divider:'File Transform - Event',
      system: 'File Transform - Lambda',
      desc:'Event Example passed into the Step Function',
      errors: [{type:'Server Error', code: '500', message: 'Unauthorized'}],
      code: `
{
  job_id: "d803fa3e-22a5-4128-be5f-2e714f51806f",
  config_name: "stev0b-data-store-extract",
  file_name: "stev0b-data-store-extract_20230724.json",
  status_code: 200,
  message: "landing success",
  landing: "pass",
  bronze: "pass",
  silver: "",
  gold: "",
  env_name: "dev",
  region_name: "us-east-1",
  as_of_date: "20230724",
  file_config: {
    dataset_name: "stev0b-data-store-extract",
    expected_type: "json",
    frequency: "week"
  }
}`}, 
{
  divider:'File Transformer - Ingestion Lambda Logic',
  system: 'Stev0B File Transformer - Lambda',
  desc:'Lambda evaluates the event and determines which file config to use for Transformation to Gold',
  code: `
const { data_feed } = require('./stev0b-data-store-extract');

exports.handler = async (event) => {
  let response;
  
  switch(true) {
    ...
    case event.config_name === "stev0b-data-store-extract": 
    response = await data_feed(event);
    break;
  default:
    response = {
        statusCode: 404,
        body: JSON.stringify(event),
    };
  }
};
`,
errors: [{type:'Undetermined File Config', code: '404', message: 'Invalid File Config Name'}, {type:'Server Error', code: '500', message: 'Server Error'}],
},
{
  divider:'File Transformer - Ingestion Lambda Logic',
  system: 'Stev0B File Transformer - Lambda',
  desc:'Data Store extract will parse the Event Data and create files in Gold for new Films and Restaurants for the week, and all House Transactional data - watches, eats, listens, and reviews',
  code: `
const data_feed = async (event) => {
  
  try {
    const data = await s3.getObject({
      Bucket : "stev0b-bronze-dev-us-east-1",
      Key : event.keystring,
  }).promise()
      
  let ingestedData = JSON.parse(data.Body);
      
  //Filter on Eats, Watches, Listens, and Reviews
  let filteredTypes = ingestedData.filter((i) => {
    return i.type == "EAT" || i.type == "WATCH" || i.type == "LISTEN" || i.review_answer
  });
      
  //Filter on Films
  let filteredFilms = ingestedData.filter((i) => {
    return i.pk.startsWith('FILM')
  });
      
  //Filter on Restaurants
  let filteredRestaurants = ingestedData.filter((i) => {
    return i.pk.startsWith('REST')
  });
      
  if (filteredFilms.length > 0) {
    try { 
      const orig_house_data_unparsed = await s3.getObject({
        Bucket : "stev0b-gold-dev-us-east-1",
        Key: \`stev0b-films/films.json\`
      }).promise()
        
      const orig_house_data = JSON.parse(orig_house_data_unparsed.Body)      
      const jsonOutput = JSON.stringify([...orig_house_data, ...filteredFilms]);
      await saveS3(jsonOutput, \`stev0b-films/films\`)

    } catch (error) {
        const jsonOutput = JSON.stringify(filteredFilms);
        await saveS3(jsonOutput, \`stev0b-films/films\`)
    }
  }
      
  if (filteredRestaurants.length > 0) {
    try { 
      const orig_house_data_unparsed = await s3.getObject({
        Bucket : "stev0b-gold-dev-us-east-1",
        Key: \`stev0b-restaurants/restaurants.json\`
      }).promise()
          
      const orig_house_data = JSON.parse(orig_house_data_unparsed.Body)     
      const jsonOutput = JSON.stringify([...orig_house_data, ...filteredRestaurants]);
      await saveS3(jsonOutput, \`stev0b-restaurants/restaurants\`)

      } catch (error) {
        const jsonOutput = JSON.stringify(filteredRestaurants);
        await saveS3(jsonOutput, \`stev0b-restaurants/restaurants\`)
      }
  }
      
  //Get each unique house ID
  const unique_houses = [...new Set(filteredTypes.map(item => item.house_id))];
      
  if (unique_houses.length > 0) {
    for (let item of unique_houses) {
      try { 
        const orig_house_data_unparsed = await s3.getObject({
          Bucket : "stev0b-gold-dev-us-east-1",
          Key: \`stev0b-houses/\${item}.json\`
          }).promise()
          const orig_house_data = JSON.parse(orig_house_data_unparsed.Body)
      
          let house_data = filteredTypes.filter((type) => {
            return type.house_id == item;
          });
          const jsonOutput = JSON.stringify([...orig_house_data, ...house_data]);
          await saveS3(jsonOutput, \`stev0b-houses/\${item}\`)
      } catch (error) {
          let house_data = filteredTypes.filter((type) => {
            return type.house_id == item;
          });
          const jsonOutput = JSON.stringify(house_data);
          await saveS3(jsonOutput, \`stev0b-houses/\${item}\`)
      }
    }
} catch(error) {
    return 'Error on Feed'
  }
    
}`,
errors: [{type:'Server Error', code: '500', message: 'Server Error'}],
on_success: {label: 'Lambda returns a success response for the Step Function to continue to the Transformation Step of the ingestion process', nav: '/docs'}
}]
},
]
  
   return (
    <div>
        <SvgStepper imageTitle = {imageTitle} steps = {steps} classes={classes} theme={theme.colorScheme === 'dark' ? theme.colors.yellow[6] : theme.colors.blue[6]}/>
    </div>
  );
}
const mapStateToProps = state => {
    return { zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(ArchDatafeed)