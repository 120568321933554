import { connect } from 'react-redux'
import React from 'react';
import { useNavigate} from "react-router-dom";
import { Paper, Blockquote, createStyles, Image, Container, Title, Group, Text, List, ThemeIcon, rem, Space, Timeline, Table, Alert, Tabs, Badge} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery} from '@mantine/hooks';
import { Prism } from '@mantine/prism';

import '../index.css';
import { IconMovie, IconDeviceTv, IconCheck, IconAlertCircle, IconInfoCircle, IconSend, IconPackage, IconArrowNarrowLeft, IconArrowNarrowRight} from '@tabler/icons-react';

import {ReactComponent as Dogsvg} from './svgs/test_dog.svg';
import ArchWatches from '../components/architecture/archWatches';

import nav1 from './images/celery-nav-1.png';
import nav2 from './images/listen-nav-2.png';
import details1 from './images/celery-details-1.png';
import search1 from './images/celery-search-1.png';
import search2 from './images/celery-search-2.png';
import error from './images/watch-messages-1.png';
import success from './images/watch-messages-2.png';
import notification from './images/watch-messages-3.png';

const useStyles = createStyles((theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,
        [theme.fn.smallerThan('55em')]: {
            maxWidth: '100%',
            marginRight: 0,
          },
      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
}))

function Celery({dispatch, ld_theme, subscribeFocus}) {
    const { classes, cx, theme } = useStyles();
    const isMobile = useMediaQuery("(max-width: 55em)");
    let navigate = useNavigate();

    function toggleSubscriber() {
        dispatch({
            type: 'TOGGLE_SUBSCRIBER',
            payload: {...subscribeFocus, open: !subscribeFocus.open}
        })
      }
    
    function addCheck() {
        return(
            <ThemeIcon size={20} variant="filled" color={ld_theme.theme == "dark" ? "#BE4BDB" : "#FA5252"}>
                <IconCheck size={rem(12)} stroke={3} />
            </ThemeIcon>
        )
    }
   return (
    <div>
        <Container>
            <div className={classes.inner}>
            <div className={classes.content}>
                <Title className={classes.title}>
                Do you want <br />some celery?
                </Title>
                <Text mt="md" fz="md">
                Secondary in the entire theme of the iOS documentation, but in no way should be left out, are <span className={classes.highlight}>Celery</span> Items.
                The humans in the household can't be the only ones tracking their featured items for the week; our four-legged dog, Tucker, needs his feature too.
                Why?  Because our four-legged dog, Tucker, demands it. And because Tucker gets what he wants in this household, I wanted to at least give him an ability to reach
                his audience by tracking his weekly celery stats. This page will be a bit light on the technical information.  Currently, his stats are only displayed in the <span className={classes.textHighlight}><a onClick={()=> toggleSubscriber()}>weekly datafeed emails</a></span> so if you're not <span className={classes.textHighlight}><a onClick={()=> toggleSubscriber()}>subscribed</a></span> you'll miss out each week!
                </Text>
                </div>
                {isMobile ? null : <Dogsvg width='35%' viewBox="15 0 200 200" className={ld_theme.theme == 'dark' ? classes.tv_dark : classes.tv_light}/> }
            </div>
            {isMobile ? <Group position="center"><Dogsvg height={rem(200)} width={rem(300)} viewBox="0 15 200 200" className={ld_theme.theme == 'dark' ? classes.tv_dark : classes.tv_light}/></Group>: null}
            <Title className={classes.subHeading}>
                What's the story behind the celery?
            </Title>
            <Text mt="md" fz="md">
                Tucker loves to play fetch.  Like, alot.  Out of the top things a dog can enjoy - eating, sleeping, and attention -
                his favorite is for someone to pick up a stick or a ball, and just throw.  Unfortunately, there's only so much daylight in a day, 
                and there's only so much time we can spend outdoors. We need to get some energy out in the evening.  

                <Space h="md" />
                Cut to our household prepping a dinner one evening, and dropping a piece of celery on the floor.  Tucker, as all dogs do, 
                vacuumed that piece of celery up and had such a happy expression... we had to drop another piece.  And as the more pieces were dropped, the further we 
                would spread out until we eventually began an evening tradition.  
                <Space h="md" />
                Picture this.  Each evening, around Tucker's dinner time, he begins to ask for his celery ritual to begin.  We all get hyped, and eventually ask the question:

                <Blockquote color={ld_theme.theme == "dark" ? "yellow" : "blue"} cite="- Anyone not Tucker">
                    Do you want some celery?
                </Blockquote>
                <Space h="sm" />
                Once he answers, it's game time.  Celery is pulled from the fridge, and it's time to throw.  First a few warmup reps of some throws down
                the hallway to get his energy out like he's outside again - just enough to chase, chomp, and come back - but when he's good and ready, the catching session begins.  We pull up this section of
                the iOS App to track some stats for the week:
                <Space h="md" />
                <List>
                    <List.Item>Best catch streak of the week</List.Item>
                < List.Item>Total catches of the week</List.Item>
                </List>
            </Text> 
            <Space h="md" />
            <Title className={classes.subHeading}>
                User Flow
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="Navigate">
                    <Text color="dimmed" size="sm">Navigate to Celery area of application</Text>
                </Timeline.Item>
                <Timeline.Item title="Start Throwing">
                    <Text color="dimmed" size="sm">Enable a Throwing Session to pull down the current week's stats</Text>
                </Timeline.Item>
                <Timeline.Item title="Track Catches">
                    <Text color="dimmed" size="sm">Add +1s to Streaks or Resets for the Throw Sessions</Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Navigate
            </Title>
            <Text mt="md" fz="md">
                The iOS App features a dedicated section for entering Celery Items.  By default, the app initializes and lands on the <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Item</a></span> entry screen. To reach the Celery Item screen, the user can navigate to the dedicated
                Celery section of the iOS app by a horizontal touch gesture from Left-to-Right to reveal the menu and selecting Celery. 
                Also, the menu can be revealed by tapping the Avatar icon in the upper-right area of the screen 
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> 
            </Group>
            }
           <Space h="md" />
            <Title className={classes.thirdHeading}>
                Start Throwing
            </Title>
            <Space h="sm" />
            <Text mt="md" fz="md">
                With the Celery section accessed, the user is prompted to Start Throwing.  If there is already a week underway with Stats, the System will pass back the week's
                stats to continue.  If there is not a week, the iOS App will prompt for the user to initiate a new week's set of stats.
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={search1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={search2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={search1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={search2} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                Starting a new <b>Celery run</b> will create a new Week of status in DynamoDB.  The system determines a new Week based on the current date of the week to cause a systematic reset; prompting to create a new weekly run.
            </Alert>
            <Space h="sm" />
            <Space h="md"/>
            <Title className={classes.thirdHeading}>
                Track Catches
            </Title>
            <Text mt="md" fz="md">
                There will be three (3) scores presented for running totals:  
                <List>
                    <List.Item><b>Total</b> Total Catches for the week</List.Item>
                    <List.Item><b>High Score</b> Best Streak for the week (consecutive catches)</List.Item>
                    <List.Item><b>Current</b> Streak of Catches for the current run</List.Item>
                </List>  
                <Space h="sm" />
                The tracker keeps it really simple with three (3) buttons to hep track the scores: 
                <Space h="sm" />
                <List>
                    <List.Item><b>+1</b> Increment current streak count and weekly total by 1 </List.Item>
                    <List.Item><b>Undo Throw</b> Decrease current streak count and weekly total by 1 (mistaken count)</List.Item>
                    <List.Item><b>Reset Streak</b> Set current streak count back to 0 (missed throw)</List.Item>
                </List>
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={details1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={details1} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                <b>High Scores</b> are determined server-side.  Once a current streak surpasses the high score for the week, the server will
                place that number as the highest score and persist that number for subsequent runs for the week. 
            </Alert>
            <Space h="md" />
            <Text>
                It's a simple tracker, but still gets the job done.
                It doesn't need its own full-blown Tech section, like other sections of the documentation.  Again, even though it's not in the House Dashboard, you can track Tucker's highest catch streak of the week by <span className={classes.textHighlight}><a onClick={()=> toggleSubscriber()}>subscribing</a></span> to our weekly House Stats.
            </Text>
            <Space h="xl" />
            <Space h="xl" />
            {isMobile ?
          <div>  

          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/reviews')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">Review Items - @iosapp/reviewitems</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/datafeed')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>Data Feed - @docs/datafeed</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/reviews')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">Reviews - @iosapp/reviews</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/datafeed')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>Data Feed - @docs/datafeed</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, ld_theme: state.ld_theme, subscribeFocus: state.subscribeFocus}
}
export default connect(mapStateToProps)(Celery)