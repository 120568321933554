import { legacy_createStore as createStore } from 'redux';

const time = new Date().getHours()
const theme = time > 17 || time < 6 ? 'dark':'light'

const initialState = {
    ld_theme: {
        theme: "dark",
    },
    navbar: {
      open: false
    },
    music_play: {
      play: false
    },
    all_card: {
      open: false
    },
    dash_data: {
      data:{}
    },
    cal_data: {
      data:[],
      events:[],
      view: false,
      selection: null,
      selectedDate: new Date(),
      scrollPosition: 0
    },
    toc:{
      open: false
    },
    zoom_animation:{
      zoom: true
    },
    info_popover:{
      popover: true
    },
    subscribeFocus:{
      open:false
    },
    lastPath:{
      path:''
    }
  }

  const reducer = (state = initialState, action) => {
    if (action.type === 'TOGGLE_THEME') {
      return {
        ...state,
          ld_theme: action.payload
        }
    }
    if (action.type === 'TOGGLE_NAVBAR') {
      return {
        ...state,
          navbar: action.payload
        }
    }
    if (action.type === 'TOGGLE_PLAYPAUSE') {
      return {
        ...state,
          music_play: action.payload
        }
    }
    if (action.type === 'TOGGLE_ALLCARD') {
      return {
        ...state,
          all_card: action.payload
        }
    }
    if (action.type === 'TOGGLE_TOC') {
      return {
        ...state,
          toc: action.payload
        }
    }
    if (action.type === 'UPDATE_DATA') {
      return {
        ...state,
          dash_data: action.payload
        }
    }
    if (action.type === 'UPDATE_CAL') {
      return {
        ...state,
          cal_data: action.payload
        }
    }
    if (action.type === 'TOGGLE_ZOOM') {
      return {
        ...state,
          zoom_animation: action.payload
        }
    }
    if (action.type === 'TOGGLE_POPOVER') {
      return {
        ...state,
          info_popover: action.payload
        }
    }
    if (action.type === 'TOGGLE_SUBSCRIBER') {
      return {
        ...state,
          subscribeFocus: action.payload
        }
    }
    if (action.type === 'UPDATE_PATH') {
      return {
        ...state,
          lastPath: action.payload
        }
    }
  
    return state
  }

const store = createStore(reducer)

export default store