import { connect } from 'react-redux'
import React, {useState} from 'react';
import { useNavigate} from "react-router-dom";
import { Paper, Button, keyframes,Blockquote, createStyles, Image, Container, Title, Group, Text, List, ThemeIcon, rem, Space, Timeline, Alert, Tabs} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery} from '@mantine/hooks';
import { Prism } from '@mantine/prism';

import '../index.css';
import { IconCheck, IconAlertCircle, IconInfoCircle, IconPackage, IconLock, IconArrowNarrowLeft, IconArrowNarrowRight} from '@tabler/icons-react';

import {ReactComponent as Musicsvg} from './svgs/music.svg';
import ArchListens from '../components/architecture/archListens';

import nav1 from './images/listen-nav-1.png';
import nav2 from './images/listen-nav-2.png';
import link1 from './images/listen-link-1.png';
import link2 from './images/listen-link-2.png';
import link3 from './images/listen-link-3.png';
import log1 from './images/listen-log-1.png';
import log2 from './images/listen-log-2.png';
import log3 from './images/listen-log-3.png';



const authSetup = `
const spotifyAuthConfig = {
  clientId: SPOTIFY_CLIENT_ID,
  clientSecret: SPOTIFY_CLIENT_SECRET,
  redirectUrl: 'com.stev0b:/oauth',
  scopes: [
      'playlist-read-private',
      'playlist-modify-public',
      'playlist-modify-private',
      'user-library-read',
      'user-library-modify',
      'user-top-read',
      'user-read-playback-state',
      'user-modify-playback-state',
      'user-read-currently-playing',
      'app-remote-control',
      'streaming',
      'user-follow-read',
      'user-read-playback-position',
      'user-read-recently-played'
  ],
  serviceConfiguration: {
    authorizationEndpoint: 'https://accounts.spotify.com/authorize',
    tokenEndpoint: 'https://accounts.spotify.com/api/token',
  },
}
const updateUser = async (data) => {
  try {
    setIsSaving(true)
    axios({
      method: 'put',
      url: \`\${BASE_URL}/user/\${user.user}\`,
      headers: {'Authorization': \`bearer \${userToken}\`, 'x-api-key': API_KEY, 'Content-Type': CONTENT_TYPE},
      data: data
    })
    .then(res => {
      if (data.user_request == "linkSpotify") {
        setUser({...user, spotify_token: data.spotify_token})
      }
      if (data.user_request == "unlinkSpotify") {
        setUser({...user, spotify_token: undefined, spotify_refresh_token: undefined, spotify_token_expiration: undefined})
      }
      showMessage({
        message: "Success",
        description: "User Updated",
        type: "success",
      });
  })
  .catch(e => {
    showMessage({
        message: "Error",
        description: "Error Saving Spotify Token",
        type: "danger",
    });
  })
} catch(e) {
  console.log(e)
  }
}`

const userEntity = `
{
  pk: "USER#stev0b@stev0b.com",
  sk: "USER#stev0b@stev0b.com",
  createdAt: "2023-04-01T19:47:56.014Z",
  email: "stev0b@stev0b.com",
  gsi1pk: "HOUSE#b37692e6-288f-4bb6-be0b-3d48439be132",
  gsi1sk: "USER#stev0b@stev0b.com",
  house_admin: true,
  house_id: "b37692e6-288f-4bb6-be0b-3d48439be132",
  ios_arn: "arn:aws:sns:us-east-1:[redacted]/APNS_SANDBOX/stev0b/8345e60f-410b",
  ios_device: "[redacted]",
  name: "steven b",
  password: "$2a$10$NjV/Qo6DsLR0zz1wQkaNu.diMgpz",   '<--- encrypted
  spotify_refresh_token: "AQC0nrYrdHbHVjqDupJAzW",
  spotify_token: "BQAA142paT4tliz9nAzFxE3hyCV3zl",
  spotify_token_expiration: "2023-07-09T16:00:37.152Z",
  type: "USER"
}`

const float_top = keyframes({
    '0%': {transform:'translatey(0px)'},
    '50%': {transform:'translatey(-10px)'},
    '100%': {transform: 'translatey(0px)'}
  });

const useStyles = createStyles((theme) => ({
    music_light : {
        animation: `${float_top} 6s ease-in-out infinite`,
        '#accent':{fill: 'white', stroke: 'white'},
        '#accent-2':{fill: 'white', stroke: 'white'},
        '#headband': {fill: theme.colors.gray[8]},
        '#headband-2': {fill: theme.colors.gray[8]},
        '#headband_accent': {stroke: theme.colors.blue[6]},
        '#outer_accent_button': {fill: theme.colors.blue[6]}, 
        '#outer_accent': {fill: theme.colors.lime[6], stroke: theme.colors.blue[7]},
        '#inner_light':{fill: 'black', stroke: theme.colors.gray[8]},
        '#outer_light':{fill: theme.colors.lime[7], stroke: theme.colors.lime[7]},
        '#outer_light-2': {fill: theme.colors.lime[7], stroke: theme.colors.lime[7]},
        '#inner_dark':{fill: theme.colors.gray[8], stroke: theme.colors.gray[8]},
        '#inner_dark-2': {fill: theme.colors.gray[9]},
        '#outer_dark': {fill: theme.colors.lime[9], stroke: theme.colors.lime[9]},
        '#outer_dark-2':  {fill: theme.colors.lime[9], stroke: theme.colors.lime[9]},
    },
    music_dark: {
        animation: `${float_top} 6s ease-in-out infinite`,
        '#accent':{fill: 'white', stroke: 'white'},
        '#accent-2':{fill: 'white', stroke: 'white'},
        '#headband': {fill: theme.colors.gray[8]},
        '#headband-2': {fill: theme.colors.gray[8]},
        '#headband_accent': {stroke: theme.colors.yellow[4]},
        '#outer_accent_button': {fill: theme.colors.yellow[5]}, 
        '#outer_accent': {fill: theme.colors.grape[5], stroke: theme.colors.yellow[5]},
        '#inner_light':{fill: 'black', stroke: theme.colors.gray[8]},
        '#outer_light':{fill: theme.colors.grape[7], stroke: theme.colors.grape[7]},
        '#outer_light-2': {fill: theme.colors.grape[7], stroke: theme.colors.grape[7]},
        '#inner_dark':{fill: theme.colors.gray[8], stroke: theme.colors.gray[8]},
        '#inner_dark-2': {fill: theme.colors.gray[9]},
        '#outer_dark': {fill: theme.colors.grape[9], stroke: theme.colors.grape[9]},
        '#outer_dark-2':  {fill: theme.colors.grape[9], stroke: theme.colors.grape[9]},
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,
        [theme.fn.smallerThan('55em')]: {
            maxWidth: '100%',
            marginRight: 0,
          },
      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
      },

      alertHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).color : theme.fn.variant({ variant: 'fill', color: 'red' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).background : theme.fn.variant({ variant: 'light', color: 'red' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'fill', color: 'red' }).color : theme.fn.variant({ variant: 'light', color: 'red' }).color, }
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      }
}))

function Listens({ld_theme}) {
    const { classes, cx, theme } = useStyles();
    const [loading, setLoading] = useState(false);
    const [s3, setS3] = useState({click_to_run: [{no_run: 'click Check S3 Song Pile to see what is queued up for Reviews in the household'}]});
    const isMobile = useMediaQuery("(max-width: 55em)");
    let navigate = useNavigate();
    
   const callMusicS3 = async () =>  {
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_MUSIC_DATA, {
            method: 'GET',
            headers: {'x-api-key': process.env.REACT_APP_API_KEY, 'Content-Type': 'application/json'},
        });
        const data = await response.json()
        if (data.data && data.data.length > 0) {
            data.data.sort(function(a,b){
              return a.played_at.localeCompare(b.played_at);
        })}
        setS3(data);
        setLoading(false)
    }

    function addCheck() {
        return(
            <ThemeIcon size={20} variant="filled" color={ld_theme.theme == "dark" ? "#BE4BDB" : "#FA5252"}>
                <IconCheck size={rem(12)} stroke={3} />
            </ThemeIcon>
        )
    }
   function test() {
    s3.toString()
    }
   return (
    <div>
        <Container>
            <div className={classes.inner}>
            <div className={classes.content}>
                <Title className={classes.title}>
                You know that new sound you’re looking for? <br /> Well <span className={classes.highlight}>listen</span> to this!
                </Title>
                <Text color="dimmed" mt="md">
                Listen Items are reviews where the household has spent some time rating their artists they've listened to throughout the week. A daily poll tracks the amount of household songs have been played and
                generates reviews as each day of the week progresses.  At the end of the week, the top 10 Artist listens gets aggregated for each user and displayed on the dashboard.
                </Text>

                <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                    <ThemeIcon size={20} radius="xl" variant="light" color={ld_theme.theme == "dark" ? "yellow" : "blue"}>
                    <IconCheck size={rem(12)} stroke={2} />
                    </ThemeIcon>
                }
                >
                <List.Item>
                    <b>Spotify Integration</b> - The iOS Application integrates directly with the Spotify API for tracking the songs played per user
                </List.Item>
                <List.Item>
                    <b>No Manual Input</b> - The iOS Application allows the user to connect their Spotify account, and the application handles everything else.  No manual entry
                    required. 
                </List.Item>
                <List.Item>
                    <b>Weekly Listens</b> – after each Listen experience is reviewed, it is picked up by the Data Feed to be aggregated in the Stev0B frontend for the week. 
                </List.Item>
                </List>
                </div>
                {isMobile ? null : <Musicsvg width='35%' viewBox="-20 0 300 300" className={ld_theme.theme == 'dark' ? classes.music_dark : classes.music_light}/> }
            </div>
            {isMobile ? <Group position="center"><Musicsvg height={rem(200)} width={rem(300)} viewBox="-20 0 300 300" className={ld_theme.theme == 'dark' ? classes.music_dark : classes.music_light}/></Group>: null}
            <Title className={classes.subHeading}>
                What are Listen Items?
            </Title>
            <Text mt="md" fz="md">
                I'm a big fan of the fun things streaming services provide to recap the year.  In particular, <span className={classes.textHighlight}><a href='https://newsroom.spotify.com/2020-12-09/3-tips-tricks-to-enhance-your-2020-wrapped-experience/'>Spotify Wrapped</a></span>
                is a fun and unique experience that's always a surprise when you get your "wrapped experience" delivered to you each year.  And I wanted
                to recreate that for the household here weekly.  The Listens in each Household are to celebrate the most popular artists each user listened to - and how much each user listened to that artist - each week. 
            </Text> 
            <Space h="md" />
            <Title className={classes.subHeading}>
                How are they created?
            </Title>
            <Text mt="md" fz="md">
                Rather than having the App require the user to manually enter in a Listening experience - wayyyy too tedious - the App will simply ask for the user's Spotify credentials.  Once entered, the user is all set.  All that's left 
                for the user is to just simply listen to music from their <span className={classes.textHighlight}><a href='https://open.spotify.com/?/'>Spotify Account</a></span>.
                Throughout the course of the day, the App ecosystem will poll Spotify, see what's been listened to, and log the household songs played.  Once the household has listened to enough songs, the system will 
                bulk generate <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>Reviews</a></span> for each user that listened to music that day.  
                As each Listen experience gets reviewed by each Artist, the Data Feed will pick up all the 'Listens' for the household and aggregate the Artists ranked by count for each user for the week.
            </Text>
            <Space h="md" />
            <Title className={classes.subHeading}>
                User Flow
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="Navigate">
                    <Text color="dimmed" size="sm">Navigate to User Profile area of application</Text>
                </Timeline.Item>
                <Timeline.Item title="Add Credentials">
                    <Text color="dimmed" size="sm">Attach Spotify Account credentials</Text>
                </Timeline.Item>
                <Timeline.Item title="Jam Out">
                    <Text color="dimmed" size="sm">Listen to Music - View Household Logs of listened songs</Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Navigate
            </Title>
            <Text mt="md" fz="md">
                The iOS App features a dedicated section for adding your <span className={classes.textHighlight}><a href='https://open.spotify.com/?/'>Spotify Account</a></span> credentials.  By default, the app initializes and lands on the <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Item</a></span> entry screen. To reach the Profile screen, the user can navigate to the dedicated
                Profile section of the iOS app by a horizontal touch gesture from Left-to-Right to reveal the menu and selecting Profile. 
                Also, the menu can be revealed by tapping the Avatar icon in the upper-right area of the screen 
            </Text>
            <Space h="sm" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                Every user has access to their profile and should be able to navigate to this section of the application. Currently, the Profile page is primarily for a user to view Household Members, Link Credentials, Verify their Email Address, and check the Household Music Log. 
            </Alert>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> 
            </Group>
            }
           <Space h="md" />
            <Title className={classes.thirdHeading}>
                Add Credentials
            </Title>
            <Space h="sm" />
            <Text mt="md" fz="md">
                With the Profile section accessed, the iOS App will, by default, render a simple page with an area for the user to <b>Link</b> their Spotify account
            </Text>
            <Space h="sm" />
            <Text mt="md" fz="md">
                By tapping the <b>Link</b> button, the iOS App will provide a guided walkthrough of authorizing the user to use their <span className={classes.textHighlight}><a href='https://open.spotify.com/?/'>Spotify Account</a></span> credentials with the iOS App.
                The walkthrough is standard for iOS apps in providing 3rd Party credentials:
                <List>
                    <List.Item>User - Sign In to Spotify Account</List.Item>
                    <List.Item>User - Agree and Authorize Access to the Stev0b Application</List.Item>
                </List>
                <Space h="sm" />  
                The detailed authorization flow is spelled out in more detail in the <b>Tech Overview</b> section below. Once the <span className={classes.textHighlight}><a href='https://open.spotify.com/?/'>Spotify Account</a></span> is <b>Linked</b>, the iOS App will provide the option to <b>Unlink</b> the User Account, if preferred. 
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={link1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={link2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={link3} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={link1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={link2} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={link3} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="sm" />
            <Alert icon={<IconAlertCircle size="1rem" />} title="Note" color="red">
                <b>Linking</b> an account will stay Linked, until the user has manually <b>Unlinked</b> the Account or the user has not listened to music for a minimum of 30 Days.  If the user exceeds 30 days with no listen, the iOS App
                will automatically <b>Unlink</b> the user's account in accordance with <span className={classes.alertHighlight}><a href='https://www.spotify.com/us/legal/end-user-agreement/'>Spotify's Terms and Conditions of Use</a></span> for applications.
            </Alert>
            <Space h="md"/>
            <Title className={classes.thirdHeading}>
                Jam Out
            </Title>
            <Text mt="md" fz="md">
                Once a user profile is <b>Linked</b>, the rest is easy - listen to music.  Between the hours of 8AM-10PM (NYC Timezone), every day of the week, the App Ecosystem will poll Spotify every 30 minutes to track what songs each user listens to in their Household from their <span className={classes.textHighlight}><a href='https://open.spotify.com/?/'>Spotify Account</a></span>  
                After each poll, there is a subsequent Reviewing poll that will evaluate if enough songs have been experienced in the Household to generate user <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>reviews</a></span>  
                If enough user listens pass the Household listen count of ~20 songs (avg), then the next Reviewing poll run will scoop up all the songs listened from the Household and generate one (1) Review for each Artist the user listened to, with the count of listens on the Review.  
                More on this in the <b>Tech Overview</b> below.
                The user can see when user reviews will be generated from the <b>Music Log</b> section of the Profile page.  Checking the <b>Music Log</b>, the user will find two (2) sections worth noting:
            </Text>
            <Space h="sm" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                If enough user listens pass the Household listen count of ~20 songs (avg), then the next polling run will scoop up all the songs listened from the Household and generate one (1) Review for each Artist, per user.
                For example, if user <b>Stev0b</b> listens to <b>Prince</b> Five (5) times within a polling window, <b>Stev0b</b> would receive only one (1) Review for <b>Prince</b> with the Review containing a count of five (5).  
            </Alert>
            <Text mt="md" fz="md">
                The user can see when user reviews will be generated from the <b>Music Log</b> section of the Profile page.  Checking the <b>Music Log</b>, the user will find a few sections worth noting:
            </Text>
            <Space h="sm" />
            <Space h="md"/>
            <List>
                <List.Item>A Percent(%) Progress Indicator, noting next user review generation</List.Item>
                <List.Item>Currently Polled Songs with Details
                    <List withPadding listStyleType="circle">
                        <List.Item>Artist</List.Item>
                        <List.Item>Song</List.Item> 
                        <List.Item>User</List.Item>
                        <List.Item>Date & Time listened</List.Item> 
                    </List>
                </List.Item>
                <List.Item>An option to <b>Push</b> the polled songs to generate user reviews immediately.</List.Item>
            </List>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={log1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={log2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={log3} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={log1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={log2} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={log3} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="md" />
            <Title className={classes.subHeading}>
                Tech Overview
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="Credentials Auth">
                    <Text color="dimmed" size="sm">OAuth2 Workflow</Text>
                </Timeline.Item>
                <Timeline.Item title="Tech Flow">
                    <Text color="dimmed" size="sm">Tech flow for Listen Items</Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Credentials Auth
            </Title>
            <Text mt="md" fz="md">
                The entire point of having Listen Items is to make it as simple as possible to keep track of Artists the household listens to throughout the day.  The app design would be completely pointless if it required the user to
                manually document every Song or Artist listed by scrolling or searching through a database.  I believe it works for <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat Items</a></span> and <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/watchitems')}>Watch Items</a></span>
                since the User Flows for documenting a Film or Restaurant is for a moment-in-time experience.  Listening to music, however, is a bit abstracted or "in the background" usually.  So that's why this key feature is necessary to link up credentials as the only step for this feature on the front-end. 
            <Space h="sm" />
                To provide the authorization, the iOS App takes advantage of a 3rd party package called <span className={classes.textHighlight}><a href='https://developer.themoviedb.org/docs'>react-native-app-auth</a></span> It's a configuration package that makes it as hassle-free as possible to allow communication from the iOS Application to the Spotify Application API endpoints when
                authorizing users.  Below is the code used to authorize the user, and after a successful authorization response, the iOS App adds the credentials to the User Entity in DynamoDB:       
            </Text>
            <Space h="sm" />
            <Space h="md" />
            <Tabs color={ld_theme.theme == "dark" ? "grape":"red"}variant="default" defaultValue="authSetup">
            <Tabs.List grow>
                <Tabs.Tab value="authSetup" icon={<IconLock size="0.8rem" />}>Authorization</Tabs.Tab>
                <Tabs.Tab value="userEntity" icon={<IconPackage size="0.8rem" />}>User Entity</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="authSetup" pt="xs">
                <Prism noCopy language="jsx">{authSetup}</Prism>
            </Tabs.Panel>
            <Tabs.Panel value="userEntity" pt="xs">
                <Prism noCopy language="jsx">{userEntity}</Prism>
            </Tabs.Panel>
            </Tabs>
            <Space h="md" />
            <Text mt="md" fz="md">
                Here's a breakdown of the <span className={classes.textHighlight}>spotifyAuthConfig</span> function:
            <Space h="sm" />
            <List>
                <List.Item>The <b>clientId</b> and <b>clientSecret</b> are the iOS App's handshake with <span className={classes.textHighlight}><a href='https://developer.spotify.com/documentation/web-api'>Spotify Developer API</a></span></List.Item>
                <List.Item>The <b>redirectUrl</b> helps verify the application with Spotify and redirect back to the iOS App when the Auth walkthrough is complete</List.Item>
                <List.Item>The <b>scopes</b> are the access rights the iOS App is requesting permission from the user</List.Item>
            </List> 
            <Space h="md" />
                Depending on the iOS App request to <b>Link</b> or <b>Unlink</b> a user will determine how the attributes are set on the User Entity.  The App Ecosystem requires three (3) attributes from Spotify to successfully collect a user's listens from the application:
            <Space h="sm" />
            <List>
                <List.Item>spotify_token</List.Item>
                <List.Item>spotify_refresh_token</List.Item>
                <List.Item>spotify_token_expiration</List.Item>
            </List> 
            <Space h="sm" />
            And.... That's it! The music listened through the user's Spotify Account will get tracked, and the App Ecosystem will use these three (3) attributes to perform the Polling in the background.  
            For a full breakdown, see the <b>Tech Flow</b> below. 
            </Text>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Tech Flow
            </Title>
            <Text mt="md" fz="md">
                So! The User is set up, so.... now what?  As you'll see below, what's set up is essentially just a method to just check in on ole Spotify and ask, "You got any songs listened by my household?" And if so, 
                just pile those song listens in a JSON file in S3.  When the pile of songs reaches a certain threshold <b>BOOM</b> <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>Reviews</a></span> get generated for each user that was
                a part of the song pile. We know to knock on Spotify's door like clockwork - literally.  There is an <span className={classes.textHighlight}><a href='https://aws.amazon.com/eventbridge/'>Event Bridge</a></span> that triggers a lambda to check in on Household Spotify Users every 30min
                From 8AM-10PM (US East) and either creates a JSON file or appends to the existing one out there. 
                <Space h="md" />
                In fact, go ahead and call my S3 bucket.  If it's between those hours now, there's a good chance you can see what's piled up for the Household waiting for Reviews to get generated: 
                <Space h="md" />
                <Prism noCopy language="jsx" className={s3.data ? 'inner-scroll' : null}>{`${JSON.stringify(s3, null, 2)}`}</Prism>
                <Space h="sm" />
                <Group position="left" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
                <Button loading={loading} variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "blue"} onClick={callMusicS3}>Check Household Music Pile in AWS S3</Button>
                </Group>
               
                <Space h="md" />
                So when do the Reviews get created?  Great question -- there's <i>another</i> <span className={classes.textHighlight}><a href='https://aws.amazon.com/eventbridge/'>Event Bridge</a></span>
                that calls that S3 folder - that you may or may not have just queried - asking <i>that</i> folder, "Hey! You have enough songs for me to create reviews?"  That's the threshold I've been referencing.  
                The calls to the folder occur every 2 hours between 10:05AM-10:05PM (US East) and checks on the Household total song count.  If it's over ~20'sh  songs for the house, then it will take care of creating <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>Reviews</a></span>
                 If not, then it'll just exit out and check back in another 2hrs.  At the end of the day, there is an attribute at the 10:05PM mark the Event Bridge will use that will force any remaining songs of the day to get their <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>Reviews</a></span>
                created. Here's the full listed breakdown:
                <Space h="sm" />
                <List>
                    <List.Item>Poll Spotify for Listens every 30 min</List.Item>
                    <List withPadding listStyleType="circle">
                        <List.Item>Begin: 8AM (US East)</List.Item>
                        <List.Item>End: 10PM (US East)</List.Item>
                    </List>
                    <List.Item>Poll App Ecosystem Music S3 for Listens every 2 hrs</List.Item>
                    <List withPadding listStyleType="circle">
                        <List.Item>Begin: 10:05AM (US East)</List.Item>
                        <List.Item>End: 10:05PM (US East)</List.Item>
                    </List>
                    <List.Item>If total S3 size (in KB) is met or Day has ended, create Reviews</List.Item>
                </List>
                <Space h="sm" />
                Why S3 and so over-the-top?  Again, it's probably not how I'd design this in any real-world scenario, but I think it's fun to play around with AWS Services to build a solution.
                And at any rate, I stay within the AWS Free tier with all these lambda calls, since it's just my Household in this ecosystem. So why not just take advantage of that, ya know? 
                <Space h="sm" /> 
            </Text>
            <Blockquote color={ld_theme.theme == "dark" ? "yellow" : "blue"} cite="- Marty McFly">
            Sounds pretty heavy.
            </Blockquote>
            <Space h="sm" />
            <Group position="center"><ArchListens /></Group>
            <Space h="xl" />
            <Space h="xl" />
            {isMobile ?
          <div>  

          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/watchitems')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">Watch Items - @iosapp/watchitems</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/reviews')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>Reviews - @iosapp/reviews</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/watchitems')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">Watch Items - @iosapp/watchitems</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/reviews')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>Reviews - @iosapp/reviews</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(Listens)