import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { useNavigate} from "react-router-dom";
import { Space, Container, Title, SimpleGrid, Group, Box, Divider, Button, LoadingOverlay, Card, Text, Image, Tooltip, Avatar, rem, createStyles, Flex, Paper } from '@mantine/core';
import { IconSmartHome, IconPizza, IconDeviceTvOld, IconHeadphonesFilled, IconInfoCircle, IconArrowNarrowRight, IconArrowNarrowLeft} from '@tabler/icons-react';
import { useMediaQuery} from '@mantine/hooks';

import TotalStats from './dashboard/totalstats'
import WeekStats from './dashboard/weekstats'
import FilmCard from './dashboard/filmcard';
import MusicCard from './dashboard/musiccard';
import RestCard from './dashboard/restcard';
import AllCard from './dashboard/allcard';

const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  rating: {
    position: 'absolute',
    top: theme.spacing.xs,
    right: rem(12),
    pointerEvents: 'none',
  },
  highlight: {
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color
  },
  mobilePadding: {
    marginBottom: theme.spacing.lg
  },

  cardTitle: {
    display: 'block',
    marginTop: theme.spacing.md,
    marginBottom: rem(5),
  },

  overview: {
    marginBottom: theme.spacing.lg,
    align:"flex-end",
    direction:"row",
  },

  action: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    }),
  },

  footer: {
    marginTop: theme.spacing.md,
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: `calc(${theme.spacing.xl} * .75)`,
    paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('55em')]: {
      fontSize: rem(28),
    },
  },
  navButton: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    size:"lg",
    cursor: 'pointer'
  },

  textHighlight:{
      position: 'relative',
      color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
      backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
      borderRadius: theme.radius.sm,
      padding: `${rem(2)} ${rem(6)}`,
      cursor: 'pointer',
      a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
    },
}));

function Dashboard({dash_data, all_card, dispatch}) {
  const { classes, cx, theme } = useStyles();
  const [loading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const isMobile = useMediaQuery("(max-width: 55em)");
  let navigate = useNavigate();

  useEffect(() => {
    const getDatas = async () => {
        setIsLoading(true)
        const response = await fetch(process.env.REACT_APP_HOUSE_DATA, {
          method: 'GET',
          headers: {'x-api-key': process.env.REACT_APP_API_KEY, 'Content-Type': 'application/json'},
        });
        const data = await response.json();
        dispatch({
          type: 'UPDATE_DATA',
          payload: {...dash_data, data: data.data}
        })
        setData(data.data);
        setIsLoading(false)
    }
    if (Object.keys(dash_data.data).length === 0) {
      getDatas() 
    } else {
      setData(dash_data.data)
    }
    
  },[]);

  function nullData(user, type, description) {
    return (
      <Card withBorder radius="md" className={cx(classes.card)}>
        <Card.Section>
        <a>
          {type == 'restaurants' ? <Image src='' height={180} withPlaceholder placeholder={<IconPizza  size="3rem"/>}/> : null}
          {type == 'films' ? <Image src='' height={180} withPlaceholder placeholder={<IconDeviceTvOld  size="3rem"/>}/> : null}
          {type == 'music' ? <Image src='' height={180} withPlaceholder placeholder={<IconHeadphonesFilled  size="3rem"/>}/> : null}
        </a>
      </Card.Section>
      <Text className={classes.cardTitle} fw={500}>
        No Reviews This Week
      </Text>
      <Text fz="sm" color="dimmed" lineClamp={3}>
          {`${user} did not review any ${type != 'music' ? `new ${type}` : type} this week.  ${description}  Check back in next week!`}
      </Text>
      <Group position="apart"  className={classes.footer}>
        <Group>
            <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="md">
              <Tooltip label="Steven B" withArrow>
                <Avatar src={null} alt="Steven B" color="gray" size="md" radius="md">SB</Avatar>
              </Tooltip>
              <Tooltip label="Amanda A" withArrow>
                <Avatar src={null} alt={`Amanda A`}  color="gray" size="md" radius="md">AA</Avatar>
              </Tooltip>
            </Avatar.Group>
            </Tooltip.Group>
        </Group>
      </Group>
      </Card>
    )
  }
  
  function getBadge(data) {
    return data.steven_b.count > data.amanda_a.count ? data.steven_b.count : data.amanda_a.count
  }
  
  function toggleAllCard(e) {
    dispatch({
        type: 'TOGGLE_ALLCARD',
        payload: {...all_card, open: !all_card.open}
    })
  }

  return (
    <div>
      {data ?
        <div>
          <WeekStats data={data.current_week_totals} />
          <TotalStats data={data.overall_totals}/>
          <Divider
            my="xs"
            variant="dashed"
            labelPosition="center"
            label={
              <>
                <IconSmartHome size={12} />
                <Box ml={5}>House Hits for the Week</Box>
              </>
            }
          />
          <SimpleGrid cols={4}
            spacing="lg"
            breakpoints={[
              { maxWidth: '62rem', cols: 2, spacing: 'md' },

              { maxWidth: '36rem', cols: 1, spacing: 'sm' },
            ]}
          >
        {data.film_items[0] ? <FilmCard data={data.film_items[0]} badge={getBadge(data.film_items[0])}/> : nullData('The Household', 'films',  '') }
        {data.rest_items[0] ? <RestCard data={data.rest_items[0]} badge={getBadge(data.rest_items[0])}/> : nullData('The Household', 'restaurants',  '') }
        {data.steve_top_music[0] ? <MusicCard data={data.steve_top_music[0]} person={data.steve_top_music[0].steven_b_total} other={data.steve_top_music[0].amanda_a_total} array={data.steve_top_music} user={{full:"Steve", alt:"SB"}} /> : nullData('Steve', 'music', '')}
        {data.amanda_top_music[0] ? <MusicCard data={data.amanda_top_music[0]} person={data.amanda_top_music[0].amanda_a_total} other={data.amanda_top_music[0].steven_b_total} array={data.amanda_top_music} user={{full:"Amanda", alt:"AA"}} />: nullData('Amanda', 'music', '')}
      </SimpleGrid>
      <AllCard data={data.rest_items}/>
        </div>
        : 
        <LoadingOverlay visible={loading} overlayBlur={2} />
      }
      {/* <Group position="right" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
        <Button variant="light" radius="md" size="md" onClick={()=> toggleAllCard()}>
          View this week's reviewed eats
        </Button>
      </Group> */}
      <Divider
          my="xs"
          variant="dashed"
          labelPosition="center"
          label={
            <>
              <IconInfoCircle size={12} />
              <Box ml={5}>About</Box>
            </>
          }
          />
      <Container size="lg">
      <div className={classes.inner}>
      <div className={classes.content}>
          <Title className={classes.title}>
          Welcome to our<span className={classes.textHighlight}>Home!</span>
          </Title>
          <Text mt="md" fz="md">
          What you’re looking at above is our home's weekly dashboard - our collective log of what we’ve Watched, Ate, and Listened to throughout the week.  And this isn’t just any dashboard mockup. This dashboard is data-driven, fed by an App Ecosystem that I built - including the front-end you’re viewing now, a central ETL pipeline to serve up the data week-over-week, and yes, even an iOS App to capture everything.  
          <Space h="md" />
          Head on over to the <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/')}>Docs</a></span> to learn more.  Everything from the design and execution is documented - from the single-table-design, the external API integrations, and all the AWS Services used to provide this dashboard served to you, week-over-week.
          </Text>
          <Space h="xl" />
          {isMobile ?
          <div> 
          {/* <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/eatitems')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">About - @docs/</Text>
            </Group>
          </Paper>
          <Space h="lg" /> */}
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>About - @docs/</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         {/* <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/eatitems')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">About - @docs/</Text>
           </Group>
         </Paper> */}
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>About - @docs/</Text>
           </Group>
         </Paper>
         </Group>
          }
 
        </div>
        </div>
        </Container>
      </div>
    
  );
}

const mapStateToProps = state => {
    return { dash_data: state.dash_data, navbar: state.navbar, all_card: state.all_card}
}
export default connect(mapStateToProps)(Dashboard)
