import * as yup from 'yup';
import React, {useState} from 'react';
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import { setUserSession } from '../../service/authService';
import { TextInput, Space, Anchor, Group, Alert, Button, Box, Container , Text, PasswordInput, PinInput, createStyles, rem, Flex} from '@mantine/core';
import { useForm, yupResolver  } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';

const loginSchema = yup.object({
    email: yup
    .string('String Values only')
    .email('Not a valid email address')
    .required('Email Address is Required')
    .max(254,'Emails cannot be more than 254 characters'),
    
    password: yup
      .string('String Values only')
      .required('Password is Required')
      .max(254,'Passwords cannot be more than 254 characters')
});

const useStyles = createStyles((theme) => ({
      alertHighlight:{
        position: 'relative',
        color: theme.fn.variant({ variant: 'fill', color: 'red' }).color,
        backgroundColor:  theme.fn.variant({ variant: 'light', color: 'red' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.fn.variant({ variant: 'light', color: 'red' }).color, }
      }
}))

const Login = () => {
    const { classes, cx, theme } = useStyles();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();
    const [code, setCode] = useState();
    const [error, setError] = useState(false);
    let navigate = useNavigate();

    const form = useForm({
        initialValues: {
          email: '',
          password:''
        },
        validate: yupResolver(loginSchema),
      });

    const submitHandler = (event) => {
        setLoading(true)
        setCode('')
        setError()
        form.clearErrors();
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
            }
        }
        const requestBody = {
            email: form.values.email,
            password: form.values.password
        }
        axios.post(process.env.REACT_APP_LOGIN_DATA, requestBody, requestConfig).then((response) => {
            setUser({user: response.data.user, token: response.data.token, followers: response.data.followers});
            setLoading(false)
            
        }).catch((error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                form.setErrors({email: error.response.data.message, password: error.response.data.message})
                setLoading(false)
            } else {
                form.setErrors({email: 'Backend server down. Try again later.', password: 'Backend server down. Try again later.'})
                setLoading(false)
            }
        })
    }


    const twilioHandler = (event) => {
        setError(false)
        setLoading(true)
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
            }
        }
        const requestBody = {
            phone_number: user.user.phone_number,
            code: code
        }
        axios.post(process.env.REACT_APP_SMSVERIFY_DATA, requestBody, requestConfig).then((response) => {
            setUserSession(user.user, user.token, user.followers);
            navigate(`/home/dashboard`);
        }).catch((error) => {
            setError(true)
            setLoading(false)
        })
    }

    return (
        <Container >
        <div>
        <Space h="md" />
            <form onSubmit={form.onSubmit((e) => submitHandler(e))}>
            {user ? 
            <Box maw={400} h={300} mx="auto">
                <Text>Verification Code</Text>
                <Space h="sm" />
                <PinInput oneTimeCode length={6} type="number" value={code} onChange={setCode} onComplete={twilioHandler} error={error} size="md"/>
                {error ? <Text fz="sm" c="red"><Space h="sm" />*Error on code validation</Text> : null}
                <Space h="sm" />
                <Group>
                <Flex align="flex-end" gap="md">
                <Button onClick={twilioHandler} loading={loading}>Verify</Button>
                <Anchor td="underline" c="dimmed" fz="sm" component="button" type="button" onClick={submitHandler}>resend code</Anchor>
                </Flex>
                </Group>
                <Space h="xl" />
                <Text fz="sm" color="dimmed">A 6-digit pin has been sent to your registered number for verification</Text>
            </Box>
            :
            <Box maw={400} mx="auto">
            <TextInput
                label="Email"
                type = "text"
                size="md"
                mt="md"
                placeholder="email"
                name="email"
                withAsterisk
                {...form.getInputProps('email')}
            />
            <Space h="sm" />
            <PasswordInput 
                placeholder="password"
                label="Password"
                size="md"
                withAsterisk
                {...form.getInputProps('password')}
            />
            
            <Group position="left" mt="xl">
                <Button loading={loading} type="submit" size="md">
                Login
                </Button>
            </Group>
            <Space h="xl" />
            <Alert icon={<IconAlertCircle size="1rem" />} title="Hold up! " color="red" variant="outline">
                Odds are you're not supposed to be here.  If you have access to our calendar <span className={classes.alertHighlight}><a onClick={()=> navigate('/home/login/visitor')}>you can log in here</a></span>
            </Alert>
            </Box>}
            </form>
        </div>
        </Container>
    )
}

export default Login;