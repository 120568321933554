import React, {useEffect, useState} from 'react';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux'

import { Affix, rem, AppShell, MantineProvider, ActionIcon,createStyles } from '@mantine/core';
import { IconCircleX, IconCodeCircle } from '@tabler/icons-react';

import TopNav from './components/header';
import NavbarMinimal from './components/navigation/index2';
import Footer from './components/footer';
import { useMediaQuery} from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';

import { Outlet } from "react-router-dom";

import { getToken, getUser, resetUserSession, setUserSession} from './service/authService';

function App({ld_theme, toc, dispatch}) {
  const [isAuthenticating, setAuthenticating] = useState(true);
  const size = useMediaQuery('(min-width: 48em)');

  useEffect(() => {
    const token = getToken();
    if (token === 'undefined' || token === undefined || token === null || !token){
      return;
    }
    const decoded_token = jwtDecode(token);
    const expiration =  new Date(decoded_token.exp *1000)
    const now = new Date()
    if (Date.now() >= expiration) {
        resetUserSession();
        setAuthenticating(false);
    } else {
        setAuthenticating(false);
    }
  }, []);

  const token = getToken();

  function toggleTOC(e) {
    dispatch({
        type: 'TOGGLE_TOC',
        payload: {...toc, open: !toc.open}
    })
  }
  return (
        <MantineProvider theme={{ colorScheme: ld_theme.theme, loader: 'bars' }} withGlobalStyles withNormalizeCSS>
        <Notifications />
        <AppShell
            padding="md"
            navbarOffsetBreakpoint="sm"
            navbar={
              <NavbarMinimal />
            }
            header={<TopNav links={[
              {"link": "/", "label": "Dashboard", "disabled": false}, 
              {"link": "/docs", "label": "Docs", "disabled": false}
            ]}>{/* header content */}</TopNav>}
            footer={<Footer />}
          >
          <Outlet />
          {size ? null :
          <Affix position={{ bottom: rem(20), right: rem(20) }}zIndex={500}>
            <ActionIcon size="3rem" onClick={()=> toggleTOC()} style = {{transform: 'rotate(90deg)' }} color={ld_theme.theme == 'dark' ? 'gray' : 'blue'} radius="xl" variant="filled">
              {!toc.open ? <IconCodeCircle size="1.65rem" />: <IconCircleX size="1.65rem" />}
            </ActionIcon>
          </Affix>}
        </AppShell>
        </MantineProvider>
  );
}

const mapStateToProps = state => {
  return { ld_theme: state.ld_theme, navbar: state.navbar, toc: state.toc}
}

export default connect(mapStateToProps)(App)
