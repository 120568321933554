import { connect } from 'react-redux'
import React from 'react';
import { rem, createStyles, keyframes } from '@mantine/core';

import SvgStepper from './svgStepper';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';



const useStyles = createStyles((theme) => ({
  highlight: {
    position: 'relative',
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(2)} ${rem(6)}`,
  },
    second : {
      '#spotify_outer_circle':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#spotify_band_1':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#spotify_band_2':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#spotify_band_3':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#music_get_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#musicget_to_spotify': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#music_get_to_s3_temp': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#s3_music_tmp': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#music_get_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
      '#music_get_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    third : {
      '#s3_music_tmp': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#music_polling_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#musicpolling_to_s3_music_tmp':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#music_polling_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
      '#music_polling_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    }

}))

function ArchWatches() {
const { classes, cx, theme } = useStyles();

const imageTitle = "Listen Experience"
const steps =[
{step: "first", 
    viewbox:"0 0 250 300",
    breakdown: [], 
},
{step: "second", 
  viewbox:"110 80 160 180", 
  desc: "App ecosystem calls the Spotify API to retrieve House User song listens every 30 minutes between the hours of 8AM and 10PM (US East) and saves the music log to S3",
  breakdown:[
  {
      divider:'Scheduled Event',
      system: 'Music Get - Event Bridge',
      desc:'Scheduled event fires every 30 minutes from the Event Bridge with an empty payload to trigger the Lambda to call the Spotify API',
      code: `
cron expression: */30 8-22 * * ? *
payload: { 
  "key1": "value1", 
  "key2": "value2", 
  "key3": "value3" 
}`,
  },
  {
    divider:'Fire Event to Call Spotify',
    system: 'Music Get - Lambda',
    desc:'Lambda grabs each User from DynamoDB that has linked their User Accounts to Spotify, and for each user, evaluates and submits the refresh_token to Spotify to re-authorize and successfully make a GET request to pull users\' song listens',
    external_packages: [{name: 'Spotify API', url: 'https://developer.spotify.com/documentation/web-api'},{name: 'Axios', url: 'https://www.npmjs.com/package/axios'}],
    code: `
const axios = require("axios");
const { marshall, unmarshall } = require("@aws-sdk/util-dynamodb");
const AWS = require('aws-sdk');
const dynamodb = new AWS.DynamoDB();

exports.handler = async (event) => {
  const dateNow = new Date()
  const users = await getHouseUsers(house)
  const spotifyData = []
}
const client_id = process.env.SPOTIFY_CLIENT_ID;
const client_secret = process.env.SPOTIFY_SECRET;

for (let item of users) {
  const spotify_expiration = new Date(item.spotify_token_expiration).getTime()
  
  let response;
  response = await refreshUserToken(item.spotify_refresh_token)
  
  if (response.access_token) {
    try {
      const tracks = await getTracks(response.access_token, dateNow)
      
      if (tracks.items.length > 0) {
        const track_map = tracks.items.map( (i) => { 
          return spotifyData.push({
            name: i.track.name,
            name_id: i.track.id,
            artist: i.track.artists[0].name,
            artist_id: i.track.artists[0].id,
            explicit: i.track.explicit,
            preview_url: i.track.preview_url,
            image: i.track.album.images[0],
            played_at: i.played_at,
            user: item.email
          })
        });
      } else {
        console.log("User  had no listens")
      }
    } catch (e) {
      console.log("Error on access token")
    }
}  
const getHouseUsers = async (house) => {
  let response
  try {
    response = await dynamodb.query({
      TableName: process.env.TABLE_NAME,
      IndexName: process.env.INDEX_NAME,
      KeyConditionExpression: "#gsi1pk = :v_gsi1pk AND begins_with(#gsi1sk, :v_gsi1sk)",
      ExpressionAttributeNames:{
          "#gsi1pk": "gsi1pk",
          "#gsi1sk": "gsi1sk"
      },
      ExpressionAttributeValues: {
          ":v_gsi1pk": { "S": \`\${house}\` },
          ":v_gsi1sk": {"S": 'USER#' }
      }
    }).promise()
    const items = response.Items.map((item) => {
      const norm_json = unmarshall(item)
      return norm_json
    });
    const filtered_users = items.filter(x => x.hasOwnProperty("spotify_token"))
    return filtered_users
  } catch(error) {
      return 'Error on Get Function Call'
  }
}

const refreshUserToken = async (refresh_token) => {
  try{
    const token_url = 'https://accounts.spotify.com/api/token';
    const data = {
      'grant_type':'refresh_token', 
      'refresh_token': refresh_token,
    };
    const response = await axios.post(token_url, data, {
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + (new Buffer.from(client_id + ':' + client_secret).toString('base64'))
      }
    })
    return response.data; 
  } catch(error){
  }
}`,
errors: [{type:'Invalid URL or Path', code: '403', message: 'Unauthorized'}, {type:'Invalid Access or Refresh Token', code: '403', message: 'Forbidden'}],
},
{
  divider:'S3 Save of House Listens',
  system: 'Music Get - Lambda',
  desc:'Lambda attempts to store the returned Spotify House listens in a temp folder in S3 (Bronze layer) ',
  code: `
if (spotifyData.length > 0) {
  try { 
    const orig_house_data_unparsed = await s3.getObject({
      Bucket : "stev0b-bronze-dev-us-east-1",
      Key: \`stev0b-music-tmp/house_listens.json\`
    }).promise()      
    const orig_house_data = JSON.parse(orig_house_data_unparsed.Body)
        
    const jsonOutput = JSON.stringify([...orig_house_data, ...spotifyData]);
    await saveS3(jsonOutput, \`stev0b-music-tmp/house_listens\`)

  } catch (error) {
      const jsonOutput = JSON.stringify(spotifyData);
      await saveS3(jsonOutput, \`stev0b-music-tmp/house_listens\`)
  }
}`,
errors: [{type:'Invalid S3 Path or Key', code: '500', message: 'Server Error'}, {type:'Invalid Access Rights', code: '403', message: 'Forbidden'}],
on_success: {label: 'All user listens are successfully returned from Spotify and stored in the App Ecosystem via S3, ready to be polled until Reviews can get created.', nav: '/docs'}
},
  ]
},
{step: "third", 
  viewbox:"110 80 160 180", 
  desc: "App ecosystem calls the Music S3 Folder to retrieve House User song listens collected from Spotify. If Enough listens have occurred, the System will generate Reviews for each user.",
  breakdown:[
  {
      divider:'Scheduled Event',
      system: 'Music Poll - Event Bridge',
      desc:'Scheduled event fires every 2 hours from 10:05AM to 10:05PM (US East) using an Event Bridge with a payload of, either, Poll or Push to trigger the Lambda to Poll the S3 folder for evaluating listens or to Push whatever songs are available to generate reviews.',
      code: `
// Poll -- every 2 Hours 10AM to 8PM (US East)
cron expression: 5 10,12,14,16,18,20,22 * * ? *
payload: { "type": "poll" }

// Push -- every day at 10PM (US East)
cron expression: 15 22 * * ? *
payload: { "type": "push" }
`,
  },
  {
    divider:'Poll S3 Folder',
    system: 'Music Get - Lambda',
    desc:'Lambda evaluates the folder to see if the song threshold is met with creating Reviews for users.  If it doesn\'t meet, the function will simply exit.  If it is meeting the threshhold, the System will create Reviews by distinct(Artist) listened to by each User with counts of songs, delete the temp S3 File, and notify users of of newly created Music Reviews',
    code: `
exports.handler = async (event) => {
  try {
    const data = await s3.getObject({
      Bucket : "stev0b-bronze-dev-us-east-1",
      Key : 'stev0b-music-tmp/house_listens.json',
    }).promise()
    const ingestedData = JSON.parse(data.Body);
    
    //Check the size of the File to see if it should get ingested to Reviews, ~1kb per 3min song, 17.5kb = ~20songs
    if (data.ContentLength > (7.5 * 1000) && event.type == "poll" || data.ContentLength > 0 && event.type == "push"){
      const review_setup = await getCountsforReviews(ingestedData)
      const reviews = await createReviewsForUsers(review_setup)
      return "success"
    } else {
      console.log("Not ready for ingestion...")  
    }
  } catch(error) {
      return 'No new listens'
  }
}
const getCountsforReviews = async (ingested_data) => {
  const tempResult = {}
  for(let { user, artist_id, artist, preview_url, image, played_at } of ingested_data)
    tempResult[artist_id] = { 
      artist_id,
      artist,
      user,
      count: tempResult[artist_id] ? tempResult[artist_id].count + 1 : 1,
      played_at,
      preview_url,
      image
    } 
  let result = Object.values(tempResult)
  return result
}

const createReviewsForUsers = async (data) => {
  const reviewput = await data.map((item) => {
    const reviewItem = new Review({
      user: item.user,
      r_id: item.artist_id,
      title : item.artist,
      preview_url: item.preview_url,
      count: item.count,
      type: 'listen',
      image: item.image.url,
      played_at: item.played_at,
      house_id : house_id
    })
    return { PutRequest: {
      Item: reviewItem.toItem() }
    }   
  })

  // BatchWriteItem takes a max of 25 items at a time.
  const batches = Math.ceil(reviewput.length / 25)
  for (let i = 0; i < batches; i++) {
    const batch = reviewput.slice(i * 25, (i + 1) * 25)
    const count = 0
    // Reprocess 5 Times if a Batch fails (rare)
    while (count < 5) {
      const { unprocessed } = await processBatch({ requestItems: batch })
      if (!unprocessed) {
          break
      }
      count++
    }
  }
  const unique_users = [...new Set(data.map(item => item.user))];
  await notify_users (unique_users)
  await deleteS3Object()
}

const processBatch = async ({ requestItems }) => {
 const result = await dynamodb.batchWriteItem({
    RequestItems: {
        [process.env.TABLE_NAME]: requestItems
    }
 }).promise()
 if (result.UnprocessedItems.PutRequest) {
    return {
        unprocessed: result.UnprocessedItems.PutRequest
    }
 }
 return
}

async function deleteS3Object() {
  AWS.config.update({
      region: "us-east-1"
  });
  const delete_params = {
      Bucket : "stev0b-bronze-dev-us-east-1",
      Key : 'stev0b-music-tmp/house_listens.json',
  };
  return await s3.deleteObject(delete_params).promise()
  .then(response => {
      return console.log("Updates made, file successfully removed")
  }, error => {
      return console.log("Updates failed to remove file")
  })
}`,
on_success: {label: 'Temp file removed with reviews created for users. New temp file will be created on next Spotify API call.  ', nav: '/docs'},
errors: [{type:'Error on AWS S3 or DynamoDB', code: '500', message: 'Unauthorized'}],
}
  ]
},
]
  
   return (
    <div>
        <SvgStepper imageTitle = {imageTitle} steps = {steps} classes={classes} theme={theme.colorScheme === 'dark' ? theme.colors.yellow[6] : theme.colors.blue[6]}/>
    </div>
  );
}
const mapStateToProps = state => {
    return { zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(ArchWatches)