import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import React, {useState, useRef} from 'react';
import { connect } from 'react-redux'
import { IconPlayerPause, IconPlayerPlay, IconInfoSquare } from '@tabler/icons-react';
import {
  Card,
  Image,
  Text,
  ActionIcon,
  Group,
  Tooltip,
  Avatar,
  createStyles,
  rem,
  Modal,
  Anchor,
  Progress,
  ScrollArea,
  Table,
  Button
} from '@mantine/core';



const useStyles = createStyles((theme) => ({
  playButton: {
    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.blue[6],
    // color: theme.colorScheme === 'dark' ? theme.colors.yellow[6] : theme.white,
    // borderColor: theme.colorScheme === 'dark' ? theme.colors.yellow[6] : theme.colors.blue[6],
      '&, &:hover': {
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        borderColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
  },
  card: {
    position: 'relative',
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  highlight: {
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color : theme.primaryColor,
  },
  rating: {
    position: 'absolute',
    top: theme.spacing.xs,
    right: rem(12),
    pointerEvents: 'none',
  },

  title: {
    display: 'block',
    marginTop: theme.spacing.md,
    marginBottom: rem(5),
  },

  action: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    }),
  },

  footer: {
    marginTop: theme.spacing.md,
  },
  progressBar: {
    '&:not(:first-of-type)': {
      borderLeft: `${rem(3)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white
      }`,
    },
  },
}));

const MusicCard =({
    data,
    user,
    className,
    dispatch,
    music_play,
    person,
    other,
    array,
    ...others
}) => {
  const { classes, cx, theme } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [playing, setPlaying] = useState(false)
  const myRef = useRef();

 const startPlaying = () => {
    myRef.current.play();
    setPlaying(true)
    dispatch({
        type: 'TOGGLE_PLAYPAUSE',
        payload: {...music_play, play: !music_play.play}
    })
  }
  
  const stopPlaying = () => {
    myRef.current.pause();
    setPlaying(false)
    dispatch({
        type: 'TOGGLE_PLAYPAUSE',
        payload: {...music_play, play: !music_play.play}
    })
  }

  const endedPlaying = () => {
    setPlaying(false)
    dispatch({
        type: 'TOGGLE_PLAYPAUSE',
        payload: {...music_play, play: !music_play.play}
    })
  }

  const onPlay = function(item) {
    if(!playing && !music_play.play) {
      startPlaying()
    } 
    
    if(playing && music_play.play) {
        stopPlaying()
    }
  }

  function addHighlight(e) {
    return(<Text span className={classes.highlight} inherit>{e}</Text>)
  }

  const person_total = person.total_listens;
  const house_total = (person.total_listens + other.total_listens)
  const map_array = array.slice(0,9)
  
  function count(item){
    return item.count;
  }
  function sum(prev, next){
    return prev + next;
  }

  const map_count = map_array.map(count).reduce(sum);
  const rows = map_array.map((row) => {

    const positiveReviews = (row.count/map_count) * 100;
    const negativeReviews = ((map_count - row.count) / map_count) * 100;

    return (
      <tr key={row.artist}>
        <td>
          <Anchor color={theme.colors.primaryColor}href={row.reference_url} target="_blank" fz="sm">
            {row.artist}
          </Anchor>
        </td>
        <td>
          <Group position="apart">
            <Text fz="xs" color={theme.colorScheme === 'dark' ? theme.colors.blue[5] : theme.colors} weight={700}>
              {positiveReviews.toFixed(0)}%
            </Text>
          </Group>
          <Progress
            classNames={{ bar: classes.progressBar }}
            sections={[
              {
                value: positiveReviews,
                color: theme.colorScheme === 'dark' ? theme.colors.blue[5] : theme.colors.white,
              },
              {
                value: negativeReviews,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3],
              },
            ]}
          />
        </td>
      </tr>
    );
  });


  const linkProps = {  target: '_blank', rel: 'noopener noreferrer' };

  return (
    <Card withBorder radius="md" className={cx(classes.card, className)} {...others}>
      <Card.Section>
        <a {...linkProps}>
          <Image src={data.image} height={180} withPlaceholder/>
        </a>
      </Card.Section>

      <Text className={classes.title} fw={500} component="a" {...linkProps}>
        {data.artist}
      </Text>

      <Text fz="sm" color="dimmed" lineClamp={3}>
        {user.full} listened to {addHighlight(data.artist)} at least {addHighlight(data.count)} times last week. In total, {user.full} made up over {addHighlight((person_total/house_total *100).toFixed(2)+ '%')} of the household listens this week.
      </Text>
      <Group position="apart"  className={classes.footer}>
        <Group>
            <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="md">
                <Tooltip label={user.full} withArrow>
                    <Avatar src={null} alt={user} size="md" radius="md">{user.alt}</Avatar>
                </Tooltip>
                {/* <Avatar radius="xl">+5</Avatar> */}
            </Avatar.Group>
            </Tooltip.Group>
        </Group>
        <Group spacing={8} mr={0}>
          <ActionIcon onClick={(e)=> onPlay(data.preview_url)} className={playing ? classes.playButton : null} variant="light">
            {playing ? <IconPlayerPause size="1.5rem" className={classes.playButton}/>:<IconPlayerPlay size="1.5rem" />}
            
          </ActionIcon>
        </Group>
        <Group spacing={8} mr={0}>
        <ActionIcon className={classes.action} size="lg" onClick={open}>
            <IconInfoSquare size="1.1rem" />
          </ActionIcon>
        </Group>
      </Group>
      <audio
        ref={myRef}
        src={data.preview_url}
        onEnded={()=> endedPlaying()}
      />
      <Modal
        opened={opened}
        onClose={close}
        title={addHighlight(`Music Top 10 Weekly Breakdown`)}
        fullScreen={isMobile}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <ScrollArea>
        <Table verticalSpacing="xs" className={classes.table} captionSide="bottom">
        <caption>All data sourced from 
            <Anchor href={`https://developer.spotify.com/documentation/web-api`}> {addHighlight('Spotify')}</Anchor>
          </caption>
          <thead>
            <tr>
              <th>Artist</th>
              <th>Music Distribution</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <br />
      <Group className={classes.MobilePadding} position="right" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
          <Button onClick={close} variant="light" radius="md" size="md">
            Close
          </Button>
        </Group>
      </Modal>
    </Card>
  );
}

const mapStateToProps = state => {
    return { music_play: state.music_play}
}
export default connect(mapStateToProps)(MusicCard)
