import dayjs from 'dayjs';
import * as yup from 'yup';
import { connect } from 'react-redux'
import React, {useState, useEffect, useRef} from 'react';
import {getUser, getToken, getFollowers} from '../../service/authService';
import axios from 'axios';
import { setUserSession } from '../../service/authService';
import { useNavigate, useLocation} from "react-router-dom";
import { useMediaQuery} from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { LoadingOverlay, Box, Paper, Button, ActionIcon, MultiSelect,  CloseButton, Checkbox, TextInput, Stack, Space, Container, createStyles, SegmentedControl, Textarea, Grid, Card, Group, Text, Avatar, Badge, Image, Table, Divider, Select, Switch, NumberInput, ThemeIcon} from '@mantine/core';
import { useForm, yupResolver  } from '@mantine/form';
import { DatePicker, TimeInput } from '@mantine/dates';
import { IconDeviceFloppy, IconCheck, IconClock, IconX, IconRefresh, IconPlaneInflight, IconTrain, IconHotelService, IconTicket,IconCar, IconArrowBadgeUp, IconArrowBadgeDown, IconTrash, IconCirclePlus, IconTrashFilled, IconArrowBadgeUpFilled, IconArrowBadgeDownFilled } from '@tabler/icons-react';

const oneDay = 24 * 60 * 60 * 1000;



const formSchema = yup.object({
    title: yup
    .string('String Values only')
    .required('Title is Required')
    .max(25,'Titles cannot be more than 25 characters'),
    
    description: yup
    .string('String Values only')
    .required('Description is Required')
    .max(140,'Descriptions cannot be more than 140 characters'),

    image_url: yup
    .string('String Values only')
    .required('Image URL is Required'),

    event_type: yup
    .string('String Values only')
    .required('Event type is Required'),

    invite: yup
    .string('String Values only')
    .required('Invite type is Required'),

    steven_b: yup
    .boolean('Boolean Values only')
    .required('Steven B is Required'),

    amanda_a: yup
    .boolean('Boolean Values only')
    .required('Amanda A is Required'),

    dates: yup
    .array().length(2,'Dates must be selected.  Double-press the same date if local trip.')
    .required('Dates are required'),
});

const useStyles = createStyles((theme) => ({
    cal_trip: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[2] : theme.colors.red[7],
        fontWeight: 'bold'
      },
    cal_local: {
        color: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.dark[7],
        fontWeight: 'bold'
    },
    card_trip: {
      color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'filled', color: 'red' }).color,
      backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'filled', color: 'red' }).background ,
    },
    card_local: {
      color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'blue' }).color : theme.fn.variant({ variant: 'filled', color: 'blue' }).color,
      backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'blue' }).background : theme.fn.variant({ variant: 'filled', color: 'dark' }).background ,
  },
    indicator: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.colors.blue[7],
    },
    navButton: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
      size:"lg",
      cursor: 'pointer'
    },
 
}));

const EditEvent = ({ld_theme}) => {
    const { classes, cx, theme } = useStyles();
    const [loading, setLoading] = useState(false);
    const [followerLoading, setFollowerLoading] = useState(false);
    const user = getUser();
    const token = getToken();
    const followers = getFollowers();
    const isMobile = useMediaQuery("(max-width: 55em)");
    const [followerList, setFollowers] = useState([]);
    const [invites, setInvites] = useState([]);
    const [pricing, setPricing] = useState([]);
    const [saving, setSaving] = useState(false);
    const [email, setEmail] = useState({});
    const [formSave, setFormSave] = useState(false);
    

    const ref = useRef(HTMLInputElement);
    const ref2 = useRef(HTMLDivElement);

    const {state} = useLocation();
    const [legs, setLegs] = useState(state.legs.length > 0 ? state.legs : []);
    const r = (Math.random() + 1).toString(36).substring(7);
    
    const total = legs.reduce((allTotal, leg) => {
      const sum = leg.events.reduce((total, event) => {
        return total + event.total;
      }, 0);
      return allTotal + sum}, 0);

    const sums = function (type) { return legs.map((i)=>{
      return i.events.filter((e)=> e.type == type)}).flat().reduce((a,c)=> a+c.total ,0)};

    function edit_leg(signal, index, event, prop, value) {

      if (signal == "add_event") {
        // const x = index.events.push({event_id: r})
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              return { ...item, events: [...item.events, {event_id: r, pending: false, total: 0}] };
            } else {
              return item;
            }
          })
        );
      }
      if (signal == "update_event") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              const elem = item.events.find(({event_id}) => event_id===event.event_id);
              if (elem) elem[prop] = value;
              return {...item};
            } else {
              return item;
            }
          })
        );

      // console.log(legs)
      }
      if (signal == "delete_event") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              let elem
              item.events.map((e, i) => {
                if (e.event_id===event.event_id) {
                  elem= i
                }});
              if (elem == 0) item.events.shift()
              if (elem) item.events.splice(elem, 1)
              return {...item};
            } else {
              return item;
            }
          })
        );
      }
      if (signal == "delete_leg") {
        let elem
        console.log(index)
        var element = legs[index];
        legs.splice(index, 1);
        setLegs([...legs]);
      }
      if (signal == "move_eventUp") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              let elem
              item.events.map((e, i) => {
                if (e.event_id===event.event_id) {
                  elem= i
                }});
              if (elem) item.events.splice(elem - 1, 0, item.events.splice(elem, 1)[0]);
              return {...item};
            } else {
              return item;
            }
          })
        );
      }
      if (signal == "move_legUp") {
        let elem
        var element = legs[index];
        legs.splice(index, 1);
        legs.splice(index - 1, 0, element);
        setLegs([...legs]);
      }
      if (signal == "move_legDown") {
        let elem
        var element = legs[index];
        legs.splice(index, 1);
        legs.splice(index + 1, 0, element);
        setLegs([...legs]);
      }
      if (signal == "move_eventDown") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              let elem
              item.events.map((e, i) => {
                if (e.event_id===event.event_id) {
                  elem= i
                }});
              if (elem == 0) item.events.splice(elem + 1, 0, item.events.splice(elem, 1)[0]);
              if (elem) item.events.splice(elem + 1, 0, item.events.splice(elem, 1)[0]);
              return {...item};
            } else {
              return item;
            }
          })
        );
      }
      if (signal == "update_leg") {
        setLegs(
          legs.map((item) => {
            if (item.leg_id === index.leg_id) {
              item[prop] = value;
              return {...item};
            } else {
              return item;
            }
          })
        );
      }
    }

    function format_invites() {
      const values = invites.map((x) => {
        const l = followers.find(e => e.email === x)
        return {email: x, first_name: l.first_name, last_name: l.last_name, status: 'pending'}
      });
      return values
    }

    let navigate = useNavigate();

    useEffect(() => {
      const authUser = async () => { 
        setLoading(true)            
          if (!user || user == 'undefine') {
              return navigate('/home/login/visitor')
            }
          if (!user.role == "admin") {
              return navigate('/home/dashboard')
          }
          const values = followers.map((x) => ({value: x.email, label: `${x.first_name} ${x.last_name}`, disabled: state.invites.some(e => e.email === x.email)}));
          const values_sorted = values.sort((a, b) => a.label.localeCompare(b.label))
          setFollowers(values_sorted)
          state.invites.map((x) => ({...x, disabled: true}))
          setLoading(false)   
      }
      authUser()    
    },[]);

    const form = useForm({
      initialValues: {
        title: state.title,
        description: state.description,
        image_url: state.image_url,
        event_type: state.event_type,
        invite: state.invite,
        time: state.time,
        dates: [new Date(state.from_date), new Date(state.to_date)],  
        steven_b: state.steven_b,
        amanda_a: state.amanda_a
      },
      validate: yupResolver(formSchema),
    });
    
    const submitHandler = (event) => {
        setFormSave(true)
        form.clearErrors();
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY 
            }
        }
        const requestBody = {
          user: user,
          token: token,
          event_id: state.id,
          title: form.values.title,
          description: form.values.description,
          image_url: form.values.image_url,
          event_type: form.values.event_type,
          invite: form.values.invite,
          invites: state.invites.concat(format_invites()),
          new_invites: format_invites(),
          totals: [
            {type: "flight", total: sums("flight"), part: Math.round((sums("flight")/total)*100)},
            {type: "hotel", total: sums("hotel"), part: Math.round((sums("hotel")/total)*100)},
            {type: "event", total: sums("event"), part: Math.round((sums("event")/total)*100)},
            {type: "car", total: sums("car"), part: Math.round((sums("car")/total)*100)},
            {type: "train", total: sums("train"), part: Math.round((sums("train")/total)*100)}
          ],
          legs: legs,
          time: form.values.time,
          from_date: form.values.dates[0],
          to_date: form.values.dates[1],
          steven_b: form.values.steven_b,
          amanda_a: form.values.amanda_a
        }
        console.log(requestBody)
        axios.put(process.env.REACT_APP_EVENT, requestBody, requestConfig).then((response) => {
            //success
            notifications.show({
                title: 'Save Success',
                message: 'Success on saving the event',
                color: 'green'
            })
            setFormSave(false)
            navigate(`/home/dashboard/event/${response.data.id}`)
        }).catch((error) => {
            form.setErrors()
            notifications.show({
                title: error.response ? `Error ${error.response.status}` : 'Server Error',
                message: error.response ? error.response.data.message : "Server Error",
                color: 'red'
            })

            setFormSave(false)
        })
    }
    const requestHandler = (follower, status, type) => {
      setSaving(true)

      const requestConfig = {
          headers: {
              'x-api-key': process.env.REACT_APP_LOGIN_API_KEY 
          }
      }
      const requestBody = {
          user: follower,
          requestor: user,
          token: token,
          event_id: state.id,
          status: status,
          signal: type
      } 
      axios.post(process.env.REACT_APP_INVITE, requestBody, requestConfig).then((response) => {
        if (type == "pricing"){
          const elem = state.pricing.find(({email}) => email=== follower.email);
          if (elem) elem.status = status
          setPricing([{...pricing, follower}])
        } 
        if (type == "status"){
          const elem = state.invites.find(({email}) => email=== follower.email);
          if (elem) elem.status = status
          setPricing([{...invites, follower}])
        } 
        setEmail({})
        setSaving(false)
          
      }).catch((error) => {
          notifications.show({
            title: error.response ? `Error ${error.response.status}` : 'Server Error',
            message: error.response ? error.response.data.message : "Server Error",
            color: 'red'
          })
          setEmail({})
          setSaving(false)
      })
    }
  
    const getFollowersHandler = (event) => {
      setFollowerLoading(true)
      const requestConfig = {
          headers: {
              'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
          }
      }
      const requestBody = {
          token: token
      }
      axios.get(process.env.REACT_APP_LOGIN_DATA, requestConfig).then((response) => {
          setUserSession(user, token, response.data.followers);
          const values = response.data.followers.map((x) => ({value: x.email, label: `${x.first_name} ${x.last_name}`, disabled: state.invites.some(e => e.email === x.email)}));
          const values_sorted = values.sort((a, b) => a.label.localeCompare(b.label))
          setFollowers(values_sorted)
          setFollowerLoading(false)

      }).catch((error) => {
          notifications.show({
            title: error.response ? `Error ${error.response.status}` : 'Server Error',
            message: error.response ? error.response.data.message : "Server Error",
            color: 'red'
          })
          setFollowerLoading(false)
      })
    }

    function updateInvite (e, status){
      setEmail({...e, status: status})

      requestHandler(e, status, "status")
    }

    function updatePricing (e, status){
      setEmail({...e, status: status})

      requestHandler(e, status, "pricing")
    }

    const nights = Math.round((form.values.dates[1] - form.values.dates[0])/oneDay)
    return (
        <Container >
        {loading ? <LoadingOverlay visible={loading} overlayBlur={2} /> :
        <div>
        <Space h="md" />
            <form onSubmit={form.onSubmit((e) => submitHandler(e))}>
            <Grid>
            <Grid.Col span={isMobile ? 12: 7}>
            <SegmentedControl 
                mt="md"
                label="Type"
                size="md"
                data={['local', 'trip']} 
                {...form.getInputProps('event_type')}
            />
            <TextInput
                mt="md"
                size="md"
                placeholder="title"
                label="Title"
                withAsterisk
                maxLength={25}
                // rightSection={<Text size="xs" c={data && form.values.title.length == 25 ? "red" : ""}>{data ? 0:null}</Text>}
                {...form.getInputProps('title')}
            />
            <Textarea
                mt="md"
                size="md"
                placeholder="description"
                label="Description"
                maxLength={140}
                withAsterisk
                rightSection={<Text size="xs" c={form.values.description.length == 140 ? "red" : ""}>{form.values.description.length}</Text>}
                {...form.getInputProps('description')}
            />
            <TextInput
                mt="md"
                size="md"
                placeholder="image_url"
                label="Image"
                withAsterisk
                rightSection={<CloseButton aria-label="Close modal" onClick={()=> form.setFieldValue('image_url', '')}/>}
                {...form.getInputProps('image_url')}
            />
            </Grid.Col>
            <Grid.Col span={isMobile ? 12: 5}>
                <Stack align="center" >
                <Text mt="xl" c="dimmed">{`Select ${form.values.type == "local" ? "a date" : "dates"}`}</Text>
                <DatePicker
                    size="md"
                    weekendDays={[]}
                    firstDayOfWeek={0}
                    defaultDate={new Date(state.from_date)}
                    allowSingleDateInRange
                    type="range"
                    {...form.getInputProps('dates')}
                />
                {form.values.event_type == "local" ? 
                <TimeInput
                    label="Time"
                    size="md"
                    ref={ref}
                    withAsterisk
                    rightSection={
                        <ActionIcon onClick={() => ref.current.showPicker()}>
                          <IconClock size="1rem" stroke={1.5} />
                        </ActionIcon>
                    }
                    maw={400}
                    mx="auto"
                    {...form.getInputProps('time')}
                >
                </TimeInput> : null 
                }
                </Stack>
            </Grid.Col>
            <Grid.Col span={isMobile ? 12: 7}>
              <Text mt="md" c="dimmed">Invitees</Text>

              <SegmentedControl 
                  mt="md"
                  label="Type"
                  size="md"
                  data={['private', 'open']} 
                  {...form.getInputProps('invite')}
              />
              <Group>
                  <Checkbox mt="md" {...form.getInputProps('amanda_a')} label="Amanda A" defaultChecked/>
                  <Checkbox mt="md" {...form.getInputProps('steven_b')} label="Steven B" defaultChecked/>
              </Group>

              <MultiSelect
                  mt="lg"
                  label="Send invites"
                  rightSection={<ActionIcon loading={followerLoading} onClick={() => getFollowersHandler()}><IconRefresh /></ActionIcon>}
                  data={followerList}
                  onChange={setInvites}
                  placeholder="Select registered users"
                  maxDropdownHeight={300}
                  size="md"
                  nothingFound="Nobody here"
                  clearable
                  searchable
              />
              <Space h="md" />
              {state.invites.map((x) => (x.status == "accepted" || x.status == "house_accepted" ? <Badge mr="xs" color="green" variant="light" key={x.email}>{`${x.first_name.charAt(0).toUpperCase()}${x.first_name.slice(1)} ${x.last_name.substr(0, 1).toUpperCase()}`}</Badge> : null))}
              {state.invites.map((x) => (x.status == "pending" ? <Badge mr="xs" color="yellow" variant="light" key={x.email}>{`${x.first_name.charAt(0).toUpperCase()}${x.first_name.slice(1)} ${x.last_name.substr(0, 1).toUpperCase()}`}</Badge> : null))}
              {state.invites.map((x) => (x.status == "rejected" || x.status == "house_rejected" ? <Badge mr="xs" color="red" variant="light" key={x.email}>{`${x.first_name.charAt(0).toUpperCase()}${x.first_name.slice(1)} ${x.last_name.substr(0, 1).toUpperCase()}`}</Badge> : null))}
              <Divider mt="xl" my="xs" label="Pricing Requests " labelPosition="left" />
              {state.pricing.map((x) => (x.status == "house_accepted" ? <Badge mr="xs" color="green" variant="light" key={x.email}>{`${x.first_name.charAt(0).toUpperCase()}${x.first_name.slice(1)} ${x.last_name.substr(0, 1).toUpperCase()}`}</Badge> : null))}
              {state.pricing.map((x) => (x.status == "house_rejected" ? <Badge mr="xs" color="red" variant="light" key={x.email}>{`${x.first_name.charAt(0).toUpperCase()}${x.first_name.slice(1)} ${x.last_name.substr(0, 1).toUpperCase()}`}</Badge> : null))}
            </Grid.Col>
            <Grid.Col span={isMobile ? 12: 5} >
            <Group position="center">
              <Text mt="md" c="dimmed">Card Preview</Text>
            </Group>
              <Card  id="123" mt="xl" shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src={form.values.image_url}
                    height={160}
                    alt={form.values.title}
                    withPlaceholder
                  />
                </Card.Section>

                <Group position="apart" mt="md" mb="xs">
                  <Text weight={500}>{form.values.title}</Text>
                  <Badge className={form.values.event_type == "local" ? classes.card_local : classes.card_trip} variant="light">
                    {form.values.event_type}
                  </Badge>
                </Group>

                <Text size="sm" color="dimmed">
                  {form.values.description}
                </Text>
                <Group position="apart" mt="md">
                  <Avatar.Group spacing="xs">
                    {form.values.steven_b ? <Avatar size="md" radius="lg" variant="filled">SB</Avatar> : null }
                    {form.values.amanda_a ?<Avatar size="md" radius="lg" variant="filled">AA</Avatar> : null }
                    {state.invites && state.invites.filter((x) => (x.status == "accepted" || x.status == "house_accepted")).length > 0 ? <Avatar size="sm" radius="lg" variant="outline">{`+${state.invites.filter((x) => (x.status == "accepted" || x.status == "house_accepted")).length}`}</Avatar>: null}
                  </Avatar.Group>
                  <Text size="sm" fw={700} color={form.values.dates.length > 0 ? "dimmed":"red"}>
                    {form.values.dates.length > 0 ? form.values.dates[0].toLocaleDateString() : form.values.event_type == "local" ? "Select date" : "Select dates"}
                  </Text>
                  {form.values.event_type == "trip" ? <Text size="sm" color="dimmed">
                    {nights > 1 && form.values.dates.length == 2 || nights == 0 && form.values.dates.length == 2? `${nights} nights`: nights == 1 && form.values.dates.length == 2 ?`${nights} night` : null}
                  </Text> : null 
                  }
                  {form.values.event_type == "local" ? <Text size="sm" fw={700} color={!form.values.time ? "red":"dimmed"}>
                    {!form.values.time ? 'Select time' : new Date('1970-01-01T' + form.values.time + 'Z').toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'})}
                  </Text> : null 
                  }
                </Group>     
            </Card>
            </Grid.Col>
            { state.invites.filter(e => e.status === "house_pending").length > 0 ? <Table mt="md" fontSize="sm" captionSide="top" striped withBorder>
              <caption>Pending Invites</caption>
              <tbody>
              {state.invites.map((x) => (x.status == "house_pending" ? <tr key={x.email}><td>{x.first_name}</td><td>{x.last_name}</td><td>{x.email}</td><td><Button size="xs" compact color="green" loading={x.email == email.email && email.status == "house_accepted" ? true : false} onClick={()=> updateInvite(x, "house_accepted")}><IconCheck size="0.9rem"/></Button></td><td><Button size="xs" compact color="red" loading={x.email == email.email && email.status == "house_rejected" ? true : false} onClick={()=> updateInvite(x, "house_rejected")}><IconX size="0.9rem"/></Button></td></tr>: null))}
              </tbody> 
            </Table>: null}
            { state.pricing.filter(e => e.status === "house_pending").length > 0 ? <Table mt="md" fontSize="sm" captionSide="top" striped withBorder>
              <caption>Pending Pricing</caption>
              <tbody>
              {state.pricing.map((x) => (x.status == "house_pending" ? <tr key={x.email}><td>{x.first_name}</td><td>{x.last_name}</td><td>{x.email}</td><td><Button size="xs" compact color="green" loading={x.email == email.email && email.status == "house_accepted" ? true : false} onClick={()=> updatePricing(x, "house_accepted")}><IconCheck size="0.9rem"/></Button></td><td><Button size="xs" compact color="red" loading={x.email == email.email && email.status == "house_rejected" ? true : false} onClick={()=> updatePricing(x, "house_rejected")}><IconX size="0.9rem"/></Button></td></tr>: null))}
              </tbody> 
            </Table> : null}
            </Grid>
            {state.event_type == "trip" ? <div> 
            <Space h="sm" />
            <Divider mt="xl" my="xs" label="Legs" labelPosition="left" />
            {legs.map((i, index) => (
              <div key={i.leg_id} style={{border: `1px dashed ${theme.colors.dark[3]}`, borderRadius: '25px', marginTop: "1rem"}}>
                <Group position="apart"> 
                  <Text ml="md" fw={700}>{`Leg ${index +1}`}</Text>
                  <TextInput 
                  placeholder="leg description"
                  withAsterisk
                  mt="xs"
                  mr="md"
                  size="md"
                  defaultValue={i.leg_description}
                  onChange={(e) => edit_leg("update_leg", i, index, 'leg_description', e.currentTarget.value)}
                  />
                </Group>
                <Container>
              {i.events.map((x, index) => (  
                <div key={x.event_id} >
                <Group position="apart" mt="lg">
                <Select
                  label="Event Type"
                  placeholder="Pick one"
                  size="md"
                  data={['flight', 'train', 'hotel', 'event', 'car']}
                  onChange={(e) => edit_leg("update_event", i, x, 'type', e)}
                  defaultValue={x.type}
                  withAsterisk
                />
                <Switch
                  label="pending"
                  defaultChecked={x.pending}
                  onChange={(e) => edit_leg("update_event", i, x, 'pending', e.currentTarget.checked)}
                />
                <NumberInput
                  label="Total"
                  size="md"
                  defaultValue={x.total}
                  onChange={(e) => edit_leg("update_event", i, x, 'total', e)}
                  withAsterisk
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  formatter={(value) =>
                    !Number.isNaN(parseFloat(value))
                    ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                    : '$ '
                  }
                />
                </Group>
                  {x.type == "train" || x.type=="flight"? 
                  <Group position="apart" mt="md">
                  <TextInput
                    label="from"
                    size="md"
                    defaultValue={x.from}
                    onChange={(e) => edit_leg("update_event", i, x, 'from', e.currentTarget.value)}
                    withAsterisk
                  />
                  <TimeInput
                    label="time"
                    size="md"
                    ref={ref}
                    withAsterisk
                    onChange={(e) => edit_leg("update_event", i, x, 'from_time', new Date('1970-01-01T' + e.target.value + 'Z').toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}))}
                    maw={400}

                  />
                  <Switch
                    label="direct"
                    defaultChecked={x.direct}
                    onChange={(e) => edit_leg("update_event", i, x, 'direct', e.currentTarget.checked)}
                  />
                  <TextInput
                    label="to"
                    size="md"
                    defaultValue={x.to}
                    onChange={(e) => edit_leg("update_event", i, x, 'to', e.currentTarget.value)}
                    withAsterisk
                  />
                  <TimeInput
                    label="time"
                    size="md"
                    ref={ref}
                    withAsterisk
                    defaultValue={x.to_time}
                    onChange={(e) => edit_leg("update_event", i, x, 'to_time', new Date('1970-01-01T' + e.target.value + 'Z').toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}))}
                    maw={400}
                  />
                  </Group>: null }
                  {x.type == "hotel"? 
                  <Group position="apart" mt="md">
                  <TextInput
                    label="name"
                    size="md"
                    defaultValue={x.name}
                    onChange={(e) => edit_leg("update_event", i, x, 'name', e.currentTarget.value)}
                    withAsterisk
                  />
                  <NumberInput
                    label="nights"
                    size="md"
                    defaultValue={x.nights}
                    onChange={(e) => edit_leg("update_event", i, x, 'nights', e)}
                    withAsterisk
                    // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    // formatter={(value) =>
                    //   !Number.isNaN(parseFloat(value))
                    //   ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                    //   : '$ '
                    // }
                    
                  />
                  <NumberInput
                    label="rating"
                    size="md"
                    onChange={(e) => edit_leg("update_event", i, x, 'rating', e)}
                    withAsterisk
                    defaultValue={x.rating}
                    precision={2}
                    min={0}
                    step={0.05}
                    max={5}
                  />
                  </Group>: null }
                  {x.type == "event"? 
                  <Group position="apart" mt="md">
                  <TextInput
                    label="act or event name"
                    size="md"
                    defaultValue={x.act}
                    onChange={(e) => edit_leg("update_event", i, x, 'act', e.currentTarget.value)}
                    withAsterisk
                  />
                  <TextInput
                    label="venue or location"
                    size="md"
                    defaultValue={x.venue}
                    onChange={(e) => edit_leg("update_event", i, x, 'venue', e.currentTarget.value)}
                    withAsterisk
                  />
                  </Group>: null }
                  {x.type == "car"? 
                  <Group position="apart" mt="md">
                  <TextInput
                    label="rental company"
                    size="md"
                    defaultValue={x.rental_company}
                    onChange={(e) => edit_leg("update_event", i, x, 'rental_company', e.currentTarget.value)}
                    withAsterisk
                  />
                  </Group>: null }
                  <TextInput
                    label="image url"
                    size="md"
                    mt="md"
                    defaultValue={x.image}
                    // rightSection={<CloseButton aria-label="Close modal" onClick={()=> {edit_leg("update_event", i, x, 'image', '')}}/>}
                    onChange={(e) => edit_leg("update_event", i, x, 'image', e.currentTarget.value)}
                  />

                <Group position="apart">
                  <Group position="left">
                    {x.image ? <Avatar src={x.image} radius="sm" size="sm" /> : <ThemeIcon variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "red"}>{x.type == "flight" ? <IconPlaneInflight color={ld_theme.theme == "dark" ? "black" : "black"}/>: x.type == "train" ? <IconTrain color={ld_theme.theme == "dark" ? "black" : "black"}/> : x.type == "hotel" ?<IconHotelService color={ld_theme.theme == "dark" ? "black" : "black"}/> : x.type == "event" ?<IconTicket color={ld_theme.theme == "dark" ? "black" : "black"}/> : x.type == "car" ?<IconCar color={ld_theme.theme == "dark" ? "black" : "black"}/> : null}</ThemeIcon>}
                  </Group>
                  <Group position="right">
                  <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("move_eventUp", i, x)}>
                    <IconArrowBadgeUp size="2rem" stroke={1.5} />
                  </ActionIcon> 
                  <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("move_eventDown", i, x)}>
                    <IconArrowBadgeDown size="2rem" stroke={1.5} />
                  </ActionIcon>              
                  <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("delete_event", i, x)}>
                    <IconTrash size="2rem" stroke={1.5} />
                  </ActionIcon>
                  </Group>
                </Group> 
                <Divider mt="xl" my="xs"/></div>))}

                <Group position="apart">
                  <Group position="left">
                    <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("add_event", i)}>
                      <IconCirclePlus size="2rem" stroke={1.5} />
                    </ActionIcon>
                  </Group>
                  <Group position="right">
                    <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("move_legUp", index)}>
                    <IconArrowBadgeUpFilled size="2rem" stroke={1.5} />
                    </ActionIcon> 
                    <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("move_legDown", index)}>
                      <IconArrowBadgeDownFilled size="2rem" stroke={1.5} />
                    </ActionIcon>              
                    <ActionIcon mt="lg" mb="lg" onClick={() => edit_leg("delete_leg", index)}>
                      <IconTrashFilled size="2rem" stroke={1.5} />
                    </ActionIcon>
                </Group>
                </Group>

                </Container>
                
              </div>
            ))}
            <Group position="center">
            <ActionIcon mt="lg" onClick={() => setLegs([...legs, {leg_id: r, events: []}])}>
              <IconCirclePlus size="2rem" stroke={1.5} />
            </ActionIcon>

            </Group></div>: null}
            <Group grow mt="lg">
              <Box pos="relative">
                <LoadingOverlay visible={formSave} overlayBlur={2} loaderProps={{ color: 'white', variant: 'bars' }}/>
              <Paper withBorder p="md" radius="md" className={classes.navButton}
              component="a" onClick={()=> submitHandler()}
              >
                <Group position="apart">
                  <IconDeviceFloppy className={classes.navButton}/>
                  <Text size="lg" className={classes.navButton}>
                    Save
                  </Text>
                </Group>
                <Group position="right" spacing="xs" shadow="xs">
                  <Text fz="sm" color="dimmed">Calendar - @home/dashboard/view_event</Text>
                </Group>
              </Paper>
            </Box>
            </Group>
            </form>
        </div>}
        </Container>
    )
}

const mapStateToProps = state => {
    return { ld_theme: state.ld_theme}
}

export default connect(mapStateToProps)(EditEvent);

