import { connect } from 'react-redux'
import { useLocation, useNavigate} from "react-router-dom";
import { ScrollArea, Modal, Group, Navbar, NavLink, Box, Text, createStyles, rem} from '@mantine/core';
import { IconListSearch, IconAdjustments } from '@tabler/icons-react';
import { useMediaQuery} from '@mantine/hooks';
import {getUser, resetUserSession} from '../../service/authService';

const useStyles = createStyles((theme) => ({
  link: {
    ...theme.fn.focusStyles(),
    display: 'block',
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    lineHeight: 1.2,
    cursor: 'pointer',
    fontSize: theme.fontSizes.sm,
    padding: theme.spacing.xs,
    borderTopRightRadius: theme.radius.sm,
    borderBottomRightRadius: theme.radius.sm,
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkActive: {
    fontWeight: 500,
    borderLeftColor: theme.colorScheme === 'dark' ? theme.colors.yellow[5] :theme.colors.blue[5],
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,

    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors.yellow[5], 0.25)
          : theme.colors[theme.primaryColor][0],
    },
  },
}));

const mockdata = [
  { key:'/',label: 'Dashboard', order:1 },
  { key:'/docs/',label: 'About', order: 1 },
  { key:'/docs/iosapp',label: 'iOS App', order: 1 },
  { key:'/docs/iosapp/eatitems',label: 'Eat', order: 2 },
  { key:'/docs/iosapp/watchitems',label: 'Watch', order: 2 },
  { key:'/docs/iosapp/listenitems',label: 'Listen', order: 2 },
  { key:'/docs/iosapp/reviews',label: 'Review', order: 2 },
  { key:'/docs/iosapp/celery',label: 'Celery', order: 2 },
  { key:'/docs/datafeed',label: 'Data Feed', order: 1 },
  { key:'/docs/frontend',label: 'Frontend', order: 1},
  { key:'/home/dashboard',label: 'Home Dashboard', order: 1},
  // { icon: IconNotebook, label: 'Docs', disabled: true }
];

const homeData = [
  { key:'/home/dashboard',label: 'Home Dashboard', order: 1, disabled: true },
  { key:'/home/login/visitor',label: 'Login', order: 2 },
]

const NavbarMinimal = ({navbar, toc, dispatch}) => {
  let location = useLocation();
  let navigate = useNavigate();
  const user = getUser();
  const { classes, cx } = useStyles();
  const isMobile = useMediaQuery("(max-width: 48em)");

  const logoutHandler = function (){
    resetUserSession();
    navigate(`/home/login/visitor`);
    if (isMobile) {
      toggleTOC()
    }
  }
  
  const navLink =  function(item, isMobile){
    navigate(item.key)
    if (isMobile) {
      toggleTOC()
    }
  }
  function toggleTOC(e) {
    dispatch({
        type: 'TOGGLE_TOC',
        payload: {...toc, open: !toc.open}
    })
  }

const refLocation = location.pathname.includes('/home/dashboard/') ? '/home/dashboard' : location.pathname

const items = mockdata.map((item) => (
  <Box
    component="a"
    onClick={() => navLink(item, isMobile)}
    key={item.label}
    disabled={item.disabled}
    className={cx(classes.link, { [classes.linkActive]: refLocation === item.key})}
    sx={(theme) => ({ paddingLeft: `calc(${item.order} * ${theme.spacing.md})` })}
  >
    {item.label}
  </Box>
));

return (
  <div>
    <Navbar width={{ sm:180}} p="md" hiddenBreakpoint="sm" hidden={!navbar.open}>
    {items}
    <Box
        component="a"
        onClick={() => user ? logoutHandler() : navLink({key: '/home/dashboard'}, isMobile)}

        key="Login"
        className={cx(classes.link) }
        sx={(theme) => ({ paddingLeft: `calc(2 * ${theme.spacing.md})` })}
      >
        {user ? "Logout":"Login"}
      </Box>
    </Navbar>
    <Modal
      zIndex={499}
      opened={toc.open}
      onClose={()=> toggleTOC()}
      fullScreen={isMobile}
      transitionProps={{ transition: 'slide-up', duration:275, timingFunction:"ease" }}
    >
      <ScrollArea>
      <div>
      <Group mb="md">
        <IconListSearch size="1.1rem" stroke={1.5} />
        <Text>Navigation</Text>
      </Group>
        {items}
      </div>
      <Box
        component="a"
        onClick={() => user ? logoutHandler() : navLink({key: '/home/dashboard'}, isMobile)}

        key="Login"
        className={cx(classes.link) }
        sx={(theme) => ({ paddingLeft: `calc(2 * ${theme.spacing.md})` })}
      >
        {user ? "Logout":"Login"}
      </Box>
      </ScrollArea>
    </Modal>
</div>
);
}

const mapStateToProps = state => {
    return { navbar: state.navbar, toc: state.toc}
}

export default connect(mapStateToProps)(NavbarMinimal)