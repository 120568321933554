import React, {useState, useEffect} from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getToken, getUser, resetUserSession, setUserSession} from '../service/authService';
import { connect } from 'react-redux'

const PrivateRoute = ({dispatch, lastPath}) => {
    let location = useLocation();
    const currentPath=location.pathname
    const path = currentPath.substring(currentPath.lastIndexOf("/") + 1, currentPath.length);   
    
    useEffect(() => {
        const last_path = () => {        
            dispatch({
                type: 'UPDATE_PATH',
                payload: {...lastPath, path: path}
            })
        }
        last_path()    
    },[]);
    
    const auth = getToken(); // determine if authorized

    return auth ? <Outlet /> :  <Navigate to="/home/login/visitor"/>;
}

const mapStateToProps = state => {
    return { lastPath: state.lastPath}
}

export default connect(mapStateToProps)(PrivateRoute);