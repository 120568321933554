import { connect } from 'react-redux'
import React from 'react';
import { useNavigate} from "react-router-dom";
import { Paper, createStyles, Image, Container, Title, Group, Text, List, ThemeIcon, rem, Space, Timeline, Table, Tabs} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery} from '@mantine/hooks';
import { Prism } from '@mantine/prism';

import '../index.css';
import { IconCheck, IconDeviceTvOld, IconHeadphones, IconPizza, IconThumbDownFilled, IconThumbUpFilled, IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';

import {ReactComponent as Reviewsvg} from './svgs/reviews.svg';
import ArchReviews from '../components/architecture/archReviews';

import nav1 from './images/review-nav-1.png';
import nav2 from './images/review-nav-2.png';
import card1 from './images/review-card-1.png';
import card2 from './images/review-card-2.png';
import card3 from './images/review-card-3.png';
import indicator1 from './images/review-indicator-1.png';
import indicator2 from './images/review-indicator-2.png';
import emptied from './images/review-emptied-1.png';
import error from './images/review-messages-1.png';
import info from './images/review-messages-2.png';

const light_theme = []

const watchReview = `{
  pk: "USER#steve@stev0b.com",
  sk: "REVIEW#2RIPQEw3XUGyp4S4BAoXclk88Pu",
  createdAt: "2023-06-16T16:56:00.849Z",
  gsi1pk: "FEED",
  gsi1sk: "2023-06-18T17:47:26.371Z",
  house_id: "b37692e6-288f-4bb6-be0b-3d48439be132",
  id: "2RIPQEw3XUGyp4S4BAoXclk88Pu",
  image: "stev0b-film-images/fNOH9f1aA7XRTzl1sAOx9iF553Q.jpg",
  review_answer: 1,
  r_id: "105",
  title: "Back to the Future",
  TTL: 1688319160.71,
  type: "MOVIE",
  user: "steve@stev0b.com"
}`;

const listenReview = `{
  pk: "USER#steve@stev0b.com",
  sk: "REVIEW#2RJWy6R1zTfcs5IkOtY90Zm8pm3",
  count: 3,
  createdAt: "2023-06-17T02:28:35.777Z",
  gsi1pk: "FEED",
  gsi1sk: "2023-06-18T17:47:13.849Z",
  house_id: "b37692e6-288f-4bb6-be0b-3d48439be132",
  id: "2RJWy6R1zTfcs5IkOtY90Zm8pm3",
  image: "https://i.scdn.co/image/ab67616d0000b273d4d4c752eccb62aa42f6487d",
  preview_url: "https://p.scdn.co/mp3-preview/452c99b73be7c98b9ff5e93b5487920539cb506e?cid=b93d20e672a442f7bbd1329cb9c1de00",
  review_answer: 1,
  r_id: "4uN3DsfENc7dp0OLO0FEIb",
  title: "Sugar Ray",
  TTL: 1688320002.264,
  type: "listen",
  user: "steve@stev0b.com"
}`;

const eatReview = `{
  pk: "USER#steve@stev0b.com",
  sk: "REVIEW#2RLfr9cmCfj8531beWLrd1bBQhG",
  createdAt: "2023-06-17T20:41:24.726Z",
  gsi1pk: "FEED",
  gsi1sk: "2023-06-18T17:47:26.371Z",
  house_id: "b37692e6-288f-4bb6",
  id: "2RLfr9cmCfj8531beWLrd1bBQhG",
  image: "stev0b-restaurant-images/ChIJ8zm7vvC-rkwRvL4cJ-irMTk"
  review_answer: 0,
  r_id: "ChIJ8zm7vvC-rkwRvL4cJ-irMTk",
  title: "Jordan Pond House Restaurant",
  TTL: 1688320002.264,
  type: "eat",
  user: "steve@stev0b.com"
}`;

const useStyles = createStyles((theme) => ({
    review_light: {
        '#iphone-button': {fill: theme.colors.dark[9], stroke: theme.colors.dark[9]},
    },
    review_dark: {
        '#iphone_bezel': {stroke: theme.colors.yellow[4]},
        '#iphone_frame': {stroke: theme.colors.yellow[4]},
        '#iphone-button': {fill: theme.colors.gray[4], stroke: theme.colors.gray[4]},
        '#iphone_card': {fill: theme.colors.dark[9], stroke: theme.colors.yellow[4]},
        '#iphone_card-2': {fill: theme.colors.dark[9], stroke: theme.colors.yellow[4]},
        '#heart': {stroke: theme.colors.gray[4]},
        // outline: '3px solid green',
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,
        [theme.fn.smallerThan('55em')]: {
            maxWidth: '100%',
            marginRight: 0,
          },
      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,}
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
}))

function Reviews({ld_theme}) {
    const { classes, cx, theme } = useStyles();
    const isMobile = useMediaQuery("(max-width: 55em)");
    let navigate = useNavigate();
  
   return (
    <div>
        <Container>
            <div className={classes.inner}>
            <div className={classes.content}>
                <Title className={classes.title}>
                Swipe left or <br /> right to <span className={classes.highlight}>review</span>
                </Title>
                <Text color="dimmed" mt="md">
                Reviews are where the household can rate all the items throughout the week.
                As Eats, Watches, and Listens are created, Review items are generated. Users will swipe Review Cards, left or right, to rate
                each experience. 
                </Text>

                <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                    <ThemeIcon size={20} radius="xl" variant="light" color={ld_theme.theme == "dark" ? "yellow" : "blue"}>
                    <IconCheck size={rem(12)} stroke={2} />
                    </ThemeIcon>
                }
                >
                <List.Item>
                    <b>Forget Checkboxes</b> - having the user swipe Review Cards in the App helps retain the user
                    engagement
                </List.Item>
                <List.Item>
                    <b>Feedback for the Experience</b> – the direction of the swipe will indicate the user's rating of the experience:
                    Right is <span><ThemeIcon size={20}variant="light" color={ld_theme.theme == "dark" ? "yellow" : "blue"}><IconThumbUpFilled size={rem(12)}/></ThemeIcon></span> Left is <span><ThemeIcon size={20}variant="light" color={ld_theme.theme == "dark" ? "yellow" : "blue"}><IconThumbDownFilled size={rem(12)}/></ThemeIcon></span>
                </List.Item>
                <List.Item>
                    <b>Set for the Data Feed</b> – on each swipe, the user is marking reviews for the Weekly Feed
                    to pick up on its next run.
                </List.Item>
                </List>
                </div>
                {isMobile ? null : <Reviewsvg width='55%' viewBox="0 0 250 300" className={ld_theme.theme == 'dark' ? classes.review_dark : classes.review_light}/> }
            </div>
            {isMobile ? <Group position="center"><Reviewsvg height={rem(200)} viewBox="50 80 125 150" className={ld_theme.theme == 'dark' ? classes.review_dark : classes.review_light}/></Group>: null}
            <Title className={classes.subHeading}>
                What are Reviews?
            </Title>
            <Text mt="md" fz="md">
                Reviews are how the system captures whether a user liked or disliked an experience. They 
                are the application's instrument to communicate to the data feed that 
                a user has left a review, and it needs to get picked up.
            </Text> 
            <Space h="md" />
            <Title className={classes.subHeading}>
                How are they created?
            </Title>
            <Text mt="md" fz="md">
                For <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/eatitems')}>Eat items</a></span>, 
                reviews are created at the time of selecting the restaurant that the user - and other household users -
                are tagged in dining out.  If the restaurant is new to the household, then the reviews will be created at the final step of the
                Step Function. Swiping Left 
                <Space h="md" />
                Likewise, for <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/watchitems')}>Watch items</a></span>, 
                reviews are created at the time of selecting the film or show that the user - and other household users -
                are tagged in enjoying the flick.  Just like the Eat Items, if the Film is a new watch for the household, then the reviews will be created at the final step of the
                Step Function.
                <Space h="md" />
                <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/listenitems')}>Listen item</a></span> 
                reviews are generated for each user as the household listens pile up each day. When the polling system determines enough songs have been played in the house, each user that has been listening to music will
                have a Review for each Artist available for review.   
            </Text>
            <Space h="md" />
            <Title className={classes.subHeading}>
                User Flow
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="Get Reviews">
                    <Text color="dimmed" size="sm">Reviews refreshed on each login/session</Text>
                </Timeline.Item>
                <Timeline.Item title="Navigate">
                    <Text color="dimmed" size="sm">Navigate to Review area of application</Text>
                </Timeline.Item>
                <Timeline.Item title="Present Review Cards">
                    <Text color="dimmed" size="sm">Application displays a stack of Review cards for the user to interact</Text>
                </Timeline.Item>
                <Timeline.Item title="Update Review">
                    <Text color="dimmed" size="sm">User swipes left or right on each card until the card deck is empty</Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Navigate
            </Title>
            <Text mt="md" fz="md">
                The iOS App features a dedicated section for user reviews that have been generated for the signed-in user.  
                A horizontal touch gesture from Left-to-Right will reveal the menu where the Review section can be accessed. 
                Also, the menu can be revealed by tapping the Avatar icon in the upper-right area of the screen
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> 
            </Group>
            }
           <Space h="md" />
            <Title className={classes.thirdHeading}>
                Review Cards
            </Title>
            <Text mt="md" fz="md">
                When accessing the Review section, if the user has one (1) or more reviews assigned, the App will display a card deck.  
                Depending on the type of experience to review, the Card will display a different image:  
                <Space h="sm" />
                <List>
                    <List.Item>Eat Items - zoomed-in image of the gps location of the restaurant</List.Item>
                    <List.Item>Watch Items - featured poster of the film or show</List.Item>
                    <List.Item>Listen Items - randomly selected album cover from the songs listened for that session</List.Item>
                </List>
                <Space h="sm" />
                Underneath the stack of Review cards will be the Film/Show, Restaurant, or Artist name, and the date when the Review was generated.
                Listen Items will also feature a 30-second playback, if the user needs a refresher of an Artist or Song that may be unfamiliar.
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={card1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={card2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={card3} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={card1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={card2} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={card3} maw={240} mx="auto" radius="md" withPlaceholder /> 
            </Group>
            }
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Update Review
            </Title>
            <Text mt="md" fz="md">
                For each card in the deck, the user will have the option to swipe that card in any direction.  The direction of the card swipe determines the
                "Like" or "Dislike" of that Restaurant, Film, or Artist being reviewed:
                <Space h="sm" />
                <List>
                    <List.Item>Swipe Right - Like</List.Item>
                    <List.Item>Swipe Left - Dislike</List.Item>
                    <List.Item>Swipe Up or Down - Skip Over Review</List.Item>
                </List>
                <Space h="sm" />
                If the Review is skipped or ignored (swiping up or down), the Review Card will still remain un-reviewed. The user will continue to see the Review Card in the deck after every App refresh, until that card is swiped Left or Right.   
                Once the deck is cleared of all Review Cards, the Application will display an image to signal to the user that all Reviews are complete with the option to refresh reviews. 
                Users can interact with the cards by long-pressing by touch on the card and moving the card in the intended direction for review.  As cards are guided left or right, a visual icon will be displayed to remind
                the user how the Review will be answered for that experience.  The further the card's rotation will determine the icon's opacity.
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={indicator1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={indicator2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={emptied} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={indicator1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={indicator2} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={emptied} maw={240} mx="auto" radius="md" withPlaceholder /> 
            </Group>
            }
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                System Messages
            </Title>
            <Text mt="md" fz="md">
                There are two (2) types of messages a user can be presented when Updating a Review or when Refreshing the Review card deck:
                <Space h="sm" />
                <List>
                    <List.Item>Error - System fails to update the Record due to invalid session, an invalid Review ID, or a Server Error</List.Item>
                    <List.Item>Informational - There are no additional Reviews for the User to Review (after refresh)</List.Item>
                </List>
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={error} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={info} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={error} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={info} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="md" />
            <Title className={classes.subHeading}>
                Tech Overview
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="Data Model">
                    <Text color="dimmed" size="sm">Review Entity data schema</Text>
                </Timeline.Item>
                <Timeline.Item title="Tech Flow">
                    <Text color="dimmed" size="sm">Tech flow for updating Reviews</Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Data Model
            </Title>
            <Text mt="md" fz="md">
                The Data Model needs to be shared across all three (3) experiences of Eat, Watch, and Listens, so the 
                data model needs to reflect the same.  Since the Reviews are user-specific, they have an access pattern by User 
                and Review Date created, represented by a <span className={classes.textHighlight}><a href='https://www.npmjs.com/package/ksuid'>KSUID</a></span>.
                As mentioned in the Tech sections, KSUIDs are great for creating unique IDs that have an embedded Date within it.  This allows the system to take
                advantage of DynamoDB to use this as its sort key. The Global Secondary Index (GSI) on create will have the House ID as its primary key, and the Review KSUID
                as its sort key.  This allows any House Admin for that house to view how many users have Reviews outstanding.  
                <Space h="sm" />
                After a Review is updated by a User, the GSI pk is stamped with marks the weekly Data Feed can query: gsi-pk labeled with <b>FEED</b> and the gsi-sk with a readable date.
                <Space h="sm" />
                Below is a summary table for the Review Entity and example records:
            </Text>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md" >
            <caption>Before User Reviewed</caption>
            <thead>
                <tr>
                    <th colSpan="2">Primary Key</th>
                    <th colSpan="2">Secondary Index</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>pk</th>
                    <th>sk</th>
                    <th>gsi1-pk</th>
                    <th>gsi1-sk</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>USER#[user_id]</td>
                    <td>REVIEW#[ksuid]</td>
                    <td>HOUSE#[house_id]</td>
                    <td>REVIEW#[ksuid]</td>
                </tr>
            </tbody>
            </Table>
            <Space h="md" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <caption>After User Reviewed</caption>
            <thead>
                <tr>
                    <th colSpan="2">Primary Key</th>
                    <th colSpan="2">Secondary Index</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>pk</th>
                    <th>sk</th>
                    <th>gsi1-pk</th>
                    <th>gsi1-sk</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>USER#[user_id]</td>
                    <td>REVIEW#[ksuid]</td>
                    <td>FEED</td>
                    <td>[created_date]</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <caption>Item Attributes</caption>
            <thead>
                <tr>
                    <th>attribute</th>
                    <th>description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>pk</td>
                    <td rowSpan="2">primary key</td>
                </tr>
                <tr>
                    <td>sk</td>
                </tr>
                <tr>
                    <td>count</td>
                    <td>Listens only: Count of songs played for an Artist for the Artist in Review</td>
                </tr>
                <tr>
                    <td>createdAt</td>
                    <td>Date review was generated (ISO format)</td>
                </tr>
                <tr>
                    <td>gsi1pk</td>
                    <td rowSpan="2">global secondary key</td>
                </tr>
                <tr>
                    <td>gsi1sk</td>
                </tr>
                <tr>
                    <td>house_id</td>
                    <td>household id</td>
                </tr>
                <tr>
                    <td>id</td>
                    <td>review id</td>
                </tr>
                <tr>
                    <td>image</td>
                    <td>Eat or Watch: S3 path to image
                        <Space />
                        Listen: Spotify CDN Path to image
                    </td>
                </tr>
                <tr>
                    <td>preview_url</td>
                    <td>Listens only: Spotify CDN Path to 30-sec song preview url
                    </td>
                </tr>
                <tr>
                    <td>review_answer</td>
                    <td>on Update:  User Answer</td>
                </tr>
                <tr>
                    <td>r_id</td>
                    <td>Reference ID of the Experience from the data provider
                        <Space />
                        Watch Item: Film ID - TMDB
                        <Space />
                        Eat Item: Place ID - Google
                        <Space />
                        Listen Item: Artist ID - Spotify
                    </td>
                </tr>
                <tr>
                    <td>title</td>
                    <td>Film/Restaurant/Artist Name</td>
                </tr>
                <tr>
                    <td>TTL</td>
                    <td>on Update: Time-to-Live (milliseconds) remaining </td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>Type of Experience</td>
                </tr>
                <tr>
                    <td>user</td>
                    <td>User assigned for Review</td>
                </tr>
            </tbody>
            </Table>
            <Space h="md" />
            <Title className={classes.fourthHeading}>Examples</Title>
            <Space h="md" />
            <Tabs color={ld_theme.theme == "dark" ? "grape":"red"}variant="default" defaultValue="eat">
            <Tabs.List grow>
                <Tabs.Tab value="eat" icon={<IconPizza size="0.8rem" />}>Eat</Tabs.Tab>
                <Tabs.Tab value="watch" icon={<IconDeviceTvOld size="0.8rem" />}>Watch</Tabs.Tab>
                <Tabs.Tab value="listen" icon={<IconHeadphones size="0.8rem" />}>Listen</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="eat" pt="xs">
                <Prism noCopy language="jsx">{eatReview}</Prism>
            </Tabs.Panel>
            <Tabs.Panel value="watch" pt="xs">
                <Prism noCopy language="jsx">{watchReview}</Prism>
            </Tabs.Panel>

            <Tabs.Panel value="listen" pt="xs">
                <Prism noCopy language="jsx">{listenReview}</Prism>
            </Tabs.Panel>
            </Tabs>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Tech Flow
            </Title>
            <Text mt="md" fz="md">
                The Tech Flow for updating Reviews is straight-forward.  Each card in the user deck is associated with the User ID and the Review ID (pk and sk).  As cards are getting swiped, Left or Right, there is an API call to make the update to DynamoDB on that Primary Key with the Answer ID.
                As each DynamoDB item or record is updated, the GSI is updated and associated a Time-to-Live (TTL), since the record should/will be picked up by the weekly Data Feed and pulled into the downstream Data Lake.  Use the stepper below to follow the full tech cycle: 
            </Text>
            <Space h="sm" />
            <Group position="center"><ArchReviews /></Group>
            <Space h="xl" />
            {isMobile ?
          <div>  

          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/listenitems')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">Listen Items - @iosapp/listenitems</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/celery')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>Celery Run - @iosapp/celery</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/listenitems')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">Listen Items - @iosapp/listenitems</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/celery')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>Celery Run - @iosapp/celery</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(Reviews)