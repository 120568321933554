import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { connect } from 'react-redux'
import {
  Text,
  Group,
  Tooltip,
  Avatar,
  createStyles,
  Modal,
  Anchor,
  ScrollArea,
  Table,
  Button
} from '@mantine/core';



const useStyles = createStyles((theme) => ({

  highlight: {
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color : theme.primaryColor,
  },
}));

const AllCard =({
    data,
    user,
    className,
    dispatch,
    all_card,
}) => {
  const { classes, cx, theme } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 50em)");

  function addHighlight(e) {
    return(<Text span className={classes.highlight} inherit>{e}</Text>)
  }

  function toggleAllCard(e) {
    dispatch({
        type: 'TOGGLE_ALLCARD',
        payload: {...all_card, open: !all_card.open}
    })
  }

  const rows = data.map((row) => {
    return (
      <tr key={row.name}>
        <td>
          <Anchor color={theme.colors.primaryColor}href={row.reference_url} target="_blank" fz="sm">
            {row.name}
          </Anchor>
        </td>
        <td>
        <Group>
            <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="md">
                {row.steven_b.count >= 1 ?                
                  <Tooltip label="Steven B" withArrow>
                    <Avatar src={null} alt="Steven B" color={row.steven_b.count !==0 && row.steven_b.count == row.steven_b.sum ? "green": "red"} size="md" radius="md">SB</Avatar>
                </Tooltip>: null
                }
                {row.amanda_a.count >= 1 ?
                <Tooltip label="Amanda A" withArrow>
                    <Avatar src={null} alt={`Amanda A`}  color={row.amanda_a.count !=0 && row.amanda_a.count == row.amanda_a.sum ? "green": "red"} size="md" radius="md">AA</Avatar>
                </Tooltip>: null}
                {/* <Avatar radius="xl">+5</Avatar> */}
            </Avatar.Group>
            </Tooltip.Group>
        </Group>
        </td>
      </tr>
    );
  });


  const linkProps = {  target: '_blank', rel: 'noopener noreferrer' };

  return (
      <Modal
        opened={all_card.open}
        onClose={()=> toggleAllCard()}
        title={addHighlight(`Weekly Update`)}
        fullScreen={isMobile}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <ScrollArea>
        <Table verticalSpacing="xs" className={classes.table} captionSide="bottom">
        <caption>All data sourced from 
            <Anchor > {addHighlight('Google')}</Anchor>
          </caption>
          <thead>
            <tr>
              <th>Restaurant</th>
              <th>House Rating</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <br />
      <Group className={classes.MobilePadding} position="right" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
          <Button onClick={()=> toggleAllCard()} variant="light" radius="md" size="md">
            Close
          </Button>
        </Group>
      </Modal>
  );
}

const mapStateToProps = state => {
    return { all_card: state.all_card}
}
export default connect(mapStateToProps)(AllCard)
