import { connect } from 'react-redux'
import React from 'react';
import { rem, createStyles, keyframes } from '@mantine/core';

import SvgStepper from './svgStepper';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';

  const card_swipe = keyframes({
    '0%': {transform:'translate3d(7.6rem, 1.18rem, 0) rotate(0turn)'},
    '50%': {transform:'translate3d(8.6rem, 0.18rem, 0) rotate(1.5turn)'},
    '100%': {transform: 'translate3d(9rem, 1.99rem, 0) rotate(3turn)'}
  });


const useStyles = createStyles((theme) => ({
  highlight: {
    position: 'relative',
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(2)} ${rem(6)}`,
  },
    second : {
        '#iphone_card': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, transformOrigin: '3.953065px 4.905575px', animation:`${card_swipe} .75s linear forwards`},
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#apigateway_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#apigateway_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    third : {
        '#iphone_card': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, transformOrigin: '3.953065px 4.905575px', transform: 'translate3d(9rem, 1.99rem, 0)'},
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#sessions_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    fourth : {
        '#iphone_card': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, transformOrigin: '3.953065px 4.905575px', transform: 'translate3d(9rem, 1.99rem, 0)'},
        '#iphone_button':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_bezel':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#iphone_frame':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_gateway_stroke': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dynamodb_path': {fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#app_to_api': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#api_to_sessions': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#sessions_to_dynamo': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
        '#dynamodb_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
        '#dynamodb_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    }
}))

function ArchReviews() {
const { classes, cx, theme } = useStyles();

const imageTitle = "Review Swipe Left or Right"
const steps =[
{   step: "first", 
    viewbox:"0 0 250 300",
    breakdown: [], 
},
{
step: "second", 
viewbox:"60 10 150 150", 
desc: "User swipes left or right on a review. As a card is swiped, the App makes a request to the API to save the review answer.",
breakdown:[
{
    divider:'App Request',
    system: 'iOS App',
    desc:'Application sends a request to the API Endpoint with its application-specific API Token in the request header, a bearer token to verify the User Session, and the Review request body:',
    external_packages: [{name: 'Axios', url: 'https://www.npmjs.com/package/axios'}],
    code: `{
  url: 'stev0b-api-url/review',
  method: 'put',
  headers: {
    Authorization: 'bearer jardv3Lp-Szew-UHSbf',
    x-api-key: 'TXkOp3J1SbuadZqudDXT11CsWNa8xi'
  },
  body: {
    review_id: '2Q6xjY28rASGnzft3hg7G5WJwO3',
    review_answer: 1
  }
}`,
},
    {
        divider: 'Evaluate Request',
        system: 'API Gateway',
        desc: 'The API Gateway evaluates the request header url and ensures the path is a valid endpoint. As an added layer of security, the x-api-key is validated as only the application should have that token.',
        errors: [{type:'Invalid URL or Path', code: '403', message: 'Unauthorized'}, {type:'Invalid API Key', code: '403', message: 'Forbidden'}],
        on_success: {label: 'Payload passes to Sessions lambda as an event for further processing', nav: '/docs'}
    }
]
},
{step: "third", 
viewbox:"60 10 150 150", 
desc: "The Sessions Lambda will determine which function to execute based on the validated payload received from the API Gateway and process the event.",
breakdown:[
{
    divider:'API Event',
    system: 'API Gateway',
    desc:'API Gateway passes the payload to the Sessions Lambda for further processing:',
    code: `{
  url: 'stev0b-api-url/review',
  method: 'put',
  headers: {
    Authorization: 'bearer jardv3Lp-Szew-UHSbf',
    x-api-key: 'TXkOp3J1SbuadZqudDXT11CsWNa8xi'
  },
  body: {
    review_id: '2Q6xjY28rASGnzft3hg7G5WJwO3',
    review_answer: 1
  }
}`,
},
    {
        divider: 'Determine Function to Execute',
        system: 'Sessions Lambda',
        desc: 'The Sessions Lambda contains many stored functions and will use the API Gateway event to determine which function to execute based on the payload received',
        code: `const { updateReview } = require('./handlers/updateReview');
const updateReviewPath = '/review';

exports.handler = async (event) => {
  let response;
  switch(true) {
    ...
    case event.httpMethod === 'PUT' && event.path === updateReviewPath: 
      response = await updateReview(event);
      break;
    
    default:
      response = { statusCode: 404, body: JSON.stringify(event)};
  }
  return response
}`,
        errors: [{type:'Invalid Path or Undetermined Function', code: '404', message: 'Unauthorized'}],
    },
    {
        divider: 'Update Review - Validate Session',
        system: 'Sessions Lambda',
        desc: 'The Update Review function will first confirm the user is a valid and logged-in user from the Authorization token from the passed event',
        code: `const { getSession } = require('../data');
const { Session } = require('../entities')
const { buildResponse } = require('./utils');

const handler = async (event) => {
  const sessionId = event.headers.Authorization.toLowerCase().replace('bearer ', '');
  const sessionItem = new Session({sessionId: sessionId}) 
  
  try {
    const user = await getSession(sessionItem)
    ...
  } catch(error) {
    buildResponse(403, "Invalid Session, Login Required")
  }

}
module.exports.updateReview = handler;`,
        errors: [{type:'Invalid Session', code: '403', message: 'Unauthorized'}],
    },
    {
        divider: 'Update Review - Validate Schema',
        system: 'Sessions Lambda',
        desc: `The Update Review function will also confirm the schema in the event payload is also valid.  If the function returns an error, the entire error array will be sent in the response (i.e. more than one field could have an error). Sessions uses the external library Yup for schema validation`,
        external_packages: [{name: 'Yup', url: 'https://www.npmjs.com/package/yup'}],
        code: `const { buildResponse } = require('./utils');

const handler = async (event) => {
  const event_body = JSON.parse(event.body);
  
  try {
    await reviewSchema.validate(event_body)
    ...
  } catch(error) {
    buildResponse(400, {client_errors: err.errors})
  }

}
module.exports.updateReview = handler;

const reviewSchema = yup.object({
    review_id: yup
      .string({review_id:'String Values only'})
      .required({review_id:'Review ID is required'}),     
    review_answer: yup
        .number({review_number: 'Number Values only'})
        .required({review_number: 'Review is required'})
});`,
        errors: [{type:'Invalid Schema', code: '400', message: 'Unauthorized'}],
        on_success: {label: 'Sessions passes the event body to DynamoDB to commit the update', nav: '/docs'}
    }
 ],
},
{step: "fourth", 
viewbox:"60 10 150 150", 
desc: "The Sessions Lambda will attempt to save the updated Review in DynamoDB",
breakdown: [
{
    divider:'Sessions Prep Data',
    system: 'Sessions Lambda',
    desc:'With the user and schema validated, Sessions will construct the Review in a format for saving to DynamoDB by calling its Review Class and constructing the Review',
    code: `const { Review } = require('../entities')
const { updateReview } = require('../data');  

const handler = async (event) => {
  const event_body = JSON.parse(event.body);
  ...
  try {
    const reviewItem = new Review({
      id: event_body.review_id,
      review_answer: event_body.review_answer
    })
    const response = await updateReview(reviewItem)
    const statusCode = response.review ? 200:500
    return buildResponse(statusCode, {
        response,
        server_error: response.error
      })
  } catch(error) {
    buildResponse(500, "API Error")
  }
}`,
},
    {
        divider: 'Update Review',
        system: 'DynamoDB',
        desc: 'The Sessions Lambda executes a function to save the Review in DynamoDB. The function will apply a Time-To-Live (ttl) to the Item and set Global Secondary Index attributes (gsi1) for the Feed to query on its next run. TTL indicates that the record can expire or auto-delete after 14 days.',
        code: `const EXPIRATION = 14 * 86400000;
const currentDate = new Date();
const currentDateExp = new Date(currentDate.getTime() + EXPIRATION);
const ttl = currentDateExp.getTime() / 1000

const updateReview = async (review) => {
  try {
    await dynamodb.updateItem({
        TableName: process.env.TABLE_NAME,
        Key: review.key(),
        UpdateExpression: SET #gsi1pk =:v_gsi1pk, #gsi1sk =:v_gsi1sk, #review_answer =:v_review_answer, #TTL =:v_TTL,
        ExpressionAttributeNames: {
            #gsi1pk: gsi1pk,
            #gsi1sk: gsi1sk,
            #review_answer: review_answer,
            #TTL : TTL
        },
        ExpressionAttributeValues: {
            :v_gsi1pk: {S: 'FEED'},
            :v_gsi1sk": {S: createdAt.toISOString()},
            :v_review_answer: {N: review.review_answer',
            :v_TTL : {N: ttl.toString()}
        },
    }).promise()
    return { review }
  } catch (error) {
    return { error: error }
  }
}
module.exports = { updateReview }`,
        errors: [{type:'Server Error', code: '500', message: 'Unauthorized'}],
        on_success: {label: 'Review successfully saved and Sessions sends response back to the iOS App', nav: '/docs'}
    },    
]
}]
  
   return (
    <div>
        <SvgStepper imageTitle = {imageTitle} steps = {steps} classes={classes} theme={theme.colorScheme === 'dark' ? theme.colors.yellow[6] : theme.colors.blue[6]}/>
    </div>
  );
}
const mapStateToProps = state => {
    return { zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(ArchReviews)