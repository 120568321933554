import dayjs from 'dayjs';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux'
import React, {useState, useEffect, useRef} from 'react';
import {getUser, getToken, getFollowers} from '../../service/authService';
import axios from 'axios';
import { useNavigate, useLocation} from "react-router-dom";
import { useMediaQuery} from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { HoverCard, Modal, Popover, Button, Center, Skeleton, LoadingOverlay, Box,  Paper, rem, Space, Container, createStyles, Grid, Card, Group, Text, Avatar, Badge, Image, Stack, Anchor, Rating, ThemeIcon, Divider, Progress, SimpleGrid, ActionIcon, Textarea, Overlay, AspectRatio} from '@mantine/core';
import { Calendar} from '@mantine/dates';
import { IconCirclePlus, IconUser, IconArrowRotaryStraight, IconArrowNarrowRight, IconHotelService, IconTicket, IconPlaneInflight, IconDeviceAnalytics, IconUsers, IconArrowNarrowLeft, IconPencil, IconTrain, IconCar} from '@tabler/icons-react';

const oneDay = 24 * 60 * 60 * 1000;

function getDatesBetween(startDate, endDate) {
  const currentDate = new Date(startDate.getTime());
  const dates = [];
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

const colorsLight = ["#0085CA","#101820", "#FA5252", "#7950F2", "#E64980"]
const colorsDark = ["#15AABF", "#FAB005", "#BE4BDB", "#82C91E", "#FF8787"]
const statusTripAllows = ["accepted", "pending", "house_accepted"]
const pricingRolesAllowed = ["admin", "close"]

const useStyles = createStyles((theme) => ({
  pending: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.md,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.pink[6], theme.colors.orange[6]),
    },
  },
  accepted: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.md,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.green[9]),
    },
  },
  rejected: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.md,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.red[6], theme.colors.red[9]),
    },
  },
  private: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.md,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.dark[3], theme.colors.dark[6]),
    },
  },
  house_pending: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.md,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.pink[6], theme.colors.orange[6]),
    },
  },
  house_approved: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.md,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.green[9]),
    },
  },
  house_rejected: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.md,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.red[6], theme.colors.red[9]),
    },
  },
  open: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.md,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.green[9]),
    },
  },
  cal_trip: {
      color: theme.colorScheme === 'dark' ? theme.colors.yellow[2] : theme.colors.red[7],
      fontWeight: 'bold'
  },
  cal_local: {
      color: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.dark[7],
      fontWeight: 'bold'
  },
  card_trip: {
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'filled', color: 'red' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'filled', color: 'red' }).background ,
  },
  card_local: {
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'blue' }).color : theme.fn.variant({ variant: 'filled', color: 'blue' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'blue' }).background : theme.fn.variant({ variant: 'filled', color: 'dark' }).background ,
  },
  rating: {
    position: 'absolute',
    bottom: rem(10),
    pointerEvents: 'none',
  },
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  imageSection: {
    padding: theme.spacing.md,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: rem(5),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },
  progressLabel: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    fontSize: theme.fontSizes.sm,
  },

  stat: {
    borderBottom: `${rem(3)} solid`,
    paddingBottom: rem(5),
  },

  statCount: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.3,
  },

  diff: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    display: 'flex',
    alignItems: 'center',
  },

  icon_aggregate: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
  },

  highlight: {
    position: 'relative',
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'outline', color: 'red' }).color : theme.fn.variant({ variant: 'outline', color: 'grape' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'outline', color: 'red' }).background : theme.fn.variant({ variant: 'outline', color: 'grape' }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(4)}`,
  },
  link : {
    cursor: 'pointer'
  },
  navButton: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    size:"lg",
    cursor: 'pointer'
  },
 
}));


const ViewEvent = ({ld_theme, dispatch, lastPath}) => {
    const { classes, cx, theme } = useStyles();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState();
    const user = getUser();
    const token = getToken();
    const followers = getFollowers();
    const isMobile = useMediaQuery("(max-width: 55em)");
    const [data, setData] = useState([]);
    const [rsvp, setRsvp] = useState(false)
    const [pricing, setPricing] = useState(false)
    const [messageModal, setMessageModal] = useState(false)
    const [message, setMessage] = useState()
    const [messageLoading, setMessageLoading] = useState(false)

    function datePast () {
      if (new Date(data.to_date) < new Date) {
        return true
      }
    }

    function eligibleView () {
      if (pricingRolesAllowed.includes(user.role) || data.invite == "open" || (pricing? pricing[0].status == "house_accepted":null) || (rsvp? statusTripAllows.includes(rsvp[0].status) :null)) {
        return true
      }
    }

    function viewTrip () {
      if (
        (data.event_type == "trip" && pricingRolesAllowed.includes(user.role)) || 
        (datePast() && data.event_type == "trip") || 
        (data.event_type == "trip" && data.invite == "private" && rsvp && statusTripAllows.includes(rsvp[0].status)) || 
        (data.event_type == "trip" && data.invite == "open")
      ) {
        return true
      } else {
        return false
      }
    }

    let navigate = useNavigate();
    let location = useLocation();
    const currentPath=location.pathname
    const path = currentPath.substring(currentPath.lastIndexOf("/") + 1, currentPath.length);
    const [selected, setSelected] = useState([]); 

    const rsvp_verbiage = {
      pending: {title:"Invited", description: `You've been invited to join ${data.amanda_a ? "Amanda" : null} ${data.amanda_a && data.steven_b ? "and" : null} ${data.steven_b ? "Steve" : null} for this ${data.event_type == "local" ? "local event": "trip"}. Please respond using the actions below.`},
      accepted: {title:"Going", description: `You're joining ${data.amanda_a ? "Amanda" : null} ${data.amanda_a && data.steven_b ? "and" : null} ${data.steven_b ? "Steve" : null} for this ${data.event_type == "local" ? "local event": "trip"}. Email reminders and event updates will be sent to you.`},
      rejected: {title:"Not Going", description: "You've declined joining this event."},
      private: {title:"Private", description: "This event is marked private, so some details are hidden.  Wanna go? We're always open to have others join us!  Click the button below and we'll follow up with you."},
      open: {title:"Open Invite", description: "This event is marked open, so all details are here.  Let us know if you'd like to join by clicking the button below."},
      house_pending: {title: "Pending", description:`${data.amanda_a ? "Amanda" : null} ${data.amanda_a && data.steven_b ? "and" : null} ${data.steven_b ? "Steve" : null} will reach out to you for joining this ${data.event_type == "local" ? "local event": "trip"}. Once approved, you will be able to see all event details`},
      house_accepted: {title: "Going", description:`Let's go! You're in on this ${data.event_type == "local" ? "local event": "trip"}.  All details should be available for view now.`},
      house_rejected: {title: "Not Going", description:`It's rare, but we needed to pass on joining this ${data.event_type == "local" ? "local event": "trip"}.  Check back here for more events to join with us.`}
    }

    const pricing_verbiage = {
      ineligible: {title: "Pricing", description:`All past trips have the simple details, but we don't typically share the pricing info once an event is in the history books.  Curious or wanting to just see how much it cost us? Click the button below and we'll follow up with you.`},
      house_pending: {title: "Pricing Pending", description:`Amanda and Steve will reach out to you for getting the pricing for this ${data.event_type == "local" ? "local event": "trip"}. Once approved, you will be able to see all event pricing`},
      house_rejected: {title: "Pricing Rejected", description:`It's rare, but we needed to pass on pricing this ${data.event_type == "local" ? "local event": "trip"} for you.  Check back here for more events to join with us or for past trips to view our itineraries.`}
    }

    function LegCard(i, index) {
      return (
        <Grid.Col key={i.leg_id} span={isMobile ? 12: 6}>
        <Card withBorder radius="md" className={classes.card}>
        
        { i.events.map((x, e_i) => (
        <Card.Section key={e_i}className={classes.imageSection}>
          {(x.type == "flight" || x.type == "train") && !x.pending ?
          <Grid>            
            <Grid.Col span={2}>
              {x.image ? <Avatar src={x.image} radius="sm" size="sm" /> : <ThemeIcon variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "red"}>{x.type == "flight" ? <IconPlaneInflight color={ld_theme.theme == "dark" ? "black" : "black"}/>: <IconTrain color={ld_theme.theme == "dark" ? "black" : "black"}/>}</ThemeIcon>}
            </Grid.Col>
            <Grid.Col span={eligibleView() ? 2: 3}>
              <Center><Text size="xs">{x.from_time.replace(/\s/g,'')}</Text></Center>
              <Center><Text fz="xs" c="dimmed">{x.from}</Text></Center>
            </Grid.Col>
            <Grid.Col span={eligibleView() ? 3: 4}><Center>
              <Popover width={200} position="bottom" withArrow shadow="md">
                <Popover.Target><ActionIcon>
                {!x.direct ? <IconArrowRotaryStraight  style={{transform:'rotate(90deg)'}}/> : <IconArrowNarrowRight />}
                </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                <Text size="sm">{x.direct ? `This ${x.type} ${!datePast() ? 'is' : 'was'} direct to ${x.to} `: `${x.type == "flight"? 'Flight': 'Train'} ${!datePast() ? 'has' : 'had'} one or more connections to ${x.to}.`}</Text>
                </Popover.Dropdown>
              </Popover></Center></Grid.Col>
            <Grid.Col span={eligibleView() ? 2: 3}>
              <Center><Text size="xs">{x.to_time.replace(/\s/g,'')}</Text></Center>
              <Center><Text fz="xs" c="dimmed">{x.to}</Text></Center>
            </Grid.Col>
            { !eligibleView() || !x.total ? null : <Grid.Col span={3}><Group position="right"><Text fw={500} size="sm" c={ld_theme.theme == "dark" ? theme.colors.teal[5] : theme.colors.green[8]}>{`$${x.total.toLocaleString()}`}</Text></Group></Grid.Col> }
          </Grid> : null 
          }
          {x.type == "hotel" && !x.pending ?
          <Grid>            
            <Grid.Col span={2}>
              {x.image ? <Avatar src={x.image} radius="sm" size="sm" /> : <ThemeIcon variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "red"}><IconHotelService color={ld_theme.theme == "dark" ? "black" : "black"}/></ThemeIcon>}
            </Grid.Col>
            <Grid.Col span={(data.legs.length > 1 && eligibleView()) ? 5 : (data.legs.length > 1 && eligibleView()) ? 7 : 7}><Text truncate size="xs">{x.name}</Text><Rating size="xs" value={x.rating} fractions={3} readOnly></Rating></Grid.Col>
            {data.legs.length > 1 ? <Grid.Col span={eligibleView() ? 2: 3}><Center><Text size="xs">{x.nights}</Text></Center><Center><Text fz="xs" c="dimmed" >nights</Text></Center></Grid.Col> : null}
            { !eligibleView() ? null : <Grid.Col span={3}><Group position="right"><Text fw={500} size="sm" c={ld_theme.theme == "dark" ? theme.colors.teal[5] : theme.colors.green[8]}>{`$${x.total.toLocaleString()}`}</Text></Group></Grid.Col> }
          </Grid> : null 
          }
          {
            x.type == "event" && !x.pending ? 
            <Grid>
            <Grid.Col span={2}>
              {x.image ? <Avatar src={x.image} radius="sm" size="sm" /> : <ThemeIcon variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "red"}><IconTicket color={ld_theme.theme == "dark" ? "black" : "black"}/></ThemeIcon>}
            </Grid.Col>
            <Grid.Col span={eligibleView() ? 7 : 10}>
              <Text truncate size="xs">{x.act}</Text>
              <Text fz="xs" c="dimmed">
                {x.venue}
              </Text>
            </Grid.Col>
            { !eligibleView() ? null : <Grid.Col span={3}><Group position="right"><Text fw={500} size="sm" c={ld_theme.theme == "dark" ? theme.colors.teal[5] : theme.colors.green[8]}>{`$${x.total.toLocaleString()}`}</Text></Group></Grid.Col> }
          </Grid> : null
          }
          {
            x.type == "car" && !x.pending ? 
            <Grid>
            <Grid.Col span={2}>
              {x.image ? <Avatar src={x.image} radius="sm" size="sm" /> : <ThemeIcon variant="filled" color={ld_theme.theme == "dark" ? "yellow" : "red"}><IconCar color={ld_theme.theme == "dark" ? "black" : "black"}/></ThemeIcon>}
            </Grid.Col>
            <Grid.Col span={eligibleView() ? 7 : 10}>
              <Text truncate size="xs">{x.rental_company}</Text>
              <Text fz="xs" c="dimmed">
                car rental
              </Text>
            </Grid.Col>
            { !eligibleView() ? null : <Grid.Col span={3}><Group position="right"><Text fw={500} size="sm" c={ld_theme.theme == "dark" ? theme.colors.teal[5] : theme.colors.green[8]}>{`$${x.total.toLocaleString()}`}</Text></Group></Grid.Col> }
          </Grid> : null
          }
          {x.pending ? 
            <Grid>
            <Grid.Col span={2}>
            <Center><Text fz="xs" c="dimmed">{`${x.type} pending`}</Text></Center>
            </Grid.Col>
            <Grid.Col span={7}>

                <Skeleton animate={true} height={8} radius="xl" />
                <Skeleton animate={true} height={8} mt={6} width="70%" radius="xl" />
            </Grid.Col>
            <Grid.Col span={3}><Group position="right"><Skeleton  animate={true} height={8} radius="xl" width="40%"/></Group></Grid.Col>
          </Grid> : null
          }
        </Card.Section>
      ))}
{ !eligibleView() ? null : 
      <Card.Section className={classes.section}>
          <Group spacing={30} position='center'>
          <div>
              <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>{`$${i.events.map(item => item.total).reduce((prev, next) => prev + next).toLocaleString()}`}</Text>
          </div>
  
            {/* <Button radius="xl" style={{ flex: 1 }}>
              Buy now
            </Button> */}
          </Group>
        </Card.Section>}
        <Card.Section>
          <Group grow >
            <Badge radius="xs" variant="light" color="gray">
              {data.legs.length > 1 ? `leg ${index + 1} ${i.leg_description ? ` - ${i.leg_description}`:null}` : 'roundtrip' }
            </Badge>
          </Group>
        </Card.Section> 
      </Card>
      </Grid.Col>
      );
    }
    
    const saveMessage = (event) => {
      setMessageLoading(true);
      

      const requestConfig = {
          headers: {
              'x-api-key': process.env.REACT_APP_API_KEY
          }
      }
      const requestBody = {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          subject: `Trip Q - ${data.title}`,
          message: message
      }
      axios.post(process.env.REACT_APP_MESSAGE, requestBody, requestConfig).then((response) => {
        setMessageLoading(false)
        setMessageModal(false)
        setMessage()
        notifications.show({
          title: 'Message Success',
          message: 'Message Received! Thanks!',
          color: 'green'
        })
      }).catch((error) => {
          if (error.response.status == 400) {
              setMessageLoading(false)
              notifications.show({
                title: 'Error on Save',
                message: 'Message save didn\'t take.  Please try again.',
                color: 'red'
              })
          } else {
              setMessageLoading(false)
              notifications.show({
                title: 'Error on Save',
                message: 'Message server is down. Try again later.',
                color: 'red'
              })
          }
    })
    }

    const segments = function(data) { return data.totals.map((segment, index) => ({
      value: segment.part,
      color: ld_theme.theme == "dark" ? colorsDark[index]: colorsLight[index],
      label: segment.part > 10 ? `${segment.part}%` : undefined,
    }))};
    
    const descriptions = function (data) { return data.totals.map((stat, index) => (
      stat.total == 0 ? null : 
      <Box key={stat.type} sx={{ borderBottomColor: ld_theme.theme == "dark" ? colorsDark[index]: colorsLight[index] }} className={classes.stat}>
         <Group position="apart" align="flex-end" spacing={0}>
          <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
            {stat.type}
          </Text>
          <Popover width={200} position="bottom" withArrow shadow="md">
          <Popover.Target>
          <ActionIcon>
          {stat.type == "hotel" || stat.type == "car" ? <IconUsers size="1rem" className={classes.icon_aggregate} stroke={1.5}></IconUsers>:<IconUser size="1.2rem" className={classes.icon_aggregate} stroke={1.5}></IconUser>}
          </ActionIcon></Popover.Target>
          <Popover.Dropdown>
            <Text size="sm">{stat.type == "hotel"? "Costs can be per couple or group per hotel room" : stat.type == "car"? "Costs can be per couple or group per vehicle" : stat.type == "flight"? `Costs can be per person.  Flights assume origin departure from Charlotte.` : stat.type == "train"? 'Costs can be per person.  Trains assume costs per rail pass or  one-way destination.' : 'Costs are per person for an event'}</Text>
          </Popover.Dropdown>
          </Popover></Group>
        <Group position="apart" align="flex-end" spacing={0}>
          <Text fw={700}>{`$${stat.total.toLocaleString()}`}</Text>
          <Text c={ld_theme.theme == "dark" ? colorsDark[index]: colorsLight[index]} fw={700} size="sm" className={classes.statCount}>
            {stat.part}%
          </Text>
        </Group>
      </Box>
    ))};
  
    function rsvp_card () {
      let title;
      let description;

      const buttons = function () {
        if(!rsvp && data.invite == "private"){
          return (        
            <Group >
              <Button mt="md" variant="outline" color="gray" onClick={()=> submitHandler("house_pending", "status")}>I wanna go</Button>
            </Group>
          )
        }

        if(!rsvp && data.invite == "open"){
          return (        
            <Group >
              <Button mt="md" variant="outline" color="teal" onClick={()=> submitHandler("accepted", "status")}>I wanna go</Button>
            </Group>
          )
        }

        if (rsvp && rsvp[0].status == "pending") {
          return (        
            <Group >
              <Button mt="md" variant="outline" color="red" onClick={()=> submitHandler("rejected", "status")}>Not Going</Button>
              <Button mt="md" variant="outline" color="teal" onClick={()=> submitHandler("accepted", "status")}>Going</Button>
            </Group>
          )
        }
      }
      return (
        <Paper withBorder radius="md" className={rsvp ? classes[rsvp[0].status] : classes[data.invite]}>
        <LoadingOverlay visible={saving} overlayBlur={2} />
        <Group position="apart">
        <Text size="lg" weight={500} mt="md">
          {rsvp ? rsvp_verbiage[rsvp[0].status].title : rsvp_verbiage[data.invite].title}
        </Text>
        {rsvp && rsvp[0].status == "accepted" || rsvp && rsvp[0].status == "house_approved" ? <Anchor mt="md" size="xs" color="dimmed" component="button" type="button" onClick={()=> submitHandler("rejected", "status")}>decline event</Anchor>: null}
        {rsvp && rsvp[0].status == "rejected" ? <Anchor mt="md" size="xs" color="dimmed" component="button" type="button" onClick={()=> submitHandler("accepted", "status")}>accept again</Anchor>: null}
        </Group>
        <Text size="sm" mt="sm" color="dimmed">
        {rsvp ? rsvp_verbiage[rsvp[0].status].description : rsvp_verbiage[data.invite].description}
        </Text>
          {buttons()}
      </Paper>
      )
    }
    useEffect(() => {
        const authUser = async () => {  
            setLoading(true)      

            const response = await fetch(`${process.env.REACT_APP_EVENT}/${path}`, {
              method: 'GET',
              headers: {'x-api-key': process.env.REACT_APP_LOGIN_API_KEY, 'Content-Type': 'application/json'},
            });
            if (!user || user == 'undefine') {
                return navigate('/home/login/visitor')
              }
            if (!user.role == "admin") {
                return navigate('/home/dashboard')
            }
            
            if (response.status != 200) {
              notifications.show({
                title: 'System Error',
                message: 'Event does not exist or our backend system is down.  Please contact Stev0B',
                color: 'red'
              })
              return navigate('/home/dashboard')

            }
            const data = await response.json();
            
            setData(data.event)
            const dates = getDatesBetween(new Date(data.event.from_date), new Date(data.event.to_date))
            if (data.event.invites.some(e => e.email === user.email)) {
              setRsvp(data.event.invites.filter(e => e.email === user.email))
            } else {
              setRsvp(false)
            }
            if (data.event.pricing.some(e => e.email === user.email)) {
              setPricing(data.event.pricing.filter(e => e.email === user.email))
            } else {
              setPricing(false)
            }
            setSelected(dates)
            setLoading(false)      
            // const values = followers.map((x) => ({value: x.email, label: `${x.first_name} ${x.last_name}`}));
            // const values_sorted = values.sort((a, b) => a.label.localeCompare(b.label))
            // setFollowers(values_sorted)
        }
        authUser()    
      },[]);
        
    const submitHandler = (status, type) => {
        setSaving(true)

        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY 
            }
        }
        const requestBody = {
            user: user,
            requestor: user,
            token: token,
            event_id: data.id,
            status: status,
            signal: type
        } 
        axios.post(process.env.REACT_APP_INVITE, requestBody, requestConfig).then((response) => {
            //success
            //setRsvp([{status: status}])
            setData(response.data.event)
            if (response.data.event.invites.some(e => e.email === user.email)) {
              setRsvp(response.data.event.invites.filter(e => e.email === user.email))
            } else {
              setRsvp(false)
            }
            if (response.data.event.pricing.some(e => e.email === user.email)) {
              setPricing(response.data.event.pricing.filter(e => e.email === user.email))
            } else {
              setPricing(false)
            }
            setSaving(false)
            
        }).catch((error) => {
            notifications.show({
              title: error.response ? `Error ${error.response.status}` : 'Server Error',
              message: error.response ? error.response.data.message : "Server Error",
              color: 'red'
            })
            setSaving(false)
        })
    }

    const nights = Math.round((new Date(data.to_date) - new Date(data.from_date))/oneDay)
    return (
        <Container >
          {loading ? <LoadingOverlay visible={loading} overlayBlur={2} /> :
        <div>
        <Space h="md" />
            {user.role == "admin" || new Date(data.to_date) < new Date() ?  null: rsvp_card()}
            <Grid align="flex-start">
            <Grid.Col span={isMobile ? 12: 6} >
            <Card  id="123" mt="xl" shadow="sm" padding="lg" radius="md" withBorder component="a" onClick={()=> navigate(`/home/dashboard/event/${data.id}`)} target="_blank">
              <Card.Section>
                <Image
                  src={data.image_url}
                  height={160}
                  alt={data.title}
                  withPlaceholder
                />
              </Card.Section>

              <Group position="apart" mt="md" mb="xs">
                <Text weight={500}>{data.title}</Text>
                <Badge className={data.event_type == "local" ? classes.card_local : classes.card_trip} variant="light">
                  {data.event_type}
                </Badge>
              </Group>
              <Box h={65}>
              <Text size="sm" color="dimmed">
                {data.description}
              </Text></Box>
              <Group position="apart" mt="md">
                <Avatar.Group spacing="xs">
                  {data.steven_b ? <Avatar size="md" radius="lg" variant="filled">SB</Avatar> : null }
                  {data.amanda_a ?<Avatar size="md" radius="lg" variant="filled">AA</Avatar> : null }
                  {data.invites && data.invites.filter((x) => (x.status == "accepted" || x.status=="house_accepted")).length > 0  ? 
                    <HoverCard width={280} shadow="md">
                      {statusTripAllows.includes(rsvp && rsvp[0].status) || user.role == "admin" ?
                      <HoverCard.Dropdown>
                        <Text size="xs" c="dimmed" fw={700}>Accepted</Text>{data.invites.map((i, index) => i.status == "accepted" || i.status == "house_accepted" ? <Text key={i.email} size="sm">{`${i.first_name.charAt(0).toUpperCase()}${i.first_name.slice(1)} ${i.last_name.substr(0, 1).toUpperCase()}`}</Text>: null)}
                      </HoverCard.Dropdown>: null } 
                      <HoverCard.Target>
                        <Avatar size="sm" radius="lg" variant="outline">{`+${data.invites.filter((x) => (x.status == "accepted" || x.status == "house_accepted")).length}`}</Avatar>
                      </HoverCard.Target>
                    </HoverCard>: null}
                </Avatar.Group>
                <Text size="sm" fw={700} color="dimmed">
                  {new Date(data.from_date).toLocaleDateString()}
                </Text>
                {data.event_type == "trip" ? <Text size="sm" color="dimmed">
                  {nights > 1 ? `${nights} nights`: `${nights} night`}
                </Text> : null 
                }
                {data.event_type == "local" ? <Text size="sm" color="dimmed">
                  {new Date('1970-01-01T' + data.time + 'Z').toLocaleTimeString('en-US', {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'})}
                </Text> : null 
                }
              </Group> 
              <Card.Section>
              <Group grow mt="xs">
              { new Date(data.to_date) > new Date() ? 
                <Badge radius="xs" variant="light" color={data.invite == "open"? "green" : "gray"}>
                    {data.invite}
                </Badge>
              : 
              <Badge radius="xs" variant="light" color="grape">
                  past event
              </Badge>}
              </Group>
              </Card.Section>           
            </Card>
            </Grid.Col>
            <Grid.Col span={isMobile ? 12: 6} >
              <Stack align="center" >
                  <Calendar
                    mt="lg"
                    static
                    weekendDays={[]}
                    firstDayOfWeek={0}
                    defaultDate={new Date(data.from_date)}
                    type="range"
                    renderDay={(date) => {
                      const days = selected.some((s) => dayjs(date).isSame(s, 'date'));
                      const day = date.getDate();
                      return (
                          <div className={days && data.event_type == "trip" ? classes.cal_trip : days && data.event_type == "local" ? classes.cal_local : null }>{day}</div>
                      );
                    }} 
                  />
              </Stack>
              { user.role == "admin" ? 
              <Group grow mt="lg">
                <Paper withBorder p="md" radius="md" className={classes.navButton}
                component="a" onClick={()=> navigate('/home/dashboard/edit_event/', { state: data })}
                >
                <Group position="apart">
                  <IconPencil className={classes.navButton}/>
                  <Text size="lg" className={classes.navButton}>
                    Edit
                  </Text>
              </Group>
              <Group position="right" spacing="xs" shadow="xs">
                <Text fz="sm" color="dimmed">Calendar - @home/dashboard/edit_event</Text>
              </Group>
            </Paper>
            </Group>: null}
            </Grid.Col>
            <Grid.Col span={12}>
          </Grid.Col>
            {viewTrip() ? <Grid.Col span={12} ><Divider my="xs" label="Trip" labelPosition="center" /></Grid.Col> : null }
            {viewTrip() && data.legs.length > 0 ? data.legs.map((i, index) => ( LegCard(i, index))) : null }
            {viewTrip () && eligibleView() ?
            <Grid.Col span={isMobile ? 12 : 6}>
              <Paper withBorder p="md" radius="md" size={700}>
              <Group position="apart">
                <Group align="flex-end" spacing="xs">
                  <Text fz="xl" fw={700}>
                    Total Rundown
                  </Text>
                </Group>
                <IconDeviceAnalytics size="1.4rem" className={classes.icon_aggregate} stroke={1.5} />
              </Group>
              {user.role == "admin" || new Date(data.to_date) < new Date() || (rsvp && rsvp[0].status == "accepted") ?  null: <Text fz="xs" c="dimmed" className={classes.link} component="a" onClick={()=> setMessageModal(true)}>Wanna go but have a concern on pricing?</Text>}
              <Progress
                sections={segments(data)}
                size={34}
                classNames={{ label: classes.progressLabel }}
                mt={40}
              />
              <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt="xl">
                {descriptions(data)}
              </SimpleGrid>
            </Paper>
            </Grid.Col> : viewTrip() ? 
             <Grid.Col span={12}>
            <Paper mt="md" withBorder radius="md" className={pricing ? classes[pricing[0].status] : classes.pending}>
              <LoadingOverlay visible={saving} overlayBlur={2} />
              <Group position="apart">
              <Text size="lg" weight={500} mt="md">
                {pricing ? pricing_verbiage[pricing[0].status].title : pricing_verbiage.ineligible.title}
              </Text>
              </Group>
              <Text size="sm" mt="sm" color="dimmed">
              {pricing ? pricing_verbiage[pricing[0].status].description : pricing_verbiage.ineligible.description}
              </Text>
              {!pricing ? <Button mt="md" variant="outline" color="gray" onClick={()=> submitHandler("house_pending", "pricing")}>Submit request</Button>:null}
            </Paper></Grid.Col> : null}

            </Grid>
            {isMobile ?
          <div> 
          <Paper mt="md" withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/home/dashboard')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">Calendar - @home/dashboard</Text>
            </Group>
          </Paper>
          </div>:
         <Group grow mt="lg">
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/home/dashboard')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">Calendar - @home/dashboard</Text>
           </Group>
         </Paper>
         </Group>
          }
        </div>}
        <Modal opened={messageModal} onClose={()=> setMessageModal(false)} title="Helping you plan" >
          <Text fz="sm">Have a concerns on booking a flight? Price of the hotel? Or just questions in general? We're always here to help.  Just send us a quick message and we can help you with any details or concerns.</Text>
          <Textarea mt="md" label="Message" withAsterisk value={message} size="md" onChange={(e) => setMessage(e.currentTarget.value)} />
          <Group position="right" mt="md" ><Button loading={messageLoading} onClick={saveMessage}>Send Message</Button></Group>
        </Modal>
        </Container>
        
    )
}

const mapStateToProps = state => {
  return { ld_theme: state.ld_theme}
}
export default connect(mapStateToProps)(ViewEvent);