import * as yup from 'yup';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate, useLocation} from "react-router-dom";
import { notifications } from '@mantine/notifications';
import { setUserSession } from '../../service/authService';
import { TextInput, Space, Anchor, Group, LoadingOverlay, Button, rem, Box, Container , Text, PasswordInput, PinInput, Center, Flex} from '@mantine/core';
import { useForm, yupResolver  } from '@mantine/form';


function formatPhoneNumber(input) {
    //  if the input is null, return a null value
    if (!input) return input;
    // remove all characters from the input except number input.
    const numberInput = input.replace(/[^\d]/g, "");
    //  take the length of the value of the input
    const numberInputLength = numberInput.length;
    // if the number length is 1, 2, or 3, then return it as it is.
    if (numberInputLength < 4) {
      return numberInput;
    } else if (numberInputLength < 7) {
      // if the number input length is 4, 5, or 6, format it accordingly.
      return `(${numberInput.slice(0, 3)}) ${numberInput.slice(3)}`;
    } else {
      //  if the number input length is 7, 8, 9, 10, or more, format it like the below.
      return `(${numberInput.slice(0, 3)}) ${numberInput.slice(
        3,
        6
      )}-${numberInput.slice(6, 10)}`;
    }
    // return empty string in case any condition doesn't satisfy.
    return "";
}

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const registerSchema = yup.object({
    email: yup
    .string('String Values only')
    .email('Not a valid email address')
    .required('Email Address is Required')
    .max(254,'Emails cannot be more than 254 characters'),

    first_name: yup
    .string('String values only')
    .required('First name is required')
    .max(50,'Names cannot exceed 50 characters'),

    last_name: yup
    .string('String values only')
    .required('Last name is required')
    .max(50,'Names cannot exceed 50 characters'),
});

const Register = () => {
    const [siteLoading, setSiteLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();
    const [code, setCode] = useState();
    const [errorMessage, setErrorMessage] = useState()
    const [error, setError] = useState(false);
    const [phoneError, setPhoneError] = useState(false)
    const [phoneNumber, setphoneNumber] = useState("");

    let navigate = useNavigate();
    let location = useLocation();
    const currentPath=location.pathname

    useEffect(() => {
        const email = currentPath.substring(currentPath.lastIndexOf("/") + 1, currentPath.length);
        
        const newFollower = (email) => {
          setSiteLoading(true);
          setErrorMessage(null);
          const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
            }
        }
        const requestBody = {
            email: email
        }
        axios.post(`${process.env.REACT_APP_FOLLOWER_DATA}/newfollower`, requestBody, requestConfig).then((response) => {
            setSiteLoading(false)
          }).catch((error) => {
            navigate(`/home/login/visitor`);
            notifications.show({
                title: 'Registration Error',
                message: 'Registration for this user is complete or the service encountered an error. Please contact Stev0B for support.',
                color: 'red'
            })
            setSiteLoading(false);
          })
      }
    
      newFollower(email);
        
      },[]);

    
    const form = useForm({
        initialValues: {
          email: currentPath.substring(currentPath.lastIndexOf("/") + 1, currentPath.length),
          first_name: '',
          last_name: ''
        },
        validate: yupResolver(registerSchema),
      });
    
      const handlephoneNumber = (e) => {
        // format phone number
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        //  set the formatted phone number to the input value
        setphoneNumber(formattedPhoneNumber)
    };

    const submitHandler = (event) => {
        setLoading(true)
        setCode('')
        setError()
        setPhoneError()
        form.clearErrors();
        const normPhone = phoneNumber.replace(/[- )(]/g,'');
        if (normPhone.length < 10 || normPhone.length > 10 ) {
            setPhoneError("Invalid phone number")
            setLoading(false)
            return
        }
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
            }
        }
        const requestBody = {
            email: form.values.email,
            phone_number: `+1${normPhone}`,
            first_name: form.values.first_name,
            last_name: form.values.last_name
        }
        axios.put(process.env.REACT_APP_FOLLOWER_DATA, requestBody, requestConfig).then((response) => {
            setUser({user: response.data.user, token: response.data.token});
            setLoading(false)
            
        }).catch((error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                form.setErrors({email: error.response.data.message})
                setLoading(false)
            } else {
                form.setErrors({email: 'Backend server down. Try again later.', password: 'Backend server down. Try again later.'})
                setLoading(false)
            }
        })
    }


    const twilioHandler = (event) => {
        setError(false)
        setLoading(true)
        const requestConfig = {
            headers: {
                'x-api-key': process.env.REACT_APP_LOGIN_API_KEY
            }
        }
        const requestBody = {
            phone_number: user.user.phone_number,
            code: code
        }
        axios.post(process.env.REACT_APP_SMSVERIFY_DATA, requestBody, requestConfig).then((response) => {
            setUserSession(user.user, user.token);
            navigate(`/home/dashboard`);
        }).catch((error) => {
            setError(true)
            setLoading(false)
        })
    }

    return (
        <Container >
        <div>
        <Space h="md" />
        {siteLoading ? <LoadingOverlay visible={siteLoading} overlayBlur={2} /> :
            <form onSubmit={form.onSubmit((e) => submitHandler(e))}>
            {user ? 
            <Box maw={400} h={300} mx="auto">
                <Text>Verification Code</Text>
                <Space h="sm" />
                <PinInput oneTimeCode length={6} type="number" value={code} onChange={setCode} onComplete={twilioHandler} error={error} size="md"/>
                {error ? <Text fz="sm" c="red"><Space h="sm" />*Error on code validation</Text> : null}
                <Space h="sm" />
                <Group>
                <Flex align="flex-end" gap="md">
                <Button onClick={twilioHandler} loading={loading}>Verify</Button>
                <Anchor td="underline" c="dimmed" fz="sm" component="button" type="button" onClick={submitHandler}>resend code</Anchor>
                </Flex>
                </Group>
            </Box>
            :
            <Box maw={400} mx="auto">
            <TextInput
                label="Email"
                type = "text"
                size="md"
                mt="md"
                c="blue"
                disabled
                placeholder="email"
                name="email"
                {...form.getInputProps('email')}
            />
            <Space h="sm" />
            <TextInput
                label="Phone number"
                type="tel"
                size="md"
                mt="md"
                c="blue"
                error={phoneError}
                withAsterisk
                onChange={(e) => handlephoneNumber(e)} 
                value={phoneNumber}
            />
            <TextInput
                label="First name"
                type="text"
                size="md"
                mt="md"
                withAsterisk
                {...form.getInputProps('first_name')}
            />
            <TextInput
                label="Last name"
                type="text"
                size="md"
                mt="md"
                withAsterisk
                {...form.getInputProps('last_name')}
            />
            <Group position="left" mt="xl">
                <Button loading={loading} type="submit" size="md">
                Register
                </Button>
            </Group>
            </Box>}
            </form> 
        }
        </div>
        </Container>
    )
}

export default Register;