import { connect } from 'react-redux'
import React from 'react';
import { useNavigate} from "react-router-dom";
import { createStyles, Image, Paper, Container, Title, Group, Text, List, ThemeIcon, rem, Space, Timeline, Table, Alert, Tabs, Badge} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery} from '@mantine/hooks';
import { Prism } from '@mantine/prism';

import '../index.css';
import { IconCheck, IconAlertCircle, IconInfoCircle, IconSend, IconPackage, IconArrowNarrowLeft, IconArrowNarrowRight} from '@tabler/icons-react';

import {ReactComponent as Eatsvg} from './svgs/eat.svg';
import ArchEats from '../components/architecture/archEats';

import nav1 from './images/eat-nav-1.png';
import nav2 from './images/listen-nav-2.png';
import location1 from './images/eat-location-1.png';
import location2 from './images/eat-location-2.png';
import location3 from './images/eat-location-3.png';
import details1 from './images/eat-details-1.png';
import details2 from './images/eat-details-2.png';
import search1 from './images/eat-search-1.png';
import search2 from './images/eat-search-2.png';
import search3 from './images/eat-search-3.png';
import search4 from './images/eat-search-4.png';
import search5 from './images/eat-search-5.png';
import search6 from './images/eat-search-6.png';
import type1 from './images/eat-type-1.png';
import type2 from './images/eat-type-2.png';
import error from './images/eat-messages-1.png';
import success from './images/eat-messages-2.png';
import notification from './images/eat-messages-3.png';

const apiCall = `
if (selectTab == 1 ) { //My Location selected
  search = {
    name: searchInput.name,
    type: searchType,
    lat: searchInput.location.latitude,
    long: searchInput.location.longitude,
    radius: 3000
  }
} else { //Search selected
  search = {
    name: searchInput.name,
    type: searchType,
    other: searchInput.other
    }
}

let search_url;
if (page_token) {
  search_url = https://maps.googleapis.com/maps/api/place/textsearch/json?pagetoken=\${page_token}&key=\${GOOGLE_API_KEY}
} 

if (selectTab == 1) {
  search_url = https://maps.googleapis.com/maps/api/place/textsearch/json?query=\${search.name}&location=\${search.lat}%2C\${search.long}&radius=\${search.radius}&type=\${search.type}&key=\${GOOGLE_API_KEY}
}

if (selectTab == 2) {
  search_url = https://maps.googleapis.com/maps/api/place/textsearch/json?query=\${search.name}+\${search.other}&type=\${search.type}&key=\${GOOGLE_API_KEY}
}`;

const apiResponse = `
{
  results: [
    ...
    {
      business_status: "OPERATIONAL", 
      formatted_address: "2000 South Blvd, Charlotte, NC 28203", 
      geometry: {location: {lat: 35.20955, lng: -80.86048129999999}, viewport: {northeast: [Object], southwest: [Object]}}, 
      icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png", 
      icon_background_color: "#FF9E67", 
      icon_mask_base_uri: "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet", 
      name: "O-Ku", 
      opening_hours: {open_now: false}, 
      photos: [{height: 3024, photo_reference: "AZose0nYAXQBi3fVQOZePc-7BVwm_sDRLRi3hwf4KZX17TswyJrNh6esm6mv5LQ", width: 4032}], 
      place_id: "ChIJaaSMp3CfVogRpYMMsxTAWhQ", 
      plus_code: {"compound_code": "645Q+RR Charlotte, North Carolina", "global_code": "867X645Q+RR"}, 
      price_level: 3, 
      rating: 4.6, 
      reference: "ChIJaaSMp3CfVogRpYMMsxTAWhQ", 
      types: ["night_club", "bar", "restaurant", "food", "point_of_interest", "establishment"], 
      user_ratings_total: 789
    }
  ]
}`;

const eatItem = `
{
  pk: "USER#stev0b@stev0b.com",
  sk: "EAT#2RZnRnFlHouNZ4UpNAAukJhdeQh",
  createdAt: "2023-06-22T20:41:04.824Z",
  expiresAt: "2023-07-06T20:36:45.974Z",
  gsi1pk: "FEED",
  gsi1sk: "2023-06-22T20:41:04.824Z",
  house_id: "b37692e6-288f-4bb6-be0b-3d48439be132",
  id: "2RZnRnFlHouNZ4UpNAAukJhdeQh",
  restaurant_id: "ChIJaaSMp3CfVogRpYMMsxTAWhQ",
  restaurant_name: "O-Ku",
  TTL: 1688675805.974,
  type: "EAT",
  user: "stev0b@stev0b.com"
}`;

const restItem = `
{
  pk: "REST#ChIJaaSMp3CfVogRpYMMsxTAWhQ",
  sk: "REST#ChIJaaSMp3CfVogRpYMMsxTAWhQ",
  confirmed: true,
  createdAt: "2023-05-09T19:16:51.083Z",
  editorial_summary: "A chic option for sushi & unique Japanese dishes, plus sake, beer & cocktails.",
  formatted_address: "2000 South Blvd, Charlotte, NC 28203, USA",
  formatted_phone_number: "(704) 594-1922",
  gsi1pk: "FEED",
  gsi1sk: "2023-05-09T19:16:51.083Z",
  image_path: "ChIJaaSMp3CfVogRpYMMsxTAWhQ",
  lat: 35.20955,
  lng: -80.86048129999999,
  name: "O-Ku",
  price_level: 3,
  rating: 4.6,
  restaurant_id: "ChIJaaSMp3CfVogRpYMMsxTAWhQ",
  type: "RESTAURANT",
  user_ratings_total: 778,
  website: "https://www.o-kusushi.com/location/o-ku-charlotte/?y_source=1_ODE4NDQwNjAtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D"
}`;
const useStyles = createStyles((theme) => ({
    eat_light: {
        '#pepp-1':{fill: theme.colors.red[6]},
        '#pepp-1-shadow':{fill: theme.colors.red[9]},
        '#pepp-2':{fill: theme.colors.red[6]},
        '#pepp-2-shadow':{fill: theme.colors.red[9]},
        '#pepp-3':{fill: theme.colors.red[6]},
        '#pepp-3-shadow':{fill: theme.colors.red[9]},
        '#cheese':{fill: theme.colors.yellow[4]},
        '#drip-1':{fill: theme.colors.yellow[4]},
        '#drip-2':{fill: theme.colors.yellow[4]},
        '#sauce':{fill: theme.colors.yellow[6]},
        '#crust':{fill: theme.colors.orange[7]},
        '#hot-steam-1': {stroke: theme.colors.gray[6]},
        '#hot-steam-2': {stroke: theme.colors.gray[6]},
        '#glass-opening': {stroke: theme.colors.gray[6]},
        '#straw-outline': {stroke:theme.colors.lime[6]},
        '#right-ankle': {stroke: theme.colors.gray[6]},
        '#left-ankle': {stroke: theme.colors.gray[6]},
        '#ball': {stroke: theme.colors.gray[6]},
        '#juice-top': {fill:theme.colors.blue[8]},
        '#glass-body': {stroke: theme.colors.gray[6]},
        '#drink-body': {fill:theme.colors.blue[5]},
        '#glass-base': {stroke: theme.colors.gray[6]},
        '#straw-band-1': {stroke:theme.colors.lime[6]},
        '#straw-band-2': {stroke:theme.colors.lime[6]},
        '#straw-band-3': {stroke:theme.colors.lime[6]},
        '#straw-band-4': {stroke:theme.colors.lime[6]},
        '#straw-band-5': {stroke:theme.colors.lime[6]},
        '#straw-band-6': {stroke:theme.colors.lime[6]},
        '#straw-band-7': {stroke:theme.colors.lime[6]},
        '#straw-band-8': {stroke:theme.colors.lime[6]},
        '#straw-band-9': {stroke:theme.colors.lime[6]},
        '#straw-band-10': {stroke:theme.colors.lime[6]},
        '#straw-band-11': {stroke:theme.colors.lime[6]},
        '#straw-band-12': {stroke:theme.colors.lime[6]},
        '#straw-band-13': {stroke:theme.colors.lime[6]},
        '#straw-band-14': {stroke:theme.colors.lime[6]},
        '#umbrella-stick': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-1': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-2': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-3': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-4': {stroke: theme.colors.gray[7]},
        '#umbrella-fan-5': {stroke: theme.colors.gray[7]},
        '#bubble-1': {stroke: theme.colors.gray[3]},
        '#bub-2': {stroke: theme.colors.gray[3]},
        '#bub-3': {stroke: theme.colors.gray[3]},
        '#bub-4': {stroke: theme.colors.gray[3]},
        '#bub-5': {stroke: theme.colors.gray[3]},
        '#bub-6': {stroke: theme.colors.gray[3]},
    },
    eat_dark: {
        //outline: '3px solid green',
        '#pepp-1':{fill: theme.colors.red[5]},
        '#pepp-1-shadow':{fill: theme.colors.red[9]},
        '#pepp-2':{fill: theme.colors.red[5]},
        '#pepp-2-shadow':{fill: theme.colors.red[9]},
        '#pepp-3':{fill: theme.colors.red[5]},
        '#pepp-3-shadow':{fill: theme.colors.red[9]},
        '#cheese':{fill: theme.colors.yellow[3]},
        '#drip-1':{fill: theme.colors.yellow[3]},
        '#drip-2':{fill: theme.colors.yellow[3]},
        '#sauce':{fill: theme.colors.yellow[5]},
        '#crust':{fill: theme.colors.orange[6]},
        '#hot-steam-1': {stroke: theme.colors.gray[5]},
        '#hot-steam-2': {stroke: theme.colors.gray[5]},
        '#glass-opening': {stroke: theme.colors.gray[4]},
        '#straw-outline': {stroke:theme.colors.yellow[5]},
        '#right-ankle': {stroke: theme.colors.gray[4]},
        '#left-ankle': {stroke: theme.colors.gray[4]},
        '#ball': {stroke: theme.colors.gray[4]},
        '#juice-top': {fill:theme.colors.grape[8]},
        '#glass-body': {stroke: theme.colors.gray[4]},
        '#drink-body': {fill:theme.colors.grape[5]},
        '#glass-base': {stroke: theme.colors.gray[4]},
        '#straw-band-1': {stroke:theme.colors.yellow[5]},
        '#straw-band-2': {stroke:theme.colors.yellow[5]},
        '#straw-band-3': {stroke:theme.colors.yellow[5]},
        '#straw-band-4': {stroke:theme.colors.yellow[5]},
        '#straw-band-5': {stroke:theme.colors.yellow[5]},
        '#straw-band-6': {stroke:theme.colors.yellow[5]},
        '#straw-band-7': {stroke:theme.colors.yellow[5]},
        '#straw-band-8': {stroke:theme.colors.yellow[5]},
        '#straw-band-9': {stroke:theme.colors.yellow[5]},
        '#straw-band-10': {stroke:theme.colors.yellow[5]},
        '#straw-band-11': {stroke:theme.colors.yellow[5]},
        '#straw-band-12': {stroke:theme.colors.yellow[5]},
        '#straw-band-13': {stroke:theme.colors.yellow[5]},
        '#straw-band-14': {stroke:theme.colors.yellow[5]},
        '#umbrella-stick': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-1': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-2': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-3': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-4': {stroke: theme.colors.gray[4]},
        '#umbrella-fan-5': {stroke: theme.colors.gray[4]},
        '#bubble-1': {stroke: theme.colors.gray[2]},
        '#bub-2': {stroke: theme.colors.gray[2]},
        '#bub-3': {stroke: theme.colors.gray[2]},
        '#bub-4': {stroke: theme.colors.gray[2]},
        '#bub-5': {stroke: theme.colors.gray[2]},
        '#bub-6': {stroke: theme.colors.gray[2]},
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    
      content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,
        [theme.fn.smallerThan('55em')]: {
            maxWidth: '100%',
            marginRight: 0,
          },
      },
    
      title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(28),
        },
      },

      subHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(24),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(23),
        },
      },

      thirdHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(20),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(20),
        },
      },

      fourthHeading: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(16),
        lineHeight: 1.2,
        fontWeight: 900,
    
        [theme.fn.smallerThan('55em')]: {
          fontSize: rem(16),
        },
      },

      textHighlight:{
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background ,
        borderRadius: theme.radius.sm,
        padding: `${rem(2)} ${rem(6)}`,
        cursor: 'pointer',
        a: {textDecoration: 'none', color:theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color, }
      },

      image: {
        flex: 1,
      },
    
      highlight: {
        position: 'relative',
        color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
      },
      indicator: {
        background: 'red'
      },
      navButton: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        size:"lg",
        cursor: 'pointer'
      },
      feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
      },
    
      overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.colorScheme === 'dark' ? "yellow": "blue" }).background,
        zIndex: 1,
      },
      featureContent: {
        position: 'relative',
        zIndex: 2,
      },
      featureIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.primaryColor
      },
    
      featureTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
}))

function Eats({ld_theme}) {
    const { classes, cx, theme } = useStyles();
    const isMobile = useMediaQuery("(max-width: 55em)");
    let navigate = useNavigate();
    
    function addCheck() {
        return(
            <ThemeIcon size={20} variant="filled" color={ld_theme.theme == "dark" ? "#BE4BDB" : "#FA5252"}>
                <IconCheck size={rem(12)} stroke={3} />
            </ThemeIcon>
        )
    }
   return (
    <div>
        <Container>
            <div className={classes.inner}>
            <div className={classes.content}>
                <Title className={classes.title}>
                It's a date!  <br /> time to <span className={classes.highlight}>eat</span>
                </Title>
                <Text color="dimmed" mt="md">
                Eat Items are where the household has gone out to restaurants throughout the week. If this is the household's first time eating at the restaurant,
                a reference item for the Restaurant will be generated. As Eat items are created, users are given a chance to rate their experience. 
                </Text>

                <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                    <ThemeIcon size={20} radius="xl" variant="light" color={ld_theme.theme == "dark" ? "yellow" : "blue"}>
                    <IconCheck size={rem(12)} stroke={2} />
                    </ThemeIcon>
                }
                >
                <List.Item>
                    <b>Google Integration</b> - The iOS Application integrates directly with the Google Places API for real-time Reviews and Ratings for all Restaurants
                </List.Item>
                <List.Item>
                    <b>Location, Location, Location</b> – For a better user experience, the Application takes advantage of using Location Services to search nearby as an option
                </List.Item>
                <List.Item>
                    <b>Weekly Eats</b> – after each Eat experience is reviewed, it is picked up by the Data Feed to be viewable in the Stev0B frontend for the week. 
                </List.Item>
                </List>
                </div>
                {isMobile ? null : <Eatsvg width='35%' viewBox="0 0 200 300" className={ld_theme.theme == 'dark' ? classes.eat_dark : classes.eat_light}/> }
            </div>
            {isMobile ? <Group position="center"><Eatsvg height={rem(200)} width={rem(300)} viewBox="70 90 100 150" className={ld_theme.theme == 'dark' ? classes.eat_dark : classes.eat_light}/></Group>: null}
            <Title className={classes.subHeading}>
                What are Eat Items?
            </Title>
            <Text mt="md" fz="md">
                Eat Items are, in other words, every date our household experiences.  Every Restaurant visited throughout the week will 
                get 'punched' into the App as an item to be <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>Reviewed</a></span>.  
                As each Restaurant experience gets reviewed, the Data Feed will pick it up and present all the weekly 'Eats' for the household, and the user ratings for each
                experience.
            </Text> 
            <Space h="md" />
            <Title className={classes.subHeading}>
                How are they created?
            </Title>
            <Text mt="md" fz="md">
                Eat Items, just like <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/watchitems')}>Watch Items</a></span>, are generated directly by the User.  It's up to each household to track (and remember) to enter the Restaurant experience on each Date Night. From 
                there, the system takes care of the rest.  Although the entries are tracked for each Eat Item, the system will also step through and create a Reference Data item for each Restaurant that is new to
                the household (more details on that below).    
            </Text>
            <Space h="md" />
            <Title className={classes.subHeading}>
                User Flow
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="Navigate">
                    <Text color="dimmed" size="sm">Navigate to Eat area of application</Text>
                </Timeline.Item>
                <Timeline.Item title="Search Restaurants">
                    <Text color="dimmed" size="sm">Search for Restaurants by Location or by Text Search</Text>
                </Timeline.Item>
                <Timeline.Item title="Get Details">
                    <Text color="dimmed" size="sm">Get ratings, reviews and more details for a selected Restaurant</Text>
                </Timeline.Item>
                <Timeline.Item title="Save Experience">
                    <Text color="dimmed" size="sm">User sets all household members for Reviewing the Experience and Saves </Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Navigate
            </Title>
            <Text mt="md" fz="md">
                The iOS App features a dedicated section for entering Eat Items.  By default, the app initializes and lands on the Eat Item entry screen. To reach the screen, the user can navigate to the dedicated
                Eat section of the iOS app by a horizontal touch gesture from Left-to-Right to reveal the menu and selecting Eat. 
                Also, the menu can be revealed by tapping the Avatar icon in the upper-right area of the screen 
            </Text>
            <Space h="sm" />
            <Alert icon={<IconAlertCircle size="1rem" />} title="Note" color="red">
                Only House Admins can create Eat Items.  If the user is not a House Admin, they will not have access to this section of the application
            </Alert>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={nav1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={nav2} maw={240} mx="auto" radius="md" withPlaceholder /> 
            </Group>
            }
           <Space h="md" />
            <Title className={classes.thirdHeading}>
                Search Restaurants
            </Title>
            <Text mt="md" fz="md">
                When accessing the Eat section, the App will prompt for use of Location services.  With Location services enabled, the App will make very targeted
                searches and make for a better user experience overall since the <span className={classes.textHighlight}><a href='https://developers.google.com/maps/documentation/places/web-service/overview'>Google Places API</a></span> will tailor its search based on the location of the iOS device.  For example, with
                location services enabled, the user has the opportunity to enter in the name of the restaurant to search -- or just do an open search for all restaurants nearest to that individual's location. 
                If the user does not allow Location services, then the App will display an informational message to direct the User to enable the service.  Clicking the hyperlink will open the Stev0b settings, allowing
                the user to manually adjust the Location preference.  If the update is applied, the user can prompt the App to check for the enabled Service to enable Searches by Location. 
            </Text>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={location1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={location2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={location3} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={location1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={location2} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={location3} maw={240} mx="auto" radius="md" withPlaceholder /> 
            </Group>
            }
            <Space h="sm" />
            <Text mt="md" fz="md">
                With Location Services Enabled, the user has two (2) methods of searching:
            </Text>
            <Space h="sm" />
            <List>
                <List.Item><b>My Location</b> - useful for in-the-moment experience additions while the iOS device is located near the Restaurant</List.Item>
                <List.Item><b>Search</b> - useful for a manual search, based on key words, addresses, or phrases to find the Restaurant for the experience.  This is helpful when the experience has already occurred, and the Restaurant is not nearby (e.g. Past Experiences or International Locations)</List.Item>
            </List>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={search1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={search2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={search1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={search2} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="sm" />
            <Text mt="md" fz="md">
                Since the <span className={classes.textHighlight}><a href='https://developers.google.com/maps/documentation/places/web-service/overview'>Google Places API</a></span> narrows its searches by types of locations, the iOS App will mimic the same.  This will help narrow restaurants into different types a user can (single) select:
            </Text>
            <Space h="sm" />
            <List>
                <List.Item>Bakery</List.Item>
                <List.Item>Bar</List.Item>
                <List.Item>Cafe</List.Item>
                <List.Item>Restaurant (Default)</List.Item>
            </List>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={type1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={type2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={type1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={type2} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="sm" />
            <Text>Tapping the Search bar will render the iOS Keyboard.  After the user has input the name of the restaurant, bakery, bar, or cafe, the application will call the <span className={classes.textHighlight}><a href='https://developers.google.com/maps/documentation/places/web-service/overview'>Google Places API</a></span> to fetch the results</Text>
            <Space h="sm" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                With <b>My Location</b> enabled, leaving the Search bar blank/empty will fetch all results based on the device's location
            </Alert>
            <Space h="sm" />
            <Text>When the API returns a successful response, the application will dismiss the iOS keyboard and render the results in a Vertical, Scrollable List for the User to view a few key pieces of information from the object array returned from the API.
                Remember, the goal is to ensure the user identifies the correct location to log the datenight, so the presented information needs to be helpful.  Each List Item contains the following datapoints from the <span className={classes.textHighlight}><a href='https://developers.google.com/maps/documentation/places/web-service/overview'>Google Place API</a></span>
            </Text>
            <Space h="sm" />
                <List>
                    <List.Item>Name</List.Item>
                    <List.Item>Street Address</List.Item>
                    <List.Item>Image</List.Item>
                </List>
            <Space h="sm" />
            <Text>The user has additional controls to view the details of an item, fetch more eateries, or clear the results and Search again:</Text>
            <Space h="sm" />
            <List>
                <List.Item>Details - View the eatery</List.Item>
                <List.Item>Load More - Request an additional +20 Results from the API (control appended to last list item)</List.Item>
                <List.Item>Clear Results - Empties the result set</List.Item>
                <List.Item>Reset Search - Clear the Search entry</List.Item>
            </List>
            <Space h="sm" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                The <b>Reset Search</b> option is only available after <b>Clear Results</b> has been pressed.  Resetting a search will clear the search bar of text, focus the cursor on the Input, and render the iOS Keyboard. 
            </Alert>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={search3} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={search5} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={search6} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={search3} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={search5} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={search6} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="md"/>
            <Title className={classes.thirdHeading}>
                Get Details
            </Title>
            <Text mt="md" fz="md">
                If the user clicks <b>Details</b> from the Search Results the application will shift over to render a new Screen with a few more datapoints from the  <span className={classes.textHighlight}><a href='https://developers.google.com/maps/documentation/places/web-service/overview'>Google Places API</a></span>
            </Text>
            <Space h="sm" />
            <List>
                <List.Item>Rating - Current Rating from Google</List.Item>
                <List.Item>Reviews - Current Review Count from Google</List.Item>
                <List.Item>Map - Zoomable iOS Apple Map with surrounding landmarks</List.Item>
            </List>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={details1} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={details2} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={details1} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={details2} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="md"/>
            <Title className={classes.thirdHeading}>
                Save Experience
            </Title>
            <Text mt="md" fz="md">
                If the eatery is correct for saving the experience, the user simply just needs to make the selection of all household members attending the same experience and click <b>Save</b> 
            </Text>
            <Space h="sm" />
            <Alert icon={<IconAlertCircle size="1rem" />} title="Note" color="red">
                The currently signed-in User in the application cannot de-select themselves from the list of household members. For example, If <b>Steven B</b> is signed into the Application, he cannot
                remove or de-select <b>Steven B</b> from the experience
            </Alert>
            <Space h="sm" />
            <Text mt="md" fz="md">
                There are two (2) types of messages a user can be presented when saving an Experience:
                <Space h="sm" />
                <List>
                    <List.Item>Error - System fails to save the experience due to one of the following:
                        <List withPadding listStyleType="circle">
                            <List.Item>Invalid session</List.Item>
                            <List.Item>Invalid Restaurant ID</List.Item> 
                            <List.Item>Blank or Empty Restaurant Name</List.Item>
                            <List.Item>No Selected Household Users</List.Item> 
                            <List.Item>Internal Server Error</List.Item>
                        </List>
                    </List.Item>
                    <List.Item>Success - System saves the Experience with no returned errors.  
                        Users tagged to the Experience will receive a notification to leave a <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/reviews')}>Review</a></span>
                    </List.Item>
                </List>
            </Text>
            <Space h="sm" />
            <Alert icon={<IconInfoCircle size="1rem" />} title="Tip" color="blue">
                On <b>Success</b> the application will programmatically navigate back to the search results screen.  
            </Alert>
            <Space h="sm" />
            {isMobile ?             
            <Carousel slideSize="70%" slideGap="lg" withIndicators styles={{indicator: {width: rem(12),height: rem(4),background: ld_theme.theme == "light" ? '#339AF0' : '#BE4BDB', transition: 'width 250ms ease','&[data-active]': { width: rem(40),}}}}>
                <Carousel.Slide><Image src={error} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={success} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
                <Carousel.Slide><Image src={notification} maw={240} mx="auto" radius="md" withPlaceholder /> </Carousel.Slide>
            </Carousel> :
            <Group>
                <Image src={error} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={success} maw={240} mx="auto" radius="md" withPlaceholder />
                <Image src={notification} maw={240} mx="auto" radius="md" withPlaceholder />
            </Group>
            }
            <Space h="md" />
            <Title className={classes.subHeading}>
                Tech Overview
            </Title>
            <Space h="md" />
                <Timeline color="yellow" active={-1} bulletSize={14}>
                <Timeline.Item title="API Call">
                    <Text color="dimmed" size="sm">Google Places API</Text>
                </Timeline.Item>
                <Timeline.Item title="Data Model">
                    <Text color="dimmed" size="sm">Eat & Restaurant Entity data schemas</Text>
                </Timeline.Item>
                <Timeline.Item title="Tech Flow">
                    <Text color="dimmed" size="sm">Tech flow for saving Eat Items</Text>
                </Timeline.Item>
                </Timeline>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                API Call
            </Title>
            <Text mt="md" fz="md">
                As mentioned throughout this section, almost all of the Eat experience is revolving around the <span className={classes.textHighlight}><a href='https://developers.google.com/maps/documentation/places/web-service/overview'>Google Places API</a></span>
                to capture a datenight. The application uses the <span className={classes.textHighlight}><a href='https://developers.google.com/maps/documentation/places/web-service/search-text'>Text Search</a></span>
                API to make all calls.  This is due to Text Search being able to query by Name, Descriptor, Type, and importantly, by location. So the Application constructs or builds a URL to send to the API.  
                A simple case statement is used to build the query string differently based on whether the user enables <b>My Location</b> or <b>Search</b>.  There is also a case statement to build a different query based on the <b>Load More</b> in the same function.
                See example code below:
            </Text>
            <Space h="sm" />
            <Space h="md" />
            <Tabs color={ld_theme.theme == "dark" ? "grape":"red"}variant="default" defaultValue="apicall">
            <Tabs.List grow>
                <Tabs.Tab value="apicall" icon={<IconSend size="0.8rem" />}>API Call</Tabs.Tab>
                <Tabs.Tab value="apiresponse" icon={<IconPackage size="0.8rem" />}>Response</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="apicall" pt="xs">
                <Prism noCopy language="jsx">{apiCall}</Prism>
            </Tabs.Panel>
            <Tabs.Panel value="apiresponse" pt="xs">
                <Prism noCopy language="jsx">{apiResponse}</Prism>
            </Tabs.Panel>
            </Tabs>
            <Space h="md" />
            <Text mt="md" fz="md">
              The Results array is parsed by the Application in the Search Results screen for the user to interact and scroll through in the UI.  The results attribute definitions can be found alongside the Developer documentation <span className={classes.textHighlight}><a href='https://developers.google.com/maps/documentation/places/web-service/search-text'>here</a></span>  
                <Space h="md" />
                The table below goes through each returned attribute from the API and 
                documents its relationship to where it's used in the UI of the iOS App
            </Text>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md" >
            <thead>
                <tr>
                    <th colSpan="3">Google Place API</th>
                </tr>
                <tr>
                    <th colSpan="1" style={{"background": ld_theme.theme == "dark" ? "#AE3EC9" : "#FA5252", "color": "white"}}>Results</th>
                    <th colSpan="2" style={{"background": ld_theme.theme == "dark" ? "#AE3EC9" : "#FA5252", "color": "white"}}>iOS App</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>attr</th>
                    <th>search</th>
                    <th>detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>business_status</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>formatted_address</td>
                    <td>{addCheck()}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>geometry</td>
                    <td></td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>icon</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>icon_background_color</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>icon_mask_base_uri</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>name</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>opening_hours</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>photos</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>place_id</td>
                    <td>{addCheck()}</td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>plus_code</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>price_level</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>rating</td>
                    <td></td>
                    <td>{addCheck()}</td>
                </tr>
                <tr>
                    <td>reference</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>types</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>user_ratings_total</td>
                    <td></td>
                    <td>{addCheck()}</td>
                </tr>
            </tbody>
            </Table>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Data Model
            </Title>
            <Text mt="md" fz="md">
                Much like <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/watchitems')}>Watch Items</a></span> Eat Items have a very simple data model.  
                The goal of the Eat entity is to log the experience, so the DynamoDB Item will not contain the full set of reference data attributes of the Restaurant itself - all the= information
                tucked away in the Restaurant Cards on the <span className={classes.textHighlight}><a onClick={()=> navigate('/')}>Dashboard</a></span> - more on this in a minute.  
                <Space h="sm" />
                The Eat Item just needs to save a few reference pieces from the Google API Response when saving an item to log the experience.  
                Since the Eat Items are user-specific, they have an access pattern by User 
                and Eat Date created, represented by a <span className={classes.textHighlight}><a href='https://www.npmjs.com/package/ksuid'>KSUID</a></span>.
                As mentioned, or you will see mentioned, other sections of the application, KSUIDs are great for creating unique IDs that have an embedded Date within it.  This allows the system to take
                advantage of DynamoDB to use this as its sort key. 
                The Global Secondary Index (GSI) on create will have the gsi-pk labeled with <b>FEED</b> and the gsi-sk with a readable date. 
                This will be what the weekly data feed queries to know what data to pick up on its next run. 
                <Space h="sm" />
                Below is a summary table for the Eat Entity and example records:
            </Text>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <thead>
                <tr>
                    <th colSpan="2">Primary Key</th>
                    <th colSpan="2">Secondary Index</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>pk</th>
                    <th>sk</th>
                    <th>gsi1-pk</th>
                    <th>gsi1-sk</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>USER#[user_id]</td>
                    <td>EAT#[ksuid]</td>
                    <td>FEED</td>
                    <td>[created_date]</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'}horizontalSpacing="md">
            <caption>Item Attributes</caption>
            <thead>
                <tr>
                    <th>attribute</th>
                    <th>description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>pk</td>
                    <td rowSpan="2">Primary key</td>
                </tr>
                <tr>
                    <td>sk</td>
                </tr>
                <tr>
                    <td>createdAt</td>
                    <td>Date experience was generated (ISO format)</td>
                </tr>
                <tr>
                    <td>expiresAt</td>
                    <td>Date the record will be removed (readable TTL)</td>
                </tr>
                <tr>
                    <td>gsi1pk</td>
                    <td rowSpan="2">Global secondary key</td>
                </tr>
                <tr>
                    <td>gsi1sk</td>
                </tr>
                <tr>
                    <td>house_id</td>
                    <td>Household id</td>
                </tr>
                <tr>
                    <td>id</td>
                    <td>Eat id</td>
                </tr>
                <tr>
                    <td>restaurant_id</td>
                    <td>API Response.[place_id]</td>
                </tr>
                <tr>
                    <td>restaurant_name</td>
                    <td>API Response.[name]</td>
                </tr>
                <tr>
                    <td>TTL</td>
                    <td>Time-to-Live (milliseconds) remaining </td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>Type of Experience</td>
                </tr>
                <tr>
                    <td>user</td>
                    <td>User who saved the experience</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Prism language="jsx">{eatItem}</Prism>
            <Space h="sm" />
            <Text>If you look closely, there's some restaurant fields lacking that we used in the iOS App and on the Dashboard cards.
                Just like <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/watchitems')}>Watch Items</a></span> we use a separate entity for our reference information: <b>restaurants</b>. The reasoning
                for separating this bit of reference data is due to the 1:N relationship we have between a Restaurant and the number of times our household can eat at that restaurant, and also to keep track of when our household 
                experiences a new restaurant. Since this is a Reference Entity, Restaurants will need to be easily retrievable and hold all the necessary attributes to present our info in the Dashboard.  As a reference data item,
                it only needs a Primary Key of the <b>place_id</b> passed from the Eat experience. We create the Secondary Index to allow the Feed to pick up the new Restaurant and tag it appropriately, so it can render as a <Badge color="green">First Eat</Badge> for the Household in the Dashboard. 
                The Tech Flow section below will walk through the details of how Restaurants are created from an Eat experience:   
            </Text>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <thead>
                <tr>
                    <th colSpan="2">Primary Key</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>pk</th>
                    <th>sk</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>REST#[place_id]</td>
                    <td>REST#[place_id]</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Table fontSize={isMobile ? "xs" : 'md'} horizontalSpacing="md">
            <caption>Item Attributes</caption>
            <thead>
                <tr>
                    <th>attribute</th>
                    <th>description</th>
                </tr>
            </thead>
            
            <tbody>
                <tr>
                    <td>pk</td>
                    <td rowSpan="2">primary key</td>
                </tr>
                <tr>
                    <td>sk</td>
                </tr>
                <tr>
                    <td>confirmed</td>
                    <td>Boolean Flag for successful Update from Step Function (below)</td>
                </tr>
                <tr>
                    <td>createdAt</td>
                    <td>Date the record was created</td>
                </tr>
                <tr>
                    <td>editorial_summary</td>
                    <td>Short summary provided by API</td>
                </tr>
                <tr>
                    <td>formatted_address</td>
                    <td>Restaurant address provided by API</td>
                </tr>
                <tr>
                    <td>formatted_phone_number</td>
                    <td>Restaurant phone number provided by API</td>
                </tr>
                <tr>
                    <td>gsi1pk</td>
                    <td rowSpan="2">global secondary key</td>
                </tr>
                <tr>
                    <td>gsi1sk</td>
                </tr>
                <tr>
                    <td>image_path</td>
                    <td>S3 image identifier</td>
                </tr>
                <tr>
                    <td>lat</td>
                    <td>Restaurant latitude coordinate provided by API</td>
                </tr>
                <tr>
                    <td>lng</td>
                    <td>Restaurant longitude coordinate provided by API</td>
                </tr>
                <tr>
                    <td>name</td>
                    <td>Restaurant name provided by API</td>
                </tr>
                <tr>
                    <td>price_level</td>
                    <td>Restaurant price level provided by API</td>
                </tr>
                <tr>
                    <td>rating</td>
                    <td>Restaurant rating provided by API</td>
                </tr>
                <tr>
                    <td>restaurant_id</td>
                    <td>Restaurant place_id provided by API</td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>Type of Reference Data</td>
                </tr>
                <tr>
                    <td>user_ratings_total</td>
                    <td>Restaurant number of ratings provided by API</td>
                </tr>
                <tr>
                    <td>user_ratings_total</td>
                    <td>Restaurant number of ratings provided by API</td>
                </tr>
                <tr>
                    <td>website</td>
                    <td>Restaurant website url provided by API</td>
                </tr>
            </tbody>
            </Table>
            <Space h="sm" />
            <Prism language="jsx">{restItem}</Prism>
            <Space h="md" />
            <Title className={classes.thirdHeading}>
                Tech Flow
            </Title>
            <Text mt="md" fz="md">
                The Tech Flow for Eat Items is pretty fun. I intentionally made the flow to be excessive to experiment & learn more of AWS services.  As mentioned above, the approach to Eat items follows  
                <span className={classes.textHighlight}><a onClick={()=> navigate('/docs/iosapp/watchitems')}>Watch Items</a></span> pretty closely.  Besides the obvious data-model differences, the two entities follow the same general round-trip-pattern:
                <Space h="sm" />
                <List>
                    <List.Item>Save Eat Experience</List.Item>
                    <List.Item>Determine if Restaurant ID exists</List.Item>
                    <List withPadding listStyleType="disc">
                            <List.Item>If Exists - Generate Reviews for Users</List.Item>
                            <List.Item>If Doesn't Exist - Kick off Step Function</List.Item>
                            <List withPadding listStyleType="circle">
                                <List.Item>Call API - Get Restaurant Data</List.Item>
                                <List.Item>Generate Map Image using coordinates</List.Item> 
                                <List.Item>Confirm data is saved in both S3 and DynamoDB</List.Item>
                                <List.Item>Generate Reviews for Users</List.Item>
                                <List.Item>If Error, set <b>confirmed == false</b></List.Item>
                            </List> 
                    </List>
                </List>
                <Space h="sm" />
                Is it overkill? Absolutely.  But what's the fun in just saving data?  One of the hallmarks of serverless, and using AWS, is that there's many ways to approach a solution.  Use the stepper below to follow the full tech cycle: 
            </Text>
            <Space h="sm" />
            <Group position="center"><ArchEats /></Group>
            <Space h="xl" />
            <Space h="xl" />
            {isMobile ?
          <div>  

          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp')}
          >
            <Group position="apart">
              <IconArrowNarrowLeft className={classes.navButton}/>
              <Text size="lg" className={classes.navButton}>
                Go back
              </Text>
            </Group>
            <Group position="right" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed">iOS App - @docs/iosapp</Text>
            </Group>
          </Paper>
          <Space h="lg" />
          <Paper withBorder p="md" radius="md" className={classes.navButton}
          component="a" onClick={()=> navigate('/docs/iosapp/watchitems')}
          >
            <Group position="apart">
              <Text size="lg" className={classes.navButton}>
                Up next
              </Text>
              <IconArrowNarrowRight className={classes.navButton}/>
            </Group>
 
            <Group align="flex-end" spacing="xs" shadow="xs">
              <Text fz="sm" color="dimmed" className={classes.value}>Watch Items - @iosapp/watchitems</Text>
            </Group>
          </Paper></div>:
         <Group grow>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp')}
         >
           <Group position="apart">
             <IconArrowNarrowLeft className={classes.navButton}/>
             <Text size="lg" className={classes.navButton}>
               Go back
             </Text>
           </Group>
           <Group position="right" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed">iOS App - @docs/iosapp</Text>
           </Group>
         </Paper>
         <Paper withBorder p="md" radius="md" className={classes.navButton}
         component="a" onClick={()=> navigate('/docs/iosapp/watchitems')}
         >
           <Group position="apart">
             <Text size="lg" className={classes.navButton}>
               Up next
             </Text>
             <IconArrowNarrowRight className={classes.navButton}/>
           </Group>

           <Group align="flex-end" spacing="xs" shadow="xs">
             <Text fz="sm" color="dimmed" className={classes.value}>Watch Items - @iosapp/watchitems</Text>
           </Group>
         </Paper>
         </Group>
          }
        </Container>
    </div>
  );
}
const mapStateToProps = state => {
    return { ld_theme: state.ld_theme, zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(Eats)