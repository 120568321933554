module.exports = {
    getUser: function () {
        const user = localStorage.getItem('user');
        if (user === 'undefined' || !user ){
            return null;
        } else {
            return JSON.parse(user);
        }
    },
    getToken: function() {
        return localStorage.getItem('token');
    },

    getFollowers: function() {
        const followers = localStorage.getItem('followers');
        if (followers === 'undefined' || !followers ){
            return null;
        } else {
            return JSON.parse(followers);
        }
    },

    setUserSession: function (user, token, followers) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        localStorage.setItem('followers', JSON.stringify(followers));
    },

    resetUserSession: function () {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('followers');
    }
}