import { connect } from 'react-redux'
import React from 'react';
import { rem, createStyles, keyframes } from '@mantine/core';

import SvgStepper from './svgStepper';

const darkTheme = '#FCC419';
const lightTheme = '#228BE6';



const useStyles = createStyles((theme) => ({
  highlight: {
    position: 'relative',
    color: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).color : theme.fn.variant({ variant: 'light', color: 'blue' }).color,
    backgroundColor: theme.colorScheme === 'dark' ? theme.fn.variant({ variant: 'light', color: 'yellow' }).background : theme.fn.variant({ variant: 'light', color: 'blue' }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(2)} ${rem(6)}`,
  },
    second : {
      '#laptop_screen':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#laptop_base':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#laptop_to_dashapi':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dash_api_stroke':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dash_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
      '#dash_api_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    },
    third : {
      '#laptop_screen':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#laptop_base':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#laptop_to_dashapi':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dashapi_to_dash':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dash_to_gold':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dash_api_stroke':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dashboard_path':{fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#gold_layer_1_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#gold_layer_2_path':{stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
      '#dashboard_animation':{ stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme,},
      '#dashboard_circle': {stroke: theme.colorScheme === 'dark' ? darkTheme : lightTheme, fill: theme.colorScheme === 'dark' ? darkTheme : lightTheme},
    }
}))

function ArchDatafeed() {
const { classes, cx, theme } = useStyles();

const imageTitle = "Frontend Gold Data Get"
const steps =[
{   step: "first", 
    viewbox:"0 0 250 300",
    breakdown: [], 
},
{
step: "second", 
viewbox:'30 80 230 230', 
desc: "User access the root or index site of the Frontend.  The App will make a request to the API upon landing on the index page to retrieve house data stored in Gold",
breakdown:[
{
    divider:'App Request',
    system: 'React App',
    desc:'Application sends a request to the API Endpoint with its application-specific API Token in the request header:',
    external_packages: [{name: 'Axios', url: 'https://www.npmjs.com/package/axios'}],
    code: `{
  url: 'stev0b-react-app-api-url/dashboard',
  method: 'get',
  headers: {
    x-api-key: 'TXkOp3J1SbuadZqudDXT11CsWNa8xi'
  }
}`,
},
    {
        divider: 'Evaluate Request',
        system: 'API Gateway',
        desc: 'The API Gateway evaluates the request header url and ensures the path is a valid endpoint. As an added layer of security, the x-api-key is validated as only the application should have that token.',
        errors: [{type:'Invalid URL or Path', code: '403', message: 'Unauthorized'}, {type:'Invalid API Key', code: '403', message: 'Forbidden'}],
        on_success: {label: 'Payload passes to React App lambda as an event for further processing', nav: '/docs'}
    }
]
},
{step: "third", 
viewbox:'30 80 230 230', 
desc: "The Sessions Lambda will determine which function to execute based on the validated payload received from the API Gateway and process the event in Gold.",
breakdown:[
{
    divider:'API Event',
    system: 'API Gateway',
    desc:'API Gateway passes the payload to the React App Lambda for further processing:',
    code: `{
  url: 'stev0b-react-app-api-url/dashboard',
  method: 'get',
  headers: {
    x-api-key: 'TXkOp3J1SbuadZqudDXT11CsWNa8xi'
  }
}`,
},
    {
        divider: 'Determine Function to Execute',
        system: 'React App Lambda',
        desc: 'The React App Lambda contains many stored functions and will use the API Gateway event to determine which function to execute based on the payload received',
        code: `const dashboardPath = '/dashboard';

exports.handler = async (event) => {
  let response;
  switch(true) {
    ...
    case event.httpMethod === 'GET' && event.path === dashboardPath: 
      response = await getDashboardData(event);
      break;
    
    default:
      response = { statusCode: 404, body: JSON.stringify(event)};
  }
  return response
}`,
        errors: [{type:'Invalid Path or Undetermined Function', code: '404', message: 'Unauthorized'}],
    },
    {
        divider: 'Update Review - Validate Schema',
        system: 'Sessions Lambda',
        desc: `The Update Review function will also confirm the schema in the event payload is also valid.  If the function returns an error, the entire error array will be sent in the response (i.e. more than one field could have an error). Sessions uses the external library Yup for schema validation`,
        code: `const handler = async event => {
    
  let response;
  try {
    const s3data = await s3.getObject({
      Bucket : "stev0b-gold-dev-us-east-1",
      Key : 'stev0b-houses/stev0b_house.json',
    }).promise()
    
    const film_data = await s3.getObject({
      Bucket : "stev0b-gold-dev-us-east-1",
      Key : 'stev0b-films/films.json',
    }).promise()
    
    const restaurant_data = await s3.getObject({
      Bucket : "stev0b-gold-dev-us-east-1",
      Key : 'stev0b-restaurants/restaurants.json',
    }).promise()
    
    if (s3data.ContentLength == 0) {
      return response = buildResponse(404, "No File Found")
    }
    
  const data = JSON.parse(s3data.Body)
  const films = JSON.parse(film_data.Body)
  const restaurants = JSON.parse(restaurant_data.Body)

  const current_week = Math.max(...data.map(o => o.week_number))

  .... /* 
  aggregation functions
  .... */

  const payload = {
    current_week: current_week,
    current_week_totals: [
      {
        title: "Movies",
        icon: "movie",
        value: counts_function(current_week_data, "WATCH", "MOVIE"),
        last_week: counts_function(prev_week_data, "WATCH","MOVIE")
      },
      {
        title: "Shows",
        icon: "tv",
        value: counts_function(current_week_data, "WATCH", "TV"),
        last_week: counts_function(prev_week_data, "WATCH","TV")
      },
      {
        title: "Restaurants",
        icon: "eat",
        value: counts_function(current_week_data, "EAT"),
        last_week: counts_function(prev_week_data, "EAT")
      },
      {
        title: "Listens",
        icon: "music",
        value: sum_function(cw_listens),
        last_week: sum_function(pw_listens)
      }
    ],
    prev_week: (current_week - 1),
    featured_music_steve: {title: steve_top_music.title, image: steve_top_music.image, count: steve_top_music.count, preview_url: steve_top_music.preview_url, total: music_counter(music_aggregated, "stev0b@stev0b.com")},
    steve_top_music:steve_music, 
    amanda_top_music: amanda_music,
    featured_music_amanda: {title: amanda_top_music.title, image: amanda_top_music.image, count: amanda_top_music.count, preview_url: amanda_top_music.preview_url, total: music_counter(music_aggregated, "amanda@stev0b.com")},
    rest_items: rest_items,
    film_items: film_items,
    return response = buildResponse(200, {
      data: payload
  });            
  } catch(error) {
    return response = buildResponse(403, {
      error: error
    })
  }
}`,
  errors: [{type:'Function Error', code: '403', message: 'Unauthorized'},{type:'File Not Found', code: '404', message: 'Unauthorized'},{type:'Server Error', code: '500', message: 'Unauthorized'}],
  on_success: {label: 'React App Lambda returns payload to the client', nav: '/docs'}
}],
}]
  
   return (
    <div>
        <SvgStepper imageTitle = {imageTitle} steps = {steps} classes={classes} theme={theme.colorScheme === 'dark' ? theme.colors.yellow[6] : theme.colors.blue[6]}/>
    </div>
  );
}
const mapStateToProps = state => {
    return { zoom_animation: state.zoom_animation}
}
export default connect(mapStateToProps)(ArchDatafeed)