import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconInfoSquare, IconThumbUp, IconThumbDown } from '@tabler/icons-react';
import {
  Card,
  Image,
  Text,
  ActionIcon,
  Badge,
  Group,
  Tooltip,
  Avatar,
  createStyles,
  rem,
  Modal,
  Table,
  Divider,
  Box,
  Button,
  Anchor
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  rating: {
    position: 'absolute',
    top: theme.spacing.xs,
    right: rem(12),
    pointerEvents: 'none',
  },
  highlight: {
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color
  },
  mobilePadding: {
    marginBottom: theme.spacing.lg
  },

  title: {
    display: 'block',
    marginTop: theme.spacing.md,
    marginBottom: rem(5),
  },

  overview: {
    marginBottom: theme.spacing.lg,
    align:"flex-end",
    direction:"row",
  },

  action: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    }),
  },

  footer: {
    marginTop: theme.spacing.md,
  },
}));


const RestCard =({
  data,
  badge,
  className,
  ...others
}) => {
  const { classes, cx, theme } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 50em)");
  const linkProps = { target: '_blank', rel: 'noopener noreferrer' };

  function addHighlight(e) {
    return(<Text span className={classes.highlight} inherit>{e}</Text>)
  }

  function price_level(e){
    if (e == 0) {
      return(<Text inherit>Free</Text>)
    }

    if (e == 1) {
      return(<Text inherit>Inexpensive</Text>)
    }
    if (e == 2) {
      return(<Text inherit>Moderate</Text>)
    }
    if (e == 3) {
      return(<Text inherit>Expensive</Text>)
    }
    if (e == 4) {
      return(<Text inherit>Very Expensive</Text>)
    }
    if (e == 99) {
      return null
    }
  }

  function editorial_description () {
    if (data.editorial_summary == "NONE" && data.rating == 99) {
      return `${data.name} recently opened or had a soft opening recently.  There are no reviews or user ratings for this Restaurant as it is brand new to this area.`
    }
    if (data.editorial_summary == "NONE" && data.rating == 99) {
      return `${data.name} is a still growing business in the area.  Its establishment has a Google rating of ${data.rating} and over ${data.user_ratings_total} reviews`
    }

    if (data.editorial_summary != "NONE" && data.rating !=99) {
      return `${data.editorial_summary} ${data.name} restaurant has a Google rating of ${data.rating} and over ${data.user_ratings_total} reviews`
    }
  }

  return (
    <Card withBorder radius="md" className={cx(classes.card, className)} {...others}>
      <Card.Section>
        <a {...linkProps}>
          <Image src={data.url} height={180} withPlaceholder/>
        </a>
      </Card.Section>

      <Text className={classes.title} fw={500} component="a" {...linkProps}>
        {data.name}
      </Text>

      <Text fz="sm" color="dimmed" lineClamp={3}>
          {editorial_description()}
      </Text>

      <Group position="apart"  className={classes.footer}>
        <Group>
            <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="md">
            {data.steven_b.count == 0 ? null :
                <Tooltip label="Steven B" withArrow>
                    <Avatar src={null} alt="Steven B" color={data.steven_b.count == data.steven_b.sum ? "green": "red"} size="md" radius="md">SB</Avatar>
                </Tooltip>}
                {data.amanda_a.count == 0 ? null :
                <Tooltip label="Amanda A" withArrow>
                    <Avatar src={null} alt={`Amanda A`}  color={data.amanda_a.count == data.amanda_a.sum ? "green": "red"} size="md" radius="md">AA</Avatar>
                </Tooltip>}
                {/* <Avatar radius="xl">+5</Avatar> */}
            </Avatar.Group>
            </Tooltip.Group>
        </Group>
        <Group spacing={8} mr={0}>
          <ActionIcon className={classes.action} onClick={open} size="lg">
            <IconInfoSquare size="1.1rem" />
          </ActionIcon>
        </Group>
      </Group>
      <Modal
        opened={opened}
        onClose={close}
        title={addHighlight(data.name)}
        fullScreen={isMobile}
        className={classes.MobilePadding}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <Divider
          my="xs"
          variant="dashed"
          labelPosition="center"
          label={
            <>
              <Box ml={5}>Reviews</Box>
            </>
          }
        />
        <Table horizontalSpacing="sm" className={classes.table} withBorder captionSide="top">
          <tbody>
          {data.amanda_a.count == 0 ? null :
            <tr>
              <td>Amanda A</td>
              <td style={{textAlign: 'left'}}>{data.amanda_a.count == data.amanda_a.sum ? 
                <IconThumbUp color="green" />: <IconThumbDown color="red" />}
              </td>
            </tr>}
            {data.steven_b.count == 0 ? null :
            <tr>
              <td>Steven B</td>
              <td style={{textAlign: 'left'}}>{data.steven_b.count == data.steven_b.sum ? 
                <IconThumbUp color="green" />: <IconThumbDown color="red" />}
              </td>
            </tr>}
            <tr>
              <td>House Status</td>
              <td style={{textAlign: 'left'}}>{badge == 1 ? <Badge color="green">First Eat</Badge>: <Badge color="yellow">{badge} Eats</Badge>}</td>
            </tr>
          </tbody>
        </Table>

        <Divider
          my="xs"
          variant="dashed"
          labelPosition="center"
          label={
            <>
              <Box ml={5}>About</Box>
            </>
          }
        />
        <Text>{data.editorial_summary == 'NONE' ? null: data.editorial_summary}</Text>
        <br />
        <Table className={classes.table} withBorder captionSide="bottom">
          <caption>All data sourced from 
            <Anchor href={`${data.reference_url}`}> {addHighlight('Google')}</Anchor>
          </caption>
          <tbody>
          {data.rating == 99 ? null :
            <tr>
              <td>Rating</td>
              <td>{`${data.rating} out of 5`}</td>
            </tr>}
            {data.user_ratings_total == 9999 ? null :
            <tr>
              <td>Reviews</td>
              <td>{data.user_ratings_total}</td>
            </tr>}
            {data.price_level == 99 ? null :
            <tr>
              <td>Price Level</td>
              <td>{price_level(data.price_level)}</td>
            </tr>}
          </tbody>
        </Table>
        <br />
        <Image src={data.url} width='100%' position='center' withPlaceholder/>
        <br />
        <Group className={classes.MobilePadding} position="right" grow={useMediaQuery('(min-width: 48em)') ? false :true} > 
          <Button onClick={close} variant="light" radius="md" size="md">
            Close
          </Button>
        </Group>
        {isMobile? <div><br />
        <br />
        <br /></div>: null}
      </Modal>
    </Card>
  );
}

export default RestCard